import { AppLayout, Box, Header, Link, SpaceBetween } from "@amzn/awsui-components-react";
import { FormattedMessage, useIntl } from "react-intl";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { HOME_ROUTE } from "../../common/routes";
import { useAuth } from "../../hooks/useAuth";
import "../styles.scss";

function AccessDeniedContent(): JSX.Element {
  const intl = useIntl();
  const { authErrorMessageId } = useAuth();

  return (
    <Box className="error-center">
      <SpaceBetween size="m">
        <Box>
          <img src="/public/error-page.svg" alt={intl.formatMessage({ id: "errorPage.img.alt" })} />
          <Header variant="h1"> {intl.formatMessage({ id: "errorPage.accessDenied.header" })} </Header>
          {authErrorMessageId !== "errorPage.accessDenied.413Error" && (
            <p>{intl.formatMessage({ id: "errorPage.accessDenied.message" })}</p>
          )}
          {authErrorMessageId && (
            <p>
              {intl.formatMessage({ id: "errorPage.accessDenied.errorMessageLeadIn" })}
              <br />
              {intl.formatMessage({ id: authErrorMessageId })}
            </p>
          )}
        </Box>
        <br />
        <Box>
          <p>
            <FormattedMessage
              id="errorPage.startOver"
              values={{
                return_link: (chunks) => (
                  <Link
                    href={HOME_ROUTE}
                    onClick={(event: any) => {
                      event.preventDefault();
                    }}
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </p>
        </Box>
      </SpaceBetween>
    </Box>
  );
}

export function AccessDenied(): JSX.Element {
  const intl = useIntl();
  return (
    <AppLayout
      content={<AccessDeniedContent />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      toolsHide
      navigationHide
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
    />
  );
}
