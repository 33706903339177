import { Breadcrumbs, Navigation } from "../../components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { AppLayout } from "@amzn/awsui-components-react";
import { Users } from "../components";
import { superUserRoles, internalEmployeeRoles, UserPage } from "../constants";
import { INTERNAL_USER_ROUTE } from "../../common/routes";
import { useLocation } from "react-router-dom";
import { useGetCurrentUserLazy } from "../api/getCurrentUser";
import { useEffect } from "react";
import { useIntl } from "react-intl";

export const UserEdit = (): JSX.Element => {
  const { state } = useLocation();
  const intl = useIntl();

  const [getCurrentUser, { data, error }] = useGetCurrentUserLazy();

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <AppLayout
      content={
        <Users
          roles={data ? (data.currentUser.userRole === "SUPER_USER" ? superUserRoles : internalEmployeeRoles) : []}
          cancel={INTERNAL_USER_ROUTE}
          data={state}
          userPageType={UserPage.EDIT}
          toggleDisabled={false}
        />
      }
      breadcrumbs={<Breadcrumbs />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      navigation={<Navigation />}
      toolsHide
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
    />
  );
};
