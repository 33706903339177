import { IntlProvider } from "react-intl";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import * as enUsMessages from "../i18n/messages/en-US.json";
import { defaultRichTextElements } from "./common/i18n";
import { getLearningConsoleRoutes } from "./common/routes";
import { Footer, Header, LoadingPage } from "./components";
import CookieConsentManager from "./cookieManagement/CookieConsentManager";
import { useAuth } from "./hooks/useAuth";
import { AccessDenied } from "./pages";

function RemoveTrailingSlash(): JSX.Element | null {
  const { pathname } = useLocation();

  if (pathname.match("/.*/$")) {
    return <Navigate replace to={pathname.slice(0, -1)} />;
  }

  return null;
}

const App = (): JSX.Element => {
  const locale = "en-US";
  const messages = { "en-US": enUsMessages };
  const cookieConsentManager: CookieConsentManager = new CookieConsentManager();
  cookieConsentManager.checkForCookieConsent();
  const { user, isAuthErrorFound } = useAuth();

  // Loading page flashed in between signIn screen being rendered - purely cosmetic and results in a less jarring UX experience
  if (!user.email && !isAuthErrorFound) {
    return <LoadingPage text="Loading..." />;
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]} defaultRichTextElements={defaultRichTextElements}>
      <RemoveTrailingSlash />
      <Header />
      {isAuthErrorFound ? (
        <AccessDenied />
      ) : (
        <Routes>
          {...getLearningConsoleRoutes().map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      )}
      <Footer />
    </IntlProvider>
  );
};

export default App;
