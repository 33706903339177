import { FlagNames, isFeatureEnabled, Stages } from "@amzn/aws-tc-commerce-feature-flags";
import { AppLayout, ContentLayout, Flashbar, FlashbarProps, SpaceBetween, Tabs } from "@amzn/awsui-components-react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { ApplicationUser } from "../../auth/ApplicationUser";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { getNodeEnvironment } from "../../common/nodeEnvironment";
import { showError } from "../../common/utilityFunctions";
import { AppHeader, Breadcrumbs, LoadingPage, Navigation } from "../../components";
import { useAuth } from "../../hooks/useAuth";
import { OrdersListTable } from "../../order/components/OrdersListTable";
import { BadRequest } from "../../pages";
import { LearningAccountUsersTable } from "../../user/components/learningAccountUsersTable/LearningAccountUsersTable";
import { LearningAccountInfoCard } from "../components/LearningAccountInfoCard";
import { useLearningAccountInfoCard } from "../components/useLearningAccountInfoCard";
import { rolesAuthorizedToViewLAOrders, rolesAuthorizedToViewLAUsers } from "../constants";

const StyledTabs = styled(Tabs)`
  padding-top: 18px;
`;

const StyledFlashbar = styled(Flashbar)`
  padding-top: 8px;
`;

const isAuthorizedToViewOrders = (user: ApplicationUser) => {
  if (user.role && rolesAuthorizedToViewLAOrders.includes(user.role)) {
    return true;
  }
  return false;
};

const isAuthorizedToViewUsers = (user: ApplicationUser, stage: Stages) => {
  if (
    isFeatureEnabled(FlagNames.BULK_USER_OPERATION, stage) &&
    user.role &&
    rolesAuthorizedToViewLAUsers.includes(user.role)
  ) {
    return true;
  }
  return false;
};

function LearningAccountDetailContent(): JSX.Element {
  const { learningAccountDetailData } = useLearningAccountInfoCard();
  const intl = useIntl();
  const STAGE = getNodeEnvironment();
  const { user } = useAuth();
  const allTabs = [];
  if (isAuthorizedToViewOrders(user)) {
    allTabs.push({
      label: intl.formatMessage({ id: "learningAccountsDetailPage.tabs.orders" }),
      id: "2",
      content: <OrdersListTable />,
    });
  }
  if (isAuthorizedToViewUsers(user, STAGE as Stages)) {
    allTabs.push({
      label: intl.formatMessage({ id: "learningAccountsDetailPage.tabs.users" }),
      id: "1",
      content: <LearningAccountUsersTable />,
    });
  }

  return (
    <ContentLayout
      header={
        <AppHeader
          title={
            learningAccountDetailData && learningAccountDetailData.learningAccountById.name
              ? learningAccountDetailData.learningAccountById.name
              : ""
          }
        />
      }
    >
      <SpaceBetween direction="vertical" size="xxl">
        <LearningAccountInfoCard />
        <StyledTabs data-testid="learningaccount-detail-tabs" tabs={allTabs} />
      </SpaceBetween>
    </ContentLayout>
  );
}

export function LearningAccountDetail(): JSX.Element {
  const intl = useIntl();
  const { learningAccountDetailError, learningAccountDetailLoading } = useLearningAccountInfoCard();
  const { state } = useLocation();
  const [items, setItems] = useState(
    state && state.successMessage
      ? ([
          {
            type: "success",
            content: state.successMessage,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setItems([]),
            statusIconAriaLabel: "success",
          },
        ] as FlashbarProps.MessageDefinition[])
      : []
  );
  return showError(learningAccountDetailError) ? (
    <BadRequest />
  ) : (
    <AppLayout
      content={
        learningAccountDetailLoading ? (
          <LoadingPage text={intl.formatMessage({ id: "loadingPage.loadingText.loadingDetails" })} />
        ) : (
          <LearningAccountDetailContent />
        )
      }
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      breadcrumbs={<Breadcrumbs />}
      navigation={<Navigation />}
      notifications={state && <StyledFlashbar data-testid="success-flashbar" items={items} />}
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
      toolsHide
    />
  );
}
