import { gql, useQuery } from "@apollo/client";
import { useAuth } from "../../hooks/useAuth";
import { RoleType } from "../../user/types";
import { clientIntegrationIsEnabled } from "../../common/featureFlags";

export const GET_ORDER_BY_ID = gql`
  query GetOrderById($id: ID!) {
    orderById(id: $id) {
      id
      items {
        id
        productTitle
        quantity
        ssoEnabled
        subTotal
        unitPrice
        classStartDate
        classEndDate
        productModality
        subscriptionType
        productOnboardingDetails {
          authenticationMethod
        }
        contractingRequestCreator {
          email
          firstName
          lastName
          phone
        }
      }
      learningAccount {
        id
        name
      }
      fulfillment {
        fulfillmentItems {
          digitalFulfillmentDetails {
            emailDomains
            identityProvider
            learningAdmins {
              emailAddress
              firstName
              lastName
            }
            organizationName
            ssoEnabled
            ssoContact {
              emailAddress
              firstName
              lastName
            }
          }
          id
          resultData {
            key
            value
          }
          status
        }
      }
      opportunityId
      orderCanceledTicketId
      status
      typeOfContract
      billingAwsAccountId
    }
  }
`;

export const GET_ORDER_BY_ID_CLIENT_INTEGRATION = gql`
  query GetOrderById($id: ID!) {
    orderById(id: $id) {
      id
      items {
        id
        productTitle
        quantity
        ssoEnabled
        subTotal
        unitPrice
        classStartDate
        classEndDate
        productModality
        subscriptionType
        productOnboardingDetails {
          authenticationMethod
        }
        contractingRequestCreator {
          email
          firstName
          lastName
          phone
        }
        metadata {
          key
          value
        }
        nmbsBillingDetails {
          agreementId
          billingStatus
          billingStatusUpdatedAt
          billId
          billingErrorMessage
          billingConsoleUILink
          invoiceId
        }
      }
      learningAccount {
        id
        name
      }
      fulfillment {
        fulfillmentItems {
          digitalFulfillmentDetails {
            emailDomains
            identityProvider
            learningAdmins {
              emailAddress
              firstName
              lastName
            }
            organizationName
            ssoEnabled
            ssoContact {
              emailAddress
              firstName
              lastName
            }
          }
          id
          resultData {
            key
            value
          }
          status
        }
      }
      opportunityId
      orderCanceledTicketId
      status
      typeOfContract
      billingAwsAccountId
    }
  }
`;

export const useGetOrderById = (orderId: string) => {
  const { user } = useAuth();
  const query = clientIntegrationIsEnabled() ? GET_ORDER_BY_ID_CLIENT_INTEGRATION : GET_ORDER_BY_ID;
  return useQuery(query, {
    variables: { id: orderId },
    notifyOnNetworkStatusChange: true,
    skip: user.role === RoleType.POST_AUTH_PRE_VIAS,
  });
};
