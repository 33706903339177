import { useLazyQuery } from "@apollo/client";
import {
  OrganizationBySfdcAccountIdDocument,
  OrganizationBySfdcAccountIdQuery,
  OrganizationBySfdcAccountIdQueryVariables,
} from "../../../__generated__/supergraph";

export const useOrganizationBySfdcAccountIdQuery = ({ sfdcAccountId }: OrganizationBySfdcAccountIdQueryVariables) => {
  return useLazyQuery<OrganizationBySfdcAccountIdQuery>(OrganizationBySfdcAccountIdDocument, {
    variables: { sfdcAccountId },
  });
};
