import { Box, ColumnLayout, Container, Header, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Order } from "../types";

interface OrderProps {
  order: Order;
}

const ValueWithLabel = (props: { label: any; children: any }) => (
  <div style={{ marginTop: "10px" }}>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

const myComponentStyle = {
  margin: "10px",
};

export function DetailsTable(props: OrderProps): JSX.Element {
  const intl = useIntl();
  return (
    <div>
      <Container
        header={
          <Header data-testid="order-details-table-header" variant="h2">
            {intl.formatMessage({ id: "orderDetailsComponent.header.orderDetails" })}
          </Header>
        }
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.customerName" })}>
              <div data-testid={"learningAccount-name"}>{props.order.learningAccount.name}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.orderId" })}>
              <div data-testid={"order-id"}>{props.order.id}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.salesforceOpportunityId" })}>
              <div data-testid={"opprotunity-id"}>{props.order.opportunityId}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.billingAwsAccountId" })}>
              <div data-testid={"aws-billing-accountId"}>
                {props.order.billingAwsAccountId ? props.order.billingAwsAccountId : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.typeOfContract" })}>
              <div data-testid={"typeOfContract"}>
                {props.order.typeOfContract ? props.order.typeOfContract : "NEW"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </div>
  );
}
