import { RoleType } from "../user/types";

export const FRONTEND_SELECTORS = {
  APP: "#app",
  HEADER: "#h",
  FOOTER: "#footer",
  DETAILS: '[data-testid="details"]',
};

export enum APPLICATION_URL {
  TEST = "http://localhost:3000",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DEVELOPMENT = "http://localhost:3000",
  BETA = "https://beta.onboard.skillbuilder.training.aws.dev",
  GAMMA = "https://onboard.skillbuilder.training.aws.dev",
  PROD = "https://onboard.skillbuilder.aws",
}

export const PRIVACY_PAGE_LINK = "https://aws.amazon.com/privacy";

export const TERMS_PAGE_LINK = "https://aws.amazon.com/terms";

//delay in milleseconds for debouncing
export const DEBOUNCE_DELAY = 800;

export const ALLOWED_ROLES = [
  RoleType.SUPER_USER,
  RoleType.INTERNAL_EMPLOYEE,
  RoleType.SERVICE,
  RoleType.POST_AUTH_PRE_VIAS,
];

export const ALLOWED_ROLES_BULK_OPERATION = [
  RoleType.SUPER_USER,
  RoleType.INTERNAL_EMPLOYEE,
  RoleType.LEARNING_ADMIN,
  RoleType.SERVICE,
  RoleType.POST_AUTH_PRE_VIAS,
  RoleType.LEARNING_ACCOUNT_IT_CONTACT,
];

/*
UserInfo card component is re-used between Internal and External users since there is only one field -
(Added by instead of Status) that is different.
This flag helps the component to identify and accordingly display the correct field based on - 
if the view has external or internal user info
*/
export const RENDER_USER_INFO_FIELDS_FOR_EXTERNAL_USERS = "EXTERNAL";

export const EMAIL_VERIFICATION_ERROR = "EmailAddress is not verified, verification required.";
