import { getNodeEnvironment, NodeEnvironment } from "./nodeEnvironment";
import { APPLICATION_URL } from "./constants";
import { ApolloError } from "@apollo/client";

// This function performs left-to-right functional-component composition. It allows HOCs to
// be composed declaratively. Similar to Ramda's pipe function: https://ramdajs.com/0.19.1/docs/#pipe
export const pipeHOCs =
  (...fn: any[]) =>
  (initialProps: (props: any) => JSX.Element) =>
    fn.reduce((composedHOC, currentHOC) => currentHOC(composedHOC), initialProps);

/**
 * Ignore the "No current user" network error that happens when the page is
 * reloaded or loaded for the first time. This helps us ignore the transient
 * error state of GraphQL's useQuery hook when it's paired with Amplify.
 *
 * @param error
 * @returns True when there is an error that we want to show the user
 */
export const showError = (error: ApolloError | undefined) => {
  // eslint-disable-next-line prettier/prettier
  return (
    error &&
    (error.graphQLErrors?.length ||
      error.clientErrors.length ||
      !(typeof error.networkError === "string" && /No current user/.test(error.networkError)))
  );
};

export const getApplicationURL = (): APPLICATION_URL => {
  const nodeEnvironment: NodeEnvironment = getNodeEnvironment();
  switch (nodeEnvironment) {
    case NodeEnvironment.BETA: {
      return APPLICATION_URL.BETA;
    }
    case NodeEnvironment.GAMMA: {
      return APPLICATION_URL.GAMMA;
    }
    case NodeEnvironment.PROD: {
      return APPLICATION_URL.PROD;
    }
    default: {
      return APPLICATION_URL.DEVELOPMENT;
    }
  }
};
