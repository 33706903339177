import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { useIntl } from "react-intl";
import { Box, Pagination } from "@amzn/awsui-components-react";
import { LEARNING_ACCOUNTS_ROUTE } from "../../common/routes";
import { useLearningAccountsTable } from "./useLearningAccountsTable";
import { AppHeader } from "../../components/appHeader/AppHeader";
import { TableNoMatchState } from "../../components/tableNoMatchState/TableNoMatchState";
import { Link } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import { DEBOUNCE_DELAY } from "../../common/constants";

export function LearningAccountsTable(): JSX.Element {
  const intl = useIntl();

  const {
    learningAccountsLoading,
    learningAccountsData,
    currentPageIndex,
    search,
    onFilterTextChange,
    onClearFilter,
    onPaginationChange,
    handleSearch,
    totalPages,
    pagination,
    totalCount,
  } = useLearningAccountsTable();

  type learningAccount = {
    id: string;
    name: string;
    awsAccountId: string;
  };

  useDebounce(
    () => {
      onFilterTextChange(search);
    },
    [search],
    DEBOUNCE_DELAY
  );

  return (
    <Table
      ariaLabels={{ tableLabel: "Learning Accounts" }}
      data-testid="table-id"
      loadingText={intl.formatMessage({ id: "learningAccountsTableComponent.loadingText" })}
      loading={learningAccountsLoading}
      items={learningAccountsData ? learningAccountsData.learningAccounts.nodes : []}
      wrapLines={false}
      resizableColumns={true}
      variant="full-page"
      stickyHeader={true}
      empty={
        search ? (
          <TableNoMatchState onClearFilter={onClearFilter} />
        ) : (
          <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              {intl.formatMessage({ id: "learningAccountsTableComponent.noLearningAccounts" })}
            </Box>
          </Box>
        )
      }
      columnDefinitions={[
        {
          id: "CompanyName",
          header: intl.formatMessage({ id: "learningAccountsTableComponent.header.companyName" }),
          cell: (item: learningAccount) => <Link to={`${LEARNING_ACCOUNTS_ROUTE}/${item.id}`}>{item.name}</Link>,
          width: 500,
          minWidth: 200,
        },
        {
          id: "AwsAccount",
          header: intl.formatMessage({ id: "learningAccountsTableComponent.header.awsAccount" }),
          cell: (item: learningAccount) => <div>{item.awsAccountId}</div>,
        },
      ]}
      filter={
        <TextFilter
          filteringText={search}
          onChange={(event: { detail: { filteringText: string } }) => handleSearch(event.detail.filteringText)}
          filteringPlaceholder={intl.formatMessage({ id: "learningAccountsTableComponent.filtering.placeholder" })}
          filteringClearAriaLabel={intl.formatMessage({
            id: "learningAccountsTableComponent.textFilter.clearButton.ariaLabel",
          })}
        />
      }
      header={
        <AppHeader title={intl.formatMessage({ id: "learningAccountsPage.header" })} counterValue={`${totalCount}`} />
      }
      pagination={
        <Pagination
          ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
          pagesCount={totalPages}
          currentPageIndex={currentPageIndex}
          disabled={false}
          onChange={(event: { detail: { currentPageIndex: any } }) => onPaginationChange(event.detail.currentPageIndex)}
          openEnd={pagination}
        />
      }
    />
  );
}
