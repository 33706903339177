import { RoleType } from "./types";

export enum Role {
  INTERNAL_EMPLOYEE = "Internal employee",
  SUPER_USER = "Super user",
  SERVICE = "Service",
  LEARNER = "Learner",
  LEARNING_ADMIN = "Learning administrator",
  ILT_INSTRUCTOR = "Ilt Instructor",
  TRAINING_OPERATIONS = "Training Operations",
}

export enum Status {
  ACTIVE = "Active",
  DEACTIVATED = "Deactivated",
}

export enum UserPage {
  CREATE = "CREATE",
  EDIT = "EDIT",
}

export const superUserRoles = [Role.INTERNAL_EMPLOYEE, Role.SUPER_USER];

export const internalEmployeeRoles = [Role.INTERNAL_EMPLOYEE];

export const EXTERNAL_EMPLOYEE_ROLES = [Role.LEARNER]; // add Learning Admin here in the future to support creating admin from the UI

export const validEmail = /^[\w\-\+\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const rolesAuthorizedForUserActions = [RoleType.LEARNING_ADMIN, RoleType.LEARNING_ACCOUNT_IT_CONTACT];

export const bulkUserFileUploadSizeLimit = 1500000;
