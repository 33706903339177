import { Box, ColumnLayout, Container, Header, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { useLearningAccountInfoCard } from "./useLearningAccountInfoCard";

export function LearningAccountInfoCard(): JSX.Element {
  const intl = useIntl();
  const { learningAccountDetailLoading, learningAccountDetailData } = useLearningAccountInfoCard();

  const ValueWithLabel = (props: { label: any; children: any }) => (
    <div style={{ marginTop: "10px" }}>
      <Box variant="awsui-key-label">{props.label}</Box>
      <div>{props.children}</div>
    </div>
  );

  const getValueIfExists = (property: string): string => {
    if (learningAccountDetailData) {
      return learningAccountDetailData.learningAccountById[property]
        ? learningAccountDetailData.learningAccountById[property]
        : "-";
    }
    return "-";
  };

  const getFormattedAddress = () => {
    if (learningAccountDetailData && learningAccountDetailData.learningAccountById.billingAddress) {
      const billingAddress = learningAccountDetailData.learningAccountById.billingAddress;
      const cityLine = billingAddress.stateOrProvince
        ? `${billingAddress.city}, ${billingAddress.stateOrProvince}, ${billingAddress.countryCode}`
        : `${billingAddress.city}, ${billingAddress.countryCode}`;
      if (learningAccountDetailData.learningAccountById.billingAddress.addressLineTwo) {
        return (
          <div data-testid={"shipto-address"}>
            {billingAddress.addressLineOne} <br />
            {billingAddress.addressLineTwo} <br />
            {cityLine} <br />
            {billingAddress.zipCode}
          </div>
        );
      } else {
        return (
          <div data-testid={"shipto-address"}>
            {billingAddress.addressLineOne} <br />
            {cityLine} <br />
            {billingAddress.zipCode}
          </div>
        );
      }
    }
    return <div data-testid={"shipto-address"}>-</div>;
  };

  return (
    <div data-testid={"learningaccount-info-card"}>
      <Container
        header={
          <Header variant="h2">{intl.formatMessage({ id: "learningAccountsDetailComponent.header.profile" })}</Header>
        }
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "learningAccountsDetailComponent.label.awsAccount" })}>
              <div data-testid={"aws-accountId"}>
                {!learningAccountDetailLoading && getValueIfExists("awsAccountId")}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "learningAccountsDetailComponent.label.phoneNumber" })}>
              <div data-testid={"phone-number"}>{!learningAccountDetailLoading && getValueIfExists("phoneNumber")}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "learningAccountsDetailComponent.label.billToAddress" })}>
              {!learningAccountDetailLoading && getFormattedAddress()}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </div>
  );
}
