import { useLocation } from "react-router-dom";
import { useGetOrderById } from "../api/useGetOrderDetails";
import { useEffect, useState } from "react";

const MAX_POLL_ATTEMPTS = 7;
const POLL_INTERVAL = 3000;

export const useOrdersDetails = () => {
  const orderId = useLocation().pathname.split("/")[4];
  const [pollCount, setPollCount] = useState(0);
  const { loading, error, data, startPolling, stopPolling } = useGetOrderById(orderId);

  const [pollingError, setPollingError] = useState(false);
  const [pollingLoading, setPollingLoading] = useState(true);
  useEffect(() => {
    startPolling(POLL_INTERVAL);
  }, []);

  useEffect(() => {
    if (pollCount >= MAX_POLL_ATTEMPTS) {
      stopPolling();
      setPollingLoading(false);
      setPollingError(true);
    } else {
      setPollCount(pollCount + 1);
      if (data !== undefined) {
        if (data.orderById.fulfillment !== null && data.orderById.fulfillment.fulfillmentItems !== null) {
          stopPolling();
          setPollingLoading(false);
        }
      }
    }
  }, [loading, error, data]);

  return {
    orderDetailsLoading: pollingLoading,
    orderDetailsError: error,
    orderDetailsData: data,
    pollingError,
  };
};
