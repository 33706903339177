/* eslint-disable react-hooks/rules-of-hooks */
import { ServerError } from "@apollo/client";
import { useEffect, useState } from "react";
import { ApplicationUser } from "../auth/ApplicationUser";
import { getSignInURL, refreshToken, signOut } from "../auth/authHelpers";
import { ALLOWED_ROLES, ALLOWED_ROLES_BULK_OPERATION, EMAIL_VERIFICATION_ERROR } from "../common/constants";
import { bulkUserOperationIsEnabled } from "../common/featureFlags";
import { useGetCurrentUser } from "../user/api/getCurrentUser";
import type { User } from "../../__generated__/supergraph"; // Example to import type from generated schema
import { RoleType, UserStatus } from "../user/types";

type AuthErrorMessageId = `errorPage.accessDenied.${string}`;
export type AuthResponse = {
  readonly user: ApplicationUser;
  readonly isAuthErrorFound: boolean;
  readonly authErrorMessageId?: AuthErrorMessageId;
};

export const useAuth = (): AuthResponse => {
  const [signedInUserEmail, setSignedInUserEmail] = useState<string | undefined>(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const [userRole, setUserRole] = useState<RoleType | undefined>(undefined);
  const [authErrorMessageId, setAuthErrorMessageId] = useState<AuthErrorMessageId | undefined>(undefined);

  const { data, error } = useGetCurrentUser();
  const allowedRoles = bulkUserOperationIsEnabled() ? ALLOWED_ROLES_BULK_OPERATION : ALLOWED_ROLES;
  useEffect(() => {
    if (error) {
      setIsLoggedIn(false);
      if ((error.networkError as ServerError)?.statusCode === 413) {
        setAuthErrorMessageId("errorPage.accessDenied.413Error");
      } else if (
        error.networkError?.message.includes(EMAIL_VERIFICATION_ERROR) ||
        error.message?.includes(EMAIL_VERIFICATION_ERROR)
      ) {
        const message = error.networkError?.message.includes(EMAIL_VERIFICATION_ERROR)
          ? error.networkError.message
          : error.message;
        const parsedString = message.substring(message.indexOf(".") + 1, message.length);
        const parsedJson = JSON.parse(parsedString);
        window.location.href = getSignInURL({
          identity_provider: parsedJson.audiencePath,
          require_email_verification: "true",
        });
      } else {
        window.location.href = getSignInURL();
      }
    }

    if (data) {
      const currentUser = data.currentUser;
      if (currentUser.status !== UserStatus.ACTIVE || !allowedRoles.includes(currentUser.userRole)) {
        setIsLoggedIn(false);
        // SignOut is called to revoke tokens/clear HttpOnly cookies - as we want the user to be able to try again w/ different credentials
        signOut().then(() => setAuthErrorMessageId("errorPage.accessDenied.invalidRoleOrInactiveUser"));
      } else {
        refreshToken()
          .then(() => {
            setSignedInUserEmail(currentUser.emailAddress);
            setIsLoggedIn(true);
            setUserRole(currentUser.userRole);
          })
          .catch(() => {
            setIsLoggedIn(false);
            signOut().then(() => (window.location.href = getSignInURL()));
          });
      }
    }
  }, [data, error]);

  const potentialUser = new ApplicationUser({
    email: signedInUserEmail,
    isLoggedIn: isLoggedIn,
    role: userRole,
  });

  return {
    user: potentialUser,
    isAuthErrorFound: !!authErrorMessageId,
    authErrorMessageId,
  };
};
