import { gql, useMutation } from "@apollo/client";

export const CREATE_UPLOAD_FILE_LOCATION = gql`
  mutation createBulkOperationFileUploadUrl($learningAccountId: ID!, $userId: ID!, $fileName: String!) {
    createBulkOperationFileUploadUrl(
      learningAccountId: $learningAccountId
      userId: $userId
      fileMetadata: { name: $fileName, size: 10 }
    ) {
      fileId
      uploadLocation
    }
  }
`;

export const useCreateBulkOperationFileUploadUrl = (learningAccountId: string, userId: string, fileName: string) => {
  return useMutation(CREATE_UPLOAD_FILE_LOCATION, {
    variables: {
      learningAccountId: learningAccountId,
      userId: userId,
      fileName: fileName,
    },
  });
};
