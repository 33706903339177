import { AppLayout } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Breadcrumbs, Navigation } from "../../components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { ImportOpportunityFlow } from "../components/";

function ImportOpportunityMainContent(): JSX.Element {
  return <ImportOpportunityFlow />;
}

export function ImportOpportunityMain(): JSX.Element {
  const intl = useIntl();

  return (
    <AppLayout
      contentType="table"
      content={<ImportOpportunityMainContent />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      breadcrumbs={<Breadcrumbs />}
      navigation={<Navigation />}
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
      toolsHide
    />
  );
}
