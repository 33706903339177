import { gql, useQuery } from "@apollo/client";

export const GET_USER_BULK_OPERATION_FILES = gql`
  query GetUserBulkOperationFiles($userId: ID!) {
    userBulkOperationFiles(userId: $userId) {
      nodes {
        fileId
        userCreationStatus
        createdAt
        fileMetadata {
          name
        }
        learningAccount {
          id
          name
        }
      }
    }
  }
`;

export const useGetUserBulkOperationFiles = (userId: string | undefined) => {
  return useQuery(GET_USER_BULK_OPERATION_FILES, {
    skip: !userId,
    variables: { userId },
    notifyOnNetworkStatusChange: true,
  });
};
