import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import styled from "styled-components";
import { Header, Container, SpaceBetween, Box } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { ReviewAndSubmitLineItem } from "../types";

const ItemsTable = styled(Table)`
  * > th:nth-child(1) {
    width: 8% !important;
  }
  * > th:nth-child(2) {
    width: 8% !important;
  }
  * > th:nth-child(3) {
    width: 8% !important;
  }
  * > th:nth-child(4) {
    width: 8% !important;
  }
`;

interface LineItemProps {
  lineItems: ReviewAndSubmitLineItem[];
}

export function ReviewAndSubmitItemsTable(lineItems: any): JSX.Element {
  const intl = useIntl();
  const renderItemsTable = (): JSX.Element => {
    return (
      <div>
        <ItemsTable
          header={
            <Header data-testid="review-and-submit-items-table-header" variant="h2">
              {intl.formatMessage({ id: "reviewAndSubmit.lineItems.header" })}
            </Header>
          }
          variant="embedded"
          ariaLabels={{ tableLabel: intl.formatMessage({ id: "reviewAndSubmit.lineItems.header" }) }}
          columnDefinitions={[
            {
              id: "Product",
              header: intl.formatMessage({ id: "reviewAndSubmit.label.product" }),
              ariaLabel: (data: TableProps.LabelData) => intl.formatMessage({ id: "reviewAndSubmit.label.product" }),
              cell: (item: ReviewAndSubmitLineItem) => item.productName,
            },
            {
              id: "Quantity",
              header: intl.formatMessage({ id: "reviewAndSubmit.label.quantity" }),
              ariaLabel: (data: TableProps.LabelData) => intl.formatMessage({ id: "reviewAndSubmit.label.quantity" }),
              cell: (item: ReviewAndSubmitLineItem) =>
                item.quantity === 1 ? `${item.quantity} seat` : `${item.quantity} seats`,
            },
            {
              id: "Item Price",
              header: intl.formatMessage({ id: "reviewAndSubmit.label.itemPrice" }),
              ariaLabel: (data: TableProps.LabelData) => intl.formatMessage({ id: "reviewAndSubmit.label.itemPrice" }),
              cell: (item: ReviewAndSubmitLineItem) =>
                intl.formatNumber(item.orderTotal / item.quantity, { style: "currency", currency: "USD" }),
            },
            {
              id: "Total",
              header: intl.formatMessage({ id: "reviewAndSubmit.label.total" }),
              ariaLabel: (data: TableProps.LabelData) => intl.formatMessage({ id: "reviewAndSubmit.label.total" }),
              cell: (item: ReviewAndSubmitLineItem) =>
                intl.formatNumber(item.orderTotal, { style: "currency", currency: "USD" }),
            },
          ]}
          footer={<Box>{/*Cheeky way to get line under last table element*/}</Box>}
          items={lineItems.itemTableProps ? lineItems.itemTableProps : []}
          empty={
            //This shouldn't happen but this can let the reviewer know something is wrong.
            <Box textAlign="center" color="inherit">
              <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No Items to Display
              </Box>
            </Box>
          }
        />
      </div>
    );
  };

  return (
    <Container>
      <SpaceBetween data-testid="review-and-submit-items-table" direction="vertical" size="xxl">
        {renderItemsTable()}
      </SpaceBetween>
    </Container>
  );
}
