import { Button, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { useNavigate } from "react-router-dom";

interface AppHeaderProps {
  title: string;
  counterValue?: string;
  buttonText?: string;
  buttonAriaLabel?: string;
  navigateTo?: string;
  description?: string;
}

export const AppHeader = (props: AppHeaderProps) => {
  const navigate = useNavigate();
  return (
    <div data-testid="appHeader-heading">
      <Header
        description={props.description ? props.description : ""}
        variant="awsui-h1-sticky"
        counter={props.counterValue ? props.counterValue : ""}
        actions={
          props.buttonText && (
            <SpaceBetween size="xs" direction="horizontal">
              <Button
                variant="primary"
                data-testid="appHeader-button"
                onClick={() => navigate(props.navigateTo || "")}
                ariaLabel={props.buttonAriaLabel}
              >
                {props.buttonText}
              </Button>
            </SpaceBetween>
          )
        }
      >
        {props.title}
      </Header>
    </div>
  );
};
