import { Box, Button, ButtonProps, SpaceBetween } from "@amzn/awsui-components-react";
import { CancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events";
import { useIntl } from "react-intl";

export type TableNoMatchStateProps = {
  onClearFilter: CancelableEventHandler<ButtonProps.ClickDetail>;
};

export const TableNoMatchState = (props: TableNoMatchStateProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
      <SpaceBetween size="xxs">
        <div>
          <b>{intl.formatMessage({ id: "tableNoMatchState.noMatches" })}</b>
          <Box variant="p" color="inherit">
            {intl.formatMessage({ id: "tableNoMatchState.weCantFindAMatch" })}
          </Box>
        </div>
        <Button onClick={props.onClearFilter}>
          {intl.formatMessage({ id: "tableNoMatchState.button.clearFilter" })}
        </Button>
      </SpaceBetween>
    </Box>
  );
};
