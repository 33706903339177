import {
  Button,
  Container,
  ContentLayout,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  RadioGroup,
  SpaceBetween,
  Toggle,
} from "@amzn/awsui-components-react";
import { AppHeader } from "../../components";
import { useUsers } from "./useUsers";
import { Role, Status } from "../constants";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUserInfoCard } from "./useUserInfoCard";

export const Users = (props: any): JSX.Element => {
  const { userStatus, firstName, lastName, emailAddress, userRole, userId } = useUserInfoCard();

  const intl = useIntl();
  const navigate = useNavigate();

  const {
    createInternalUserLoading,
    createInternalUserError,
    firstname,
    lastname,
    email,
    role,
    status,
    firstNameError,
    lastNameError,
    emailError,
    flashbarItems,
    firstNameRef,
    lastNameRef,
    emailRef,
    onEmailChange,
    onFirstNameChange,
    onLastNameChange,
    onRoleChange,
    handleSubmit,
    onIdChange,
    onStatusChange,
  } = useUsers();

  useEffect(() => {
    //change the default starting values if we are editing
    if (props.data) {
      onIdChange(props.data.userId);
      onFirstNameChange(props.data.firstName);
      onLastNameChange(props.data.lastName);
      onEmailChange(props.data.emailAddress);
      onRoleChange(props.data.userRole);
      onStatusChange(props.data.userStatus);
    } else {
      onIdChange(userId);
      onFirstNameChange(firstName);
      onLastNameChange(lastName);
      onEmailChange(emailAddress);
      onRoleChange(userRole);
      onStatusChange(userStatus);
    }
  }, [userId]);

  return (
    <ContentLayout
      data-testid="header-name"
      header={
        <AppHeader title={intl.formatMessage({ id: `userComponent.header.${props.userPageType.toLowerCase()}` })} />
      }
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <SpaceBetween direction="horizontal" size="l"></SpaceBetween>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid="cancel-button"
                formAction="none"
                variant="link"
                onClick={() => navigate(props.cancel)}
              >
                {intl.formatMessage({ id: "userComponent.cancel" })}
              </Button>
              <Button
                data-testid="save-user-button"
                variant="primary"
                loading={createInternalUserLoading}
                onClick={handleSubmit}
              >
                {intl.formatMessage({ id: `userComponent.submit.${props.userPageType.toLowerCase()}` })}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween data-testid="master-form" direction="vertical" size="l">
            <Container
              header={
                <Header data-testid="container-title" variant="h2">
                  {intl.formatMessage({ id: "userComponent.container.header" })}
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField
                  aria-label="First name input"
                  data-testid="first-name-input"
                  label={intl.formatMessage({ id: "userComponent.textfield.firstname" })}
                  errorText={firstNameError}
                  i18nStrings={{ errorIconAriaLabel: "error" }}
                >
                  <Input
                    ref={firstNameRef}
                    className="addUserInput"
                    placeholder={intl.formatMessage({ id: "userComponent.textfield.firstname" })}
                    value={firstname}
                    onChange={(event: { detail: { value: string } }) => onFirstNameChange(event.detail.value)}
                  />
                </FormField>
                <FormField
                  aria-label="Last name input"
                  data-testid="last-name-input"
                  label={intl.formatMessage({ id: "userComponent.textfield.lastname" })}
                  errorText={lastNameError}
                  i18nStrings={{ errorIconAriaLabel: "error" }}
                >
                  <Input
                    ref={lastNameRef}
                    className="addUserInput"
                    placeholder={intl.formatMessage({ id: "userComponent.textfield.lastname" })}
                    value={lastname}
                    onChange={(event: { detail: { value: string } }) => onLastNameChange(event.detail.value)}
                  />
                </FormField>
                <FormField
                  aria-label="Email input"
                  data-testid="email-input"
                  label={intl.formatMessage({ id: "userComponent.textfield.emailaddress" })}
                  errorText={emailError}
                  i18nStrings={{ errorIconAriaLabel: "error" }}
                >
                  <Input
                    ref={emailRef}
                    className="addUserInput"
                    placeholder={intl.formatMessage({ id: "userComponent.textfield.emailaddress" })}
                    value={email}
                    onChange={(event: { detail: { value: string } }) => onEmailChange(event.detail.value)}
                  />
                </FormField>
                <FormField data-testid="role-input" label={intl.formatMessage({ id: "userComponent.radio.role" })}>
                  <RadioGroup
                    role="radio"
                    aria-label="User role input"
                    data-testid="role-radio-group"
                    onChange={(event: { detail: { value: string } }) => onRoleChange(event.detail.value)}
                    value={role}
                    items={props.roles.map((userRoleType: any) => ({
                      value: Object.keys(Role)[Object.values(Role).indexOf(userRoleType)],
                      label: userRoleType,
                      description: intl.formatMessage({
                        id: `userComponent.${userRoleType.replace(/\s/g, "").toLowerCase()}`,
                      }),
                    }))}
                  />
                </FormField>
                <FormField data-testid="status-input" label={intl.formatMessage({ id: "userComponent.toggle.status" })}>
                  {
                    <Toggle
                      aria-live="polite"
                      disabled={props.toggleDisabled}
                      checked={status === Status.ACTIVE.toUpperCase() ? true : false}
                      onChange={(event: { detail: { value: boolean } }) => {
                        onStatusChange(
                          status === Status.ACTIVE.toUpperCase()
                            ? Status.DEACTIVATED.toUpperCase()
                            : Status.ACTIVE.toUpperCase()
                        );
                      }}
                      description={intl.formatMessage({ id: "userComponent.status.description" })}
                    >
                      {" "}
                      {intl.formatMessage({ id: "userComponent.status" })}
                    </Toggle>
                  }
                </FormField>
              </SpaceBetween>
            </Container>
            {createInternalUserError ? (
              <Flashbar aria-live="polite" data-testid="flashbar" items={flashbarItems} />
            ) : null}
          </SpaceBetween>
        </Form>
      </form>
    </ContentLayout>
  );
};
