import { OperationVariables, QueryResult } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_IMPORTS_ROUTE } from "../../common/routes";
import { useGetLearningAccountDetailsQueryLazy } from "../../learningAccounts/api/useGetLearningAccountDetailsQuery";
import { useLazyGetLearningAccountsQuery } from "../../learningAccounts/api/useGetLearningAccountsQuery";
import { MAX_PAGE_SIZE } from "../../learningAccounts/constants";
import { useGetCurrentUserLazy } from "../api/getCurrentUser";
import { useCreateBulkOperationFileUploadUrl } from "../api/useCreateBulkOperationFileUploadUrl";
import { useGetUserBulkOperationFileTemplate } from "../api/userBulkOperationFileTemplate";
import { bulkUserFileUploadSizeLimit } from "../constants";

const DEFAULT_FILENAME = "bulkLearnerCreationTemplate.csv";

const downloadFile = async (
  templateResults: QueryResult<any, OperationVariables>,
  defaultFilename: string = DEFAULT_FILENAME
) => {
  if (templateResults.error || !templateResults.data.userBulkOperationFileTemplate.templateUrl) {
    return false;
  }
  try {
    const presignedURL = templateResults.data.userBulkOperationFileTemplate.templateUrl;
    const response = await fetch(presignedURL);
    const blob = await response.blob();
    const downloadURL = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadURL;
    link.download = defaultFilename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return true;
  } catch (error: unknown) {
    return false;
  }
};

export function useImports() {
  const location = useLocation();
  const navigate = useNavigate();
  let defaultLearningAccountId = "";
  if (location?.state?.currentLearningAccountId) {
    defaultLearningAccountId = location.state.currentLearningAccountId;
  }

  const [getUserBulkOperationFileTemplate, _templateResults] = useGetUserBulkOperationFileTemplate();
  const [getUserQueryResults, _fileUploadLocationQueryResults] = useCreateBulkOperationFileUploadUrl("", "", "");
  const [getCurrentUser, userResults] = useGetCurrentUserLazy();
  const [getLearningAccounts, learningAccountResults] = useLazyGetLearningAccountsQuery("", "", MAX_PAGE_SIZE);
  const [selectedLearningAccount, setSelectedLearningAccount] = useState(undefined);
  const [defaultLearningAccountDisplayed, setDefaultLearningAccountDisplayed] = useState(true);
  const [downloadTemplateError, setDownloadTemplateError] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);
  const [filePath, setFilePath] = useState("");
  const [fileSizeLimitExceeded, setFileSizeLimitExceeded] = useState(false);
  const [importEnabled, setImportEnabled] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [getLazyLA] = useGetLearningAccountDetailsQueryLazy(defaultLearningAccountId);
  const [defaultLearningAccountName, setDefaultLearningAccountName] = useState<string | undefined>();

  useEffect(() => {
    getCurrentUser();
  }, []);

  const onSelectedLearningAccountChange = (value: any) => {
    setSelectedLearningAccount(value);
    const isImportEnabled =
      value !== undefined && filePath !== "" && userResults.data !== undefined && !fileSizeLimitExceeded;
    setImportEnabled(isImportEnabled);
  };

  const onSelectLoad = async () => {
    if (!learningAccountResults.called) {
      await getLearningAccounts();
    }
    if (!userResults.data) {
      await getCurrentUser();
    }
  };

  const onDefaultLearningAccountDisplayed = (value: boolean) => {
    setDefaultLearningAccountDisplayed(value);
  };

  const onDownloadTemplateError = (value: any) => {
    setDownloadTemplateError(value);
  };

  const onDownloadTemplate = async () => {
    const results = await getUserBulkOperationFileTemplate();
    const downloaded = await downloadFile(results);
    if (!downloaded) {
      onDownloadTemplateError(true);
    }
  };

  const onUploadError = (value: any) => {
    setUploadError(value);
  };

  const onUploadSuccess = (value: any) => {
    setUploadSuccess(value);
  };

  const onFileSizeLimitExceeded = (value: any) => {
    setFileSizeLimitExceeded(value);
  };

  useEffect(() => {
    const onFileUpload = async () => {
      let fileUploadSuccess = false;
      if (!importEnabled) {
        onUploadError(true);
        return { fileUploadSuccess };
      }
      const userId = userResults.data.currentUser.id;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const learingAccountId = selectedLearningAccount!["value"];
      const results = await getUserQueryResults({
        variables: { learningAccountId: learingAccountId, userId: userId, fileName: filePath },
      });
      if (results.errors) {
        onUploadError(true);
        return { fileUploadSuccess };
      }
      const uploadURL = results.data.createBulkOperationFileUploadUrl.uploadLocation;
      const { fileId } = results.data.createBulkOperationFileUploadUrl;
      try {
        await fetch(uploadURL, {
          method: "PUT",
          body: uploadFile,
          headers: {
            "x-amz-server-side-encryption": "aws:kms",
          },
        });
        fileUploadSuccess = true;
        onUploadSuccess(true);
      } catch (fetchError: unknown) {
        onUploadError(true);
      }
      if (fileUploadSuccess) navigate(USER_IMPORTS_ROUTE, { state: { showFileStatusFlashbarItems: true, fileId } });
      else setImportLoading(false);
    };

    if (importLoading) {
      onFileUpload();
    }
  }, [importLoading]);

  const onSelectedFile = async (fileInput?: any) => {
    if (!fileInput || fileInput[0] === undefined) {
      setUploadFile(undefined);
      setFilePath("");
      setImportEnabled(false);
      return;
    }
    const selectedFile = fileInput[0];
    setUploadFile(selectedFile);
    setFilePath(selectedFile.name);
    const selectedFileSizeLimitExceeded = selectedFile.size > bulkUserFileUploadSizeLimit ? true : false;
    onFileSizeLimitExceeded(selectedFileSizeLimitExceeded);
    const isImportEnabled =
      (selectedLearningAccount !== undefined || defaultLearningAccountName !== undefined) &&
      selectedFile.name !== undefined &&
      userResults.data !== undefined &&
      !selectedFileSizeLimitExceeded;
    setImportEnabled(isImportEnabled);
  };

  useEffect(() => {
    const asyncWrappedGetLearningAccountDetails = async () => {
      const { data } = await getLazyLA();
      if (data) {
        setDefaultLearningAccountName(data.learningAccountById.name);
        const learningAccount = { label: data.learningAccountById.name, value: data.learningAccountById.id };
        onSelectedLearningAccountChange(learningAccount);
      }
    };
    if (defaultLearningAccountId) {
      asyncWrappedGetLearningAccountDetails();
    }
  }, [defaultLearningAccountId]);

  return {
    learningAccountsListLoading: learningAccountResults.loading,
    learningAccountsListData: learningAccountResults.data,
    selectedLearningAccount,
    onSelectedLearningAccountChange,
    defaultLearningAccountDisplayed,
    onDefaultLearningAccountDisplayed,
    downloadTemplateError,
    onDownloadTemplateError,
    uploadSuccess,
    onUploadSuccess,
    uploadError,
    onUploadError,
    uploadFile,
    fileSizeLimitExceeded,
    importEnabled,
    importLoading,
    setImportLoading,
    defaultLearningAccountName,
    defaultLearningAccountId,
    onSelectLoad,
    onDownloadTemplate,
    onSelectedFile,
  };
}
