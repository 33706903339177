import { gql, useMutation } from "@apollo/client";
import { AddOrganizationUserInput } from "../../../__generated__/supergraph";

export const ADD_ORG_USER = gql`
  mutation addUserToOrganization($input: AddOrganizationUserInput!) {
    addUserToOrganization(input: $input) {
      user {
        id
      }
    }
  }
`;

export const useAddUserToOrganizationQuery = (input: AddOrganizationUserInput) =>
  useMutation(ADD_ORG_USER, {
    variables: { input },
    onError: () => {
      return new Error("Error in addUserToOrganization");
    },
  });
