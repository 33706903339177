import { Breadcrumbs, Navigation } from "../../components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { AppLayout } from "@amzn/awsui-components-react";
import { CreateExternalUser } from "../components";
import { EXTERNAL_EMPLOYEE_ROLES } from "../constants";
import { LEARNING_ACCOUNTS_ROUTE } from "../../common/routes";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

// This page can be reused to render any external user action pages by using props as a conditional argument in content
export const ExternalUsers = (props: any): JSX.Element => {
  const currentLearningAccountId = useLocation().pathname.split("/")[2];
  const intl = useIntl();

  return (
    <AppLayout
      content={
        <CreateExternalUser
          roles={EXTERNAL_EMPLOYEE_ROLES}
          cancel={`${LEARNING_ACCOUNTS_ROUTE}/${currentLearningAccountId}`}
          toggleDisabled={true}
          learningAccountId={currentLearningAccountId}
        />
      }
      breadcrumbs={<Breadcrumbs />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      navigation={<Navigation />}
      toolsHide
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
    />
  );
};
