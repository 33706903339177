import { PropertyFilterOption, PropertyFilterProperty } from "@amzn/awsui-collection-hooks";
import { FlashbarProps, PropertyFilterProps } from "@amzn/awsui-components-react";
import { NetworkStatus } from "@apollo/client";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useGetCurrentUser } from "../../api/getCurrentUser";
import { useGetUserBulkOperationFiles } from "../../api/useGetUserBulkOperationFilesQuery";
import {
  USER_CREATION_STATUS_TO_I18N_KEY,
  UserBulkOperationFile,
  UserBulkOperationFileExtended,
  UserCreationStatus,
} from "./constants";
import dayjs = require("dayjs");
import utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

type StackFlashbarItemProps = { readonly id: string; readonly type: FlashbarProps.Type; readonly content: string };
type ReplaceFlashbarItemProps = {
  readonly replacedItemId: string;
  readonly replacementItemId: string;
  readonly replacementItemtype: FlashbarProps.Type;
  readonly replacementItemContent: string;
};

export function useUserImportsTable() {
  const intl = useIntl();

  const { data: currentUserData } = useGetCurrentUser();
  const [loading, setLoading] = useState(true);
  const {
    error,
    data: bulkOperationFilesResponse,
    refetch,
    networkStatus,
  } = useGetUserBulkOperationFiles(currentUserData?.currentUser?.id);
  const [userBulkOperationFiles, setUserBulkOperationFiles] = useState<UserBulkOperationFileExtended[]>([]);

  const location = useLocation();
  const showFileStatusFlashbarItems = location?.state?.showFileStatusFlashbarItems ?? false;
  const uploadedFileId = location?.state?.fileId;
  const [flashbarItems, setFlashbarItems] = useState<readonly FlashbarProps.MessageDefinition[]>([]);
  const fileUploadProcessingFlashbarItemId = "fileUploadProcessing";

  /**
   * Dismiss a flashbar item.
   * @param id Flashbar item Id.
   */
  const dismissFlashbarItem = (id: string) => {
    setFlashbarItems((items) => items.filter((item) => item.id !== id));
  };

  /**
   * Stack a flashbar item. This also ensures all stacked items are unique.
   * @param param0 Instance of `StackFlashbarItemProps`.
   * @returns Nothing.
   */
  const stackFlashbarItem = ({ id, type, content }: StackFlashbarItemProps): void => {
    // Do not add duplicate items
    if (flashbarItems.some((flashbarItem) => flashbarItem.id === id)) {
      return;
    }
    setFlashbarItems((items) => [
      {
        id,
        type,
        content,
        dismissLabel: intl.formatMessage({ id: "userImportsPage.flashbar.dismissLabel" }),
        dismissible: true,
        onDismiss: () => dismissFlashbarItem(id),
        statusIconAriaLabel: type,
      },
      ...items,
    ]);
  };

  /**
   * Replace a flashbar item with a new one. The new flashbar item will appear at the top of the stack.
   * @param param0 Instance of `ReplaceFlashbarItemProps`.
   */
  const replaceFlashbarItem = ({
    replacedItemId,
    replacementItemId,
    replacementItemtype,
    replacementItemContent,
  }: ReplaceFlashbarItemProps): void => {
    dismissFlashbarItem(replacedItemId);
    stackFlashbarItem({ id: replacementItemId, type: replacementItemtype, content: replacementItemContent });
  };

  /**
   * Stack a flashbar item if the uploaded file finished processing.
   * @param fileId File Id to compare against uploaded file Id.
   * @param userCreationStatus User creation status for file.
   */
  const stackFlashbarIfFileUploadCompleted = (fileId: string, userCreationStatus: UserCreationStatus) => {
    if (showFileStatusFlashbarItems && fileId === uploadedFileId) {
      if (userCreationStatus === UserCreationStatus.Succeeded) {
        replaceFlashbarItem({
          replacedItemId: fileUploadProcessingFlashbarItemId,
          replacementItemId: "fileUploadSuccess",
          replacementItemtype: "success",
          replacementItemContent: intl.formatMessage({ id: "userImportsPage.flashbar.file.success" }),
        });
      } else if (userCreationStatus === UserCreationStatus.Failed) {
        replaceFlashbarItem({
          replacedItemId: fileUploadProcessingFlashbarItemId,
          replacementItemId: "fileUploadFailure",
          replacementItemtype: "error",
          replacementItemContent: intl.formatMessage({ id: "userImportsPage.flashbar.file.failure" }),
        });
      }
    }
  };

  // Set files when there's query data
  useEffect(() => {
    if (bulkOperationFilesResponse?.userBulkOperationFiles?.nodes) {
      const newFiles = bulkOperationFilesResponse?.userBulkOperationFiles?.nodes.map(
        (file: UserBulkOperationFile): UserBulkOperationFileExtended => {
          stackFlashbarIfFileUploadCompleted(file.fileId, file.userCreationStatus);
          return {
            ...file,
            fileName: file.fileMetadata.name,
            timeImportedText: `${dayjs.utc(file.createdAt).format("D MMM YYYY HH:mm:ss")} GMT`,
            learningAccountName: file.learningAccount.name,
            statusText: intl.formatMessage({ id: USER_CREATION_STATUS_TO_I18N_KEY[file.userCreationStatus] }),
          };
        }
      );
      setUserBulkOperationFiles(newFiles);
    }
  }, [bulkOperationFilesResponse]);

  // Set loading
  useEffect(() => {
    setLoading((!bulkOperationFilesResponse || networkStatus === NetworkStatus.refetch) && !error);
  }, [bulkOperationFilesResponse, error, networkStatus]);

  // Property filter data
  const fileNamePropertyLabel = intl.formatMessage({ id: "userImportsTableComponent.filter.fileNamePropertyLabel" });
  const timeImportedPropertyLabel = intl.formatMessage({
    id: "userImportsTableComponent.filter.timeImportedPropertyLabel",
  });
  const statusPropertyLabel = intl.formatMessage({ id: "userImportsTableComponent.filter.statusPropertyLabel" });
  const learningAccountPropertyLabel = intl.formatMessage({
    id: "userImportsTableComponent.filter.learningAccountPropertyLabel",
  });
  const propertyFilterProperties: PropertyFilterProperty[] = [
    {
      propertyLabel: fileNamePropertyLabel,
      key: "fileName",
      groupValuesLabel: intl.formatMessage(
        { id: "userImportsTableComponent.filter.groupValuesLabel" },
        { propertyLabel: fileNamePropertyLabel }
      ),
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyLabel: timeImportedPropertyLabel,
      key: "timeImportedText",
      groupValuesLabel: intl.formatMessage(
        { id: "userImportsTableComponent.filter.groupValuesLabel" },
        { propertyLabel: timeImportedPropertyLabel }
      ),
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyLabel: statusPropertyLabel,
      key: "statusText",
      groupValuesLabel: intl.formatMessage(
        { id: "userImportsTableComponent.filter.groupValuesLabel" },
        { propertyLabel: statusPropertyLabel }
      ),
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyLabel: learningAccountPropertyLabel,
      key: "learningAccountName",
      groupValuesLabel: intl.formatMessage(
        { id: "userImportsTableComponent.filter.groupValuesLabel" },
        { propertyLabel: learningAccountPropertyLabel }
      ),
      operators: [":", "!:", "=", "!="],
    },
  ];
  const propertyFilterOptions: PropertyFilterOption[] = [
    {
      propertyKey: "statusText",
      value: intl.formatMessage({ id: USER_CREATION_STATUS_TO_I18N_KEY[UserCreationStatus.Failed] }),
    },
    {
      propertyKey: "statusText",
      value: intl.formatMessage({ id: USER_CREATION_STATUS_TO_I18N_KEY[UserCreationStatus.Processing] }),
    },
    {
      propertyKey: "statusText",
      value: intl.formatMessage({ id: USER_CREATION_STATUS_TO_I18N_KEY[UserCreationStatus.Succeeded] }),
    },
  ];
  const removeTokenButtonAriaLabel = (token: PropertyFilterProps.Token): string => {
    if (token.propertyKey) {
      const filteredProperty = propertyFilterProperties.find(
        (filteringProperty: PropertyFilterProperty) => filteringProperty.key === token.propertyKey
      );
      return intl.formatMessage(
        { id: "userImportsTableComponent.filter.removeTokenButtonAriaLabelWithPropertyKey" },
        { propertyLabel: filteredProperty?.propertyLabel, operator: token.operator, value: token.value }
      );
    }
    return intl.formatMessage(
      { id: "userImportsTableComponent.filter.removeTokenButtonAriaLabelWithoutPropertyKey" },
      { value: token.value }
    );
  };
  const enteredTextLabel = (value: string): string =>
    intl.formatMessage({ id: "userImportsTableComponent.filter.enteredTextLabel" }, { enteredText: value });
  const propertyFilterI18nKeys: Array<keyof PropertyFilterProps.I18nStrings> = [
    "allPropertiesLabel",
    "applyActionText",
    "cancelActionText",
    "clearAriaLabel",
    "clearFiltersText",
    "dismissAriaLabel",
    "editTokenHeader",
    "groupPropertiesText",
    "groupValuesText", // The `groupValuesLabel` key in the PropertyFilterProperty array overrides this
    "operationAndText",
    "operationOrText",
    "operatorContainsText",
    "operatorDoesNotContainText",
    "operatorDoesNotEqualText",
    "operatorEqualsText",
    "operatorGreaterOrEqualText",
    "operatorGreaterText",
    "operatorLessOrEqualText",
    "operatorLessText",
    "operatorsText",
    "operatorText",
    "propertyText",
    "tokenLimitShowFewer",
    "tokenLimitShowMore",
    "tokenOperatorAriaLabel",
    "valueText",
  ];
  const propertyFilterI18nStrings: PropertyFilterProps.I18nStrings = propertyFilterI18nKeys.reduce(
    (accumulatedPropertyFilterI18nStrings, propertyFilterI18nKey) => {
      return {
        ...accumulatedPropertyFilterI18nStrings,
        [propertyFilterI18nKey]: intl.formatMessage({
          id: `userImportsTableComponent.filter.${propertyFilterI18nKey}`,
        }),
      };
    },
    {
      removeTokenButtonAriaLabel,
      enteredTextLabel,
    }
  );

  useEffect(() => {
    if (showFileStatusFlashbarItems) {
      stackFlashbarItem({
        id: fileUploadProcessingFlashbarItemId,
        type: "info",
        content: intl.formatMessage({ id: "userImportsPage.flashbar.file.processing" }),
      });
    }
  }, [showFileStatusFlashbarItems]);

  // Set error
  useEffect(() => {
    if (error) {
      stackFlashbarItem({
        id: "genericError",
        type: "error",
        content: intl.formatMessage({ id: "userImportsPage.flashbar.error.generic" }),
      });
    }
  }, [error]);

  return {
    files: userBulkOperationFiles,
    loading,
    refetch,
    propertyFilterProperties,
    propertyFilterOptions,
    propertyFilterI18nStrings,
    flashbarItems,
    error,
  };
}
