import { Box, Flashbar, SpaceBetween, Wizard } from "@amzn/awsui-components-react";
import * as React from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE, IMPORT_OPPORTUNITY_ROUTE } from "../../common/routes";
import { ErrorType } from "../types";
import { ImportOpportunityForm } from "./ImportOpportunityForm";
import { ReviewAndSubmitCard } from "./ReviewAndSubmitCard";
import { useImportOpportunityFlow } from "./useImportOpportunityFlow";

export const ImportOpportunityFlow = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const firstStepIndex = 0;

  const {
    fieldText,
    validationError,
    detailsCardProps,
    itemsTableProps,
    fulfillmentDetailsProps,
    flashbarItems,
    opportunityError,
    activeStepIndex,
    ordersByOpportunityLoading,
    sfdcOpportunityLoading,
    organizationLoading,
    createOrganizationLoading,
    updateOrganizationLoading,
    createOrderLoading,
    successFlashbarItems,
    orderSuccess,
    loadingOverride,
    onCancel,
    onReviewAndSubmit,
    onInputChange,
    onNavigateFunction,
  } = useImportOpportunityFlow();

  return (
    <SpaceBetween direction="vertical" size="l">
      <Wizard
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          navigationAriaLabel: intl.formatMessage({ id: "getOpportunityComponent.steps" }),
          cancelButton:
            activeStepIndex === firstStepIndex
              ? intl.formatMessage({ id: "getOpportunityComponent.clearForm" })
              : intl.formatMessage({ id: "getOpportunityComponent.cancel" }),
          previousButton: intl.formatMessage({ id: "getOpportunityComponent.previous" }),
          nextButton: intl.formatMessage({ id: "getOpportunityComponent.next" }),
          submitButton: intl.formatMessage({ id: "getOpportunityComponent.submit" }),
        }}
        onCancel={() => {
          onCancel();
        }}
        onNavigate={({ detail }) => {
          onNavigateFunction(detail);
        }}
        onSubmit={() => {
          onReviewAndSubmit();
        }}
        isLoadingNextStep={
          loadingOverride &&
          (ordersByOpportunityLoading ||
            sfdcOpportunityLoading ||
            organizationLoading ||
            createOrganizationLoading ||
            createOrderLoading ||
            updateOrganizationLoading)
        }
        activeStepIndex={activeStepIndex}
        steps={[
          {
            title: intl.formatMessage({ id: "getOpportunityComponent.header" }),
            content: (
              <SpaceBetween direction="vertical" size="xs">
                <ImportOpportunityForm
                  validationError={validationError}
                  fieldText={fieldText}
                  onInputChange={onInputChange}
                />
                {opportunityError.length !== 0 ? (
                  <Flashbar aria-live="polite" data-testid="importFlashbar" items={flashbarItems} />
                ) : null}
              </SpaceBetween>
            ),
          },
          {
            title: intl.formatMessage({ id: "reviewAndSubmit.header" }),
            content: (
              <SpaceBetween direction="vertical" size="xs">
                {orderSuccess ? (
                  <Flashbar aria-live="polite" data-testid="importSuccessFlashbar" items={successFlashbarItems} />
                ) : null}
                <ReviewAndSubmitCard
                  detailsCardProps={detailsCardProps}
                  itemsTableProps={itemsTableProps}
                  fulfillmentDetailsProps={fulfillmentDetailsProps}
                />
                {opportunityError.length !== 0 ? (
                  <Flashbar aria-live="polite" data-testid="importFlashbar" items={flashbarItems} />
                ) : null}
              </SpaceBetween>
            ),
          },
        ]}
      />
      <Box />
    </SpaceBetween>
  );
};
