import { SpaceBetween } from "@amzn/awsui-components-react";
import { ReviewAndSubmitDetailsCard } from "./ReviewAndSubmitDetailsCard";
import { ReviewAndSubmitFulfillmentDetails } from "./ReviewAndSubmitFulfillmentCard";
import { ReviewAndSubmitItemsTable } from "./ReviewAndSubmitLineItemsCard";

export function ReviewAndSubmitCard(props: any): JSX.Element {
  return (
    <SpaceBetween direction="vertical" size="l">
      <SpaceBetween direction="vertical" size="l">
        <ReviewAndSubmitDetailsCard {...props.detailsCardProps} />
        <ReviewAndSubmitItemsTable itemTableProps={props.itemsTableProps} />
        <ReviewAndSubmitFulfillmentDetails {...props.fulfillmentDetailsProps} />
      </SpaceBetween>
    </SpaceBetween>
  );
}
