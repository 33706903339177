import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { RoleType, UserStatus } from "../types";

// The query is to an endpoint currentUser but the purpose of the query is to update userID in VIAS.
// While it does return the information of the current user, each call to the query also is saving
// and updating the current user's information in VIAS.
// https://code.amazon.com/packages/AwsTcUserService/blobs/mainline/--/src/libraries/UserLibrary.ts#L96
export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      emailAddress
      firstName
      lastName
      status
      userRole
      auditMetadata {
        createdAt
      }
      gandalfDetails {
        audiencePath
      }
    }
  }
`;

export const useGetCurrentUserLazy = () => {
  return useLazyQuery(GET_CURRENT_USER, {});
};

export const useGetCurrentUser = () => {
  return useQuery(GET_CURRENT_USER, {});
};

export interface CurrentUserQueryResponseData {
  id: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  status: UserStatus;
  userRole: RoleType;
  auditMetadata: { createdAt: string };
  gandalfDetails: {
    audiencePath: string;
  };
}
