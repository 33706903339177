import { AwsAssociation } from "../../__generated__/supergraph";
import {
  Address,
  AWSAccountInfo,
  BillingPeriod,
  ErrorType,
  PointOfContact,
  ProductModality,
  ProductOnboardingAuthenticationMethod,
  PTRR,
  SalesforceOpportunity,
  SalesforceOpportunityLineItem,
  SSODetails,
  TypeOfContract,
} from "./types";

const testAddress: Address = {
  name: "testName",
  addressLineOne: "addressLine1",
  addressLineTwo: "addressLine2",
  city: "city",
  phoneNumber: "phoneNumber",
  stateOrProvince: "state",
  countryCode: "countryCode",
  zipCode: "zipCode",
};

const testPointOfContact: PointOfContact = {
  firstName: "pocName",
  lastName: "pocLastName",
  email: "pocEmail",
  phone: "pocPhoneNumber",
};

const testSSODetails: SSODetails = {
  ssoEnabled: true,
  idp: "testIDP",
  technicalPOC: testPointOfContact,
};

export const trainingDeliveryTypeToProductModalityMap: { [key: string]: ProductModality } = {
  ["Digital Subscription"]: ProductModality.DIGITAL,
  ["Private Onsite (ILT)"]: ProductModality.ILT,
  ["Private Virtual (vILT)"]: ProductModality.ILT,
};

export const genericErrorString = "There was an error retrieving this opportunity. Try submitting again.";
export const orderExistsErrorString = "A fulfillment request has already been submitted for this opportunity.";
export const multipleOrdersExistsErrorString =
  "All fulfillment requests associated with this opportunity have already been submitted";
export const opportunityNotFoundString =
  "We couldn't find this opportunity ID. Check the opportunity ID entered or try a different one.";
export const skillBuilderErrorString =
  "Skill Builder Team subscription is not available in this customer's country. Check the AWS Account information related to the opportunity in Salesforce.";
export const missingAttributesErrorString =
  "We couldn't find some required attributes for this opportunity. Check the opportunity information in Salesforce.";
export const invalidRecordErrorString =
  "The record, subscription, or stage for this opportunity is not valid. Check the opportunity information in Salesforce.";
export const invalidAWSAccountErrorString =
  "This opportunity doesn't have a valid AWS account. Check the opportunity information in Salesforce.";
export const noCountryCodeErrorString =
  "We couldn't find this customer's country code. Check the AWS Account information related to the opportunity in Salesforce.";
export const billingAddressErrorString =
  "We couldn't find this customer's billing address. Check the AWS Account information related to the opportunity in Salesforce.";
export const noAwsAccountErrorString =
  "We couldn't find an AWS Account for this opportunity. Check the opportunity information in Salesforce.";
export const invalidSSo =
  "The Skill Builder team subscription does not support this customer's identity provider (idP). Check the opportunity information in Salesforce.";
export const invalidAllowedDomainErrorString =
  "One or more of this customer's allowed email domains is formatted incorrectly. Check the opportunity information in Salesforce.";
export const samePrimaryAndSecondaryEmailErrorString =
  "Primary and secondary learning admin emails cannot be the same. Check the opportunity information in Salesforce.";
export const invalidProductNameErrorString =
  "This opportunity doesn't include a Skill Builder Team subscription. Check the opportunity information in Salesforce.";
export const multipleProductsErrorString =
  "This opportunity contains multiple products. Check the opportunity information in Salesforce.";
export const multiplePttrErrorString =
  "This opportunity contains multiple contracting requests. Check the opportunity information in Salesforce.";
export const invalidAuthenticationMethodErrorString =
  "A valid identity provider (IdP) and authentication method are required. Check the opportunity information in Salesforce.";
export const invalidIdentityProviderErrorString =
  "A valid identity provider (IdP) and authentication method are required. Check the opportunity information in Salesforce.";
export const midtermAdditionUnsupportedErrorString =
  "Midterm contract additions are not supported. Check the opportunity information in Salesforce.";
export const missingLmsIdErrorString =
  "The LMS ID is undefined for this opportunity. Check the opportunity information in Salesforce.";
export const missingAuthenticationMethodErrorString =
  "A valid identity provider (IdP) and authentication method are required. Check the opportunity information in Salesforce.";
export const multipleAWSAccountsErrorString =
  "Multiple AWS billing accounts are not supported. Check the opportunity information in Salesforce.";
export const missingClassEndDateErrorString =
  "A training expiration date is required. Check the opportunity information in Salesforce.";
export const invalidClassEndDateErrorString =
  "The training expiration date must come after the class start date. Check the opportunity information in Salesforce.";
export const invalidSeatsCountErrorString =
  "The number of seats requested is not valid for this product. Check the opportunity information in Salesforce.";
export const primaryEmailErrorString =
  "Each Skill Builder Team subscription learning administrator requires a unique email address. Check the opportunity information in Salesforce.";
export const regionNotSupported =
  "Skill Builder Team subscription is not available in this customer's region. Check the Salesforce account information related to the opportunity in Salesforce.";

const testContractingPtrr: PTRR = {
  accountName: "test",
  address1: "test",
  address2: "test",
  awsBillingAccount: "test",
  awsEndUserAccount: "test",
  city: "test",
  country: "test",
  createdDate: "test",
  geo: "test",
  legalEntityNameTraining: "test",
  ownerId: "test",
  ptrrId: "test",
  recordTypeId: "test",
  region: "test",
  state: "test",
  trainingBillingContactEmail: "test",
  trainingBillingContactName: "test",
  zipCode: "test",
  billingNotes: "test",
  billingTerms: "test",
  customerTaxVATId: "test",
  opportunityName: "test",
  paymentMethod: "test",
  poNumberOnInvoice: "test",
  ptfTemplateName: "test",
  topsName: "test",
};

const testSalesforceOpportunityLineItem: SalesforceOpportunityLineItem = {
  opportunityLineItemId: "testOpportunityLineItemId",
  productName: "testProductName",
  quantity: 100,
  classEndDate: Date().toString(),
  classStartDate: Date().toString(),
  totalBilledRevenue: 20,
  discountAmount: 3,
  totalPrice: 17,
  billingPeriod: BillingPeriod.ANNUAL,
  primaryTrainingPOC: testPointOfContact,
  secondaryTrainingPOC: testPointOfContact,
  allowedEmailDomains: ["onlyAllowed@email.com", "onlyAllowed2@email.com"],
  ssoDetails: testSSODetails,
  authenticationMethod: ProductOnboardingAuthenticationMethod.SSO,
  contractingRequestCreatedBy: testPointOfContact,
  subscriptionType: TypeOfContract.NEW,
  trainingDeliveryType: "Digital Subscription",
  timeZone: "EST",
  deliveryLanguage: "English",
  classLocation: "Seattle",
  currencyCode: "USD",
  contractingPTRR: testContractingPtrr,
};

const testAwsAccountInfo: AWSAccountInfo = {
  accountId: "testAWSAccountId",
  name: "testAWSName",
  awsAssociation: AwsAssociation.None,
  status: "testStatus",
  billingAddress: testAddress,
};

export const testSalesforceOpportunity: SalesforceOpportunity = {
  opportunityId: "12345678",
  opportunityName: "testOpportunityName",
  sfdcAccountId: "testSfdcAccountId",
  sfdcAccountName: "Test SFDC Account Name",
  grandTotal: 17,
  awsAccountInfo: testAwsAccountInfo,
  items: [testSalesforceOpportunityLineItem],
  bdm: testPointOfContact,
  lmsId: "testId",
  noOfExistingLicenses: 1,
  typeOfContract: TypeOfContract.NEW,
  sfdcParentAccountId: "testSfdcParentAccountId",
  sfdcParentAccountName: "testSfdcParentAccountName",
  sfdcParentOpportunityId: "0067g00000J7uSyLLL",
};

export const testSalesforceOpportunityWithInvalidLearningAccount = (): SalesforceOpportunity => {
  const tempOpportunity = {
    ...testSalesforceOpportunity,
    awsAccountInfo: {
      ...testAwsAccountInfo,
      accountId: "InvalidLearningAccount",
    },
  };
  return tempOpportunity;
};

export const testSalesforceOpportunityWithInvalidCreateLearningAccount = (): SalesforceOpportunity => {
  const tempOpportunity = {
    ...testSalesforceOpportunity,
    sfdcAccountId: "erroredReturn",
  };
  return tempOpportunity;
};

export const testSalesforceOpportunityWithErroredOrderCreate = (): SalesforceOpportunity => {
  const tempOpportunity = {
    ...testSalesforceOpportunity,
    awsAccountInfo: {
      ...testAwsAccountInfo,
      accountId: "erroredOrder",
    },
  };
  return tempOpportunity;
};

export const testEmptySalesforceOpportunity = {
  opportunityId: "12345678",
  opportunityName: "testOpportunityName",
  sfdcAccountId: "testSfdcAccountId",
  sfdcAccountName: "Test SFDC Account Name",
  grandTotal: 17,
  awsAccountInfo: testAwsAccountInfo,
  items: [
    {
      opportunityLineItemId: "testEmptyOpportunityLineItemId",
      productName: "testEmptyProductName",
      quantity: 100,
      classEndDate: "2024-05-31T08:00:00.000Z",
      classStartDate: "2023-06-01T08:00:00.000Z",
      totalBilledRevenue: 20,
      discountAmount: 3,
      totalPrice: 17,
      primaryTrainingPOC: testPointOfContact,
      secondaryTrainingPOC: {
        firstName: "secName",
        lastName: "secLastName",
        email: "secEmail",
      },
      allowedEmailDomains: [],
      ssoDetails: {
        ssoEnabled: true,
        idp: "testIDP",
        technicalPOC: {
          firstName: "ssoName",
          lastName: "ssoLastName",
          email: "ssoEmail",
        },
      },
      contractingRequestCreatedBy: testPointOfContact,
    },
  ],
  bdm: testPointOfContact,
  lmsId: "testId",
  noOfExistingLicenses: 1,
  typeOfContract: TypeOfContract.NEW,
};

export const validOpportunityId = /^[a-zA-Z0-9]{18}$/;
export const placeholderOpportunityId = "0015500000WO1ZiAAL";
export const validationMessage =
  "The Opportunity ID must be [exactly 18 characters long and contain only alphanumeric characters].";
export const importDescriptionMessage = "Enter the Salesforce Opportunity Id to find your customer's order information";

interface MessageMap {
  errorMessage: string;
  errorType: ErrorType;
}

export const sfdcErrorMessageMapping: MessageMap[] = [
  {
    errorMessage: "Opportunity Id not found",
    errorType: ErrorType.OPPORTUNITY_DNE,
  },
  {
    errorMessage: "Opportunity line item or ptrr data not found",
    errorType: ErrorType.OPPORTUNITY_LINE_ITEM_MISSING,
  },
  {
    errorMessage: "AWS Account associated with Opportunity not found",
    errorType: ErrorType.OPPORTUNITY_MISSING_AWS_ACCOUNT,
  },
  {
    errorMessage: "Invalid opportunity record type, sub type or stage",
    errorType: ErrorType.OPPORTUNITY_INVALID_RECORD_TYPE,
  },
  {
    errorMessage: "Opportunity has invalid AWS Account",
    errorType: ErrorType.OPPORTUNITY_INVALID_AWS_ACCOUNT,
  },
  {
    errorMessage: "Could not find address data for customerId in AWS account",
    errorType: ErrorType.OPPORTUNITY_MISSING_ADDRESS,
  },
  {
    errorMessage: "No country code associated with related AWS Account and Opportunity",
    errorType: ErrorType.OPPORTUNITY_NO_COUNTRYCODE,
  },
  {
    errorMessage: "Invalid country code associated with related AWS Account and Opportunity",
    errorType: ErrorType.OPPORTUNITY_INVALID_COUNTRYCODE,
  },
  {
    errorMessage: "System error occurred while retrieving Opportunity",
    errorType: ErrorType.SFDC_ERROR,
  },
  {
    errorMessage: "Invalid input while retrieving address associated with Opportunity",
    errorType: ErrorType.SFDC_ERROR,
  },
  {
    errorMessage:
      "Backend services are failing to process the request. Please try again later or report the issue to the SFDC Integration team.",
    errorType: ErrorType.SFDC_ERROR,
  },
  {
    errorMessage: "Invalid SSO Identity Provider (IdP)",
    errorType: ErrorType.SFDC_SSO_ERROR,
  },
  {
    errorMessage: "Invalid allowed email domain",
    errorType: ErrorType.INVALID_ALLOWED_EMAIL_DOMAIN,
  },
  {
    errorMessage: "Primary and secondary learning admin emails cannot be the same",
    errorType: ErrorType.SAME_PRIMARY_AND_SECONDARY_EMAIL,
  },
  {
    errorMessage: "Product is not supported",
    errorType: ErrorType.INVALID_PRODUCT_NAME,
  },
  {
    errorMessage: "Multiple Products found on Salesforce Opportunity",
    errorType: ErrorType.MULTIPLE_PRODUCTS,
  },
  {
    errorMessage: "Multiple Contracting PTRR found on Salesforce Opportunity",
    errorType: ErrorType.MULTIPLE_PTRRS,
  },
  {
    errorMessage: "Invalid Authentication Method",
    errorType: ErrorType.INVALID_AUTH_METHOD,
  },
  {
    errorMessage: "The SSO IDP can not be set if the SSO authentication method is not selected",
    errorType: ErrorType.INVALID_IDENTITY_PROVIDER,
  },
  {
    errorMessage: "Mid term addition is not supported",
    errorType: ErrorType.MIDTERM_ADDITION_CONTRACT_TYPE,
  },
  {
    errorMessage: "No authentication method was selected",
    errorType: ErrorType.AUTH_METHOD_MISSING,
  },
  {
    errorMessage: "Multiple AWS accounts are present",
    errorType: ErrorType.MULTIPLE_AWS_ACCOUNTS,
  },
  {
    errorMessage: "The training expiration date is empty",
    errorType: ErrorType.CLASS_END_MISSING,
  },
  {
    errorMessage: "The LMS ID is undefined",
    errorType: ErrorType.MISSING_LMS_ID,
  },
  {
    errorMessage: "The training expiration date comes before the class start date",
    errorType: ErrorType.CLASS_END_INVALID,
  },
  {
    errorMessage: "Incorrect number of seats for product",
    errorType: ErrorType.INVALID_SEATS_COUNT,
  },
  {
    errorMessage: "The primary learning admin does not have an associated email",
    errorType: ErrorType.NO_PRIMARY_EMAIL,
  },
  {
    errorMessage: "Unable to retrieve accounts associated with the opportunity",
    errorType: ErrorType.REGION_NOT_SUPPORTED,
  },
];

const metaDataFromAwsAccount = ["countryCode"];
const metaDataFromOpportunity = ["lmsId"];
const metaDataFromOpportunityLine = [
  "timeZone",
  "deliveryLanguage",
  "classLocation",
  "currencyCode",
  "trainingDeliveryType",
];
const metaDataFromPtrr = [
  "address1",
  "address2",
  "city",
  "country",
  "trainingBillingContactEmail",
  "trainingBillingContactName",
  "zipCode",
  "state",
  "customerTaxVATId",
  "awsEndUserAccount",
  "billingTerms",
  "accountName",
  "legalEntityNameTraining",
  "ptfTemplateName",
  "poNumberOnInvoice",
];

export const getMetadataInput = (lineItem: SalesforceOpportunityLineItem, opportunity: SalesforceOpportunity) => {
  const ptrr = lineItem.contractingPTRR!;
  const metadata: Array<{ key: string; value: string }> = [];
  const metadataFields = metaDataFromOpportunity
    .concat(metaDataFromOpportunityLine)
    .concat(metaDataFromPtrr)
    .concat(metaDataFromAwsAccount);
  metadataFields.forEach((item) => {
    if (metaDataFromAwsAccount.includes(item)) {
      metadata.push({
        key: item,
        value: `"${opportunity.awsAccountInfo.billingAddress[item as keyof Address]}"`,
      });
    }
    if (metaDataFromOpportunity.includes(item)) {
      metadata.push({
        key: item,
        value: `"${opportunity[item as keyof SalesforceOpportunity]}"`,
      });
    }
    if (metaDataFromOpportunityLine.includes(item)) {
      metadata.push({
        key: item,
        value: `"${lineItem[item as keyof SalesforceOpportunityLineItem]}"`,
      });
    }
    if (metaDataFromPtrr.includes(item)) {
      if (ptrr) {
        metadata.push({
          key: item,
          value: `"${ptrr[item as keyof PTRR]}"`,
        });
      }
    }
  });

  return metadata.filter(
    (pair) => pair && pair.value !== `"${undefined}"` && pair.value !== `"${null}"` && pair.value !== `""`
  );
};
