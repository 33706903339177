import { RoleType } from "../user/types";

export const rolesAuthorizedToViewLAUsers = [
  RoleType.SUPER_USER,
  RoleType.LEARNING_ADMIN,
  RoleType.INTERNAL_EMPLOYEE,
  RoleType.LEARNING_ACCOUNT_IT_CONTACT,
];

export const rolesAuthorizedToViewLAOrders = [RoleType.SUPER_USER, RoleType.INTERNAL_EMPLOYEE];

export const MAX_PAGE_SIZE = 1_000; // TODO: https://tasks.awstcprojects.com/browse/PLATFORM-3403
