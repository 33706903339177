import { useLazyQuery } from "@apollo/client";
import {
  OrganizationByVendorCodeDocument,
  OrganizationByVendorCodeQuery,
  OrganizationByVendorCodeQueryVariables,
} from "../../../__generated__/supergraph";

export const useOrganizationByVendorCode = ({ vendorCode }: OrganizationByVendorCodeQueryVariables) => {
  return useLazyQuery<OrganizationByVendorCodeQuery>(OrganizationByVendorCodeDocument, {
    variables: { vendorCode },
  });
};
