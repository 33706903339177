import { AppLayout } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Breadcrumbs, Navigation } from "../../components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { LearningAccountsTable } from "../components/LearningAccountsTable";
import { Flashbar } from "@amzn/awsui-components-react";
import { useLearningAccountsTable } from "../components/useLearningAccountsTable";
import styled from "styled-components";

const StyledFlashbar = styled(Flashbar)`
  padding-top: 8px;
`;

function LearningAccountsMainContent(): JSX.Element {
  return <LearningAccountsTable />;
}

export function LearningAccountsMain(): JSX.Element {
  const intl = useIntl();
  const { flashbarItems, learningAccountsError } = useLearningAccountsTable();

  return (
    <AppLayout
      content={<LearningAccountsMainContent />}
      contentType="table"
      notifications={learningAccountsError && <StyledFlashbar items={flashbarItems} />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      breadcrumbs={<Breadcrumbs />}
      navigation={<Navigation />}
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
      toolsHide
    />
  );
}
