export enum CookieCategory {
  Essential = "Essential",
  Performance = "Performance",
  Functional = "Functional",
  Advertising = "Advertising",
}

export const CookieNames = {
  CLOUDWATCH_RUM_S: "cwr_s",
  CLOUDWATCH_RUM_U: "cwr_u",
};
