import { Header, Container, ColumnLayout, SpaceBetween, Box } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { ReviewAndSubmitFulfillmentProps } from "../types";

const ValueWithLabel = (props: { label: any; children: any }) => (
  <div style={{ marginTop: "10px" }}>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

export function ReviewAndSubmitFulfillmentDetails(props: ReviewAndSubmitFulfillmentProps): JSX.Element {
  const emailDomains = props.allowedEmailDomains.filter((email) => email !== "");
  const intl = useIntl();
  const parseDate = (rawDate: string) => {
    const months: string[] = [
      intl.formatMessage({ id: "dates.month.january" }),
      intl.formatMessage({ id: "dates.month.february" }),
      intl.formatMessage({ id: "dates.month.march" }),
      intl.formatMessage({ id: "dates.month.april" }),
      intl.formatMessage({ id: "dates.month.may" }),
      intl.formatMessage({ id: "dates.month.june" }),
      intl.formatMessage({ id: "dates.month.july" }),
      intl.formatMessage({ id: "dates.month.august" }),
      intl.formatMessage({ id: "dates.month.september" }),
      intl.formatMessage({ id: "dates.month.october" }),
      intl.formatMessage({ id: "dates.month.november" }),
      intl.formatMessage({ id: "dates.month.december" }),
    ];

    const date = new Date(rawDate);
    const isUTCDate = rawDate.endsWith("Z");
    return isUTCDate
      ? `${months[date.getUTCMonth()]} ${date.getUTCDate()}, ${date.getUTCFullYear()}`
      : `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const classStart = props.classStartDate ? parseDate(props.classStartDate) : undefined;
  const classEnd = props.classEndDate ? parseDate(props.classEndDate) : undefined;

  let termLength = undefined;
  if (classStart && classEnd) {
    const startDate = new Date(classStart);
    const endDate = new Date(classEnd);
    endDate.setDate(endDate.getDate() + 1); // Include the end day in the term length.
    termLength = (endDate as any) - (startDate as any);
  }

  if (termLength) {
    termLength = Math.ceil(termLength / (1000 * 3600 * 24));
  }

  return (
    <div>
      <Container
        data-testid="review-and-submit-fulfillment-card"
        header={<Header variant="h2">{intl.formatMessage({ id: "reviewAndSubmit.fulfillmentDetails" })}</Header>}
      >
        <Box />
        <ColumnLayout columns={3} variant="text-grid">
          <ColumnLayout columns={1}>
            <SpaceBetween size="l">
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.customerName" })}>
                  {props.customerName}
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.branchId" })}>
                  {props.branchId}
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <SpaceBetween size="l">
                  <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.classStart" })}>
                    <div data-testid="classStartDate">{classStart ? <div>{classStart}</div> : <div>-</div>}</div>
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                  <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.classEnd" })}>
                    <div data-testid="classEndDate">{classEnd ? <div>{classEnd}</div> : <div>-</div>}</div>
                  </ValueWithLabel>
                </SpaceBetween>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.termLength" })}>
                  <div data-testid="termLengthInDays">{termLength ? <div>{termLength} days</div> : <div>-</div>}</div>
                </ValueWithLabel>
              </SpaceBetween>
              <Box />
            </SpaceBetween>
          </ColumnLayout>
          <ColumnLayout columns={1}>
            <SpaceBetween size="l">
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.onboardingTicket" })}>
                  {"Pending"}
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.supportTicket" })}>
                  {"Not applicable"}
                </ValueWithLabel>
              </SpaceBetween>
              {
                <SpaceBetween size="l">
                  <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.learningAdministrators" })}>
                    <div>
                      {props.learningAdmins.map((e, index) => (
                        <div key={index}>
                          {e.firstName} {e.lastName}, {e.email}{" "}
                        </div>
                      ))}{" "}
                    </div>
                  </ValueWithLabel>
                </SpaceBetween>
              }
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.fulfillmentStatus" })}>
                  {"Pending"}
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.allowedEmailDomains" })}>
                  <div>
                    {emailDomains && emailDomains.length > 0 && emailDomains[0] !== ""
                      ? emailDomains.map((e) => <div key={e}>{e}</div>)
                      : "Not applicable"}
                  </div>
                </ValueWithLabel>
              </SpaceBetween>
              <Box />
            </SpaceBetween>
          </ColumnLayout>
          <ColumnLayout columns={1}>
            <SpaceBetween size="l">
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.sso" })}>
                  <div>{props.ssoEnabled ? "Yes" : "No"}</div>
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.identityProvider" })}>
                  <div>{props.identityProvider ? props.identityProvider : "Not applicable"}</div>
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.SSOIntegrationContact" })}>
                  {props.ssoEnabled ? (
                    <div>
                      <div>{props.ssoContact?.firstName + " " + props.ssoContact?.lastName + " "}</div>
                      <div>{props.ssoContact?.email}</div>
                    </div>
                  ) : (
                    <div>Not applicable</div>
                  )}
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.authenticationMethod" })}>
                  <div data-testid="authenticationMethod">
                    {props.authenticationMethod ? props.authenticationMethod : "-"}
                  </div>
                </ValueWithLabel>
              </SpaceBetween>
              <Box />
            </SpaceBetween>
          </ColumnLayout>
        </ColumnLayout>
        <Box />
      </Container>
    </div>
  );
}
