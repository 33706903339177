import { StatusIndicatorProps } from "@amzn/awsui-components-react";

export enum UserCreationStatus {
  Failed = "FAILED",
  Processing = "PROCESSING",
  Succeeded = "SUCCEEDED",
}
export type UserBulkOperationFile = {
  fileId: string;
  userCreationStatus: UserCreationStatus;
  createdAt: string;
  fileMetadata: {
    name: string;
  };
  learningAccount: {
    id: string;
    name: string;
  };
};

export const USER_CREATION_STATUS_TO_INDICATOR_TYPE: Record<UserCreationStatus, StatusIndicatorProps.Type> = {
  [UserCreationStatus.Failed]: "error",
  [UserCreationStatus.Processing]: "in-progress",
  [UserCreationStatus.Succeeded]: "success",
} as const;
type UserBulkOperationFileStatusI18nKey = `userImportsTableComponent.status.${string}`;
export const USER_CREATION_STATUS_TO_I18N_KEY: Record<UserCreationStatus, UserBulkOperationFileStatusI18nKey> = {
  [UserCreationStatus.Failed]: "userImportsTableComponent.status.failed",
  [UserCreationStatus.Processing]: "userImportsTableComponent.status.processing",
  [UserCreationStatus.Succeeded]: "userImportsTableComponent.status.succeeded",
} as const;

/**
 * The property filter cannot access nested keys, so this type exists to flatten certain fields for the filter.
 */
export type UserBulkOperationFileExtended = UserBulkOperationFile & {
  readonly fileName: string;
  readonly timeImportedText: string;
  readonly learningAccountName: string;
  readonly statusText: string;
};

export const PAGE_SIZE = 20;
