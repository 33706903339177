import { BreadcrumbGroup, BreadcrumbGroupProps } from "@amzn/awsui-components-react";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { getLearningConsoleRoutes } from "../../common/routes";

export function Breadcrumbs(): JSX.Element | null {
  const navigate = useNavigate();
  const intl = useIntl();

  // Use react router breadcrumbs library to handle the logic of figuring out which breadcrumbs we need to display based on the URL.
  // https://www.npmjs.com/package/use-react-router-breadcrumbs UseReactRouterBreadCrumbs -- for breadcrumb API
  const breadcrumbs = useReactRouterBreadcrumbs(getLearningConsoleRoutes());

  const items: BreadcrumbGroupProps.Item[] = breadcrumbs.map(({ breadcrumb, key }) => ({
    text: breadcrumb,
    href: key,
  }));

  return (
    <BreadcrumbGroup
      items={items}
      onFollow={(e) => {
        e.preventDefault();
        navigate(e.detail.href);
      }}
      ariaLabel={intl.formatMessage({ id: "breadcrumbs.ariaLabel" })}
      expandAriaLabel={intl.formatMessage({ id: "breadcrumbs.expandAriaLabel" })}
    />
  );
}
