import { ApplicationUser } from "../auth/ApplicationUser";
import { rolesAuthorizedForUserActions } from "./constants";
import { bulkUserOperationIsEnabled } from "../common/featureFlags";

export const isAuthorizedForUserActions = (user: ApplicationUser) => {
  if (bulkUserOperationIsEnabled() && user.role && rolesAuthorizedForUserActions.includes(user.role)) {
    return true;
  }
  return false;
};
