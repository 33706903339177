export enum NodeEnvironment {
  TEST = "test",
  DEVELOPMENT = "development",
  BETA = "beta",
  GAMMA = "gamma",
  PROD = "prod",
}

export const getNodeEnvironment = (environmentValue: string = process.env.NODE_ENV || ""): NodeEnvironment => {
  const environment = Object.keys(NodeEnvironment).filter((key) => key === environmentValue.toUpperCase())[0];

  if (!environment) {
    throw new Error(`Node environment [${environmentValue}] is not valid`);
  }
  return NodeEnvironment[environment as keyof typeof NodeEnvironment];
};
