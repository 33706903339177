import { Button } from "@amzn/awsui-components-react";
import { ApolloError } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useMutateInternalUser } from "../api/useInternalUserMutation";
import { useNavigate } from "react-router-dom";
import { CreateInternalUserInput, UpdateUserInput } from "../types";
import { UserPage, validEmail } from "../constants";
import { INTERNAL_USER_ROUTE } from "../../common/routes";

export const ITEMS_PER_PAGE = 10;

export function useUsers() {
  const [id, setId] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("INTERNAL_EMPLOYEE");
  const [status, setStatus] = useState("ACTIVE");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  //using non primitive data type to force validation focus on every submit
  const [validationError, setValidationError] = useState({ state: false });
  const [flashbarItems, setFlashbarItems] = useState([{}]);
  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const emailRef = useRef<HTMLInputElement>();
  const intl = useIntl();
  const navigate = useNavigate();

  //Make request to graphQl with updated filter request.
  const createInternalUserInput = (): CreateInternalUserInput => {
    return {
      firstName: firstname,
      lastName: lastname,
      emailAddress: email,
      userRole: role,
    };
  };

  const createUpdateUserInput = (): UpdateUserInput => {
    return {
      userId: id,
      firstName: firstname,
      lastName: lastname,
      emailAddress: email,
      userRole: role,
      status: status,
    };
  };

  const setErrorFlashbar = async (error: ApolloError) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    //check which error we have
    let button = null;
    let content = intl.formatMessage({
      id: `userComponent.flashbar.errorMessage.${
        id === "" ? UserPage.CREATE.toLowerCase() : UserPage.EDIT.toLowerCase()
      }`,
    });
    if (error.message.includes("ConditionalCheckFailed")) {
      content = intl.formatMessage({ id: "userComponent.flashbar.userExistsMessage" });
      button = (
        <Button onClick={() => navigate(`${INTERNAL_USER_ROUTE}/${email}`)}>
          {" "}
          {intl.formatMessage({ id: "userComponent.flashbar.button.userExistsMessage" })}{" "}
        </Button>
      );
    }
    setFlashbarItems([
      {
        type: "error",
        content: content,
        dismissLabel: intl.formatMessage({ id: "internalUserTableComponent.flashbar.dismissLabel" }),
        dismissible: true,
        action: button,
        onDismiss: () => {
          setFlashbarItems([]);
        },
        statusIconAriaLabel: "error",
      },
    ]);
  };

  const [createInternalUser, { data, loading, error }] = useMutateInternalUser(
    id === "" ? createInternalUserInput() : createUpdateUserInput()
  );

  useEffect(() => {
    if (error) {
      setErrorFlashbar(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (data.createInternalUser) {
        navigate(`${INTERNAL_USER_ROUTE}/${data.createInternalUser.id}`, {
          state: { action: UserPage.CREATE },
        });
      } else if (data.updateUser) {
        navigate(`${INTERNAL_USER_ROUTE}/${data.updateUser.id}`, {
          state: { action: UserPage.EDIT },
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (validationError.state) {
      if (firstNameError !== "" && firstNameRef.current) {
        firstNameRef.current.focus();
      } else if (lastNameError !== "" && lastNameRef.current) {
        lastNameRef.current.focus();
      } else if (emailError !== "" && emailRef.current) {
        emailRef.current.focus();
      }
    }
  }, [validationError]);

  const onFirstNameChange = (value: any) => {
    setFirstNameError("");
    setFirstName(value);
  };

  const onLastNameChange = (value: any) => {
    setLastNameError("");
    setLastName(value);
  };

  const onEmailChange = (value: any) => {
    setEmailError("");
    setEmail(value);
  };

  const onRoleChange = (value: any) => {
    setRole(value);
  };

  const onIdChange = (value: any) => {
    setId(value);
  };

  const onStatusChange = (value: any) => {
    setStatus(value);
  };

  const handleSubmit = () => {
    if (firstname.trim() === "") {
      setValidationError({ state: true });
      setFirstNameError(intl.formatMessage({ id: "userComponent.firstNameRequired.validationError" }));
    } else if (firstname.length >= 80) {
      setValidationError({ state: true });
      setFirstNameError(intl.formatMessage({ id: "userComponent.firstNameLength.validationError" }));
    }

    if (lastname.trim() === "") {
      setValidationError({ state: true });
      setLastNameError(intl.formatMessage({ id: "userComponent.lastNameRequired.validationError" }));
    } else if (lastname.length >= 80) {
      setValidationError({ state: true });
      setLastNameError(intl.formatMessage({ id: "userComponent.lastNameLength.validationError" }));
    }

    if (email.trim() === "") {
      setValidationError({ state: true });
      setEmailError(intl.formatMessage({ id: "userComponent.emailAddressRequired.validationError" }));
    } else if (!validEmail.test(email)) {
      setValidationError({ state: true });
      setEmailError(intl.formatMessage({ id: "userComponent.emailAddressFormat.validationError" }));
    }

    if (
      !(
        firstname.trim() === "" ||
        firstname.length >= 80 ||
        lastname.trim() === "" ||
        lastname.length >= 80 ||
        email.trim() === "" ||
        !validEmail.test(email)
      )
    ) {
      setValidationError({ state: false });
      createInternalUser();
    }
  };
  return {
    createInternalUserLoading: loading,
    createInternalUserError: error,
    onFirstNameChange,
    onLastNameChange,
    onEmailChange,
    onRoleChange,
    handleSubmit,
    onIdChange,
    onStatusChange,
    flashbarItems,
    firstNameError,
    lastNameError,
    emailError,
    firstname,
    lastname,
    email,
    role,
    status,
    firstNameRef,
    lastNameRef,
    emailRef,
  };
}
