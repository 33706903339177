import { gql, useLazyQuery, useQuery } from "@apollo/client";

export const GET_USER_BULK_OPERATION_FILE_BY_ID = gql`
  query GetUserBulkOperationFileById($userId: ID!, $fileId: ID!) {
    userBulkOperationFileById(userId: $userId, fileId: $fileId) {
      fileMetadata {
        name
      }
      executionLogReportUrl
    }
  }
`;

export const useGetUserBulkOperationFileById = (userId: string | undefined, fileId: string) => {
  return useQuery(GET_USER_BULK_OPERATION_FILE_BY_ID, {
    skip: !userId,
    variables: { userId, fileId },
  });
};

export const useGetUserBulkOperationFileByIdLazy = (userId: string | undefined, fileId: string) => {
  return useLazyQuery(GET_USER_BULK_OPERATION_FILE_BY_ID, {
    variables: { userId, fileId },
  });
};
