import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import { Header, AppLayout, SpaceBetween } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Navigation, Breadcrumbs } from "../../components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { OrganizationInfoCard, OrganizationOverviewCard } from "../components";
import "../../pages/styles.scss";

function DashboardContent(): JSX.Element {
  const intl = useIntl();

  return (
    <ContentLayout header={<Header variant="h1">{intl.formatMessage({ id: "dashboardPage.header" })}</Header>}>
      <SpaceBetween direction="horizontal" size="l">
        <OrganizationInfoCard />
        <OrganizationOverviewCard />
      </SpaceBetween>
    </ContentLayout>
  );
}

export function Dashboard(): JSX.Element {
  const intl = useIntl();
  return (
    <AppLayout
      content={<DashboardContent />}
      breadcrumbs={<Breadcrumbs />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      navigation={<Navigation />}
      toolsHide
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
    />
  );
}
