import { FlashbarProps } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useOrganizationByIdQuery } from "../../api/useOrganizationByIdQuery";
import { UserAssociation } from "../../../../__generated__/supergraph";

export function useLearningAccountUsersTable() {
  const intl = useIntl();
  const currentLearningAccountId = useLocation().pathname.split("/")[2];

  const [filterText, setFilterText] = useState("");
  const [delayedFilterText, setDelayedFilterText] = useState("");
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [totalItemsCount, setTotalItemsCount] = useState("(0)");
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [pagination, setPagination] = useState(true);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const { error, data: organizationByIdResponse } = useOrganizationByIdQuery(
    currentLearningAccountId,
    filterText,
    cursor
  );
  const [orgnizationUserAssociations, setOrgnizationUserAssociations] = useState<UserAssociation[]>([]);
  const [flashbarItems, setFlashbarItems] = useState<readonly FlashbarProps.MessageDefinition[]>([{}]);
  /**
   * Cache the cursor used to query the page. The key is the filter text concatenated with the page number.
   * E.g. the cursor to query page 2 with the filter text "Asdf" will be at the "Asdf2" key.
   */
  const [cache, setCache] = useState<Map<string, string | undefined>>(new Map([[`${currentPageIndex}`, cursor]]));

  // Set items when there's query data
  useEffect(() => {
    if (organizationByIdResponse?.organizationById?.userAssociations?.nodes) {
      const newFiles = organizationByIdResponse?.organizationById?.userAssociations?.nodes;
      setOrgnizationUserAssociations(newFiles);
    } else {
      setOrgnizationUserAssociations([]);
    }
  }, [organizationByIdResponse]);

  useEffect(() => {
    setLoading(!organizationByIdResponse && !error);
  }, [organizationByIdResponse, error]);

  const onPaginationChange = (page: number) => {
    const cachedCursor = cache.get(`${delayedFilterText}${page}`);
    if (cachedCursor || cachedCursor === undefined) {
      // Use cached cursor, so the query does not re-run
      setCursor(cachedCursor);
    }
    setCurrentPageIndex(page);
  };

  useEffect(() => {
    if (organizationByIdResponse) {
      const nextPageCursor: string | undefined =
        organizationByIdResponse?.organizationById?.userAssociations?.pageInfo?.cursor;
      const moreData = nextPageCursor?.length;
      if (totalPagesCount === 1 && moreData) {
        // Total number of pages is 1 (initial load) and there is more data
        setCache((map) => map.set(`${delayedFilterText}${currentPageIndex + 1}`, nextPageCursor));
        setTotalPagesCount((prev) => prev + 1);
        setTotalItemsCount(`(${organizationByIdResponse.organizationById?.userAssociations?.nodes.length}+)`);
      } else if (totalPagesCount === currentPageIndex) {
        // On the last page
        if (moreData) {
          // There is more data
          setCache((map) => map.set(`${delayedFilterText}${currentPageIndex + 1}`, nextPageCursor));
          setTotalPagesCount((prev) => prev + 1);
          return;
        }
        // No more data
        if (totalPagesCount === 1) {
          // All data fits on one page
          setTotalItemsCount(`(${organizationByIdResponse.organizationById?.userAssociations.nodes.length})`);
        }
        setPagination(false);
      }
    }
  }, [organizationByIdResponse, totalPagesCount, currentPageIndex]);

  // Reset page when a user searches
  useEffect(() => {
    setPagination(true);
    setCache((map) => map.set(`${delayedFilterText}1`, undefined));
    setCursor(undefined);
    setTotalPagesCount(1);
    setCurrentPageIndex(1);
  }, [delayedFilterText]);

  const setErrorFlashbar = async () => {
    setFlashbarItems([
      {
        type: "error",
        content: intl.formatMessage({ id: "usersTableComponent.flashbar.genericErrorMessage" }),
        dismissLabel: intl.formatMessage({ id: "internalUserTableComponent.flashbar.dismissLabel" }),
        dismissible: true,
        onDismiss: () => {
          setFlashbarItems([]);
        },
        statusIconAriaLabel: "error",
      },
    ]);
  };

  // Set error
  useEffect(() => {
    if (error) {
      setErrorFlashbar();
    }
  }, [error]);

  return {
    items: orgnizationUserAssociations,
    loading,
    error,
    totalItemsCount,
    totalPagesCount,
    currentPageIndex,
    filterText,
    setFilterText,
    setDelayedFilterText,
    pagination,
    onPaginationChange,
    flashbarItems,
  };
}
