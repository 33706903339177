import { Box } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";

export type TableEmptyStateProps = {
  emptyMessageId: string;
};

export const TableEmptyState = ({ emptyMessageId }: TableEmptyStateProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
      <b>{intl.formatMessage({ id: emptyMessageId })}</b>
    </Box>
  );
};
