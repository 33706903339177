import { FlagNames, isFeatureEnabled, Stages } from "@amzn/aws-tc-commerce-feature-flags";
import { getNodeEnvironment, NodeEnvironment } from "./nodeEnvironment";

const stage = getNodeEnvironment();

export const bulkUserOperationIsEnabled = () => isFeatureEnabled(FlagNames.BULK_USER_OPERATION, stage as Stages);

export const iltLearningAdminExperienceIsEnabled = () =>
  isFeatureEnabled(FlagNames.ILT_LEARNING_ADMIN_EXPERIENCE, stage as Stages);

export const hcAdminIsEnabled = () => isFeatureEnabled(FlagNames.HC_ADMIN_ORDERING_TEAM, stage as Stages);

export const clientIntegrationIsEnabled = () => isFeatureEnabled(FlagNames.CLIENT_INTEGRATION_NMBS, stage as Stages);

export const isGetOpportunityV2Enabled = () => {
  if (stage === NodeEnvironment.PROD) {
    return clientIntegrationIsEnabled();
  }

  return hcAdminIsEnabled();
};
