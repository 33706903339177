import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { showError } from "../../common/utilityFunctions";
import { useGetOrdersByLearningAccountQuery } from "../api/useGetOrdersByLearningAccountQuery";
import { fulfillmentStatusMap } from "../constants";
import { OrderLine } from "../types";

type OrderLineData = {
  id: string;
  opportunityId: string;
  items: [any];
  auditMetadata: {
    createdBy: string;
  };
  status: string;
  fulfillment?: any;
  learningAccountId?: string;
};

const convertOrderToOrderLine = (order: OrderLineData): OrderLine => {
  return {
    id: order.id,
    opportunityId: order.opportunityId,
    amount:
      order.items.length > 0 ? order.items.map((item) => item.subTotal).reduce((total, amount) => total + amount) : 0,
    createdBy: order.auditMetadata.createdBy,
    status: order.fulfillment ? fulfillmentStatusMap.get(order.fulfillment.status as string)! : order.status,
  };
};

export const useOrdersListTable = () => {
  const intl = useIntl();
  const [flashbarItems, setFlashbarItems] = useState([{}]);

  const setErrorFlashbar = async () => {
    setFlashbarItems([
      {
        type: "error",
        content: intl.formatMessage({ id: "ordersListTableComponent.flashbar.errorMessage" }),
        dismissLabel: intl.formatMessage({ id: "ordersListTableComponent.flashbar.dismissLabel" }),
        statusIconAriaLabel: "error",
      },
    ]);
  };

  let orders = [];
  const currentLearningAccountId = useLocation().pathname.split("/")[2];
  const [cursor, setCursor] = useState("");
  const { loading, error, data } = useGetOrdersByLearningAccountQuery(currentLearningAccountId, cursor);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pagination, setPagination] = useState(true);
  //cache map has a key of pageNumber which maps to a cursor
  const [cache, setCache] = useState<Map<number, string>>(new Map([[0, ""]]));

  const listOfOrders = (data?.learningAccountById.orders.nodes as OrderLineData[]) ?? [];
  const listOfOrderLines = listOfOrders.map(convertOrderToOrderLine);
  listOfOrderLines.forEach((item) => (item.learningAccountId = currentLearningAccountId));
  orders = listOfOrderLines;

  useEffect(() => {
    setPagination(true);
    setCache((map) => new Map(map.set(0, "")));
    setCursor("");
    setTotalPages(1);
    setCurrentPageIndex(1);
  }, []);

  useEffect(() => {
    if (showError(error)) {
      setErrorFlashbar();
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const currentCursor = data.learningAccountById.orders.pageInfo.cursor;
      // handles the case for no orders
      if (currentCursor === null) {
        setPagination(false);
      }
      //handles intial loading of data on the first page
      else if (totalPages === 1 && currentCursor !== "" && totalPages === currentPageIndex) {
        setCache((map) => new Map(map.set(currentPageIndex, currentCursor)));
        setTotalPages((prev) => prev + 1);
        console.log("tp: ", totalPages);
      } else if (currentCursor === "" && totalPages === currentPageIndex) {
        //if no next page and on the last rendered page
        setPagination(false);
      } else if (cache.get(currentPageIndex - 1) && totalPages === currentPageIndex) {
        //there is next page and on last rendered page
        setCache((map) => new Map(map.set(currentPageIndex, currentCursor)));
        setTotalPages((prev) => prev + 1);
      }
    }
  }, [data, totalPages, currentPageIndex]);

  const onPaginationChange = (page: any) => {
    const cursorVal = cache.get(page - 1);
    if (cursorVal || cursorVal === "") {
      // use cached values
      setCursor(cursorVal);
    }
    setCurrentPageIndex(page);
  };

  return {
    orderData: orders,
    errorInfo: error,
    loadingData: loading,
    flashbarItems,
    totalPages,
    pagination,
    currentPageIndex,
    onPaginationChange,
  };
};
