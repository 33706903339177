import {
  Box,
  Button,
  ColumnLayout,
  Container,
  StatusIndicator,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";

export function OrganizationInfoCard(): JSX.Element {
  const intl = useIntl();

  const ValueWithLabel = (props: { label: any; children: any }) => (
    <div style={{ marginTop: "10px" }}>
      <Box variant="awsui-key-label">{props.label}</Box>
      <div>{props.children}</div>
    </div>
  );

  return (
    <div data-testid={"organization-info-card"}>
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="l">
                {" "}
                <Button>{intl.formatMessage({ id: `orgInfoCardComponent.submit.manage` })}</Button>
              </SpaceBetween>
            }
          >
            {intl.formatMessage({ id: "orgInfoCardComponent.header.organizationName" })}
          </Header>
        }
      >
        <ColumnLayout columns={2} variant="default">
          <SpaceBetween direction="vertical" size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orgInfoCardComponent.label.region" })}>
              <div data-testid={"region"}>{"US East (N. Virginia)"}</div>
            </ValueWithLabel>
            <ValueWithLabel label={intl.formatMessage({ id: "orgInfoCardComponent.label.status" })}>
              <StatusIndicator>
                {intl.formatMessage({ id: "orgInfoCardComponent.label.statusIndicator" })}
              </StatusIndicator>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </div>
  );
}
