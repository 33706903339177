import {
  Button,
  Container,
  Flashbar,
  Header,
  NonCancelableCustomEvent,
  Pagination,
  PaginationProps,
} from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import TextFilter, { TextFilterProps } from "@amzn/awsui-components-react/polaris/text-filter";
import { useIntl } from "react-intl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IMPORT_USERS_ROUTE, LEARNING_ACCOUNTS_ROUTE } from "../../../common/routes";
import { TableNoMatchState } from "../../../components";
import { TableEmptyState } from "../../../components/tableEmptyState/TableEmptyState";
import { useAuth } from "../../../hooks/useAuth";
import { LADetailsPageTable } from "../../../order/constants";
import { Role } from "../../constants";
import { isAuthorizedForUserActions } from "../../utils";
import { useLearningAccountUsersTable } from "./useLearningAccountUsersTable";
import { UserAssociation } from "../../../../__generated__/supergraph";

const maxWidthStyle = {
  maxWidth: "350px",
};

export function LearningAccountUsersTable(): JSX.Element {
  const navigate = useNavigate();
  const currentLearningAccountId = useLocation().pathname.split("/")[2];
  const intl = useIntl();
  const { user } = useAuth();

  const {
    items,
    loading,
    error,
    totalItemsCount,
    totalPagesCount,
    currentPageIndex,
    filterText,
    setFilterText,
    setDelayedFilterText,
    pagination,
    onPaginationChange,
    flashbarItems,
  } = useLearningAccountUsersTable();

  const renderUsersTable = (): JSX.Element => {
    return (
      <LADetailsPageTable
        ariaLabels={{ tableLabel: "LA Users List" }}
        data-testid="users-table"
        variant="embedded"
        visibleColumns={items.length === 0 ? [] : undefined}
        loadingText={intl.formatMessage({ id: "learningAccountsUsersTableComponent.loadingText" })}
        loading={loading}
        columnDefinitions={[
          {
            id: "Name",
            header: intl.formatMessage({ id: "learningAccountsUsersTableComponent.header.name" }),
            cell: (item: UserAssociation) => (
              <Link to={`${LEARNING_ACCOUNTS_ROUTE}/${currentLearningAccountId}/users/${item.user.id}`}>
                {item.user.firstName + " " + item.user.lastName}
              </Link>
            ),
          },
          {
            id: "Email",
            header: intl.formatMessage({ id: "learningAccountsUsersTableComponent.header.email" }),
            cell: (item: UserAssociation) => item.user.emailAddress,
          },
          {
            id: "Role",
            header: intl.formatMessage({ id: "learningAccountsUsersTableComponent.header.role" }),
            cell: (item: UserAssociation) => (
              <div>{Object.values(Role)[Object.keys(Role).indexOf(item.user.userRole)]}</div>
            ),
          },
        ]}
        items={items}
        empty={
          error ? (
            <Flashbar data-testid="LAUsers-error-flashbar" items={flashbarItems} />
          ) : items && filterText ? (
            <TableNoMatchState onClearFilter={() => setFilterText("")} />
          ) : (
            <TableEmptyState emptyMessageId="learningAccountsDetailPage.tabs.users.noUsersFound" />
          )
        }
        resizableColumns={true}
        header={
          <Header
            counter={totalItemsCount}
            actions={
              isAuthorizedForUserActions(user) ? (
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    data-testid={"add-user"}
                    onClick={() => navigate(`${LEARNING_ACCOUNTS_ROUTE}/${currentLearningAccountId}/create`)}
                  >
                    {intl.formatMessage({ id: "learningAccountsUsersTableComponent.button.addUser" })}
                  </Button>
                  <Button
                    variant="primary"
                    data-testid={"import-user"}
                    onClick={() =>
                      navigate(IMPORT_USERS_ROUTE, { state: { currentLearningAccountId: currentLearningAccountId } })
                    }
                  >
                    {intl.formatMessage({ id: "learningAccountsUsersTableComponent.button.importUser" })}
                  </Button>
                </SpaceBetween>
              ) : undefined
            }
          >
            {intl.formatMessage({ id: "learningAccountsUsersTableComponent.header" })}
          </Header>
        }
        filter={
          <div style={maxWidthStyle}>
            <TextFilter
              data-testid="search-la-users-testid"
              filteringText={filterText}
              onChange={({ detail }: NonCancelableCustomEvent<TextFilterProps.ChangeDetail>) =>
                setFilterText(detail.filteringText)
              }
              onDelayedChange={() => setDelayedFilterText(filterText)}
              filteringPlaceholder={intl.formatMessage({
                id: "learningAccountsUsersTableComponent.filtering.placeholder",
              })}
              filteringClearAriaLabel={intl.formatMessage({
                id: "learningAccountsTableComponent.textFilter.clearButton.ariaLabel",
              })}
            />
          </div>
        }
        pagination={
          <Pagination
            ariaLabels={{
              nextPageLabel: intl.formatMessage({ id: "userImportsTableComponent.pagination.nextPage" }),
              previousPageLabel: intl.formatMessage({ id: "userImportsTableComponent.pagination.previousPage" }),
              pageLabel: (pageNumber: number) =>
                intl.formatMessage({ id: "userImportsTableComponent.pagination.pageNumber" }, { pageNumber }),
            }}
            pagesCount={totalPagesCount}
            currentPageIndex={currentPageIndex}
            disabled={loading || !!error}
            onChange={({ detail }: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) =>
              onPaginationChange(detail.currentPageIndex)
            }
            openEnd={pagination}
          />
        }
      />
    );
  };

  return (
    <Container data-testid="users-table-container">
      <SpaceBetween direction="vertical" size="xxl">
        {renderUsersTable()}
      </SpaceBetween>
    </Container>
  );
}
