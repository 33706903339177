import { gql, useQuery } from "@apollo/client";

export const GET_INTERNAL_USERS = gql`
  query GetInternalUsers($search: String, $cursor: String) {
    internalUsers(search: $search, cursor: $cursor, pageSize: 20) {
      nodes {
        id
        userRole
        firstName
        lastName
        emailAddress
        gandalfDetails {
          audiencePath
        }
        status
      }
      pageInfo {
        cursor
        size
      }
    }
  }
`;

export const useGetInternalUsers = (filterText: string, cursor: string) => {
  return useQuery(GET_INTERNAL_USERS, { variables: { search: filterText, cursor: cursor } });
};
