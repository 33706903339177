import { Box, Container, Flashbar, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { TableProps } from "@amzn/awsui-components-react/polaris/table";
import { useIntl } from "react-intl";
import { OrderLine } from "../types";
import { useOrdersListTable } from "./useOrdersListTable";
import { LEARNING_ACCOUNTS_ROUTE } from "../../common/routes";
import { Link } from "react-router-dom";
import { useGetUserByIdentifier } from "../../user/api/useGetUserByIdentifier";
import { Pagination } from "@amzn/awsui-components-react";
import { LADetailsPageTable } from "../constants";

export function OrdersListTable(): JSX.Element {
  const intl = useIntl();

  const {
    orderData,
    loadingData,
    errorInfo,
    flashbarItems,
    totalPages,
    pagination,
    currentPageIndex,
    onPaginationChange,
  } = useOrdersListTable();

  const orderLines = orderData;

  const renderOrdersTable = (): JSX.Element => {
    /*Code do be stolen from https://code.amazon.com/reviews/CR-81090552/revisions/1#/diff. Thanks Josh*/

    return (
      <LADetailsPageTable
        header={
          <Header
            data-testid="orders-table-header"
            counter={
              totalPages === 1 || currentPageIndex === totalPages ? `(${orderLines.length})` : `(${orderLines.length}+)`
            }
          >
            Orders
          </Header>
        }
        data-testid="orders-table"
        variant="embedded"
        ariaLabels={{ tableLabel: intl.formatMessage({ id: "order.learningAccountPage.ordersTable.tableLabel" }) }}
        visibleColumns={orderLines.length === 0 ? [] : undefined}
        loading={loadingData}
        items={orderLines}
        sortingDisabled
        columnDefinitions={[
          {
            id: "OrderId",
            header: intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.orderId" }),
            ariaLabel: (data: TableProps.LabelData) =>
              intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.orderId" }),
            cell: (item: OrderLine) => (
              <Link to={`${LEARNING_ACCOUNTS_ROUTE}/${item.learningAccountId}/orders/${item.id}`}>{item.id}</Link>
            ),
          },
          {
            id: "OpportunityId",
            header: intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.orderOpportunity" }),
            ariaLabel: (data: TableProps.LabelData) =>
              intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.orderOpportunity" }),
            cell: (item: OrderLine) => `${item.opportunityId}`,
            sortingField: "opportunityId",
          },
          {
            id: "Amount",
            header: intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.amount" }),
            ariaLabel: (data: TableProps.LabelData) =>
              intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.amount" }),
            cell: (item: OrderLine) => intl.formatNumber(item.amount, { style: "currency", currency: "USD" }),
            sortingField: "amount",
          },
          {
            id: "SubmittedBy",
            header: intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.submittedBy" }),
            ariaLabel: (data: TableProps.LabelData) =>
              intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.submittedBy" }),
            cell: (item: OrderLine) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const { data, loading } = useGetUserByIdentifier(item.createdBy);
              return data && data.userById !== null
                ? `${data.userById.firstName} ${data.userById.lastName}`
                : loading
                ? ""
                : "System";
            },
          },
          {
            id: "Status",
            header: intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.status" }),
            ariaLabel: (data: TableProps.LabelData) =>
              intl.formatMessage({ id: "order.learningAccountPage.ordersTable.label.status" }),
            cell: (item: OrderLine) => item.status,
            sortingField: "status",
          },
        ]}
        empty={
          errorInfo ? (
            <Flashbar items={flashbarItems} />
          ) : (
            <Box textAlign="center" color="inherit">
              <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No orders to display.
              </Box>
            </Box>
          )
        }
        pagination={
          <Pagination
            ariaLabels={{
              nextPageLabel: "Next page",
              previousPageLabel: "Previous page",
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
            pagesCount={totalPages}
            currentPageIndex={currentPageIndex}
            disabled={false}
            onChange={(event: { detail: { currentPageIndex: any } }) =>
              onPaginationChange(event.detail.currentPageIndex)
            }
            openEnd={pagination}
          />
        }
      />
    );
  };

  return (
    <Container data-testid="orders-table-container">
      <SpaceBetween direction="vertical" size="xxl">
        {renderOrdersTable()}
      </SpaceBetween>
    </Container>
  );
}
