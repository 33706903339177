import { Spinner } from "@amzn/awsui-components-react";
import { FormattedMessage, useIntl } from "react-intl";
import { Navigate, useLocation } from "react-router-dom";
import { LoadingPage } from "../components";
import { useAuth } from "../hooks/useAuth";
import { useLearningAccountInfoCard } from "../learningAccounts/components/useLearningAccountInfoCard";
import { LearningAccountDetail, LearningAccountsMain } from "../learningAccounts/pages";
import { ImportOpportunityMain } from "../onboarding/pages";
import { useOrdersDetails } from "../order/components/useOrderDetailsCard";
import { AccessDenied, OrderDetails, PageNotFound } from "../pages";
import { useUserInfoCard } from "../user/components/useUserInfoCard";
import { ImportUsers, InternalUserList, UserCreate, UserDetail, UserEdit } from "../user/pages";
import { ExternalUsers } from "../user/pages/ExternalUsers";
import { LAUserDetail } from "../user/pages/LAUserDetail";
import { UserImports } from "../user/pages/UserImports";
import { RoleType } from "../user/types";
import { bulkUserOperationIsEnabled, iltLearningAdminExperienceIsEnabled } from "./featureFlags";
import { Dashboard } from "../dashboard/pages/Dashboard";

export const HOME_ROUTE = "/";
export const ORGANIZATION_ROUTE = "/organizations";
export const USER_ROUTE = `${ORGANIZATION_ROUTE}/users`;
export const TRANSACTION_ROUTE = `${ORGANIZATION_ROUTE}/transactions`;
export const ORDER_ROUTE = "/orders";
export const LEARNING_ACCOUNTS_ROUTE = "/learningAccounts";
export const LEARNING_ACCOUNT_DETAIL_ROUTE = `${LEARNING_ACCOUNTS_ROUTE}/:id`;
export const IMPORT_OPPORTUNITY_ROUTE = "/import";
export const PAGE_NOT_FOUND_ROUTE = "*";
export const INTERNAL_USER_ROUTE = "/users";
export const INTERNAL_USER_DETAIL_ROUTE = `/users/:id`;
export const CREATE_USER_ROUTE = `${INTERNAL_USER_ROUTE}/create`;
export const CREATE_EXTERNAL_USER_ROUTE = `${LEARNING_ACCOUNT_DETAIL_ROUTE}/create`;
export const EXTERNAL_USER_DETAIL_ROUTE = `${LEARNING_ACCOUNT_DETAIL_ROUTE}/users/:id`;
export const EDIT_USER_ROUTE = `/users/:id/edit`;
export const ORDERS_ROUTE = `${LEARNING_ACCOUNT_DETAIL_ROUTE}/orders`;
export const EXTERNAL_LA_USERS_ROUTE = `${LEARNING_ACCOUNT_DETAIL_ROUTE}/users`;
export const ORDER_DETAILS_ROUTE_INTERNAL = `${ORDERS_ROUTE}/:id`;
export const ACCESS_DENIED_ROUTE = "/accessdenied";
export const USER_IMPORTS_ROUTE = "/userImports";
export const IMPORT_USERS_ROUTE = "/importUsers";
export const DASHBOARD_ROUTE = "/dashboard";

export interface LearningConsoleRoute {
  path: string;
  element: JSX.Element;
  // Custom breadcrumb value to display when rendering this route; default is whatever matches in the URL
  // Add params as input to the function if need be
  breadcrumb?: () => JSX.Element | string;
}

export function RedirectOrShowLoadingMessage(): JSX.Element {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isAuthCallback = searchParams.has("code");
  const intl = useIntl();
  const { user } = useAuth();

  // If this is an auth callback, show a message to the user while waiting for
  // Amplify to redirect to 'customState', the route that user initially wanted
  // to go to.
  if (!isAuthCallback && user.role) {
    if (user.role === RoleType.LEARNING_ADMIN && bulkUserOperationIsEnabled()) {
      return <Navigate to={LEARNING_ACCOUNTS_ROUTE} />;
    } else {
      return <Navigate to={IMPORT_OPPORTUNITY_ROUTE} />;
    }
  } else {
    return <LoadingPage text={intl.formatMessage({ id: "loadingPage.loadingText.loading" })} />;
  }
}

export function getLearningConsoleRoutes(): LearningConsoleRoute[] {
  return [
    {
      path: HOME_ROUTE,
      element: <RedirectOrShowLoadingMessage />,
      breadcrumb: () => <FormattedMessage id="serviceName" />,
    },
    {
      path: LEARNING_ACCOUNTS_ROUTE,
      element: <LearningAccountsMain />,
      breadcrumb: () => <FormattedMessage id="learningAccountsPage.header" />,
    },
    {
      path: LEARNING_ACCOUNT_DETAIL_ROUTE,
      element: <LearningAccountDetail />,
      breadcrumb: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { learningAccountDetailData, learningAccountDetailLoading } = useLearningAccountInfoCard();
        return learningAccountDetailLoading ? (
          <Spinner />
        ) : learningAccountDetailData && learningAccountDetailData.learningAccountById.name ? (
          learningAccountDetailData.learningAccountById.name
        ) : (
          ""
        );
      },
    },
    {
      path: IMPORT_OPPORTUNITY_ROUTE,
      element: <ImportOpportunityMain />,
      breadcrumb: () => <FormattedMessage id="getOpportunityComponent.header" />,
    },
    {
      path: INTERNAL_USER_ROUTE,
      element: <InternalUserList />,
      breadcrumb: () => <FormattedMessage id="userPage.header" />,
    },
    {
      path: CREATE_USER_ROUTE,
      element: <UserCreate />,
      breadcrumb: () => <FormattedMessage id="userComponent.header.create" />,
    },
    {
      path: CREATE_EXTERNAL_USER_ROUTE,
      element: <ExternalUsers />,
      breadcrumb: () => <FormattedMessage id="userComponent.header.create" />,
    },
    {
      path: EDIT_USER_ROUTE,
      element: <UserEdit />,
      breadcrumb: () => <FormattedMessage id="userComponent.header.edit" />,
    },
    {
      path: IMPORT_USERS_ROUTE,
      element: <ImportUsers />,
      breadcrumb: () => <FormattedMessage id="importUsers.header" />,
    },
    ...(bulkUserOperationIsEnabled()
      ? [
          {
            path: USER_IMPORTS_ROUTE,
            element: <UserImports />,
            breadcrumb: () => <FormattedMessage id="userImportsPage.header" />,
          },
        ]
      : []),
    ...(iltLearningAdminExperienceIsEnabled()
      ? [
          {
            path: DASHBOARD_ROUTE,
            element: <Dashboard />,
            breadcrumb: () => <FormattedMessage id="dashboardPage.header" />,
          },
        ]
      : []),
    // route for internal user
    {
      path: INTERNAL_USER_DETAIL_ROUTE,
      element: <UserDetail />,
      breadcrumb: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { userDetailData, firstName, lastName } = useUserInfoCard();
        return userDetailData && firstName ? `${firstName} ${lastName}` : "";
      },
    },
    {
      path: EXTERNAL_USER_DETAIL_ROUTE,
      element: <LAUserDetail />,
      breadcrumb: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { userDetailData, firstName, lastName } = useUserInfoCard();
        return userDetailData && firstName ? `${firstName} ${lastName}` : "";
      },
    },
    {
      path: ORDER_DETAILS_ROUTE_INTERNAL,
      element: <OrderDetails />,
      breadcrumb: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { orderDetailsLoading, orderDetailsData } = useOrdersDetails();
        return orderDetailsLoading ? (
          <Spinner />
        ) : orderDetailsData && orderDetailsData.orderById.id ? (
          `Order  ${orderDetailsData.orderById.id}`
        ) : (
          ""
        );
      },
    },
    {
      path: ORDERS_ROUTE,
      element: <LearningAccountDetail />,
      breadcrumb: () => <FormattedMessage id="orderPage.header" />,
    },
    {
      path: EXTERNAL_LA_USERS_ROUTE,
      element: <LearningAccountDetail />,
      breadcrumb: () => <FormattedMessage id="learningAccountsDetailPage.tabs.users" />,
    },
    {
      path: PAGE_NOT_FOUND_ROUTE,
      element: <PageNotFound />,
    },
    {
      path: ACCESS_DENIED_ROUTE,
      element: <AccessDenied />,
    },
  ];
}
