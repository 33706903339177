import { gql, useQuery } from "@apollo/client";
import { validEmail } from "../constants";

export const GET_USER_BY_ID = gql`
  query GetUserById($id: ID!) {
    userById(id: $id) {
      id
      emailAddress
      firstName
      lastName
      status
      userRole
      auditMetadata {
        createdAt
        createdBy
      }
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: AWSEmail!) {
    userByEmail(email: $email) {
      id
      emailAddress
      firstName
      lastName
      status
      userRole
      auditMetadata {
        createdAt
      }
    }
  }
`;

export const useGetUserByIdentifier = (userIdentifier: string) => {
  return useQuery(
    validEmail.test(userIdentifier) ? GET_USER_BY_EMAIL : GET_USER_BY_ID,
    validEmail.test(userIdentifier)
      ? {
          variables: { email: userIdentifier },
        }
      : { variables: { id: userIdentifier } }
  );
};
