import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { getClientEndpoint } from "./constants";
import fetch from "node-fetch";

const httpLink = createHttpLink({
  uri: getClientEndpoint(),
  credentials: "include",
  preserveHeaderCase: true,
  fetch: fetch as any,
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});
