import { AppLayout, Flashbar } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { Breadcrumbs, Navigation } from "../../components";
import { UserImportsTable, useUserImportsTable } from "../components/userImportsTable";

const StyledFlashbar = styled(Flashbar)`
  padding-top: 8px;
`;

export const UserImports = () => {
  const intl = useIntl();
  const { flashbarItems } = useUserImportsTable();

  return (
    <AppLayout
      contentType="table"
      content={<UserImportsTable />}
      notifications={<StyledFlashbar items={flashbarItems} stackItems />}
      breadcrumbs={<Breadcrumbs />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      navigation={<Navigation />}
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
      toolsHide
    />
  );
};
