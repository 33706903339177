import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetUserByIdLazyQuery } from "../api/useGetUserByIdentifierLazyQueries";
import { LearningAccountUserStatus, RoleType } from "../types";
import { useGetUserByIdentifier } from "../api/useGetUserByIdentifier";

type LAUserByIdUser = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  userRole: RoleType;
  userStatus: LearningAccountUserStatus;
};

type LAUserByIdAuditMetadata = {
  createdAt: string;
  createdBy: string;
};

type LearningAccountUserByIdDataType = {
  user: LAUserByIdUser;
  auditMetadata: LAUserByIdAuditMetadata;
};

export const useLAUserInfoCard = () => {
  const pathItems = useLocation().pathname.split("/");
  const currentUserIdentifier = pathItems.slice(-1)[0];

  const { loading, error, data } = useGetUserByIdentifier(currentUserIdentifier);

  const [createdBy, setCreatedBy] = useState("");

  const [getUserById] = useGetUserByIdLazyQuery(data?.userById?.auditMetadata.createdBy || "");

  const fetchCreatedByUserData = async (id: string) => {
    try {
      const { data: createByUserData } = await getUserById({ variables: { id: id } });
      if (createByUserData && createByUserData.userById) {
        setCreatedBy(`${createByUserData.userById.firstName} ${createByUserData.userById.lastName}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (data && data.userById) {
      fetchCreatedByUserData(data.userById.auditMetadata.createdBy);
    }
  }, [data]);

  return {
    userDetailLoading: loading,
    userDetailError: error,
    userDetailData: data,
    firstName: data?.userById?.firstName,
    lastName: data?.userById?.lastName,
    createdDate: data?.userById?.auditMetadata.createdAt || "",
    userRole: data?.userById?.userRole || "",
    emailAddress: data?.userById?.emailAddress,
    userStatus: data?.userById?.userStatus || "",
    createdBy,
  };
};
