import { useEffect, useState } from "react";
import { client } from "../../common/api/client";
import { GraphQLRequestState, OrderId } from "../types";
import { gql } from "@apollo/client";

const GET_ORDERS_BY_OPPORTUNITY_ID = gql`
  query GetOrdersByOpportunityId($opportunityId: ID!, $cursor: String, $pageSize: Int) {
    ordersByOpportunityId(opportunityId: $opportunityId, cursor: $cursor, pageSize: $pageSize) {
      nodes {
        id
        items {
          opportunityLineItemId
        }
        learningAccount {
          id
        }
      }
      pageInfo {
        cursor
        size
      }
    }
  }
`;

export const getAllOrdersByOpportunity = async (opportunityId: string) => {
  let orderCursor = undefined;
  let error = undefined;
  const processedOrders: OrderId[] = [];
  const processedLines: string[] = [];

  do {
    const { data, loading, errors }: any = await client.query({
      query: GET_ORDERS_BY_OPPORTUNITY_ID,
      variables: {
        opportunityId: opportunityId,
        cursor: orderCursor,
        pageSize: 50,
      },
      fetchPolicy: "no-cache",
    });

    if (errors?.length) {
      error = errors[0];
      break;
    }

    data?.ordersByOpportunityId?.nodes.forEach((order: any) => {
      order.items.forEach((item: any) => {
        processedLines.push(item.opportunityLineItemId);
      });

      processedOrders.push({
        id: order.id,
        learningAccount: {
          id: order.learningAccount.id,
        },
      } as OrderId);
    });
    orderCursor = data?.ordersByOpportunityId?.pageInfo.cursor || undefined;
  } while (orderCursor);

  return [processedOrders, processedLines, error];
};

export const useGetAllOrdersByOpportunityId = () => {
  const defaultState: GraphQLRequestState = {
    data: undefined,
    loading: false,
    error: undefined,
  };

  const [requestState, setRequestState] = useState<GraphQLRequestState>(defaultState);
  const [opportunityId, setOpportunityId] = useState<{ value: string | undefined }>({ value: undefined });

  useEffect(() => {
    setRequestState(defaultState);
    const fetchAllOrders = async () => {
      if (opportunityId.value) {
        setRequestState({ ...defaultState, loading: true });
        const [orders, lineItems, error] = await getAllOrdersByOpportunity(opportunityId.value);
        setRequestState({
          data: { orders, lineItems },
          loading: false,
          error: error,
        });
      }
    };

    fetchAllOrders();
  }, [opportunityId]);

  return {
    setOpportunityId,
    requestState,
  };
};
