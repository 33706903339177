import { Box, ColumnLayout, Container, Header, Link, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { FormattedDate, useIntl } from "react-intl";
import { B2BBillingStatus, Order, OrderItem } from "../types";

interface OrderProps {
  orderItem: OrderItem;
}

const ValueWithLabel = (props: { label: any; children: any }) => (
  <div style={{ marginTop: "10px" }}>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

const myComponentStyle = {
  margin: "10px",
};

const convertBillingStatus = (billingStatus: B2BBillingStatus) => {
  switch (billingStatus) {
    case B2BBillingStatus.TO_BE_BILLED:
      return "To Be Billed";
    case B2BBillingStatus.BILL_IMMEDIATELY:
      return "Bill Immediately";
    case B2BBillingStatus.BILLING_IN_PROGRESS:
      return "Billing in Progress";
    case B2BBillingStatus.BILLED:
      return "Billed";
    case B2BBillingStatus.BILLING_FAILED:
      return "Billing Failed";
  }
};

const getMetadataField = (orderItem: OrderItem, fieldName: string) => {
  if (orderItem.metadata) {
    const response = orderItem.metadata.find((item) => item.key === fieldName);
    if (response) {
      return JSON.parse(response.value);
    }
  }
  return "-";
};

export function BillingDetails(props: OrderProps): JSX.Element {
  const intl = useIntl();
  const orderItem = props.orderItem;
  return (
    <div>
      <Container
        header={
          <Header data-testid="billing-details-table-header" variant="h2">
            {intl.formatMessage({ id: "orderBillingDetailsComponent.header.billingDetails" })}
          </Header>
        }
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.billingStatus" })}>
              <div data-testid={"billingStatus"}>
                {orderItem.nmbsBillingDetails?.billingStatus
                  ? convertBillingStatus(orderItem.nmbsBillingDetails?.billingStatus)
                  : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.endUserAWSAccountId" })}
            >
              <div data-testid={"endUserAWSAccountId"}>{getMetadataField(orderItem, "awsEndUserAccount")}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.poNumber" })}>
              <div data-testid={"poNumber"}>{getMetadataField(orderItem, "poNumberOnInvoice")}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.productName" })}>
              <div data-testid={"productName"}>{orderItem.productTitle}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.invoiceAmount" })}>
              <div data-testid={"invoiceAmount"}>
                {orderItem.subTotal && orderItem.nmbsBillingDetails?.billingStatus === B2BBillingStatus.BILLED
                  ? intl.formatNumber(orderItem.subTotal, { style: "currency", currency: "USD" })
                  : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.invoiceNumber" })}>
              <div data-testid={"invoiceNumber"}>
                {orderItem.nmbsBillingDetails?.invoiceId ? (
                  <Link href={orderItem.nmbsBillingDetails?.billingConsoleUILink} target="_blank">
                    {orderItem.nmbsBillingDetails?.invoiceId}
                  </Link>
                ) : (
                  "-"
                )}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.invoiceDate" })}>
              <div data-testid={"invoiceDate"}>
                {orderItem.nmbsBillingDetails?.billingStatus === B2BBillingStatus.BILLED ? (
                  <span title={intl.formatDate(orderItem.nmbsBillingDetails?.billingStatusUpdatedAt)}>
                    <FormattedDate value={orderItem.nmbsBillingDetails?.billingStatusUpdatedAt} />
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.errorMessage" })}>
              <div data-testid={"errorMessage"}>{orderItem.nmbsBillingDetails?.billingErrorMessage || "-"}</div>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </div>
  );
}
