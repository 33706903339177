import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { useAuth } from "../../hooks/useAuth";
import { RoleType } from "../../user/types";

export const GET_LEARNING_ACCOUNT_BY_ID = gql`
  query GetLearningAccountById($id: ID!) {
    learningAccountById(id: $id) {
      id
      name
      awsAccountId
      phoneNumber
      billingAddress {
        addressLineOne
        addressLineTwo
        city
        countryCode
        name
        stateOrProvince
        zipCode
      }
    }
  }
`;

export const useGetLearningAccountDetailsQuery = (learningAccountId: string) => {
  const { user } = useAuth();
  return useQuery(GET_LEARNING_ACCOUNT_BY_ID, {
    variables: { id: learningAccountId },
    skip: user.role === RoleType.POST_AUTH_PRE_VIAS,
  });
};

export const useGetLearningAccountDetailsQueryLazy = (learningAccountId: string) => {
  const { user } = useAuth();
  return useLazyQuery(GET_LEARNING_ACCOUNT_BY_ID, {
    variables: { id: learningAccountId },
  });
};
