import { gql, useQuery } from "@apollo/client";

export const GET_ORG_BY_ID = gql`
  query organizationById($learningAccountId: ID!, $search: String, $cursor: String) {
    organizationById(id: $learningAccountId) {
      userAssociations(cursor: $cursor, pageSize: 10, search: $search) {
        pageInfo {
          cursor
          size
        }
        nodes {
          user {
            id
            firstName
            lastName
            emailAddress
            status
            userRole
          }
        }
      }
    }
  }
`;

export const useOrganizationByIdQuery = (id: string, filterText: string, cursor: string | undefined) => {
  return useQuery(GET_ORG_BY_ID, {
    variables: { learningAccountId: id, search: filterText, cursor },
    pollInterval: 5000,
  });
};
