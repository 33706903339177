import { Breadcrumbs, Navigation } from "../../components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { AppLayout } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Imports } from "../components";

export const ImportUsers = (): JSX.Element => {
  const intl = useIntl();
  return (
    <AppLayout
      content={<Imports />}
      breadcrumbs={<Breadcrumbs />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      navigation={<Navigation />}
      toolsHide
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
    />
  );
};
