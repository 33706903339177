import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import { Header, Container, AppLayout, Tabs } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Breadcrumbs, Navigation } from "../../components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import "../styles.scss";
import styled from "styled-components";
import { OrdersListTable } from "../../order/components/OrdersListTable";

const StyledTabs = styled(Tabs)`
  padding-top: 18px;
`;

function OrderContent(): JSX.Element {
  const intl = useIntl();

  return (
    <ContentLayout header={<Header variant="h1">{intl.formatMessage({ id: "orderPage.header" })}</Header>}>
      <Container className="container">
        <StyledTabs
          data-testid="orders-list-page-tab"
          tabs={[
            {
              label: intl.formatMessage({ id: "orderPage.tab.orders" }),
              id: "1",
              content: <OrdersListTable />,
            },
          ]}
        />
      </Container>
    </ContentLayout>
  );
}

export function Order(): JSX.Element {
  const intl = useIntl();
  return (
    <AppLayout
      content={<OrderContent />}
      breadcrumbs={<Breadcrumbs />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      navigation={<Navigation />}
      toolsHide
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
    />
  );
}
