import { gql, useLazyQuery } from "@apollo/client";

export const GET_ORDER_BY_OPPORTUNITY_ID = gql`
  query GetOrderByOpportunityId($opportunityId: ID!) {
    orderByOpportunityId(opportunityId: $opportunityId) {
      id
      learningAccount {
        id
      }
    }
  }
`;

export const useGetOrderByOpportunityIdQuery = () => {
  return useLazyQuery(GET_ORDER_BY_OPPORTUNITY_ID, { fetchPolicy: "no-cache" });
};
