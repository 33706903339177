import { Spinner } from "@amzn/awsui-components-react";
import SideNavigation, { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { ApplicationUser } from "../../auth/ApplicationUser";
import { bulkUserOperationIsEnabled } from "../../common/featureFlags";
import { getNodeEnvironment } from "../../common/nodeEnvironment";
import {
  HOME_ROUTE,
  IMPORT_OPPORTUNITY_ROUTE,
  INTERNAL_USER_ROUTE,
  LEARNING_ACCOUNTS_ROUTE,
  USER_IMPORTS_ROUTE,
} from "../../common/routes";
import { useAuth } from "../../hooks/useAuth";
import { RoleType } from "../../user/types";

export function Navigation(): JSX.Element {
  // If the provided link is empty, do not redirect pages
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const { user } = useAuth();
  const STAGE = getNodeEnvironment();

  const isAuthorizedToViewSideNavBarItemsForLARole = (appUser: ApplicationUser) => {
    if (bulkUserOperationIsEnabled() && appUser.role === RoleType.LEARNING_ADMIN) {
      return true;
    }
    return false;
  };

  const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    event.preventDefault();
    if (event.detail.href) {
      navigate(event.detail.href);
    }
  };

  const internalUserNavItems: SideNavigationProps.Item[] = [
    {
      type: "link",
      text: intl.formatMessage({ id: "navigation.item.learningAccounts" }),
      href: LEARNING_ACCOUNTS_ROUTE,
    },
    {
      type: "link",
      text: intl.formatMessage({ id: "navigation.item.importOpportunity" }),
      href: IMPORT_OPPORTUNITY_ROUTE,
    },
  ];

  const superUserNavItems: SideNavigationProps.Item[] = [
    {
      type: "link",
      text: intl.formatMessage({ id: "navigation.item.learningAccounts" }),
      href: LEARNING_ACCOUNTS_ROUTE,
    },
    {
      type: "link",
      text: intl.formatMessage({ id: "navigation.item.users" }),
      href: INTERNAL_USER_ROUTE,
    },
    {
      type: "link",
      text: intl.formatMessage({ id: "navigation.item.importOpportunity" }),
      href: IMPORT_OPPORTUNITY_ROUTE,
    },
  ];

  const learningAdminUserNavItems: SideNavigationProps.Item[] = [
    {
      type: "link",
      text: intl.formatMessage({ id: "navigation.item.learningAccounts" }),
      href: LEARNING_ACCOUNTS_ROUTE,
    },
    {
      type: "link",
      text: intl.formatMessage({ id: "navigation.item.userImports" }),
      href: USER_IMPORTS_ROUTE,
    },
  ];

  let navItems: SideNavigationProps.Item[];

  if (user.role === RoleType.SUPER_USER || user.role === RoleType.SERVICE) {
    navItems = superUserNavItems;
  } else if (isAuthorizedToViewSideNavBarItemsForLARole(user)) {
    navItems = learningAdminUserNavItems;
  } else {
    navItems = internalUserNavItems;
  }

  return user.role ? (
    <SideNavigation
      header={{ text: intl.formatMessage({ id: "navigation.item.onboarding" }), href: HOME_ROUTE }}
      items={navItems}
      activeHref={location.pathname}
      onFollow={onFollowHandler}
    />
  ) : (
    <>
      <SideNavigation
        header={{ text: intl.formatMessage({ id: "navigation.item.onboarding" }), href: HOME_ROUTE }}
        activeHref={location.pathname}
        onFollow={onFollowHandler}
      />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "auto" }}>
        <Spinner size="large" />
      </div>
    </>
  );
}
