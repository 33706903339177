import {
  Button,
  Container,
  ContentLayout,
  FileUpload,
  Flashbar,
  Form,
  Header,
  Select,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { LEARNING_ACCOUNTS_ROUTE } from "../../common/routes";
import { AppHeader } from "../../components";
import { useImports } from "./useImports";

export const Imports = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    learningAccountsListLoading,
    learningAccountsListData,
    selectedLearningAccount,
    onSelectedLearningAccountChange,
    defaultLearningAccountDisplayed,
    onDefaultLearningAccountDisplayed,
    downloadTemplateError,
    onDownloadTemplateError,
    uploadSuccess,
    onUploadSuccess,
    uploadError,
    onUploadError,
    uploadFile,
    fileSizeLimitExceeded,
    importEnabled,
    importLoading,
    setImportLoading,
    defaultLearningAccountName,
    defaultLearningAccountId,
    onSelectLoad,
    onDownloadTemplate,
    onSelectedFile,
  } = useImports();

  return (
    <ContentLayout
      data-testid="header-name"
      header={
        <AppHeader
          title={intl.formatMessage({ id: `importUsers.header` })}
          description={intl.formatMessage({ id: "importUsersComponent.titleDescription" })}
        />
      }
    >
      {uploadSuccess ? (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar
            aria-live="polite"
            data-testid="upload-file-success"
            items={[
              {
                content: intl.formatMessage({ id: "importUsersComponent.successfulUploadMessage" }),
                type: "success",
                dismissible: true,
                onDismiss: () => {
                  onUploadSuccess(false);
                },
              },
            ]}
          />
          <br></br>
        </SpaceBetween>
      ) : null}
      {downloadTemplateError ? (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar
            aria-live="polite"
            data-testid="download-template-error"
            items={[
              {
                content: intl.formatMessage({ id: "importUsersComponent.failedTemplateDownloadMessage" }),
                type: "error",
                dismissible: true,
                onDismiss: () => {
                  onDownloadTemplateError(false);
                },
              },
            ]}
          />
          <br></br>
        </SpaceBetween>
      ) : null}
      {uploadError ? (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar
            aria-live="polite"
            data-testid="upload-file-error"
            items={[
              {
                content: intl.formatMessage({ id: "importUsersComponent.failedUploadMessage" }),
                type: "error",
                dismissible: true,
                onDismiss: () => {
                  onUploadError(false);
                },
              },
            ]}
          />
          <br></br>
        </SpaceBetween>
      ) : null}
      <form onSubmit={(e) => e.preventDefault()}>
        <SpaceBetween direction="horizontal" size="l"></SpaceBetween>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                aria-label="Cancel"
                data-testid="cancel-button"
                formAction="none"
                variant="link"
                onClick={() => navigate(LEARNING_ACCOUNTS_ROUTE)}
              >
                {intl.formatMessage({ id: "importUsersComponent.cancel" })}
              </Button>
              <Button
                aria-label="Import Users"
                data-testid="import-user-button"
                variant="primary"
                loading={importLoading}
                disabled={!importEnabled}
                onClick={() => setImportLoading(true)}
              >
                {intl.formatMessage({ id: `importUsersComponent.import` })}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween data-testid="master-form" direction="vertical" size="l">
            <Container
              header={
                <Header
                  data-testid="container-title"
                  variant="h2"
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        aria-label={intl.formatMessage({ id: "importUsersComponent.label.downloadTemplate" })}
                        data-testid="download-template-button"
                        onClick={async () => {
                          onDownloadTemplate();
                        }}
                      >
                        {intl.formatMessage({ id: "importUsersComponent.downloadTemplate" })}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  {intl.formatMessage({ id: "importUsers.header" })}
                </Header>
              }
            >
              <SpaceBetween direction="horizontal" size="s">
                <Select
                  data-testid="learning-account-select"
                  selectedOption={
                    defaultLearningAccountDisplayed
                      ? {
                          label:
                            defaultLearningAccountName ??
                            intl.formatMessage({ id: "importUsersComponent.learningAccountPlaceholder" }),
                          value: defaultLearningAccountId,
                          "data-testid": "learning-account-select-option",
                        }
                      : selectedLearningAccount
                  }
                  statusType={
                    learningAccountsListLoading || learningAccountsListData?.learningAccounts === undefined
                      ? "loading"
                      : "finished"
                  }
                  onChange={(event: { detail: { selectedOption: any } }) => {
                    onDefaultLearningAccountDisplayed(false);
                    onSelectedLearningAccountChange(event.detail.selectedOption);
                  }}
                  options={
                    learningAccountsListLoading || learningAccountsListData?.learningAccounts === undefined
                      ? []
                      : learningAccountsListData.learningAccounts.nodes.map((entry: { id: string; name: string }) => {
                          return {
                            label: entry.name || entry.id, // The name can sometimes be blank in lower environments so default to id in this case.
                            value: entry.id,
                            "data-testid": "learning-account-select-option",
                          };
                        })
                  }
                  onLoadItems={onSelectLoad}
                  filteringType="auto"
                  placeholder={intl.formatMessage({ id: "importUsersComponent.learningAccountPlaceholder" })}
                />
              </SpaceBetween>
              <br></br>
              <Header data-testid="container-title" variant="h3">
                {intl.formatMessage({ id: "importUsersComponent.userListFile" })}
              </Header>
              <FileUpload
                controlId="file-upload-input"
                onChange={async (fileInput: { detail: { value: any } }) => {
                  await onSelectedFile(fileInput?.detail?.value || undefined);
                }}
                value={uploadFile ? [uploadFile] : []}
                fileErrors={
                  fileSizeLimitExceeded
                    ? [intl.formatMessage({ id: "importUsersComponent.chooseFile.fileSizeLimitExceeded.error" })]
                    : []
                }
                i18nStrings={{
                  uploadButtonText: (e: any) => intl.formatMessage({ id: "importUsersComponent.chooseFile" }),
                  removeFileAriaLabel: (e: number) =>
                    intl.formatMessage({ id: "importUsersComponent.label.removeFile" }),
                }}
                accept=".csv"
                showFileSize
                tokenLimit={3}
              />
            </Container>
          </SpaceBetween>
        </Form>
      </form>
    </ContentLayout>
  );
};
