import { gql, useLazyQuery } from "@apollo/client";

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: AWSEmail!) {
    userByEmail(email: $email) {
      id
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($id: ID!) {
    userById(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

export const useGetUserByEmailLazyQuery = (userEmail: string) => {
  return useLazyQuery(GET_USER_BY_EMAIL, { variables: { email: userEmail } });
};

export const useGetUserByIdLazyQuery = (id: string) => {
  return useLazyQuery(GET_USER_BY_ID, { variables: { id: id } });
};
