// Promise polyfill
import "core-js/features/promise";

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { FRONTEND_SELECTORS } from "./common/constants";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { client } from "./common/api/client";

import "@amzn/awsui-global-styles/polaris.css";

document.addEventListener("DOMContentLoaded", () => {
  createRoot(document.querySelector(FRONTEND_SELECTORS.APP)!).render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  );
});
