import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { CreateExternalUserInput } from "../types";
import { UserPage, validEmail } from "../constants";
import { LEARNING_ACCOUNTS_ROUTE } from "../../common/routes";
import { useCreateExternalUserMutation } from "../api/useCreateExternalUserMutation";
import { useAddUserToOrganizationQuery } from "../api/useAddUserToOrganizationQuery";
import { useGetUserByEmailLazyQuery } from "../api/useGetUserByIdentifierLazyQueries";
import { AddOrganizationUserInput } from "../../../__generated__/supergraph";

export function useCreateExternalUser(currentOrganizationId: string) {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("LEARNER");
  const [status, setStatus] = useState("ACTIVE");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  //using non primitive data type to force validation focus on every submit
  const [validationError, setValidationError] = useState({ state: false });
  const [flashbarItems, setFlashbarItems] = useState([{}]);
  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const emailRef = useRef<HTMLInputElement>();
  const intl = useIntl();
  const navigate = useNavigate();

  let addLAUserId: string;
  const createExternalUserInput = (): CreateExternalUserInput => {
    return {
      firstName: firstname,
      lastName: lastname,
      emailAddress: email,
      userRole: role,
      status: status,
    };
  };

  const addOrganizationUserInput = (): AddOrganizationUserInput => {
    return {
      organizationId: currentOrganizationId,
      userId: addLAUserId,
    };
  };

  const setErrorFlashbar = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    //check which error we have
    const button = null;
    const content = intl.formatMessage({
      id: "userComponent.flashbar.errorMessage.create",
    });
    setFlashbarItems([
      {
        type: "error",
        content: content,
        dismissLabel: intl.formatMessage({ id: "userComponent.flashbar.dismissLabel" }),
        dismissible: true,
        action: button,
        onDismiss: () => {
          setFlashbarItems([]);
        },
        statusIconAriaLabel: "error",
      },
    ]);
  };

  const [createExternalUser, { loading: createExternalUserLoading, error: createExternalUserError }] =
    useCreateExternalUserMutation(createExternalUserInput());

  const [addLearningAccountUser, { data: addUserToOrgData, loading: addUserToOrgLoading, error: addUserToOrgError }] =
    useAddUserToOrganizationQuery(addOrganizationUserInput());

  const [getUserByEmail] = useGetUserByEmailLazyQuery(email);

  useEffect(() => {
    if (createExternalUserError || addUserToOrgError) {
      setErrorFlashbar();
    }
  }, [createExternalUserError, addUserToOrgError]);

  useEffect(() => {
    if (addUserToOrgData && addUserToOrgData.addUserToOrganization) {
      navigate(
        `${LEARNING_ACCOUNTS_ROUTE}/${currentOrganizationId}/users/${addUserToOrgData.addUserToOrganization.user.id}`,
        {
          state: { action: UserPage.CREATE },
        }
      );
    }
  }, [addUserToOrgData]);

  useEffect(() => {
    if (validationError.state) {
      if (firstNameError !== "" && firstNameRef.current) {
        firstNameRef.current.focus();
      } else if (lastNameError !== "" && lastNameRef.current) {
        lastNameRef.current.focus();
      } else if (emailError !== "" && emailRef.current) {
        emailRef.current.focus();
      }
    }
  }, [validationError]);

  const onFirstNameChange = (value: any) => {
    setFirstNameError("");
    setFirstName(value);
  };

  const onLastNameChange = (value: any) => {
    setLastNameError("");
    setLastName(value);
  };

  const onEmailChange = (value: any) => {
    setEmailError("");
    setEmail(value);
  };

  const onRoleChange = (value: any) => {
    setRole(value);
  };

  const handleSubmit = async () => {
    if (firstname.trim() === "") {
      setValidationError({ state: true });
      setFirstNameError(intl.formatMessage({ id: "userComponent.firstNameRequired.validationError" }));
    } else if (firstname.length >= 80) {
      setValidationError({ state: true });
      setFirstNameError(intl.formatMessage({ id: "userComponent.firstNameLength.validationError" }));
    }

    if (lastname.trim() === "") {
      setValidationError({ state: true });
      setLastNameError(intl.formatMessage({ id: "userComponent.lastNameRequired.validationError" }));
    } else if (lastname.length >= 80) {
      setValidationError({ state: true });
      setLastNameError(intl.formatMessage({ id: "userComponent.lastNameLength.validationError" }));
    }

    if (email.trim() === "") {
      setValidationError({ state: true });
      setEmailError(intl.formatMessage({ id: "userComponent.emailAddressRequired.validationError" }));
    } else if (!validEmail.test(email)) {
      setValidationError({ state: true });
      setEmailError(intl.formatMessage({ id: "userComponent.emailAddressFormat.validationError" }));
    }

    if (
      !(
        firstname.trim() === "" ||
        firstname.length >= 80 ||
        lastname.trim() === "" ||
        lastname.length >= 80 ||
        email.trim() === "" ||
        !validEmail.test(email)
      )
    ) {
      setValidationError({ state: false });

      const { data: createExternalUserData } = await createExternalUser({
        variables: { input: createExternalUserInput() },
      });
      if (createExternalUserData && createExternalUserData.createExternalUser) {
        addLAUserId = createExternalUserData.createExternalUser.id;
      }
      if (addLAUserId) {
        addLearningAccountUser({ variables: { input: addOrganizationUserInput() } });
      }
    }
  };
  return {
    createExternalUserLoading: addUserToOrgLoading || createExternalUserLoading,
    createExternalUserError: createExternalUserError || addUserToOrgError,
    onFirstNameChange,
    onLastNameChange,
    onEmailChange,
    onRoleChange,
    handleSubmit,
    flashbarItems,
    firstNameError,
    lastNameError,
    emailError,
    firstname,
    lastname,
    email,
    role,
    status,
    firstNameRef,
    lastNameRef,
    emailRef,
  };
}
