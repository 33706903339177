import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/esm/types.js';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDateTime: { input: any; output: any; }
  AWSEmail: { input: any; output: any; }
  AWSJSON: { input: any; output: any; }
  AWSPhone: { input: any; output: any; }
  AWSTimestamp: { input: any; output: any; }
  AWSURL: { input: any; output: any; }
  join__FieldSet: { input: any; output: any; }
  link__Import: { input: any; output: any; }
};

export type AwsAccountInfo = {
  __typename?: 'AWSAccountInfo';
  accountId: Scalars['String']['output'];
  awsAssociation: AwsAssociation;
  billingAddress: AwsAddress;
  name: Scalars['String']['output'];
  status?: Maybe<AccountStatus>;
};

export type AwsAddress = {
  __typename?: 'AWSAddress';
  addressLineOne: Scalars['String']['output'];
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['AWSPhone']['output']>;
  stateOrProvince?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export enum AwsAssociation {
  AwsPartner = 'AWS_PARTNER',
  None = 'NONE'
}

export type AbsoluteScheduleConfiguration = {
  __typename?: 'AbsoluteScheduleConfiguration';
  installments: Array<Installment>;
  recurrence?: Maybe<OfferBillingPeriod>;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED'
}

export type AcknowledgeFulfillmentItemInput = {
  acknowledgedDateTime: Scalars['AWSDateTime']['input'];
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
  steps?: InputMaybe<Array<InputMaybe<FulfillmentItemStepInput>>>;
};

export type ActiveInvestmentRequest = {
  __typename?: 'ActiveInvestmentRequest';
  businessUnit?: Maybe<Scalars['String']['output']>;
  irName?: Maybe<Scalars['String']['output']>;
  irStatus?: Maybe<IrStatus>;
  territory?: Maybe<Scalars['String']['output']>;
};

export enum ActivityStatus {
  Active = 'Active',
  Completed = 'Completed'
}

export type AddCommentInput = {
  author: Scalars['String']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  entityName: Scalars['String']['input'];
  /** Optional, will send notification if included */
  notificationConfig?: InputMaybe<NotificationConfiguration>;
};

export type AddCommentsToFileInput = {
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type AddCommentsToLearningContainerInput = {
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type AddFulfillmentItemResultDataInput = {
  currentStepIndex?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
  resultData?: InputMaybe<Array<KeyValuePairInput>>;
};

export type AddGroupUserInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AddLearningAccountUserInput = {
  learningAccountId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AddLicenseMetadataInput = {
  licenseId: Scalars['ID']['input'];
  licenseMetaData: Array<KeyValuePairInput>;
};

export type AddOrganizationUserInput = {
  associationStatus?: InputMaybe<UserAssociationStatus>;
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  userRoles?: InputMaybe<Array<UserRoleV2>>;
};

export type AddPaymentMethodToCheckoutSessionInput = {
  paymentMethodId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type AddPublishingWorkflowCommentInput = {
  content: Scalars['String']['input'];
  publishingWorkflowId: Scalars['ID']['input'];
};

export type AddPublishingWorkflowTagInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  tag: KeyValuePairInput;
};

export type AddPublishingWorkflowWatcherInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  watcher: Scalars['String']['input'];
};

export type AddTeamUserInput = {
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  userRoles?: InputMaybe<Array<UserRoleV2>>;
};

export type AdditionalOwner = {
  __typename?: 'AdditionalOwner';
  additional_owner_email: Scalars['String']['output'];
  additional_owner_name: Scalars['String']['output'];
};

export type Address = {
  __typename?: 'Address';
  addressLineOne: Scalars['String']['output'];
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stateOrProvince?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type AddressInput = {
  addressLineOne: Scalars['String']['input'];
  addressLineTwo?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  stateOrProvince?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type AmsDeliverySession = {
  __typename?: 'AmsDeliverySession';
  endTime?: Maybe<Scalars['AWSTimestamp']['output']>;
  id: Scalars['ID']['output'];
  learningActivity: LearningActivity;
  startTime?: Maybe<Scalars['AWSTimestamp']['output']>;
};

export type ApproveExternalUserInput = {
  userId: Scalars['ID']['input'];
};

export type ArchiveFileMetadataInput = {
  id: Scalars['ID']['input'];
};

export type ArchiveLearningContainerInput = {
  id: Scalars['ID']['input'];
};

export type Assessment = {
  __typename?: 'Assessment';
  categories?: Maybe<Array<Maybe<AssessmentCategory>>>;
  completionMessages?: Maybe<Array<Maybe<AssessmentCompletionMessage>>>;
  createdBy: Scalars['String']['output'];
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  displaySetting?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCategoryScoreEnabled?: Maybe<Scalars['Boolean']['output']>;
  isCopyPasteEnabled?: Maybe<Scalars['Boolean']['output']>;
  isCorrectAnswersShown?: Maybe<Scalars['String']['output']>;
  isDetailedResultsEnabled?: Maybe<Scalars['String']['output']>;
  isFinalScoreEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFlaggingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isNextBackEnabled?: Maybe<Scalars['Boolean']['output']>;
  isNotesEnabled?: Maybe<Scalars['Boolean']['output']>;
  isPausingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isSubmittedResponsesShown?: Maybe<Scalars['String']['output']>;
  learningObjectives?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTimestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  numQuestions?: Maybe<Scalars['Int']['output']>;
  passingScore?: Maybe<Scalars['Float']['output']>;
  programs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  questionOrdering?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recordType: Scalars['String']['output'];
  scoreDisplay?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  timeLimit?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
  waitTime?: Maybe<Scalars['Int']['output']>;
};

export type AssessmentAnswer = {
  __typename?: 'AssessmentAnswer';
  answerText: Scalars['String']['output'];
  explanation?: Maybe<Scalars['String']['output']>;
  isCorrect: Scalars['Boolean']['output'];
};

export type AssessmentAnswerInput = {
  answerText: Scalars['String']['input'];
  explanation?: InputMaybe<Scalars['String']['input']>;
  isCorrect: Scalars['Boolean']['input'];
};

export type AssessmentCategory = {
  __typename?: 'AssessmentCategory';
  categoryName?: Maybe<Scalars['String']['output']>;
  numQuestions?: Maybe<Scalars['Int']['output']>;
};

export type AssessmentCategoryInput = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  numQuestions?: InputMaybe<Scalars['Int']['input']>;
};

export type AssessmentCompletionMessage = {
  __typename?: 'AssessmentCompletionMessage';
  maxScore?: Maybe<Scalars['Float']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
};

export type AssessmentCompletionMessageInput = {
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
};

export type AssessmentInput = {
  categories?: InputMaybe<Array<InputMaybe<AssessmentCategoryInput>>>;
  completionMessages?: InputMaybe<Array<InputMaybe<AssessmentCompletionMessageInput>>>;
  displaySetting?: InputMaybe<Scalars['String']['input']>;
  isCategoryScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCopyPasteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectAnswersShown?: InputMaybe<Scalars['String']['input']>;
  isDetailedResultsEnabled?: InputMaybe<Scalars['String']['input']>;
  isFinalScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFlaggingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNextBackEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNotesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPausingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSubmittedResponsesShown?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  numQuestions?: InputMaybe<Scalars['Int']['input']>;
  passingScore?: InputMaybe<Scalars['Float']['input']>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionOrdering?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scoreDisplay?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  waitTime?: InputMaybe<Scalars['Int']['input']>;
};

export enum AssessmentLanguage {
  En = 'en'
}

export type AssessmentMetadataInput = {
  language?: InputMaybe<AssessmentLanguage>;
  name: Scalars['String']['input'];
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status: Scalars['String']['input'];
};

export type AssessmentMetadataObject = {
  __typename?: 'AssessmentMetadataObject';
  createdBy: Scalars['String']['output'];
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  id: Scalars['ID']['output'];
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTimestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  name: Scalars['String']['output'];
  programs: Array<Maybe<Scalars['String']['output']>>;
  recordType: Scalars['String']['output'];
  status: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type AssessmentQuestion = {
  __typename?: 'AssessmentQuestion';
  answers: Array<Maybe<AssessmentAnswer>>;
  attachments: Array<Maybe<Scalars['String']['output']>>;
  createdBy: Scalars['String']['output'];
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  difficulty: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  learningObjectives: Array<Maybe<Scalars['String']['output']>>;
  modifiedBy: Scalars['String']['output'];
  modifiedTimestamp: Scalars['AWSTimestamp']['output'];
  programs: Array<Maybe<Scalars['String']['output']>>;
  questionBanks: Array<Maybe<Scalars['String']['output']>>;
  questionText: Scalars['String']['output'];
  recordType: Scalars['String']['output'];
  scoringMethod: Scalars['String']['output'];
  status: Scalars['String']['output'];
  tags: Array<Maybe<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type AssessmentQuestionInput = {
  answers: Array<InputMaybe<AssessmentAnswerInput>>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  difficulty: Scalars['Int']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives: Array<InputMaybe<Scalars['String']['input']>>;
  programs: Array<InputMaybe<Scalars['String']['input']>>;
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionText: Scalars['String']['input'];
  scoringMethod: Scalars['String']['input'];
  status: Scalars['String']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: Scalars['String']['input'];
};

export type AssessmentQuestionLearningObjectiveInput = {
  description: Scalars['String']['input'];
  numQuestions: Scalars['Int']['input'];
};

export type AssessmentQuestionUpdateInput = {
  answers?: InputMaybe<Array<InputMaybe<AssessmentAnswerInput>>>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  difficulty?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionText?: InputMaybe<Scalars['String']['input']>;
  scoringMethod?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['Int']['input'];
};

export type AssessmentQuestionsInput = {
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  difficulty?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scoringMethod?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AssessmentQuestionsOutput = {
  __typename?: 'AssessmentQuestionsOutput';
  currentCount: Scalars['Int']['output'];
  questions: Array<Maybe<AssessmentQuestion>>;
  totalCount: Scalars['Int']['output'];
};

export type AssessmentQuestionsStartWorkflowInput = {
  learningObjectives: Array<InputMaybe<AssessmentQuestionLearningObjectiveInput>>;
  model: Scalars['String']['input'];
  questionType: Scalars['String']['input'];
  temperature?: InputMaybe<Scalars['Float']['input']>;
  topK?: InputMaybe<Scalars['Int']['input']>;
  topP?: InputMaybe<Scalars['Float']['input']>;
};

export type AssessmentQuestionsStartWorkflowOutput = {
  __typename?: 'AssessmentQuestionsStartWorkflowOutput';
  executionId?: Maybe<Scalars['String']['output']>;
};

export type AssessmentUpdateInput = {
  categories?: InputMaybe<Array<InputMaybe<AssessmentCategoryInput>>>;
  completionMessages?: InputMaybe<Array<InputMaybe<AssessmentCompletionMessageInput>>>;
  displaySetting?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isCategoryScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCopyPasteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectAnswersShown?: InputMaybe<Scalars['String']['input']>;
  isDetailedResultsEnabled?: InputMaybe<Scalars['String']['input']>;
  isFinalScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFlaggingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNextBackEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNotesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPausingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSubmittedResponsesShown?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  numQuestions?: InputMaybe<Scalars['Int']['input']>;
  passingScore?: InputMaybe<Scalars['Float']['input']>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionOrdering?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scoreDisplay?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['Int']['input'];
  waitTime?: InputMaybe<Scalars['Int']['input']>;
};

export type AssessmentsInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AssessmentsOutput = {
  __typename?: 'AssessmentsOutput';
  assessments: Array<Maybe<Assessment>>;
  currentCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type AssignLicenseToUserInput = {
  id: Scalars['ID']['input'];
  reassignLicense?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};

export type AssociateCatalogItemWithGroupInput = {
  groupId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
};

export type AssociatedAuthorizationResourceInput = {
  id: Scalars['ID']['input'];
  type: AuthorizationResourceType;
};

export type AssociatedPrincipalInput = {
  id: Scalars['ID']['input'];
  type: AuthorizationPrincipalType;
};

export enum AssociationResourceType {
  Group = 'GROUP',
  Organization = 'ORGANIZATION',
  Team = 'TEAM'
}

export type AttendancePolicyAttributes = {
  __typename?: 'AttendancePolicyAttributes';
  auditMetadata: AuditMetadata;
  awsAccountIds?: Maybe<Array<Scalars['ID']['output']>>;
  instructorIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  instructorType?: Maybe<Scalars['String']['output']>;
  /** local copy */
  learningActivityId: Scalars['ID']['output'];
  learningActivityStatus: Scalars['String']['output'];
  lmsType: Scalars['String']['output'];
};

export type AttendanceRecord = {
  __typename?: 'AttendanceRecord';
  amsDeliverySession: AmsDeliverySession;
  attendanceDurationPercentage?: Maybe<Scalars['Float']['output']>;
  attendanceStatus: AttendanceStatus;
  auditMetadata: AuditMetadata;
  id: Scalars['ID']['output'];
  learner: User;
  version: Scalars['Int']['output'];
};

export enum AttendanceStatus {
  Attended = 'ATTENDED',
  /**
   * Internally, there is an ATTENDANCE_THRESHOLD (current value is 60).
   * - If attendanceDurationPercentage is [ATTENDANCE_THRESHOLD, 100], attendanceStatus is mapped to ATTENDED;
   * - If attendanceDurationPercentage is (0, ATTENDANCE_THRESHOLD), attendanceStatus is mapped to PARTIALLY_ATTENDED;
   * - If attendanceDurationPercentage is 0, attendanceStatus is mapped to NO_SHOW;
   * NOT_MARKED is the initial state of attendance records created as result of a registration event.
   * REMOVED is the state of attendance records created as result of a withdrawal event.
   * REMOVED status can only be changed back to NOT_MARKED.
   */
  NotMarked = 'NOT_MARKED',
  NoShow = 'NO_SHOW',
  PartiallyAttended = 'PARTIALLY_ATTENDED',
  Removed = 'REMOVED'
}

export type Attribute = {
  __typename?: 'Attribute';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AttributeInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum Audience {
  Amazon = 'AMAZON',
  Customers = 'CUSTOMERS',
  Partners = 'PARTNERS'
}

export type AuditMetadata = {
  __typename?: 'AuditMetadata';
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy: Scalars['String']['output'];
  lastUpdatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
};

export type AuditMetadataInput = {
  createdAt: Scalars['AWSDateTime']['input'];
  createdBy: Scalars['String']['input'];
  lastUpdatedAt: Scalars['AWSDateTime']['input'];
  lastUpdatedBy: Scalars['String']['input'];
};

export enum AuthenticationMethod {
  Apn = 'APN',
  AwsBuilderId = 'AWS_BUILDER_ID',
  Lwa = 'LWA',
  Sso = 'SSO'
}

export enum AuthorizationActionType {
  Access = 'ACCESS',
  View = 'VIEW'
}

export type AuthorizationOrganizationAttribute = {
  __typename?: 'AuthorizationOrganizationAttribute';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AuthorizationPrincipal = AuthorizationOrganizationAttribute | Group | LearningAccount | UnknownPrincipal;

export type AuthorizationPrincipalIdentityInput = {
  associatedPrincipals?: InputMaybe<Array<AssociatedPrincipalInput>>;
  id: Scalars['ID']['input'];
  type: AuthorizationPrincipalIdentityType;
};

export enum AuthorizationPrincipalIdentityType {
  Organization = 'ORGANIZATION',
  Vibe = 'VIBE'
}

export enum AuthorizationPrincipalType {
  Organization = 'ORGANIZATION',
  OrganizationType = 'ORGANIZATION_TYPE',
  PreAuth = 'PRE_AUTH',
  UserGroup = 'USER_GROUP'
}

export type AuthorizationResource = CatalogGroup | CatalogItem;

export type AuthorizationResourceInput = {
  associatedResources?: InputMaybe<Array<AssociatedAuthorizationResourceInput>>;
  id: Scalars['ID']['input'];
  type: AuthorizationResourceType;
};

export enum AuthorizationResourceType {
  CatalogGroup = 'CATALOG_GROUP',
  CatalogItem = 'CATALOG_ITEM'
}

export enum AuthorizationResult {
  AuthorizedActive = 'AUTHORIZED_ACTIVE',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type AuthorizedResourceResult = {
  __typename?: 'AuthorizedResourceResult';
  resourceId: Scalars['ID']['output'];
  resourceType: AuthorizationResourceType;
};

export type AuthorizedResourcesOutput = {
  __typename?: 'AuthorizedResourcesOutput';
  results: Array<AuthorizedResourceResult>;
};

export enum B2BBillingStatus {
  Billed = 'BILLED',
  BillingFailed = 'BILLING_FAILED',
  BillingInProgress = 'BILLING_IN_PROGRESS',
  BillImmediately = 'BILL_IMMEDIATELY',
  ToBeBilled = 'TO_BE_BILLED'
}

export type BenefitRules = {
  __typename?: 'BenefitRules';
  maxUsage?: Maybe<Scalars['Int']['output']>;
  /** When an offer is redeemable at checkout (inclusive) */
  redemptionPeriod?: Maybe<RedemptionPeriod>;
};

/** A value to track what type of benefit is being used */
export enum BenefitType {
  Discount = 'DISCOUNT',
  Freetrial = 'FREETRIAL'
}

export type BillingInvoice = {
  __typename?: 'BillingInvoice';
  aws_sor?: Maybe<Scalars['String']['output']>;
  batch_id?: Maybe<Scalars['String']['output']>;
  bill_to_address_1?: Maybe<Scalars['String']['output']>;
  bill_to_address_2?: Maybe<Scalars['String']['output']>;
  bill_to_aws_account_id?: Maybe<Scalars['String']['output']>;
  bill_to_city?: Maybe<Scalars['String']['output']>;
  bill_to_country?: Maybe<Scalars['String']['output']>;
  bill_to_email?: Maybe<Scalars['String']['output']>;
  bill_to_first_name?: Maybe<Scalars['String']['output']>;
  bill_to_last_name?: Maybe<Scalars['String']['output']>;
  bill_to_postal_code?: Maybe<Scalars['String']['output']>;
  bill_to_state?: Maybe<Scalars['String']['output']>;
  bill_to_tax_id?: Maybe<Scalars['String']['output']>;
  billed_amount?: Maybe<Scalars['Float']['output']>;
  billing_status?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_aws_account_id?: Maybe<Scalars['String']['output']>;
  customer_invoice_notes?: Maybe<Scalars['String']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  do_not_group?: Maybe<Scalars['Boolean']['output']>;
  invoice_reference?: Maybe<Scalars['String']['output']>;
  is_reseller?: Maybe<Scalars['Boolean']['output']>;
  legal_entity_name?: Maybe<Scalars['String']['output']>;
  line_items?: Maybe<Array<Maybe<BillingLineItem>>>;
  mast_invoice_number?: Maybe<Scalars['String']['output']>;
  number_of_participants?: Maybe<Scalars['Int']['output']>;
  ofa_invoice_number?: Maybe<Scalars['String']['output']>;
  ofa_invoice_url?: Maybe<Scalars['String']['output']>;
  payment_terms?: Maybe<Scalars['String']['output']>;
  po_number?: Maybe<Scalars['String']['output']>;
  prepay?: Maybe<Scalars['Boolean']['output']>;
  processing_error?: Maybe<Scalars['String']['output']>;
  request_for_funds_id?: Maybe<Scalars['String']['output']>;
  s3_path?: Maybe<Scalars['String']['output']>;
  sfdc_opportunity_id?: Maybe<Scalars['String']['output']>;
  tax_rate?: Maybe<Scalars['String']['output']>;
};

export type BillingLineItem = {
  __typename?: 'BillingLineItem';
  item_amount?: Maybe<Scalars['Float']['output']>;
  item_type?: Maybe<Scalars['String']['output']>;
};

export enum BillingPeriod {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY'
}

export type BulkOperateLearningAccountUserInput = {
  learningAccountId: Scalars['String']['input'];
  operation: BulkOperation;
  operatorUserId?: InputMaybe<Scalars['String']['input']>;
  userDetails: Array<UserDetailsInput>;
};

export type BulkOperateLearningAccountUserOutput = {
  __typename?: 'BulkOperateLearningAccountUserOutput';
  failedMappingUsers: Array<Scalars['String']['output']>;
  learningAccountId: Scalars['String']['output'];
  operation: BulkOperation;
};

export type BulkOperateOrganizationUsersInput = {
  operation: BulkOperation;
  operatorUserId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type BulkOperateOrganizationUsersOutput = {
  __typename?: 'BulkOperateOrganizationUsersOutput';
  failedUserIds: Array<Scalars['String']['output']>;
  operation: BulkOperation;
  organizationId: Scalars['String']['output'];
};

export enum BulkOperation {
  Add = 'ADD'
}

export type BulkOperationFile = {
  __typename?: 'BulkOperationFile';
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  executionLogReportId?: Maybe<Scalars['ID']['output']>;
  executionLogReportUrl?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['ID']['output']>;
  fileMetadata?: Maybe<BulkOperationFileMetadata>;
  lastUpdatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  learningAccount?: Maybe<LearningAccount>;
  templateUrl?: Maybe<Scalars['String']['output']>;
  uploadLocation?: Maybe<Scalars['String']['output']>;
  userCreationStatus?: Maybe<UserOperationStatus>;
};

export type BulkOperationFileMetadata = {
  __typename?: 'BulkOperationFileMetadata';
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type BulkOperationFileMetadataInput = {
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type CancelOrderInput = {
  cancellationReason: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type CancelOrderItemInput = {
  cancellationCategory?: InputMaybe<CancellationCategory>;
  cancellationReason: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  orderItemId: Scalars['String']['input'];
};

export enum CancellationCategory {
  CustomerCanceled = 'CUSTOMER_CANCELED',
  FraudMitigation = 'FRAUD_MITIGATION',
  LearningActivityCanceledImmediate = 'LEARNING_ACTIVITY_CANCELED_IMMEDIATE',
  ManualCancellationEndofterm = 'MANUAL_CANCELLATION_ENDOFTERM',
  ManualCancellationImmediate = 'MANUAL_CANCELLATION_IMMEDIATE',
  OpportunityItemCanceledImmediate = 'OPPORTUNITY_ITEM_CANCELED_IMMEDIATE',
  PaymentCanceled = 'PAYMENT_CANCELED'
}

export type CancellationConfiguration = {
  __typename?: 'CancellationConfiguration';
  cancellationOffsetHours?: Maybe<Scalars['Int']['output']>;
  userCancellationOutcome?: Maybe<CancellationOutcome>;
};

export type CancellationDetails = {
  __typename?: 'CancellationDetails';
  cancellationCategory: CancellationCategory;
  cancellationEffectiveAt: Scalars['AWSDateTime']['output'];
  requestedCancellationAt: Scalars['AWSDateTime']['output'];
};

/** To indicate how default cancellation should be handled when a customer attempts to cancel. */
export enum CancellationOutcome {
  EndOfBillingPeriod = 'END_OF_BILLING_PERIOD',
  Immediate = 'IMMEDIATE'
}

export type Capacity = {
  __typename?: 'Capacity';
  subProviderCapacity?: Maybe<Array<SubProviderCapacity>>;
  total: Scalars['Int']['output'];
};

export type CapacityInput = {
  subProviderCapacity?: InputMaybe<Array<SubProviderCapacityInput>>;
  total: Scalars['Int']['input'];
};

/** Configures how the Cart Component is displayed */
export type CartComponent = {
  __typename?: 'CartComponent';
  /** Config of cancel redirect if user doesn't want to finalize checkout */
  cancelRedirectConfig: LinkConfig;
  /** Groups to show in the cart, details what is being checked out */
  groups: Array<CartGroup>;
  /** Heading text of Cart component */
  heading: Scalars['String']['output'];
};

export type CartComponentInput = {
  cancelRedirectConfig: LinkConfigInput;
  groups: Array<CartGroupInput>;
  heading: Scalars['String']['input'];
};

/** Represents a group of items shown in Cart component */
export type CartGroup = {
  __typename?: 'CartGroup';
  /** Text sections to display for items in group */
  items: Array<TextSection>;
  /** Name of group to display */
  name: Scalars['String']['output'];
};

export type CartGroupInput = {
  items: Array<TextSectionInput>;
  name: Scalars['String']['input'];
};

export type CatalogCustomAttribute = {
  __typename?: 'CatalogCustomAttribute';
  key: Scalars['String']['output'];
  keyDisplayString?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Scalars['String']['output']>>;
  valuesDisplayStrings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CatalogCustomAttributeSchema = {
  __typename?: 'CatalogCustomAttributeSchema';
  key: Scalars['String']['output'];
  keyDisplayStrings: Array<CatalogLocalizedString>;
  selectOptions?: Maybe<Array<CatalogCustomAttributeSchemaSelectOption>>;
  type: CatalogCustomAttributeType;
};

export type CatalogCustomAttributeSchemaConnection = {
  __typename?: 'CatalogCustomAttributeSchemaConnection';
  nodes: Array<CatalogCustomAttributeSchema>;
  pageInfo?: Maybe<PageInfo>;
};

export type CatalogCustomAttributeSchemaSelectOption = {
  __typename?: 'CatalogCustomAttributeSchemaSelectOption';
  option: Scalars['String']['output'];
  optionDisplayStrings: Array<CatalogLocalizedString>;
};

export type CatalogCustomAttributeSchemaSelectOptionInput = {
  option: Scalars['String']['input'];
  optionDisplayStrings: Array<CatalogLocalizedStringInput>;
};

export enum CatalogCustomAttributeType {
  Boolean = 'BOOLEAN',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  SingleSelect = 'SINGLE_SELECT',
  String = 'STRING'
}

export type CatalogCustomIdentifier = {
  __typename?: 'CatalogCustomIdentifier';
  name: Scalars['String']['output'];
  targetId: Scalars['ID']['output'];
  targetType: CatalogCustomIdentifierTargetType;
  unique: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type CatalogCustomIdentifierInput = {
  name: Scalars['String']['input'];
  unique: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export enum CatalogCustomIdentifierTargetType {
  CatalogGroup = 'CATALOG_GROUP',
  CatalogItem = 'CATALOG_ITEM'
}

export type CatalogDuration = {
  __typename?: 'CatalogDuration';
  unit: CatalogDurationUnit;
  value: Scalars['Float']['output'];
};

export type CatalogDurationInput = {
  dynamicDuration?: InputMaybe<Scalars['Boolean']['input']>;
  unit?: InputMaybe<CatalogDurationUnit>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export enum CatalogDurationUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Seconds = 'SECONDS'
}

export type CatalogFile = {
  __typename?: 'CatalogFile';
  cdnUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  presignedUploadUrl?: Maybe<Scalars['AWSURL']['output']>;
};

export type CatalogGroup = {
  __typename?: 'CatalogGroup';
  auditMetadata: AuditMetadata;
  customIdentifiers: Array<CatalogCustomIdentifier>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: CatalogItemConnection;
  name: Scalars['String']['output'];
  namespace: CatalogNamespace;
  status: CatalogGroupStatus;
};


export type CatalogGroupItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type CatalogGroupConnection = {
  __typename?: 'CatalogGroupConnection';
  nodes: Array<CatalogGroup>;
  pageInfo?: Maybe<PageInfo>;
};

export type CatalogGroupInput = {
  customIdentifiers?: InputMaybe<Array<CatalogCustomIdentifierInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum CatalogGroupStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CatalogItem = {
  __typename?: 'CatalogItem';
  auditMetadata: AuditMetadata;
  category: CatalogItemCategory;
  children: CatalogItemConnection;
  customAttributeByKey?: Maybe<CatalogCustomAttribute>;
  customAttributes: Array<CatalogCustomAttribute>;
  customIdentifiers: Array<CatalogCustomIdentifier>;
  description?: Maybe<Scalars['String']['output']>;
  duration: CatalogDuration;
  id: Scalars['ID']['output'];
  languageCode: Scalars['String']['output'];
  modalityDelivery: CatalogModalityDelivery;
  name: Scalars['String']['output'];
  namespace: CatalogNamespace;
  parents: CatalogItemConnection;
  rating?: Maybe<Scalars['Float']['output']>;
  semanticVersion: SemanticVersion;
  status: CatalogItemStatus;
  thumbnailFile?: Maybe<CatalogFile>;
  vendorUrl?: Maybe<Scalars['String']['output']>;
  versionedId: Scalars['ID']['output'];
};


export type CatalogItemChildrenArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogItemCustomAttributeByKeyArgs = {
  key: Scalars['String']['input'];
};


export type CatalogItemParentsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum CatalogItemCategory {
  Course = 'COURSE',
  LearningPlan = 'LEARNING_PLAN'
}

export type CatalogItemConnection = {
  __typename?: 'CatalogItemConnection';
  nodes: Array<CatalogItem>;
  pageInfo?: Maybe<PageInfo>;
};

export type CatalogItemInput = {
  category: CatalogItemCategory;
  childIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  childVersionedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  customAttributes?: InputMaybe<Array<CustomAttributeInput>>;
  customIdentifiers?: InputMaybe<Array<CatalogCustomIdentifierInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration: CatalogDurationInput;
  languageCode: Scalars['String']['input'];
  modalityDelivery: CatalogModalityDelivery;
  name: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Float']['input']>;
  thumbnailFileId?: InputMaybe<Scalars['String']['input']>;
};

export enum CatalogItemStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Maintenance = 'MAINTENANCE'
}

export type CatalogLocalizedString = {
  __typename?: 'CatalogLocalizedString';
  languageCode: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CatalogLocalizedStringInput = {
  languageCode: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum CatalogModalityDelivery {
  Blended = 'BLENDED',
  Digital = 'DIGITAL',
  Live = 'LIVE'
}

export type CatalogNamespace = {
  __typename?: 'CatalogNamespace';
  auditMetadata: AuditMetadata;
  customAttributeSchemas: CatalogCustomAttributeSchemaConnection;
  description: Scalars['String']['output'];
  groupsByCustomIdentifier: CatalogGroupConnection;
  id: Scalars['String']['output'];
  items: CatalogItemConnection;
  itemsByCustomIdentifier: CatalogItemConnection;
};


export type CatalogNamespaceCustomAttributeSchemasArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogNamespaceGroupsByCustomIdentifierArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};


export type CatalogNamespaceItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogNamespaceItemsByCustomIdentifierArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};

export type ChangeAssessmentStatusInput = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

/** Checkout Session entity */
export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  /** List of allowed billing countries that a session can accept. */
  allowedPaymentCountries?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * types of payment methods that the session can accept.
   *
   * Applicable to "NEW_ORDER" session types
   */
  allowedPaymentMethodTypes?: Maybe<Array<PaymentMethodType>>;
  /** Audit metadata */
  auditMetadata: AuditMetadata;
  /**
   * Order that is created when session is finalized.
   *
   * Applicable to "NEW_ORDER" and "REPLACE_ORDER_ITEM" session types
   */
  createdOrder?: Maybe<Order>;
  /** Timestamp for when the session will be marked as "EXPIRED" and no longer mutable */
  expiresAt: Scalars['AWSDateTime']['output'];
  /** Unique ID of checkout session */
  id: Scalars['ID']['output'];
  /** Line items for the relevant order to create/update */
  items: Array<CheckoutSessionItem>;
  /** Locale (e.g., "en") to display session text in */
  locale: Scalars['String']['output'];
  /**
   * Payment method to use when finalizing checkout and creating an order
   * Applicable to "NEW_ORDER" session types
   */
  paymentMethod?: Maybe<PaymentMethod>;
  /** Post-checkout page configuration */
  postCheckoutPage: PostCheckoutPage;
  /** Pre-checkout page configuration */
  preCheckoutPage: PreCheckoutPage;
  /** Status of session */
  status: CheckoutSessionStatus;
  /** Type of CheckoutSession */
  type: CheckoutSessionType;
  /** URL to direct customer to so they can finalize checkout */
  url: Scalars['String']['output'];
  /** User session is active for */
  user: User;
};

export type CheckoutSessionConnection = {
  __typename?: 'CheckoutSessionConnection';
  nodes: Array<CheckoutSession>;
  pageInfo?: Maybe<PageInfo>;
};

/** A single item of a checkout session that is being purchased/modified */
export type CheckoutSessionItem = {
  __typename?: 'CheckoutSessionItem';
  /**
   * List for benefits actually applied to the user with selected offer
   * Also used to calculate benefit/free trial start and end time and send it to OrderService
   * So that downstream services can directly utilize this data
   */
  appliedBenefits?: Maybe<Array<OfferBenefit>>;
  /** Unique ID of session item */
  id: Scalars['ID']['output'];
  /** Metadata to associate with new order item when order created */
  metadata?: Maybe<Array<KeyValuePair>>;
  /**
   * Offer to be purchased, associated to line item
   * whether creating a new order or replacing a previously created
   * order item with a new one and a new offer
   */
  offer?: Maybe<Offer>;
  /**
   * Pre-existing order item that should be replaced on checkout.
   * Applicable for "REPLACE_ORDER_ITEM" session types
   */
  orderItemToReplace?: Maybe<OrderItem>;
  /**
   * Quantity of offer to purchase.
   *
   * Applicable for "NEW_ORDER" and "CANCEL_ORDER_ITEM" session types
   */
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** Status of session */
export enum CheckoutSessionStatus {
  Complete = 'COMPLETE',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  PendingCompletion = 'PENDING_COMPLETION'
}

/** A term (e.g., legal) that is shown on the Finalize Checkout Session component */
export type CheckoutSessionTerm = {
  __typename?: 'CheckoutSessionTerm';
  sections: Array<TextSection>;
};

export type CheckoutSessionTermInput = {
  sections: Array<TextSectionInput>;
};

export enum CheckoutSessionType {
  NewOrder = 'NEW_ORDER',
  ReplaceOrderItem = 'REPLACE_ORDER_ITEM'
}

export type ClassAttendanceQueryInput = {
  includeRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  learningActivityId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ClassStatus {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  Hold = 'HOLD',
  New = 'NEW',
  Scheduled = 'SCHEDULED'
}

export type Classroom = {
  __typename?: 'Classroom';
  capacity?: Maybe<Capacity>;
  classroomArn: Scalars['String']['output'];
  courseId: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['AWSTimestamp']['output'];
  errorDetails?: Maybe<Scalars['String']['output']>;
  instructors?: Maybe<Array<Maybe<Scalars['AWSEmail']['output']>>>;
  jamConfig?: Maybe<JamConfig>;
  langLocale: Scalars['String']['output'];
  learningActivityId?: Maybe<Scalars['String']['output']>;
  location: Location;
  providerArn: Scalars['String']['output'];
  startTime: Scalars['AWSTimestamp']['output'];
  status: ClassroomStatus;
  timezone: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type ClassroomAck = {
  __typename?: 'ClassroomAck';
  classroomArn: Scalars['String']['output'];
  status: ClassroomStatus;
};

export type ClassroomInput = {
  capacity: CapacityInput;
  clientRequestToken: Scalars['String']['input'];
  courseId: Scalars['String']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['AWSTimestamp']['input'];
  instructors?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  jamConfig?: InputMaybe<JamConfigInput>;
  langLocale: Scalars['String']['input'];
  learningActivityId?: InputMaybe<Scalars['String']['input']>;
  location: LocationInput;
  namespace: Scalars['String']['input'];
  providerArn: Scalars['String']['input'];
  startTime: Scalars['AWSTimestamp']['input'];
  timezone: Scalars['String']['input'];
};

export enum ClassroomStatus {
  Cancelled = 'CANCELLED',
  EntitlementCreationFailed = 'ENTITLEMENT_CREATION_FAILED',
  EntitlementUpdateFailed = 'ENTITLEMENT_UPDATE_FAILED',
  Error = 'ERROR',
  InstructorInvitationCreationFailed = 'INSTRUCTOR_INVITATION_CREATION_FAILED',
  InstructorInvitationUpdateFailed = 'INSTRUCTOR_INVITATION_UPDATE_FAILED',
  JamCreationFailed = 'JAM_CREATION_FAILED',
  JamUpdateFailed = 'JAM_UPDATE_FAILED',
  LicenseTransactionFailed = 'LICENSE_TRANSACTION_FAILED',
  Provisioning = 'PROVISIONING',
  ProvisioningFailed = 'PROVISIONING_FAILED',
  Ready = 'READY',
  Updating = 'UPDATING'
}

export type Comment = {
  __typename?: 'Comment';
  auditMetadata: AuditMetadata;
  author: Scalars['String']['output'];
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export type CommentsConnection = {
  __typename?: 'CommentsConnection';
  nodes: Array<Comment>;
  pageInfo?: Maybe<PageInfo>;
};

export type CommercePlatformConfiguration = {
  __typename?: 'CommercePlatformConfiguration';
  awsAISPLOfferId?: Maybe<Scalars['String']['output']>;
  awsClassicOfferId?: Maybe<Scalars['String']['output']>;
  service: CommercePlatformService;
  serviceCode?: Maybe<Scalars['String']['output']>;
};

export enum CommercePlatformService {
  Sbis = 'SBIS',
  Sdms = 'SDMS'
}

export type CompleteFulfillmentItemInput = {
  completedDateTime: Scalars['AWSDateTime']['input'];
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
};

export type CompletePublishingWorkflowStepInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  publishingWorkflowStepId: Scalars['ID']['input'];
  values?: InputMaybe<Scalars['AWSJSON']['input']>;
};

/** Configures how the Post Checkout Confirmation Component is displayed */
export type ConfirmationComponent = {
  __typename?: 'ConfirmationComponent';
  /** Heading text of confirmation component */
  heading: Scalars['String']['output'];
  /** Config of success redirect after finalizing checkout */
  successRedirectConfig?: Maybe<LinkConfig>;
  /** Text sections to show in confirmation component */
  textSections: Array<TextSection>;
};

export type ConfirmationComponentInput = {
  heading: Scalars['String']['input'];
  successRedirectConfig?: InputMaybe<LinkConfigInput>;
  textSections: Array<TextSectionInput>;
};

export type ContentNode = FileMetadata | LearningContainer;

export enum ContentOrganization {
  Ata = 'ATA',
  Dcc = 'DCC',
  Ft = 'FT',
  Mlu = 'MLU',
  Tc = 'TC'
}

export type ContentPermission = {
  __typename?: 'ContentPermission';
  curriculumDevelopmentOwner?: Maybe<Scalars['String']['output']>;
  curriculumDevelopmentTeamOwner?: Maybe<Scalars['String']['output']>;
  organization: ContentOrganization;
  owner: Scalars['String']['output'];
};

export type ContentPermissionInput = {
  curriculumDevelopmentOwner?: InputMaybe<Scalars['String']['input']>;
  curriculumDevelopmentTeamOwner?: InputMaybe<Scalars['String']['input']>;
};

export type ContentRelatedItem = {
  __typename?: 'ContentRelatedItem';
  /** Optional description of the association. */
  description?: Maybe<Scalars['String']['output']>;
  /** Human-readable name for the content association. Usually the title of the object or link. */
  name: Scalars['String']['output'];
  /** LearningContainer ID, or File ID, or external URL. */
  relatedItemValue: Scalars['String']['output'];
  /** Relation type. */
  relation: ContentRelation;
  /** Related item type. */
  type: EntityType;
};

export type ContentRelatedItemConnection = {
  __typename?: 'ContentRelatedItemConnection';
  nodes: Array<ContentRelatedItem>;
  pageInfo?: Maybe<PageInfo>;
};

export type ContentRelatedItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relatedItemType: EntityType;
  /** LearningContainer ID, or File ID, or external URL. */
  relatedItemValue: Scalars['String']['input'];
  relation: ContentRelation;
};

export enum ContentRelation {
  IsAuthoringSourceFor = 'IS_AUTHORING_SOURCE_FOR',
  IsGeneratedFromAuthoringSource = 'IS_GENERATED_FROM_AUTHORING_SOURCE',
  IsTranslatedFromSource = 'IS_TRANSLATED_FROM_SOURCE',
  IsTranslationSourceFor = 'IS_TRANSLATION_SOURCE_FOR'
}

export enum ContentState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export enum ContentType {
  Scorm = 'SCORM',
  Tincan = 'TINCAN'
}

export type ContractingRequestCreator = {
  __typename?: 'ContractingRequestCreator';
  email: Scalars['AWSEmail']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['AWSPhone']['output']>;
};

export type ContractingRequestCreatorInput = {
  email: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['AWSPhone']['input']>;
};

export enum CountryCode {
  Ae = 'AE',
  Ar = 'AR',
  At = 'AT',
  Au = 'AU',
  Be = 'BE',
  Bh = 'BH',
  Br = 'BR',
  Ca = 'CA',
  Ch = 'CH',
  Cl = 'CL',
  Cn = 'CN',
  Co = 'CO',
  De = 'DE',
  Es = 'ES',
  Fi = 'FI',
  Fr = 'FR',
  Gb = 'GB',
  Hk = 'HK',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  In = 'IN',
  It = 'IT',
  Jp = 'JP',
  Kr = 'KR',
  Lt = 'LT',
  Lu = 'LU',
  Mx = 'MX',
  My = 'MY',
  Nl = 'NL',
  Nz = 'NZ',
  Ph = 'PH',
  Pl = 'PL',
  Pt = 'PT',
  Sa = 'SA',
  Se = 'SE',
  Sg = 'SG',
  Sk = 'SK',
  Th = 'TH',
  Tw = 'TW',
  Us = 'US',
  Vn = 'VN',
  Za = 'ZA'
}

export type CreateAwsAccountPaymentMethodInput = {
  /** AWS JWT which contains account ID to use for payment */
  awsToken: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type CreateCatalogCustomAttributeSchemaInput = {
  key: Scalars['String']['input'];
  keyDisplayStrings: Array<CatalogLocalizedStringInput>;
  namespaceId: Scalars['String']['input'];
  selectOptions?: InputMaybe<Array<CatalogCustomAttributeSchemaSelectOptionInput>>;
  type: CatalogCustomAttributeType;
};

export type CreateCatalogCustomIdentifierInput = {
  name: Scalars['String']['input'];
  targetId: Scalars['ID']['input'];
  targetType: CatalogCustomIdentifierTargetType;
  unique: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export type CreateCatalogGroupInput = {
  group: CatalogGroupInput;
  namespaceId: Scalars['String']['input'];
};

export type CreateCatalogItemInput = {
  item: CatalogItemInput;
  namespaceId: Scalars['String']['input'];
  semanticVersion: SemanticVersionInput;
  status: CatalogItemStatus;
};

export type CreateCatalogItemVersionInput = {
  id: Scalars['ID']['input'];
  item: CatalogItemInput;
  semanticVersion: SemanticVersionInput;
  status: CatalogItemStatus;
};

export type CreateCatalogNamespaceInput = {
  description: Scalars['String']['input'];
  /** Client provided unique ID for namespace; cannot be changed */
  id: Scalars['String']['input'];
};

export type CreateExternalUserInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  emailAddress: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  is3PLearningAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  status?: InputMaybe<UserStatus>;
  userRole: UserRole;
  vendorId?: InputMaybe<Scalars['String']['input']>;
  vendorStatus?: InputMaybe<UserStatus>;
  /**
   * The following attributes are only allowed to be used by SERVICE to SERVICE interactions.
   * They are required for importing of external vendor attributes.
   */
  vibeId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFileDataInput = {
  contentType?: InputMaybe<ContentType>;
  description: Scalars['String']['input'];
  file: CreateFileInput;
  isAccessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  isApprovedForReuse?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCode;
  location?: InputMaybe<Scalars['String']['input']>;
  lxp?: InputMaybe<Lxp>;
  lxpAttributes?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
  permission?: InputMaybe<ContentPermissionInput>;
  productOwner?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  tempLocation?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateFileInput = {
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type CreateFileMetadataInput = {
  data: CreateFileDataInput;
};

export type CreateFulfillmentItemUploadUrlInput = {
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
  isProd: Scalars['Boolean']['input'];
};

export type CreateGroupInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

/**
 * An IntegrationStepTemplate is a WorkflowStepTemplate with a type of INTEGRATION.
 * APPROVAL and CREATE_TICKET type WorkflowStepTemplates will be hardcoded in the backend
 * and therefore do not need create and update mutations
 */
export type CreateIntegrationStepTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fieldDefinitions: Array<FieldDefinitionInput>;
  id: Scalars['ID']['input'];
  lxp: Lxp;
  name: Scalars['String']['input'];
};

export type CreateInternalUserInput = {
  emailAddress: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  userRole: UserRole;
};

export type CreateLearningAccountInput = {
  awsAccountId: Scalars['String']['input'];
  awsAssociation?: InputMaybe<AwsAssociation>;
  billingAddress?: InputMaybe<AddressInput>;
  customerSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  learningAccountVisibilityClassification?: InputMaybe<OrganizationVisibilityClassification>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['AWSPhone']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountName?: InputMaybe<Scalars['String']['input']>;
  skillBuilderBranchId?: InputMaybe<Scalars['String']['input']>;
  skillBuilderNumericBranchId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateLearningContainerInput = {
  clientToken?: InputMaybe<Scalars['String']['input']>;
  data: LearningContainerInput;
};

export type CreateLicenseInput = {
  accessDetails: Array<KeyValuePairInput>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orderLineItemId: Scalars['ID']['input'];
  scheduledActivationAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  scheduledTerminationAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type CreateLicensePolicyInput = {
  actions: Array<AuthorizationActionType>;
  principalId: Scalars['ID']['input'];
  principalType: AuthorizationPrincipalType;
  resourceId: Scalars['ID']['input'];
  resourceType: AuthorizationResourceType;
};

export type CreateLxpAttributeTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  entity: EntityType;
  fieldDefinitions: Array<FieldDefinitionInput>;
  lxp?: InputMaybe<Lxp>;
  name: Scalars['String']['input'];
};

export type CreateNewOrderCheckoutSessionInput = {
  allowedPaymentMethodTypes: Array<PaymentMethodType>;
  clientId: Scalars['String']['input'];
  handleOpenClientSessionStrategy: HandleOpenClientSessionStrategy;
  items: Array<CreateNewOrderCheckoutSessionItemInput>;
  locale: Scalars['String']['input'];
  postCheckoutPage: PostCheckoutPageInput;
  preCheckoutPage: PreCheckoutPageInput;
  userId: Scalars['ID']['input'];
};

export type CreateNewOrderCheckoutSessionItemInput = {
  appliedBenefitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  metadata?: InputMaybe<Array<KeyValuePairInput>>;
  offerId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

/** Default eligible to free trial if not specify isFreeTrialEligible field */
export type CreateNewSbDigitalOrderCheckoutSessionInput = {
  isFreeTrialEligible?: InputMaybe<Scalars['Boolean']['input']>;
  locale: Scalars['String']['input'];
  offerId: Scalars['String']['input'];
};

export type CreateOfferInput = {
  description: Scalars['String']['input'];
  expiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  installmentPlan?: InputMaybe<InstallmentPlanInput>;
  modality: OfferModality;
  name: Scalars['String']['input'];
  offerPrice: Scalars['Float']['input'];
  type: OfferType;
};

export type CreateOrUpdateAttendanceEntitiesInput = {
  activity_status: Scalars['String']['input'];
  attendedCounter: Scalars['Int']['input'];
  customers?: InputMaybe<Array<CustomerInput>>;
  delivery_sessions?: InputMaybe<Array<DeliverySessionInput>>;
  instructors?: InputMaybe<Array<InstructorInput>>;
  lms_type: Scalars['String']['input'];
  pk: Scalars['ID']['input'];
};

export type CreateOrderInput = {
  billingAwsAccountId?: InputMaybe<Scalars['String']['input']>;
  businessDevelopmentManager?: InputMaybe<ProductOnboardingUserInput>;
  items: Array<CreateOrderItemInput>;
  learningAccountId?: InputMaybe<Scalars['String']['input']>;
  noOfExistingLicenses?: InputMaybe<Scalars['Int']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  opportunityName?: InputMaybe<Scalars['String']['input']>;
  parentOpportunityId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrderType>;
  typeOfContract?: InputMaybe<TypeOfContract>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderItemInput = {
  appliedBenefitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  classEndDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  classStartDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  contractingRequestCreator?: InputMaybe<ContractingRequestCreatorInput>;
  freeTrialDetails?: InputMaybe<FreeTrialDetailsInput>;
  metadata?: InputMaybe<Array<KeyValuePairInput>>;
  nmbsBillingDetails?: InputMaybe<NmbsBillingDetailsInput>;
  offerId?: InputMaybe<Scalars['String']['input']>;
  opportunityLineItemId?: InputMaybe<Scalars['String']['input']>;
  productModality?: InputMaybe<ProductModality>;
  productOnboardingDetails?: InputMaybe<ProductOnboardingDetailsInput>;
  productTitle?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  ssoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  subTotal?: InputMaybe<Scalars['Float']['input']>;
  subscriptionType?: InputMaybe<TypeOfContract>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateOrganizationGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupLevel: GroupLevel;
  /** Id of organization or team to associate with */
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateOrganizationInput = {
  /**
   * The following attribute(s) are only allowed to be used by SERVICE to SERVICE interactions.
   * They are required for importing of external vendor attributes.
   */
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  awsAccountId: Scalars['String']['input'];
  awsAssociation?: InputMaybe<AwsAssociation>;
  billingAddress?: InputMaybe<AddressInput>;
  customerSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  name: Scalars['String']['input'];
  organizationType: OrganizationType;
  organizationVisibilityClassification?: InputMaybe<OrganizationVisibilityClassification>;
  phoneNumber?: InputMaybe<Scalars['AWSPhone']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountName?: InputMaybe<Scalars['String']['input']>;
  vendorCode?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePresignedFileUploadUrlInput = {
  /** Provide a FileMetadata ID to associate with the file being uploaded. */
  fileMetadataId: Scalars['ID']['input'];
};

export type CreatePublishingWorkflowInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  learningContainerId: Scalars['ID']['input'];
  learningContainerVersion: Scalars['Int']['input'];
  lxpContentId?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  publishingScope?: InputMaybe<PublishingScope>;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  targetLxpEnvStages?: InputMaybe<Array<EnvStage>>;
  targetPublishDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CreateReplaceOrderItemCheckoutSessionInput = {
  clientId: Scalars['String']['input'];
  handleOpenClientSessionStrategy: HandleOpenClientSessionStrategy;
  item: CreateReplaceOrderItemCheckoutSessionItemInput;
  locale: Scalars['String']['input'];
  postCheckoutPage: PostCheckoutPageInput;
  preCheckoutPage: PreCheckoutPageInput;
  userId: Scalars['ID']['input'];
};

export type CreateReplaceOrderItemCheckoutSessionItemInput = {
  licenseToTransferIds: Array<Scalars['ID']['input']>;
  metadata?: InputMaybe<Array<KeyValuePairInput>>;
  offerId: Scalars['ID']['input'];
  orderItemToReplaceId: Scalars['ID']['input'];
  orderToReplaceId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type CreateTeamInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** Used to internally create Org-Team associations */
  organizationId: Scalars['String']['input'];
};

export type CreateTranscriptInput = {
  catalogItemId: Scalars['ID']['input'];
  catalogItemVersionedId: Scalars['String']['input'];
  completedDate?: InputMaybe<Scalars['String']['input']>;
  coursesCompleted?: InputMaybe<Scalars['Int']['input']>;
  dateAssigned?: InputMaybe<Scalars['String']['input']>;
  enrollmentDate?: InputMaybe<Scalars['String']['input']>;
  firstAccessDate?: InputMaybe<Scalars['String']['input']>;
  lastAccessDate?: InputMaybe<Scalars['String']['input']>;
  lastUpdatedAt: Scalars['String']['input'];
  score?: InputMaybe<Scalars['String']['input']>;
  status: TranscriptStatusV1;
  userVibeId?: InputMaybe<Scalars['String']['input']>;
  vendorCatalogItemId: Scalars['Int']['input'];
  vendorCatalogType: Scalars['String']['input'];
  vendorUserId: Scalars['Int']['input'];
};

export type CreateUpdateCatalogFileInput = {
  fileType: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  size: Scalars['Int']['input'];
};

export enum CurrencyCode {
  Usd = 'USD'
}

export type CustomAttributeInput = {
  key: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type Customer = {
  __typename?: 'Customer';
  customer_aws_account_id?: Maybe<Scalars['ID']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  sfdc_line_item_id?: Maybe<Scalars['String']['output']>;
  sfdc_opportunity_id?: Maybe<Scalars['String']['output']>;
};

export type CustomerInput = {
  customer_aws_account_id: Scalars['ID']['input'];
};

export type DeactivateCatalogGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeactivateCatalogItemInput = {
  id: Scalars['ID']['input'];
};

export type DeactivateCatalogItemVersionInput = {
  id: Scalars['ID']['input'];
  semanticVersion: SemanticVersionInput;
};

export type DeactivateExternalUserInput = {
  statusReason?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type DeleteCommentInput = {
  id: Scalars['ID']['input'];
  /** Optional, will send notification if included */
  notificationConfig?: InputMaybe<NotificationConfiguration>;
  updatedBy: Scalars['String']['input'];
};

export type DeleteCommentsOnFileInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  entityId: Scalars['String']['input'];
};

export type DeleteCommentsOnLearningContainerInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  entityId: Scalars['String']['input'];
};

export type DeleteFileInput = {
  fileMetadataId: Scalars['ID']['input'];
};

export type DeleteTranscriptInput = {
  vendorCatalogItemId: Scalars['Int']['input'];
  vendorCatalogType: Scalars['String']['input'];
  vendorUserId: Scalars['Int']['input'];
};

export type DeliverySession = {
  __typename?: 'DeliverySession';
  end_timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  start_timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
};

export type DeliverySessionInput = {
  end_timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  id: Scalars['ID']['input'];
  start_timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type DevDemoEventInput = {
  id: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type DigitalFulfillmentDetails = {
  __typename?: 'DigitalFulfillmentDetails';
  emailDomains?: Maybe<Array<Scalars['String']['output']>>;
  identityProvider?: Maybe<DigitalFulfillmentIdentityProvider>;
  idpAttributeMapping?: Maybe<DigitalFulfillmentIdpAttributes>;
  idpMetaDataLinks?: Maybe<FulfillmentItemUploadUrLs>;
  learningAdmins: Array<DigitalFulfillmentUser>;
  organizationName: Scalars['String']['output'];
  ssoContact?: Maybe<DigitalFulfillmentUser>;
  ssoEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type DigitalFulfillmentDetailsInput = {
  emailDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  identityProvider?: InputMaybe<DigitalFulfillmentIdentityProvider>;
  idpAttributeMapping?: InputMaybe<DigitalFulfillmentIdpAttributesInput>;
  idpMetaDataLinks?: InputMaybe<FulfillmentItemUploadLinksInput>;
  learningAdmins?: InputMaybe<Array<DigitalFulfillmentUserInput>>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  ssoContact?: InputMaybe<DigitalFulfillmentUserInput>;
  ssoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DigitalFulfillmentIdpAttributes = {
  __typename?: 'DigitalFulfillmentIDPAttributes';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DigitalFulfillmentIdpAttributesInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum DigitalFulfillmentIdentityProvider {
  Adfs = 'ADFS',
  Azure = 'AZURE',
  Duo = 'DUO',
  Google = 'GOOGLE',
  Jump = 'JUMP',
  Okta = 'OKTA',
  Onelogin = 'ONELOGIN',
  Ping = 'PING'
}

export type DigitalFulfillmentUser = {
  __typename?: 'DigitalFulfillmentUser';
  emailAddress: Scalars['AWSEmail']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type DigitalFulfillmentUserInput = {
  emailAddress: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type DisassociateCatalogItemFromGroupInput = {
  groupId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
};

export enum Domain {
  Us = 'US'
}

export type EditCommentInput = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  /** Optional, will send notification if included */
  notificationConfig?: InputMaybe<NotificationConfiguration>;
  updatedBy: Scalars['String']['input'];
};

export type EditCommentsOnFileInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type EditCommentsOnLearningContainerInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type EditPublishingWorkflowCommentInput = {
  commentId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  publishingWorkflowId: Scalars['ID']['input'];
};

export type Email = {
  __typename?: 'Email';
  emailAddress: Scalars['String']['output'];
  mailboxDisplayName?: Maybe<Scalars['String']['output']>;
};

export type EmailAttachment = {
  attachmentBody: Scalars['String']['input'];
  attachmentName: Scalars['String']['input'];
  attachmentType: MimeTypes;
};

export type EmailInput = {
  emailAddress: Scalars['String']['input'];
  mailboxDisplayName?: InputMaybe<Scalars['String']['input']>;
};

export type EmailProfile = {
  __typename?: 'EmailProfile';
  copyEmails?: Maybe<Array<Maybe<Email>>>;
  primaryEmail: Email;
};

export type EmailProfileInput = {
  copyEmails?: InputMaybe<Array<InputMaybe<EmailInput>>>;
  primaryEmail: EmailInput;
};

/** Generic entity-to-comments object that Comments Service can resolve. */
export type EntityComments = {
  __typename?: 'EntityComments';
  /** The comments for this entity. */
  comments: CommentsConnection;
  /** The ID of the entity the comments are attached to. */
  entityId: Scalars['String']['output'];
  /** The name of the entity. Creates a namespace to avoid potential ID collisions across different service entities. */
  entityName: Scalars['String']['output'];
};


/** Generic entity-to-comments object that Comments Service can resolve. */
export type EntityCommentsCommentsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum EntityType {
  File = 'FILE',
  LearningContainer = 'LEARNING_CONTAINER',
  Link = 'LINK'
}

export enum EnvStage {
  Beta = 'BETA',
  Gamma = 'GAMMA',
  Prod = 'PROD'
}

export enum EvaluationStatus {
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
  NotRequired = 'NOT_REQUIRED'
}

export type ExitResult = {
  __typename?: 'ExitResult';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ExitSurveyInput = {
  orderId?: InputMaybe<Scalars['ID']['input']>;
  source: Scalars['String']['input'];
  surveyResult: Scalars['String']['input'];
  vibeId: Scalars['String']['input'];
};

export type ExpireOfferInput = {
  id: Scalars['ID']['input'];
};

export type FailFulfillmentItemInput = {
  currentStepIndex?: InputMaybe<Scalars['Int']['input']>;
  failureDatetime: Scalars['AWSDateTime']['input'];
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
  reasonForFailure?: InputMaybe<Scalars['String']['input']>;
};

export type FailPublishingWorkflowStepInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  publishingWorkflowStepId: Scalars['ID']['input'];
  values?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type FieldDefinition = {
  __typename?: 'FieldDefinition';
  canEdit: Scalars['Boolean']['output'];
  defaultValue?: Maybe<Scalars['String']['output']>;
  defaultValueArray?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  key: Scalars['String']['output'];
  lowerCharacterLimit?: Maybe<Scalars['Int']['output']>;
  nestedFieldDefinitions?: Maybe<Array<Maybe<FieldDefinition>>>;
  options?: Maybe<Array<Scalars['String']['output']>>;
  required: Scalars['Boolean']['output'];
  type: FieldType;
  upperCharacterLimit?: Maybe<Scalars['Int']['output']>;
};

export type FieldDefinitionInput = {
  canEdit: Scalars['Boolean']['input'];
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  defaultValueArray?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  key: Scalars['String']['input'];
  lowerCharacterLimit?: InputMaybe<Scalars['Int']['input']>;
  nestedFieldDefinitions?: InputMaybe<Array<FieldDefinitionInput>>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  required: Scalars['Boolean']['input'];
  type: FieldType;
  upperCharacterLimit?: InputMaybe<Scalars['Int']['input']>;
};

export enum FieldType {
  Date = 'DATE',
  LargeText = 'LARGE_TEXT',
  MultiInput = 'MULTI_INPUT',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  SingleSelect = 'SINGLE_SELECT',
  SmallText = 'SMALL_TEXT',
  Time = 'TIME',
  Toggle = 'TOGGLE'
}

export type File = {
  __typename?: 'File';
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type FileLocation = {
  __typename?: 'FileLocation';
  fileName: Scalars['String']['output'];
  location: Scalars['String']['output'];
};

export type FileMetadata = {
  __typename?: 'FileMetadata';
  auditMetadata: AuditMetadata;
  comments?: Maybe<EntityComments>;
  contentType?: Maybe<ContentType>;
  description: Scalars['String']['output'];
  file: File;
  fileProcessingStatus?: Maybe<FileProcessingStatus>;
  id: Scalars['ID']['output'];
  isAccessibilityCompliant?: Maybe<Scalars['Boolean']['output']>;
  isApprovedForReuse?: Maybe<Scalars['Boolean']['output']>;
  languageCode: LanguageCode;
  learningContainers?: Maybe<LearningContainerConnection>;
  lxp?: Maybe<Lxp>;
  lxpAttributes?: Maybe<Array<UserInputWithFieldDefinitionKey>>;
  permission?: Maybe<ContentPermission>;
  presignedDownloadUrl?: Maybe<Scalars['AWSURL']['output']>;
  productOwner?: Maybe<Scalars['String']['output']>;
  relatedItems: Array<ContentRelatedItem>;
  s3ObjectKey?: Maybe<Scalars['String']['output']>;
  scopeChangeAndNotes?: Maybe<ScopeChangeAndNotes>;
  semanticVersion: SemanticVersion;
  state?: Maybe<ContentState>;
  tags?: Maybe<Array<Maybe<KeyValuePair>>>;
  title: Scalars['String']['output'];
  version: Scalars['Int']['output'];
  watcherEmail: Array<Scalars['String']['output']>;
};


export type FileMetadataRelatedItemsArgs = {
  state?: InputMaybe<State>;
};

export type FileMetadataConnection = {
  __typename?: 'FileMetadataConnection';
  nodes: Array<FileMetadata>;
  pageInfo?: Maybe<PageInfo>;
};

export type FileMetadataSearchFilters = {
  query?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export enum FileProcessingStatus {
  Clean = 'CLEAN',
  Infected = 'INFECTED',
  InProgress = 'IN_PROGRESS',
  NoFileUploaded = 'NO_FILE_UPLOADED',
  PresignedUrlCreated = 'PRESIGNED_URL_CREATED',
  ProcessingFailed = 'PROCESSING_FAILED'
}

export type FileRelatedItemInput = {
  fileMetadataId: Scalars['ID']['input'];
  relatedItem: ContentRelatedItemInput;
};

export type FileToLearningContainerAssignmentInput = {
  fileMetadataId: Scalars['ID']['input'];
  fileMetadataVersion: Scalars['Int']['input'];
  learningContainerId: Scalars['ID']['input'];
  learningContainerVersion: Scalars['Int']['input'];
};

/** Configures how the Finalize Checkout Session Component is displayed */
export type FinalizeCheckoutSessionComponent = {
  __typename?: 'FinalizeCheckoutSessionComponent';
  /** Text of submit CTA */
  submitText: Scalars['String']['output'];
  /** Terms to display on component */
  terms: Array<CheckoutSessionTerm>;
};

export type FinalizeCheckoutSessionComponentInput = {
  submitText: Scalars['String']['input'];
  terms: Array<CheckoutSessionTermInput>;
};

export type FinalizeCheckoutSessionInput = {
  sessionId: Scalars['ID']['input'];
};

export type FreeTrialDetails = {
  __typename?: 'FreeTrialDetails';
  freeTrialEndsAt: Scalars['AWSDateTime']['output'];
  freeTrialStartsAt: Scalars['AWSDateTime']['output'];
};

export type FreeTrialDetailsInput = {
  freeTrialEndsAt: Scalars['AWSDateTime']['input'];
  freeTrialStartsAt: Scalars['AWSDateTime']['input'];
};

export type Fulfillment = {
  __typename?: 'Fulfillment';
  fulfillmentItems: Array<FulfillmentItem>;
  id: Scalars['ID']['output'];
  learningAccount?: Maybe<LearningAccount>;
  order: Order;
  status: FulfillmentStatus;
  statusDate?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type FulfillmentItem = {
  __typename?: 'FulfillmentItem';
  /** The current step of the item's fulfillment process. */
  currentStepIndex: Scalars['Int']['output'];
  /**
   * Note: THIS FIELD IS TEMPORARY. Will be replaced by a generic `details` field in the future.
   * The fulfillment details entered by a Learning Admin for Digital modality.
   */
  digitalFulfillmentDetails?: Maybe<DigitalFulfillmentDetails>;
  id: Scalars['ID']['output'];
  /** The list of order items associated with this fulfillment item */
  orderItems?: Maybe<Array<Maybe<OrderItem>>>;
  /** Reason for failing a fulfillment item */
  reasonForFailure?: Maybe<Scalars['String']['output']>;
  /** Resulting data after fulfillment of this item has completed as a list of key-value pairs. ex. Branch ID or ticket ID */
  resultData: Array<KeyValuePair>;
  /** A general status of this item's fulfillment (PENDING, IN_PROGRESS, COMPLETED, FAILED). */
  status?: Maybe<FulfillmentItemStatus>;
  /** Date when a fulfillment item reached a status. ex: acknowledgedDateTime is when Fulfillment item was acknowledged */
  statusDate?: Maybe<Scalars['AWSDateTime']['output']>;
  /**
   * The steps of the fulfillment item, provided by the Modality Integration Service.
   * Used for surfacing fulfillment status details to users.
   */
  steps: Array<FulfillmentItemStep>;
  /** The type of fulfillment that needs to happen. ex. TeamSubscription */
  type?: Maybe<Scalars['String']['output']>;
};

export enum FulfillmentItemStatus {
  /** The Fulfillment Service stop processing awaiting addditional IDP information from customer. */
  AwaitingAdditionalInfo = 'AWAITING_ADDITIONAL_INFO',
  /**
   * If an OrderItem is canceled prior to Fulfillment completed, the FulfillmentItem
   * will be Canceled as well.
   */
  Canceled = 'CANCELED',
  /** The Modality Integration Service has completed fulfillment of this item. */
  Completed = 'COMPLETED',
  /** The FulfillmentItem has been created but has not started fulfillment. */
  Created = 'CREATED',
  /** The Modality Integration Service failed to fulfill the item. */
  Failed = 'FAILED',
  /** The Modality Integration Service has started fulfillment of this item. */
  InProgress = 'IN_PROGRESS',
  /** The Fulfillment Service has requested the item be fulfilled by a Modality Integration Service. */
  Pending = 'PENDING'
}

export type FulfillmentItemStep = {
  __typename?: 'FulfillmentItemStep';
  /** The 0-indexed step in the fulfillment process. */
  index?: Maybe<Scalars['Int']['output']>;
  /** The name of the step to display on the Fulfillment Item. */
  name?: Maybe<Scalars['String']['output']>;
};

export type FulfillmentItemStepInput = {
  index?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FulfillmentItemUploadLinksInput = {
  nonProdIDPMetaDataLink?: InputMaybe<Scalars['String']['input']>;
  prodIDPMetaDataLink?: InputMaybe<Scalars['String']['input']>;
};

export type FulfillmentItemUploadUrLs = {
  __typename?: 'FulfillmentItemUploadURLs';
  nonProdURL?: Maybe<Scalars['String']['output']>;
  prodURL?: Maybe<Scalars['String']['output']>;
};

export enum FulfillmentStatus {
  /**
   * If an Order is canceled prior to Fulfillment details being captured, the Fulfillment
   * will be Canceled as well.
   */
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Started = 'STARTED'
}

/** hatId subject to change when gandalf figures out merging */
export type GandalfDetails = {
  __typename?: 'GandalfDetails';
  allAudiencePaths?: Maybe<Array<Scalars['String']['output']>>;
  audiencePath?: Maybe<Scalars['String']['output']>;
  hatId: Scalars['String']['output'];
  lastViasUpdateAt?: Maybe<Scalars['AWSDateTime']['output']>;
  vibeId: Scalars['String']['output'];
};

export type Group = {
  __typename?: 'Group';
  auditMetadata: AuditMetadata;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Provides the Organization a Group belongs to. */
  organization?: Maybe<Organization>;
  /** Provides the Team a Group belongs to. */
  team?: Maybe<Team>;
  /**
   * List of users belonging to a Group.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  userAssociations?: Maybe<UserAssociationConnection>;
  vendorId?: Maybe<Scalars['String']['output']>;
};


export type GroupUserAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GroupAssociation = {
  __typename?: 'GroupAssociation';
  auditMetadata: AuditMetadata;
  group: Group;
  parentResourceId: Scalars['String']['output'];
  parentResourceType: AssociationResourceType;
};

export type GroupAssociationConnection = {
  __typename?: 'GroupAssociationConnection';
  nodes: Array<GroupAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export type GroupConnection = {
  __typename?: 'GroupConnection';
  nodes: Array<Group>;
  pageInfo?: Maybe<PageInfo>;
};

export enum GroupLevel {
  Organization = 'ORGANIZATION',
  Team = 'TEAM'
}

export enum GroupStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum GroupType {
  All = 'ALL',
  System = 'SYSTEM'
}

/**
 * Determines the behavior of session creation when
 * the there is already a open session for the user
 * created by the client
 */
export enum HandleOpenClientSessionStrategy {
  AllowMultiple = 'ALLOW_MULTIPLE',
  AllowSingle = 'ALLOW_SINGLE',
  ReplaceExisting = 'REPLACE_EXISTING'
}

export type HelloWorld = {
  __typename?: 'HelloWorld';
  id: Scalars['ID']['output'];
  recursiveHelloWorlds?: Maybe<Array<Maybe<HelloWorld>>>;
};

export enum IrStatus {
  Approved = 'APPROVED',
  Delivered = 'DELIVERED',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  Scheduled = 'SCHEDULED'
}

export type IltEvaluation = {
  __typename?: 'IltEvaluation';
  evaluationStatus: EvaluationStatus;
  evaluationUrl?: Maybe<Scalars['AWSURL']['output']>;
  learningActivity: LearningActivity;
  transcriptId: Scalars['ID']['output'];
  user: User;
};

export type IltEvaluationConnection = {
  __typename?: 'IltEvaluationConnection';
  nodes: Array<IltEvaluation>;
  pageInfo?: Maybe<PageInfo>;
};

export type Installment = {
  __typename?: 'Installment';
  installmentAttemptDates: Array<Scalars['AWSDateTime']['output']>;
  installmentEndDate: Scalars['AWSDateTime']['output'];
  installmentStartDate: Scalars['AWSDateTime']['output'];
};

export type InstallmentInput = {
  installmentAttemptDates: Array<Scalars['AWSDateTime']['input']>;
  installmentEndDate: Scalars['AWSDateTime']['input'];
  installmentStartDate: Scalars['AWSDateTime']['input'];
};

export type InstallmentPlan = {
  __typename?: 'InstallmentPlan';
  billingPeriod: OfferBillingPeriod;
  commercePlatformOfferId: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  scheduledInstallments: Array<Installment>;
};

export type InstallmentPlanInput = {
  billingPeriod: OfferBillingPeriod;
  commercePlatformOfferId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  scheduledInstallments: Array<InstallmentInput>;
};

export type Instructor = {
  __typename?: 'Instructor';
  email?: Maybe<Scalars['AWSEmail']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['ID']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type InstructorInput = {
  email: Scalars['AWSEmail']['input'];
  role: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type Invoice = {
  __typename?: 'Invoice';
  awsAccountId: Scalars['String']['output'];
  commercePlatformBillId: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  currencyType: CurrencyCode;
  documentArn: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  externalBillId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceCurrentStatus: InvoiceStatus;
  invoiceDueDate: Scalars['AWSDateTime']['output'];
  invoiceIssuedDate: Scalars['AWSDateTime']['output'];
  statementArn: Scalars['String']['output'];
};

export type InvoiceDocument = {
  __typename?: 'InvoiceDocument';
  contentType: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  documentData: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum InvoiceStatus {
  CreditMemoIssueSucceeded = 'CREDIT_MEMO_ISSUE_SUCCEEDED',
  InvoiceIssueFailed = 'INVOICE_ISSUE_FAILED',
  InvoiceIssueSucceeded = 'INVOICE_ISSUE_SUCCEEDED'
}

export type JamConfig = {
  __typename?: 'JamConfig';
  endsOn: Scalars['AWSTimestamp']['output'];
  startsOn: Scalars['AWSTimestamp']['output'];
  teamSize: Scalars['Int']['output'];
};

export type JamConfigInput = {
  endsOn: Scalars['AWSTimestamp']['input'];
  startsOn: Scalars['AWSTimestamp']['input'];
  teamSize: Scalars['Int']['input'];
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['AWSJSON']['output']>;
};

export type KeyValuePairInput = {
  key: Scalars['String']['input'];
  value: Scalars['AWSJSON']['input'];
};

export enum Lxp {
  Canvas = 'CANVAS',
  Knet = 'KNET',
  Skillbuilder = 'SKILLBUILDER'
}

/** Known language codes */
export enum LanguageCode {
  ArSa = 'AR_SA',
  DeDe = 'DE_DE',
  ElGr = 'EL_GR',
  EnUs = 'EN_US',
  Es_419 = 'ES_419',
  EsEs = 'ES_ES',
  FrCa = 'FR_CA',
  FrFr = 'FR_FR',
  HeIl = 'HE_IL',
  IdId = 'ID_ID',
  ItIt = 'IT_IT',
  JaJp = 'JA_JP',
  KoKr = 'KO_KR',
  PlPl = 'PL_PL',
  PtBr = 'PT_BR',
  RuRu = 'RU_RU',
  ThTh = 'TH_TH',
  TrTr = 'TR_TR',
  ViVn = 'VI_VN',
  ZhCn = 'ZH_CN',
  ZhTw = 'ZH_TW'
}

export type LearningAccount = {
  __typename?: 'LearningAccount';
  auditMetadata: AuditMetadata;
  awsAccountId: Scalars['String']['output'];
  awsAssociation?: Maybe<AwsAssociation>;
  billingAddress?: Maybe<Address>;
  billingAwsAccountIds?: Maybe<Array<Scalars['String']['output']>>;
  customerSince?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  learningAccountVisibilityClassification?: Maybe<OrganizationVisibilityClassification>;
  /** List of Licenses for this LearningAccount. */
  licenses: LicenseConnection;
  name: Scalars['String']['output'];
  /** List of orderItems for this Learning Account. */
  orderItems: OrderItemConnection;
  /** List of orders for this Learning Account. */
  orders: OrderConnection;
  phoneNumber?: Maybe<Scalars['AWSPhone']['output']>;
  sfdcAccountId?: Maybe<Scalars['String']['output']>;
  sfdcParentAccountId?: Maybe<Scalars['String']['output']>;
  skillBuilderBranchId?: Maybe<Scalars['String']['output']>;
  skillBuilderNumericBranchId?: Maybe<Scalars['Int']['output']>;
  status: LearningAccountStatus;
};


export type LearningAccountLicensesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  licenseStatus?: InputMaybe<LicenseStatus>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type LearningAccountOrderItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<OrderItemsFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type LearningAccountOrdersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<OrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum LearningAccountStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type LearningAccountUser = {
  __typename?: 'LearningAccountUser';
  auditMetadata: AuditMetadata;
  id: Scalars['ID']['output'];
  learningAccount: LearningAccount;
  status: LearningAccountUserStatus;
  user: User;
};

export enum LearningAccountUserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type LearningAccountUsersConnection = {
  __typename?: 'LearningAccountUsersConnection';
  nodes: Array<LearningAccountUser>;
  pageInfo?: Maybe<PageInfo>;
};

export type LearningAccountsConnection = {
  __typename?: 'LearningAccountsConnection';
  nodes: Array<LearningAccount>;
  pageInfo?: Maybe<PageInfo>;
};

export type LearningActivitiesInput = {
  activityPks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  activityStatus?: InputMaybe<Array<InputMaybe<ActivityStatus>>>;
  customerAwsAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  endTimestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  from?: InputMaybe<Scalars['Int']['input']>;
  nmbsAgreementIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  startTimestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type LearningActivity = {
  __typename?: 'LearningActivity';
  activity_audience: Scalars['String']['output'];
  activity_modality: Scalars['String']['output'];
  activity_name?: Maybe<Scalars['String']['output']>;
  activity_status: Scalars['String']['output'];
  activity_type: Scalars['String']['output'];
  additional_owners?: Maybe<Array<Maybe<AdditionalOwner>>>;
  assignLicenseToUser: License;
  attendedCounter: Scalars['Int']['output'];
  billing_error_message?: Maybe<Scalars['String']['output']>;
  billing_invoices?: Maybe<Array<Maybe<BillingInvoice>>>;
  class_size?: Maybe<Scalars['Int']['output']>;
  classrooms_arn?: Maybe<Scalars['String']['output']>;
  classrooms_student_url?: Maybe<Scalars['String']['output']>;
  course_name?: Maybe<Scalars['String']['output']>;
  created_timestamp?: Maybe<Scalars['Int']['output']>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  delivery_address_1?: Maybe<Scalars['String']['output']>;
  delivery_address_2?: Maybe<Scalars['String']['output']>;
  delivery_city?: Maybe<Scalars['String']['output']>;
  delivery_country?: Maybe<Scalars['String']['output']>;
  delivery_geo?: Maybe<Scalars['String']['output']>;
  delivery_language?: Maybe<Scalars['String']['output']>;
  delivery_postal_code?: Maybe<Scalars['String']['output']>;
  delivery_region?: Maybe<Scalars['String']['output']>;
  delivery_sessions: Array<Maybe<DeliverySession>>;
  delivery_state?: Maybe<Scalars['String']['output']>;
  delivery_timezone?: Maybe<Scalars['String']['output']>;
  instructors?: Maybe<Array<Maybe<Instructor>>>;
  /** List of Licenses for this LearningActivity. */
  licenses: LicenseConnection;
  lms_type?: Maybe<Scalars['String']['output']>;
  modified_timestamp?: Maybe<Scalars['Int']['output']>;
  nmbs_agreement_id?: Maybe<Scalars['String']['output']>;
  nmbs_bill_id?: Maybe<Scalars['String']['output']>;
  nmbs_billing_console_ui_link?: Maybe<Scalars['String']['output']>;
  nmbs_billing_status?: Maybe<Scalars['String']['output']>;
  nmbs_invoice_id?: Maybe<Scalars['String']['output']>;
  operations_owner?: Maybe<Scalars['String']['output']>;
  operations_owner_email?: Maybe<Scalars['AWSEmail']['output']>;
  pk: Scalars['ID']['output'];
  program?: Maybe<Scalars['String']['output']>;
  record_type: Scalars['String']['output'];
  registrationCounter: Scalars['Int']['output'];
  scheduler?: Maybe<Scalars['String']['output']>;
  scheduler_email?: Maybe<Scalars['String']['output']>;
  unassignLicenseFromUser: License;
  v_ilt_id?: Maybe<Scalars['String']['output']>;
  v_ilt_meeting_id?: Maybe<Scalars['String']['output']>;
  v_ilt_type?: Maybe<Scalars['String']['output']>;
};


export type LearningActivityAssignLicenseToUserArgs = {
  input: LearningActivityAssignLicenseToUserInput;
};


export type LearningActivityLicensesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  licenseStatus?: InputMaybe<LicenseStatus>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type LearningActivityUnassignLicenseFromUserArgs = {
  input: LearningActivityUnassignLicenseFromUserInput;
};

export type LearningActivityAssignLicenseToUserInput = {
  userId: Scalars['ID']['input'];
};

export type LearningActivityAttendanceRecord = {
  __typename?: 'LearningActivityAttendanceRecord';
  deliverySessionAttendanceRecords: Array<Maybe<AttendanceRecord>>;
  learner: User;
  learningActivity: LearningActivity;
  learningActivityAttendanceStatus: AttendanceStatus;
};

export enum LearningActivityLmsType {
  CustomerLms = 'CustomerLMS',
  Kiku = 'Kiku',
  NotDefined = 'NotDefined',
  MyClass = 'myClass'
}

export enum LearningActivityRegistrationStatus {
  Active = 'Active',
  Archived = 'Archived',
  Canceled = 'Canceled',
  Completed = 'Completed',
  /**
   * List of statuses available at
   * https://w.amazon.com/bin/view/AWS_Training_and_Certification/Product_Development/ILT/Grimsby/Schedule_Management_Tool/FunctionalSpecification/#HActivityStatus
   * Type to be deprecated once made available as a sharable type in Learning Activity Data Service (LADS)
   */
  Hold = 'Hold',
  Merged = 'Merged',
  PendingReview = 'PendingReview',
  Tentative = 'Tentative'
}

export type LearningActivityUnassignLicenseFromUserInput = {
  userId: Scalars['ID']['input'];
};

export type LearningContainer = {
  __typename?: 'LearningContainer';
  auditMetadata: AuditMetadata;
  comments?: Maybe<EntityComments>;
  description: Scalars['String']['output'];
  files?: Maybe<FileMetadataConnection>;
  id: Scalars['ID']['output'];
  isAccessibilityCompliant?: Maybe<Scalars['Boolean']['output']>;
  languageCode: LanguageCode;
  lxp?: Maybe<Lxp>;
  lxpAttributes?: Maybe<Array<UserInputWithFieldDefinitionKey>>;
  lxpContentId?: Maybe<Scalars['ID']['output']>;
  lxpCourseUid?: Maybe<Scalars['String']['output']>;
  modality?: Maybe<Modality>;
  objectives?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<ContentPermission>;
  publishingWorkflows?: Maybe<PublishingWorkflowConnection>;
  relatedItems: Array<ContentRelatedItem>;
  relevantTechnologies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  scopeChangeAndNotes?: Maybe<ScopeChangeAndNotes>;
  semanticVersion: SemanticVersion;
  simLink?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  state?: Maybe<ContentState>;
  tags?: Maybe<Array<Maybe<KeyValuePair>>>;
  title: Scalars['String']['output'];
  version?: Maybe<Scalars['Int']['output']>;
  watcherEmail: Array<Scalars['String']['output']>;
};


export type LearningContainerRelatedItemsArgs = {
  state?: InputMaybe<State>;
};

export type LearningContainerConnection = {
  __typename?: 'LearningContainerConnection';
  nodes: Array<LearningContainer>;
  pageInfo?: Maybe<PageInfo>;
};

export type LearningContainerInput = {
  audience?: InputMaybe<Audience>;
  description: Scalars['String']['input'];
  domain: Domain;
  duration: Scalars['Int']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  isAccessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCode;
  level?: InputMaybe<Level>;
  lxp?: InputMaybe<Lxp>;
  lxpAttributes?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
  modality: Modality;
  objectives: Scalars['String']['input'];
  permission?: InputMaybe<ContentPermissionInput>;
  prerequisites?: InputMaybe<Array<Scalars['String']['input']>>;
  relevantServices?: InputMaybe<Array<Scalars['String']['input']>>;
  relevantTechnologies?: InputMaybe<Array<Scalars['String']['input']>>;
  role?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<Scalars['String']['input']>;
  state: State;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  title: Scalars['String']['input'];
};

export type LearningContainerRelatedItemInput = {
  learningContainerId: Scalars['ID']['input'];
  relatedItem: ContentRelatedItemInput;
};

export type LearningContainerSearchFilters = {
  query?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export enum Level {
  Advanced = 'ADVANCED',
  Fundamental = 'FUNDAMENTAL',
  Intermediate = 'INTERMEDIATE'
}

export type License = {
  __typename?: 'License';
  accessDetails: Array<KeyValuePair>;
  activatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  assignmentCount?: Maybe<Scalars['Int']['output']>;
  auditMetadata: AuditMetadata;
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  lastAssignedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  lastUnassignedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  learningAccount?: Maybe<LearningAccount>;
  learningActivity?: Maybe<LearningActivity>;
  licenseMetaData?: Maybe<Array<KeyValuePair>>;
  licenseType?: Maybe<LicenseType>;
  name: Scalars['String']['output'];
  orderItemAssociations: Array<Maybe<LicenseOrderItemAssociation>>;
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
  status: LicenseStatus;
  statusReason: Scalars['String']['output'];
  terminatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  user: User;
};

/**
 * This denotes the access for an individual type of configuration
 * LWA and APN are set within seperate offers
 */
export type LicenseConfiguration = {
  __typename?: 'LicenseConfiguration';
  doceboConfiguration?: Maybe<Scalars['String']['output']>;
  licenseOffsetInHours?: Maybe<Scalars['Int']['output']>;
  viasConfiguration?: Maybe<Scalars['String']['output']>;
};

export type LicenseConnection = {
  __typename?: 'LicenseConnection';
  nodes: Array<License>;
  pageInfo?: Maybe<PageInfo>;
};

export type LicenseOrderItemAssociation = {
  __typename?: 'LicenseOrderItemAssociation';
  auditMetadata: AuditMetadata;
  orderItem: OrderItem;
  status?: Maybe<OrderItemAssociationStatus>;
  statusDate?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type LicensePolicy = {
  __typename?: 'LicensePolicy';
  actions: Array<AuthorizationActionType>;
  auditMetadata: AuditMetadata;
  id: Scalars['ID']['output'];
  policyStatus: LicensePolicyStatus;
  principal: AuthorizationPrincipal;
  resource: AuthorizationResource;
};

export type LicensePolicyConnection = {
  __typename?: 'LicensePolicyConnection';
  nodes: Array<LicensePolicy>;
  pageInfo?: Maybe<PageInfo>;
};

export enum LicensePolicyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum LicenseStatus {
  Active = 'ACTIVE',
  Scheduled = 'SCHEDULED',
  Terminated = 'TERMINATED',
  Unused = 'UNUSED'
}

export enum LicenseType {
  B2B = 'B2B',
  B2I = 'B2I'
}

export type LineItem = {
  __typename?: 'LineItem';
  chargeAmountBeforeTax: Scalars['Float']['output'];
  chargePeriodEndDate: Scalars['AWSDateTime']['output'];
  chargePeriodStartDate: Scalars['AWSDateTime']['output'];
  currencyCode: CurrencyCode;
  description: Scalars['String']['output'];
  itemType: Scalars['String']['output'];
  lineItemAmount?: Maybe<Scalars['Float']['output']>;
  lineItemBalanceAmount?: Maybe<Scalars['Float']['output']>;
  lineNumber?: Maybe<Scalars['String']['output']>;
  purchaseId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  region: Scalars['String']['output'];
  sfdcOpportunityId?: Maybe<Scalars['String']['output']>;
  sfdcOpportunityLineItemId?: Maybe<Scalars['String']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  usageUnitName?: Maybe<Scalars['String']['output']>;
};

export type LineItemInput = {
  chargeAmountBeforeTax: Scalars['Float']['input'];
  chargePeriodEndDate: Scalars['AWSDateTime']['input'];
  chargePeriodStartDate: Scalars['AWSDateTime']['input'];
  currencyCode: CurrencyCode;
  description: Scalars['String']['input'];
  itemType: Scalars['String']['input'];
  lineItemAmount?: InputMaybe<Scalars['Float']['input']>;
  lineItemBalanceAmount?: InputMaybe<Scalars['Float']['input']>;
  quantity: Scalars['Float']['input'];
  region: Scalars['String']['input'];
  sfdcOpportunityId?: InputMaybe<Scalars['String']['input']>;
  sfdcOpportunityLineItemId?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  usageUnitName?: InputMaybe<Scalars['String']['input']>;
};

export type LinkConfig = {
  __typename?: 'LinkConfig';
  /** Display text to render for link */
  displayText: Scalars['String']['output'];
  /**
   * When a link is inserted inline, this replacement key is used to look up the text to replace
   *
   * For example, if you want to render text "View your order [here]" with a link on "here",
   * the provided text could be "View your order %(ORDER_LINK)"
   * the inlineReplacementKey is "ORDER_LINK",
   * and the displayText is "here".
   *
   * This field is only applicable when the link is used in inline text
   */
  inlineReplacementKey?: Maybe<Scalars['String']['output']>;
  /** URL to link to */
  url: Scalars['String']['output'];
};

export type LinkConfigInput = {
  displayText: Scalars['String']['input'];
  inlineReplacementKey?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** A Array to hold localization values for individual feilds */
export type Localization = {
  __typename?: 'Localization';
  description: Scalars['String']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
};

export type LocalizedStringV2 = {
  __typename?: 'LocalizedStringV2';
  id: Scalars['String']['output'];
  value: Scalars['String']['output'];
  vendorId?: Maybe<Scalars['String']['output']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<PhysicalAddress>;
  locationType: LocationType;
  url?: Maybe<Scalars['AWSURL']['output']>;
  virtualLocationSource?: Maybe<VirtualLocationSource>;
};

export type LocationInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  locationType: LocationType;
  url?: InputMaybe<Scalars['AWSURL']['input']>;
  virtualLocationSource?: InputMaybe<VirtualLocationSource>;
};

export enum LocationType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export type LumosEntity = {
  __typename?: 'LumosEntity';
  id: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LxpAttributeTemplate = {
  __typename?: 'LxpAttributeTemplate';
  auditMetadata: AuditMetadata;
  description?: Maybe<Scalars['String']['output']>;
  entity: EntityType;
  fieldDefinitions: Array<FieldDefinition>;
  id: Scalars['ID']['output'];
  lxp?: Maybe<Lxp>;
  name: Scalars['String']['output'];
};

export type LxpAttributeTemplateConnection = {
  __typename?: 'LxpAttributeTemplateConnection';
  nodes: Array<LxpAttributeTemplate>;
  pageInfo?: Maybe<PageInfo>;
};

export enum MimeTypes {
  Html = 'HTML',
  Ics = 'ICS',
  Pdf = 'PDF'
}

export type MailingAddress = {
  __typename?: 'MailingAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  attentionName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  districtOrCounty?: Maybe<Scalars['String']['output']>;
  municipality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  stateOrRegion?: Maybe<Scalars['String']['output']>;
};

export type MailingAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  attentionName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  districtOrCounty?: InputMaybe<Scalars['String']['input']>;
  municipality?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateOrRegion?: InputMaybe<Scalars['String']['input']>;
};

export type MarkAttendanceInput = {
  deliverySessionId: Scalars['ID']['input'];
  learnerAttendance: Array<LearnerAttendanceInput>;
};

export enum Modality {
  Curriculum = 'CURRICULUM',
  Digital = 'DIGITAL',
  Event = 'EVENT'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Assigns the publishing workflow to the requestor */
  acceptPublishingWorkflows: Array<PublishingWorkflow>;
  acknowledgeFulfillmentItem?: Maybe<Fulfillment>;
  addComment: Comment;
  addCommentsToFile: FileMetadata;
  addCommentsToLearningContainer: LearningContainer;
  addFulfillmentItemResultData?: Maybe<Fulfillment>;
  /** Add a new LearningAccountUser. */
  addLearningAccountUser?: Maybe<LearningAccountUser>;
  /** Add key value pairs to licenseMetadata */
  addLicenseMetadata: License;
  /**
   * Associates a payment method with a checkout session.
   *
   * Only allowed for "NEW_ORDER" session types, and when they are "OPEN"
   */
  addPaymentMethodToCheckoutSession: CheckoutSession;
  addPublishingWorkflowComment: PublishingWorkflow;
  addPublishingWorkflowTag: PublishingWorkflow;
  addPublishingWorkflowWatcher: PublishingWorkflow;
  /** Add User Associtation Role(s) for a user in Org/Team */
  addUserAssociationRoles: UserAssociation;
  /** Add a user to an existing group */
  addUserToGroup?: Maybe<UserAssociation>;
  /** Add a user association to an Organization. */
  addUserToOrganization?: Maybe<UserAssociation>;
  /** Add a user association to a Team. */
  addUserToTeam?: Maybe<UserAssociation>;
  /**
   * Approve a new External User. Part of the manual authentication process where
   * an internal user will manually verify over email that a new External user
   * has received the welcome email. Approval can only be done by an internal user.
   */
  approveExternalUser?: Maybe<User>;
  /** Archive a FileMetadata. */
  archiveFileMetadata: FileMetadata;
  /** Archive a LearningContainer. */
  archiveLearningContainer: LearningContainer;
  /** Assign a license to a user */
  assignLicenseToUser: License;
  associateCatalogItemWithGroup: CatalogGroup;
  /** Add a new association between a File and an external URL. */
  associateFileToExternalUrl: FileMetadata;
  /** Add a new association between a File and a File. */
  associateFileToFile: FileMetadata;
  /** Add a new association between a LearningContainer and a File. */
  associateFileToLearningContainer: LearningContainer;
  /** Add a new association between a LearningContainer and an external URL. */
  associateLearningContainerToExternalUrl: LearningContainer;
  /** Add a new association between a LearningContainer and a LearningContainer. */
  associateLearningContainerToLearningContainer: LearningContainer;
  /** Add a new association between a LearningContainer and a related FileMetadata. */
  associateLearningContainerToRelatedFile: LearningContainer;
  /** Perform a bulk operation on LA users. */
  bulkOperateLearningAccountUser?: Maybe<BulkOperateLearningAccountUserOutput>;
  /** Perform a bulk operation on Organization users. */
  bulkOperateOrganizationUsers?: Maybe<BulkOperateOrganizationUsersOutput>;
  /** Register bulk users to a LearningActivity */
  bulkRegisterLearningActivity?: Maybe<RegistrationConnection>;
  /** Withdraw bulk users from a LearningActivity */
  bulkWithdrawLearningActivity?: Maybe<RegistrationConnection>;
  cancelClassroom: ClassroomAck;
  cancelOrder: Order;
  cancelOrderItem: Order;
  cancelPublishingWorkflow: PublishingWorkflow;
  completeFulfillmentItem?: Maybe<Fulfillment>;
  completePublishingWorkflowStep: PublishingWorkflow;
  /** Creates a payment method for customer using their AWS account */
  createAWSAccountPaymentMethod: PaymentMethod;
  createAssessment?: Maybe<Assessment>;
  createAssessmentLearningObjective?: Maybe<AssessmentMetadataObject>;
  createAssessmentQuestion?: Maybe<AssessmentQuestion>;
  createAssessmentQuestionBank?: Maybe<AssessmentMetadataObject>;
  createAssessmentTag?: Maybe<AssessmentMetadataObject>;
  /** Create/Update LearningActivity, DeliverySession, AttendancePolicyAttributes locally */
  createAttendanceEntities: AttendancePolicyAttributes;
  /** Generate a location with presigned url for user to upload bulk operation file. */
  createBulkOperationFileUploadUrl?: Maybe<BulkOperationFile>;
  createCatalogCustomAttributeSchema: CatalogCustomAttributeSchema;
  createCatalogCustomIdentifier: CatalogCustomIdentifier;
  createCatalogGroup: CatalogGroup;
  createCatalogItem: CatalogItem;
  createCatalogItemVersion: CatalogItem;
  createCatalogNamespace: CatalogNamespace;
  createClassroom: ClassroomAck;
  createDevDemoEvent: Scalars['String']['output'];
  /** Create a new External User. */
  createExternalUser?: Maybe<User>;
  /** Create FileMetadata. */
  createFileMetadata: FileMetadata;
  createFulfillmentItemUploadURL: Scalars['String']['output'];
  /** Create a new system group. */
  createGroup?: Maybe<Group>;
  createIntegrationStepTemplate: WorkflowStepTemplate;
  /** Create a new Internal User. Can only be done by another internal user by providing internal alias. */
  createInternalUser?: Maybe<User>;
  /** Create a new LearningAccount from SFDC/ADMS sync integration. */
  createLearningAccount?: Maybe<LearningAccount>;
  /** Create a new LearningContainer. */
  createLearningContainer: LearningContainer;
  /** Create a new License. */
  createLicense: License;
  /** Create a new license policy */
  createLicensePolicy: LicensePolicy;
  createLxpAttributeTemplate: LxpAttributeTemplate;
  /** Creates a new checkout session that when finalized will create a new order */
  createNewOrderCheckoutSession: CheckoutSession;
  /**
   * It is a SkillBuilder Digital use case specific API hosted as a resolver at back-end
   * Only used by Middle Man Page in CheckoutService front end
   * Firstly it validates:
   * - if user already has orders in Created or FulfilmentStarted status
   * - if user is applicable for free trial with the selected offer
   *
   * Secondly, it:
   * - creates a new checkout session at the SERVICE level for SkillBuilder Digital use case
   * - returns the checkout session redirect url string if succeed
   */
  createNewSbDigitalOrderCheckoutSession?: Maybe<Scalars['String']['output']>;
  createOffer: Offer;
  createOrder: Order;
  /** Create a new Organization. */
  createOrganization?: Maybe<Organization>;
  /** Create a new organization/team group. */
  createOrganizationGroup?: Maybe<Group>;
  createOutOfCycleBill?: Maybe<OutOfCycleBill>;
  createPlaygroundEvent: Scalars['String']['output'];
  /** Create a new pre-signed Url for uploading a file to Lumos. */
  createPresignedFileUploadUrl: Scalars['AWSURL']['output'];
  createPublishingWorkflow: PublishingWorkflow;
  /** Create LearningActivity Registration Policy Attributes - store registration information locally */
  createRegistrationPolicyAttributes: RegistrationPolicyAttributes;
  /**
   * Creates a new checkout session that when finalized will replace an order item
   * (cancel it and create a new order)
   */
  createReplaceOrderItemCheckoutSession: CheckoutSession;
  /** Create a new Team. */
  createTeam?: Maybe<Team>;
  /** Create a new transcript for the user */
  createTranscript: TranscriptV1;
  createUpdateCatalogFile: CatalogFile;
  createUserAssessment?: Maybe<UserAssessment>;
  deactivateCatalogGroup: CatalogGroup;
  deactivateCatalogItem: CatalogItem;
  deactivateCatalogItemVersion: CatalogItem;
  /** Deactivate an existing External User. Can only be done by an internal user. */
  deactivateExternalUser?: Maybe<User>;
  /** Deactivate an existing group */
  deactivateGroup: Scalars['Boolean']['output'];
  /** Deactivate an organization. */
  deactivateOrganization: Scalars['Boolean']['output'];
  deleteComment: Scalars['Boolean']['output'];
  deleteCommentsOnFile: Scalars['Boolean']['output'];
  deleteCommentsOnLearningContainer: Scalars['Boolean']['output'];
  /** Delete a file. */
  deleteFile: FileMetadata;
  deletePublishingWorkflowComment: Scalars['Boolean']['output'];
  /** Delete a transcript record */
  deleteTranscript: Scalars['Boolean']['output'];
  disassociateCatalogItemFromGroup: CatalogGroup;
  /** Remove an association between a File and an external URL. */
  disassociateFileToExternalUrl: FileMetadata;
  /** Remove an association between a File and a File. */
  disassociateFileToFile: FileMetadata;
  /** Remove an association between a LearningContainer and a File. */
  disassociateFileToLearningContainer: LearningContainer;
  /** Remove an association between a LearningContainer and an external URL. */
  disassociateLearningContainerToExternalUrl: LearningContainer;
  /** Remove an association between a LearningContainer and a LearningContainer. */
  disassociateLearningContainerToLearningContainer: LearningContainer;
  /** Remove an association between a LearningContainer and a related FileMetadata. */
  disassociateLearningContainerToRelatedFile: LearningContainer;
  editComment: Comment;
  editCommentsOnFile: FileMetadata;
  editCommentsOnLearningContainer: LearningContainer;
  editPublishingWorkflowComment: PublishingWorkflow;
  expireOffer: Scalars['Boolean']['output'];
  failFulfillmentItem?: Maybe<Fulfillment>;
  failPublishingWorkflowStep: PublishingWorkflow;
  /**
   * Marks the checkout session as "COMPLETE", and depending on the type will:
   * - Create a new order
   * - Cancel an order item
   *
   * Can only be called when the session is "OPEN"
   */
  finalizeCheckoutSession: CheckoutSession;
  generateDownloadablePublishingWorkflowAuditLog?: Maybe<Scalars['AWSURL']['output']>;
  /** Mark Attendance */
  markAttendance: Array<Maybe<AttendanceRecord>>;
  /** Move an existing user from one organization to another organization. Move will migrate only Learner roles between Orgs */
  moveUserBetweenOrganizations?: Maybe<UserAssociation>;
  /** Move an existing user from one (system) group to another (system) group. */
  moveUserBetweenSystemGroups?: Maybe<UserAssociation>;
  pauseAssessment?: Maybe<UserAssessment>;
  /** Register user to a Learning Activity */
  registerLearningActivity: Registration;
  removePublishingWorkflowTag: PublishingWorkflow;
  removePublishingWorkflowWatcher: PublishingWorkflow;
  /** Remove an existing Team. Also removes Team associations */
  removeTeam: Scalars['Boolean']['output'];
  /** Remove User Associtation Role(s) for a user in Org/Team */
  removeUserAssociationRoles: Scalars['Boolean']['output'];
  /** Remove an existing user from an existing group. */
  removeUserFromGroup: Scalars['Boolean']['output'];
  /** Remove a user from an organization. Will also remove any associations on user declining invitation */
  removeUserFromOrganization: Scalars['Boolean']['output'];
  /** Remove an existing user from an existing team. */
  removeUserFromTeam: Scalars['Boolean']['output'];
  replaceOrderItem: ReplaceOrderItemOutput;
  /** Use an older version of a FileMetadata as the latest data. */
  restoreFileMetadataVersion: FileMetadata;
  /** Use an older version of a LearningContainer as the latest data. */
  restoreLearningContainerVersion: LearningContainer;
  /** Save an evaluation response id for a transcript */
  saveIltEvaluationResponse: IltEvaluation;
  sendNotificationWithQuery?: Maybe<Notification>;
  startAssessment?: Maybe<UserAssessment>;
  /** Mutation to start Assessments Questions Generation workflow using GenAI */
  startAssessmentQuestionsGenerationWorkflow?: Maybe<AssessmentQuestionsStartWorkflowOutput>;
  startPublishingWorkflowStep: PublishingWorkflow;
  /** Records the exit survey results */
  submitExit: ExitResult;
  submitFulfillmentItemDetails: Scalars['Boolean']['output'];
  /** Terminate a License. */
  terminateLicense: License;
  /** Unarchive a FileMetadata. */
  unarchiveFileMetadata: FileMetadata;
  /** Unarchive a LearningContainer. */
  unarchiveLearningContainer: LearningContainer;
  /** Unassign a license from a user */
  unassignLicenseFromUser: License;
  updateAssessment?: Maybe<Assessment>;
  updateAssessmentQuestion?: Maybe<AssessmentQuestion>;
  updateAttendanceEntities: AttendancePolicyAttributes;
  updateCatalogCustomAttributeSchema: CatalogCustomAttributeSchema;
  updateCatalogGroup: CatalogGroup;
  updateCatalogItem: CatalogItem;
  updateCatalogItemVersion: CatalogItem;
  updateCatalogNamespace: CatalogNamespace;
  updateClassroom: ClassroomAck;
  /** Update a FileMetadata's data. */
  updateFileMetadata: FileMetadata;
  /** Update an existing system group. */
  updateGroup?: Maybe<Group>;
  updateIntegrationStepTemplate: WorkflowStepTemplate;
  /** Update existing LearningAccount from SFDC/ADMS sync integration. */
  updateLearningAccount?: Maybe<LearningAccount>;
  /** Update a LearningContainer's data. */
  updateLearningContainer: LearningContainer;
  /** Update a license policy */
  updateLicensePolicy: LicensePolicy;
  /** Update a License to OrderItem association. */
  updateLicenseToOrderItemAssociation: License;
  updateLxpAttributeTemplate: LxpAttributeTemplate;
  /** Update an existing Organization. */
  updateOrganization?: Maybe<Organization>;
  /** Update an existing organization/team group. */
  updateOrganizationGroup?: Maybe<Group>;
  updatePublishingWorkflow: PublishingWorkflow;
  updatePublishingWorkflowStepValues: PublishingWorkflow;
  /** Update LearningActivity Registration Policy Attributes - store registration information locally */
  updateRegistrationPolicyAttributes: RegistrationPolicyAttributes;
  /** Update an existing Team. */
  updateTeam?: Maybe<Team>;
  /** Update a transcript record. */
  updateTranscript: TranscriptV1;
  /**
   * Update a User entity. Typically used for updating userRole or supporting information.
   *
   * This mutation does not support the deletion of fields; leaving out an optional field
   * from the input will not remove that field from the user.
   */
  updateUser?: Maybe<User>;
  /** Flip the status of User to LA association (Active to Inactive, Inactive to Active). */
  updateUserLearningAccountAssociation?: Maybe<LearningAccountUser>;
  /** Update user's association status with Organization. */
  updateUserOrganizationAssociationStatus: UserAssociation;
  /** Withdraw user from a Learning Activity */
  withdrawLearningActivity: Registration;
};


export type MutationAcceptPublishingWorkflowsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationAcknowledgeFulfillmentItemArgs = {
  input?: InputMaybe<AcknowledgeFulfillmentItemInput>;
};


export type MutationAddCommentArgs = {
  input?: InputMaybe<AddCommentInput>;
};


export type MutationAddCommentsToFileArgs = {
  input: AddCommentsToFileInput;
};


export type MutationAddCommentsToLearningContainerArgs = {
  input?: InputMaybe<AddCommentsToLearningContainerInput>;
};


export type MutationAddFulfillmentItemResultDataArgs = {
  input: AddFulfillmentItemResultDataInput;
};


export type MutationAddLearningAccountUserArgs = {
  input?: InputMaybe<AddLearningAccountUserInput>;
};


export type MutationAddLicenseMetadataArgs = {
  input: AddLicenseMetadataInput;
};


export type MutationAddPaymentMethodToCheckoutSessionArgs = {
  input: AddPaymentMethodToCheckoutSessionInput;
};


export type MutationAddPublishingWorkflowCommentArgs = {
  input?: InputMaybe<AddPublishingWorkflowCommentInput>;
};


export type MutationAddPublishingWorkflowTagArgs = {
  input?: InputMaybe<AddPublishingWorkflowTagInput>;
};


export type MutationAddPublishingWorkflowWatcherArgs = {
  input?: InputMaybe<AddPublishingWorkflowWatcherInput>;
};


export type MutationAddUserAssociationRolesArgs = {
  parentResourceId: Scalars['String']['input'];
  parentResourceType: AssociationResourceType;
  userId: Scalars['String']['input'];
  userRoles?: InputMaybe<Array<UserRoleV2>>;
};


export type MutationAddUserToGroupArgs = {
  input: AddGroupUserInput;
};


export type MutationAddUserToOrganizationArgs = {
  input: AddOrganizationUserInput;
};


export type MutationAddUserToTeamArgs = {
  input: AddTeamUserInput;
};


export type MutationApproveExternalUserArgs = {
  input: ApproveExternalUserInput;
};


export type MutationArchiveFileMetadataArgs = {
  input: ArchiveFileMetadataInput;
};


export type MutationArchiveLearningContainerArgs = {
  input: ArchiveLearningContainerInput;
};


export type MutationAssignLicenseToUserArgs = {
  input: AssignLicenseToUserInput;
};


export type MutationAssociateCatalogItemWithGroupArgs = {
  input: AssociateCatalogItemWithGroupInput;
};


export type MutationAssociateFileToExternalUrlArgs = {
  input: FileRelatedItemInput;
};


export type MutationAssociateFileToFileArgs = {
  input: FileRelatedItemInput;
};


export type MutationAssociateFileToLearningContainerArgs = {
  input: FileToLearningContainerAssignmentInput;
};


export type MutationAssociateLearningContainerToExternalUrlArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationAssociateLearningContainerToLearningContainerArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationAssociateLearningContainerToRelatedFileArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationBulkOperateLearningAccountUserArgs = {
  input?: InputMaybe<BulkOperateLearningAccountUserInput>;
};


export type MutationBulkOperateOrganizationUsersArgs = {
  input: BulkOperateOrganizationUsersInput;
};


export type MutationBulkRegisterLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationBulkWithdrawLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationCancelClassroomArgs = {
  classroomArn: Scalars['String']['input'];
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationCancelOrderItemArgs = {
  input: CancelOrderItemInput;
};


export type MutationCancelPublishingWorkflowArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCompleteFulfillmentItemArgs = {
  input?: InputMaybe<CompleteFulfillmentItemInput>;
};


export type MutationCompletePublishingWorkflowStepArgs = {
  input?: InputMaybe<CompletePublishingWorkflowStepInput>;
};


export type MutationCreateAwsAccountPaymentMethodArgs = {
  input: CreateAwsAccountPaymentMethodInput;
};


export type MutationCreateAssessmentArgs = {
  input: AssessmentInput;
};


export type MutationCreateAssessmentLearningObjectiveArgs = {
  input: AssessmentMetadataInput;
};


export type MutationCreateAssessmentQuestionArgs = {
  input: AssessmentQuestionInput;
};


export type MutationCreateAssessmentQuestionBankArgs = {
  input: AssessmentMetadataInput;
};


export type MutationCreateAssessmentTagArgs = {
  input: AssessmentMetadataInput;
};


export type MutationCreateAttendanceEntitiesArgs = {
  input: CreateOrUpdateAttendanceEntitiesInput;
};


export type MutationCreateBulkOperationFileUploadUrlArgs = {
  fileMetadata: BulkOperationFileMetadataInput;
  learningAccountId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationCreateCatalogCustomAttributeSchemaArgs = {
  input: CreateCatalogCustomAttributeSchemaInput;
};


export type MutationCreateCatalogCustomIdentifierArgs = {
  input: CreateCatalogCustomIdentifierInput;
};


export type MutationCreateCatalogGroupArgs = {
  input: CreateCatalogGroupInput;
};


export type MutationCreateCatalogItemArgs = {
  input: CreateCatalogItemInput;
};


export type MutationCreateCatalogItemVersionArgs = {
  input: CreateCatalogItemVersionInput;
};


export type MutationCreateCatalogNamespaceArgs = {
  input: CreateCatalogNamespaceInput;
};


export type MutationCreateClassroomArgs = {
  input: ClassroomInput;
};


export type MutationCreateDevDemoEventArgs = {
  input?: InputMaybe<DevDemoEventInput>;
};


export type MutationCreateExternalUserArgs = {
  input: CreateExternalUserInput;
};


export type MutationCreateFileMetadataArgs = {
  input: CreateFileMetadataInput;
};


export type MutationCreateFulfillmentItemUploadUrlArgs = {
  input?: InputMaybe<CreateFulfillmentItemUploadUrlInput>;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateIntegrationStepTemplateArgs = {
  input?: InputMaybe<CreateIntegrationStepTemplateInput>;
};


export type MutationCreateInternalUserArgs = {
  input: CreateInternalUserInput;
};


export type MutationCreateLearningAccountArgs = {
  input?: InputMaybe<CreateLearningAccountInput>;
};


export type MutationCreateLearningContainerArgs = {
  input: CreateLearningContainerInput;
};


export type MutationCreateLicenseArgs = {
  input: CreateLicenseInput;
};


export type MutationCreateLicensePolicyArgs = {
  input: CreateLicensePolicyInput;
};


export type MutationCreateLxpAttributeTemplateArgs = {
  input?: InputMaybe<CreateLxpAttributeTemplateInput>;
};


export type MutationCreateNewOrderCheckoutSessionArgs = {
  input: CreateNewOrderCheckoutSessionInput;
};


export type MutationCreateNewSbDigitalOrderCheckoutSessionArgs = {
  input: CreateNewSbDigitalOrderCheckoutSessionInput;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrganizationGroupArgs = {
  input: CreateOrganizationGroupInput;
};


export type MutationCreateOutOfCycleBillArgs = {
  input: CreateOutOfCycleBillInput;
};


export type MutationCreatePlaygroundEventArgs = {
  input?: InputMaybe<PlaygroundEventInput>;
};


export type MutationCreatePresignedFileUploadUrlArgs = {
  input: CreatePresignedFileUploadUrlInput;
};


export type MutationCreatePublishingWorkflowArgs = {
  input?: InputMaybe<CreatePublishingWorkflowInput>;
};


export type MutationCreateRegistrationPolicyAttributesArgs = {
  input: RegistrationPolicyAttributesInput;
};


export type MutationCreateReplaceOrderItemCheckoutSessionArgs = {
  input: CreateReplaceOrderItemCheckoutSessionInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTranscriptArgs = {
  input: CreateTranscriptInput;
};


export type MutationCreateUpdateCatalogFileArgs = {
  input: CreateUpdateCatalogFileInput;
};


export type MutationCreateUserAssessmentArgs = {
  input: UserAssessmentInput;
};


export type MutationDeactivateCatalogGroupArgs = {
  input: DeactivateCatalogGroupInput;
};


export type MutationDeactivateCatalogItemArgs = {
  input: DeactivateCatalogItemInput;
};


export type MutationDeactivateCatalogItemVersionArgs = {
  input: DeactivateCatalogItemVersionInput;
};


export type MutationDeactivateExternalUserArgs = {
  input: DeactivateExternalUserInput;
};


export type MutationDeactivateGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type MutationDeactivateOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteCommentArgs = {
  input?: InputMaybe<DeleteCommentInput>;
};


export type MutationDeleteCommentsOnFileArgs = {
  input: DeleteCommentsOnFileInput;
};


export type MutationDeleteCommentsOnLearningContainerArgs = {
  input?: InputMaybe<DeleteCommentsOnLearningContainerInput>;
};


export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


export type MutationDeletePublishingWorkflowCommentArgs = {
  commentId: Scalars['ID']['input'];
};


export type MutationDeleteTranscriptArgs = {
  input: DeleteTranscriptInput;
};


export type MutationDisassociateCatalogItemFromGroupArgs = {
  input: DisassociateCatalogItemFromGroupInput;
};


export type MutationDisassociateFileToExternalUrlArgs = {
  input: FileRelatedItemInput;
};


export type MutationDisassociateFileToFileArgs = {
  input: FileRelatedItemInput;
};


export type MutationDisassociateFileToLearningContainerArgs = {
  input: FileToLearningContainerAssignmentInput;
};


export type MutationDisassociateLearningContainerToExternalUrlArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationDisassociateLearningContainerToLearningContainerArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationDisassociateLearningContainerToRelatedFileArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationEditCommentArgs = {
  input?: InputMaybe<EditCommentInput>;
};


export type MutationEditCommentsOnFileArgs = {
  input: EditCommentsOnFileInput;
};


export type MutationEditCommentsOnLearningContainerArgs = {
  input?: InputMaybe<EditCommentsOnLearningContainerInput>;
};


export type MutationEditPublishingWorkflowCommentArgs = {
  input?: InputMaybe<EditPublishingWorkflowCommentInput>;
};


export type MutationExpireOfferArgs = {
  input: ExpireOfferInput;
};


export type MutationFailFulfillmentItemArgs = {
  input?: InputMaybe<FailFulfillmentItemInput>;
};


export type MutationFailPublishingWorkflowStepArgs = {
  input?: InputMaybe<FailPublishingWorkflowStepInput>;
};


export type MutationFinalizeCheckoutSessionArgs = {
  input: FinalizeCheckoutSessionInput;
};


export type MutationGenerateDownloadablePublishingWorkflowAuditLogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarkAttendanceArgs = {
  input: MarkAttendanceInput;
};


export type MutationMoveUserBetweenOrganizationsArgs = {
  destinationOrganizationId: Scalars['String']['input'];
  sourceOrganizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationMoveUserBetweenSystemGroupsArgs = {
  destinationGroupId: Scalars['String']['input'];
  sourceGroupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationPauseAssessmentArgs = {
  input: ChangeAssessmentStatusInput;
};


export type MutationRegisterLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemovePublishingWorkflowTagArgs = {
  input?: InputMaybe<RemovePublishingWorkflowTagInput>;
};


export type MutationRemovePublishingWorkflowWatcherArgs = {
  input?: InputMaybe<RemovePublishingWorkflowWatcherInput>;
};


export type MutationRemoveTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type MutationRemoveUserAssociationRolesArgs = {
  parentResourceId: Scalars['String']['input'];
  parentResourceType: AssociationResourceType;
  userId: Scalars['String']['input'];
  userRoles?: InputMaybe<Array<UserRoleV2>>;
};


export type MutationRemoveUserFromGroupArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveUserFromOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveUserFromTeamArgs = {
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationReplaceOrderItemArgs = {
  input: ReplaceOrderItemInput;
};


export type MutationRestoreFileMetadataVersionArgs = {
  input: RestoreFileMetadataVersionInput;
};


export type MutationRestoreLearningContainerVersionArgs = {
  input: RestoreLearningContainerVersionInput;
};


export type MutationSaveIltEvaluationResponseArgs = {
  evaluationResponseId: Scalars['ID']['input'];
  transcriptId: Scalars['ID']['input'];
};


export type MutationSendNotificationWithQueryArgs = {
  input: SendNotificationWithQuery;
};


export type MutationStartAssessmentArgs = {
  input: ChangeAssessmentStatusInput;
};


export type MutationStartAssessmentQuestionsGenerationWorkflowArgs = {
  input?: InputMaybe<AssessmentQuestionsStartWorkflowInput>;
};


export type MutationStartPublishingWorkflowStepArgs = {
  input?: InputMaybe<StartPublishingWorkflowStepInput>;
};


export type MutationSubmitExitArgs = {
  input: ExitSurveyInput;
};


export type MutationSubmitFulfillmentItemDetailsArgs = {
  input: SubmitFulfillmentItemDetailsInput;
};


export type MutationTerminateLicenseArgs = {
  input: TerminateLicenseInput;
};


export type MutationUnarchiveFileMetadataArgs = {
  input: UnarchiveFileMetadataInput;
};


export type MutationUnarchiveLearningContainerArgs = {
  input: UnarchiveLearningContainerInput;
};


export type MutationUnassignLicenseFromUserArgs = {
  input: UnassignLicenseFromUserInput;
};


export type MutationUpdateAssessmentArgs = {
  input: AssessmentUpdateInput;
};


export type MutationUpdateAssessmentQuestionArgs = {
  input: AssessmentQuestionUpdateInput;
};


export type MutationUpdateAttendanceEntitiesArgs = {
  input: CreateOrUpdateAttendanceEntitiesInput;
};


export type MutationUpdateCatalogCustomAttributeSchemaArgs = {
  input: UpdateCatalogCustomAttributeSchemaInput;
};


export type MutationUpdateCatalogGroupArgs = {
  input: UpdateCatalogGroupInput;
};


export type MutationUpdateCatalogItemArgs = {
  input: UpdateCatalogItemInput;
};


export type MutationUpdateCatalogItemVersionArgs = {
  input: UpdateCatalogItemVersionInput;
};


export type MutationUpdateCatalogNamespaceArgs = {
  input: UpdateCatalogNamespaceInput;
};


export type MutationUpdateClassroomArgs = {
  classroomArn: Scalars['String']['input'];
  input: UpdateClassroomInput;
  version: Scalars['Int']['input'];
};


export type MutationUpdateFileMetadataArgs = {
  input: UpdateFileMetadataInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateIntegrationStepTemplateArgs = {
  input?: InputMaybe<UpdateIntegrationStepTemplateInput>;
};


export type MutationUpdateLearningAccountArgs = {
  input?: InputMaybe<UpdateLearningAccountInput>;
};


export type MutationUpdateLearningContainerArgs = {
  input: UpdateLearningContainerInput;
};


export type MutationUpdateLicensePolicyArgs = {
  input: UpdateLicensePolicyInput;
};


export type MutationUpdateLicenseToOrderItemAssociationArgs = {
  input: UpdateLicenseToOrderItemAssociationInput;
};


export type MutationUpdateLxpAttributeTemplateArgs = {
  input?: InputMaybe<UpdateLxpAttributeTemplateInput>;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationGroupArgs = {
  input: UpdateOrganizationGroupInput;
};


export type MutationUpdatePublishingWorkflowArgs = {
  input?: InputMaybe<UpdatePublishingWorkflowInput>;
};


export type MutationUpdatePublishingWorkflowStepValuesArgs = {
  input?: InputMaybe<UpdatePublishingWorkflowStepValuesInput>;
};


export type MutationUpdateRegistrationPolicyAttributesArgs = {
  input: RegistrationPolicyAttributesInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationUpdateTranscriptArgs = {
  input: UpdateTranscriptInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserLearningAccountAssociationArgs = {
  input?: InputMaybe<UpdateUserLearningAccountAssociationInput>;
};


export type MutationUpdateUserOrganizationAssociationStatusArgs = {
  associationStatus: UserAssociationStatus;
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationWithdrawLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type NmbsBillingDetails = {
  __typename?: 'NMBSBillingDetails';
  agreementId?: Maybe<Scalars['String']['output']>;
  billId?: Maybe<Scalars['String']['output']>;
  billInitiatedAt?: Maybe<Scalars['String']['output']>;
  billingConsoleUILink?: Maybe<Scalars['String']['output']>;
  billingErrorMessage?: Maybe<Scalars['String']['output']>;
  billingStatus: B2BBillingStatus;
  billingStatusUpdatedAt: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
};

export type NmbsBillingDetailsInput = {
  billingStatus: B2BBillingStatus;
};

export type Name = {
  __typename?: 'Name';
  fullName: Scalars['String']['output'];
  nameFields?: Maybe<NameFields>;
};

export type NameFields = {
  __typename?: 'NameFields';
  familyName?: Maybe<Scalars['String']['output']>;
  giveName: Scalars['String']['output'];
  middleNameOrInitials?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NameFieldsInput = {
  familyName?: InputMaybe<Scalars['String']['input']>;
  giveName: Scalars['String']['input'];
  middleNameOrInitials?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NameInput = {
  fullName: Scalars['String']['input'];
  nameFields?: InputMaybe<NameFieldsInput>;
};

export type Notification = {
  __typename?: 'Notification';
  cultureCode?: Maybe<Scalars['String']['output']>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  notificationId: Scalars['String']['output'];
  notificationType?: Maybe<Scalars['String']['output']>;
  receivedAt: Scalars['AWSDateTime']['output'];
  templateValues?: Maybe<Scalars['AWSJSON']['output']>;
};

export type NotificationConfiguration = {
  link: Scalars['AWSURL']['input'];
  /** Must be in the form email@email.com */
  recipientEmails: Array<Scalars['String']['input']>;
};

export type NotificationOverride = {
  __typename?: 'NotificationOverride';
  benefitIds?: Maybe<Array<Scalars['ID']['output']>>;
  notificationConfigurations?: Maybe<Array<OfferNotificationConfiguration>>;
};

/** The Notifications we want to have configured within an Offer */
export enum NotificationType {
  EntitlementCancelation = 'ENTITLEMENT_CANCELATION',
  FreetrialCancelation = 'FREETRIAL_CANCELATION',
  FreetrialEnd = 'FREETRIAL_END',
  FreetrialStart = 'FREETRIAL_START',
  FreetrialThreeDayReminder = 'FREETRIAL_THREE_DAY_REMINDER',
  MonthlyRenewalReminder = 'MONTHLY_RENEWAL_REMINDER',
  PaidStart = 'PAID_START',
  PaymentFailureCancelation = 'PAYMENT_FAILURE_CANCELATION',
  PaymentFailureWarning = 'PAYMENT_FAILURE_WARNING',
  RenewsToday = 'RENEWS_TODAY',
  SubscriptionCancelation = 'SUBSCRIPTION_CANCELATION',
  YearlyRenewalReminderFiveDays = 'YEARLY_RENEWAL_REMINDER_FIVE_DAYS',
  YearlyRenewalReminderFortyfiveDays = 'YEARLY_RENEWAL_REMINDER_FORTYFIVE_DAYS'
}

export type Offer = {
  __typename?: 'Offer';
  /** Identifying which benefits can be applied to a given offer */
  applicableBenefits?: Maybe<Array<Maybe<OfferBenefit>>>;
  /**
   * Retrieves offer benefits applicable to a user with selected offer
   * Default eligible to free trial if not specify isFreeTrialEligible field
   */
  applicableBenefitsForUser?: Maybe<Array<OfferBenefit>>;
  /** Offer Object metadata */
  auditMetadata: AuditMetadata;
  /** When should an offer cancel when a custoemr attempts to cancel */
  cancellationConfiguration?: Maybe<CancellationConfiguration>;
  /** Description of the offer */
  description: Scalars['String']['output'];
  /** Discounted price for Offer with benefits */
  discountedPrice?: Maybe<Scalars['Float']['output']>;
  /** When the offer expires */
  expiresAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Unique ID of an Offer */
  id: Scalars['ID']['output'];
  /** License configuration stores information on details related to the entitlements a user may recieve on purchase of the Offer. This could be VIAS and docebo values */
  licenseConfiguration?: Maybe<LicenseConfiguration>;
  localizationByCode?: Maybe<Localization>;
  /** Avalible Localizations for an Offer */
  localizations?: Maybe<Array<Localization>>;
  /** Which service this offer is being provided to */
  modality: OfferModality;
  /** Given Name of an Offer */
  name: Scalars['String']['output'];
  /** Notification Configuration holds the data on specific notifications ot send for a given offer. As such this will detail which notifications need to be sent and when */
  notificationConfigurations?: Maybe<Array<OfferNotificationConfiguration>>;
  /** Notification configurations to override when applying benefits */
  notificationOverrides?: Maybe<Array<NotificationOverride>>;
  /** Base price for Offer without benefits */
  offerPrice: Scalars['Float']['output'];
  /** Payment configuration which stores information on the basic details that downstream services need within an offer to complete a purchase. */
  paymentConfiguration?: Maybe<PaymentConfiguration>;
  /** How the offer is being purchased and through what steps */
  purchasingPlan?: Maybe<PurchasingPlan>;
  /** Current Status of an offer */
  status: OfferStatus;
  /** What type of offer is provided */
  type: OfferType;
};


export type OfferApplicableBenefitsForUserArgs = {
  isFreeTrialEligible?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};


export type OfferLocalizationByCodeArgs = {
  locale: Scalars['String']['input'];
};

export type OfferBenefit = {
  __typename?: 'OfferBenefit';
  benefitRules?: Maybe<BenefitRules>;
  description: Scalars['String']['output'];
  discountAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  localizationByCode?: Maybe<Localization>;
  localizations?: Maybe<Array<Localization>>;
  name: Scalars['String']['output'];
  platformConfiguration?: Maybe<PlatformConfiguration>;
  scheduledPaymentOffsetHours?: Maybe<Scalars['Int']['output']>;
  type: BenefitType;
};


export type OfferBenefitLocalizationByCodeArgs = {
  locale: Scalars['String']['input'];
};

export enum OfferBillingPeriod {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  OneTime = 'ONE_TIME'
}

export enum OfferModality {
  AwsJam = 'AWS_JAM',
  Digital = 'DIGITAL',
  Padawan = 'PADAWAN'
}

export type OfferNotificationConfiguration = {
  __typename?: 'OfferNotificationConfiguration';
  eventName?: Maybe<Scalars['String']['output']>;
  notificationType: NotificationType;
  offsetInHours?: Maybe<Scalars['Int']['output']>;
  templateId: Scalars['ID']['output'];
};

export type OfferRetryConfiguration = {
  __typename?: 'OfferRetryConfiguration';
  attemptAmount: Scalars['Int']['output'];
  attemptCadenceHours: Scalars['Int']['output'];
};

export enum OfferStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export enum OfferType {
  Alacarte = 'ALACARTE',
  Subscription = 'SUBSCRIPTION',
  Tuition = 'TUITION'
}

export type Order = {
  __typename?: 'Order';
  auditMetadata: AuditMetadata;
  billingAwsAccountId?: Maybe<Scalars['String']['output']>;
  businessDevelopmentManager?: Maybe<ProductOnboardingUser>;
  fulfillment?: Maybe<Fulfillment>;
  id: Scalars['ID']['output'];
  items: Array<OrderItem>;
  /** Paginated orderItem query for ordersByOpportunityId */
  itemsPaginated?: Maybe<OrderItemConnection>;
  learningAccount?: Maybe<LearningAccount>;
  noOfExistingLicenses?: Maybe<Scalars['Int']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  opportunityName?: Maybe<Scalars['String']['output']>;
  orderCanceledTicketId?: Maybe<Scalars['String']['output']>;
  parentOpportunityId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: OrderStatus;
  type?: Maybe<OrderType>;
  typeOfContract?: Maybe<TypeOfContract>;
  user?: Maybe<User>;
};


export type OrderItemsPaginatedArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  nodes: Array<Order>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  /** List for benefits actually applied to the order item with selected offer */
  appliedBenefits?: Maybe<Array<OfferBenefit>>;
  billingPeriod?: Maybe<BillingPeriod>;
  cancellationDetails?: Maybe<CancellationDetails>;
  classEndDate?: Maybe<Scalars['AWSDateTime']['output']>;
  classStartDate?: Maybe<Scalars['AWSDateTime']['output']>;
  contractingRequestCreator?: Maybe<ContractingRequestCreator>;
  effectiveAt?: Maybe<Scalars['AWSDateTime']['output']>;
  freeTrialDetails?: Maybe<FreeTrialDetails>;
  id: Scalars['ID']['output'];
  learningAccount?: Maybe<LearningAccount>;
  learningActivity?: Maybe<LearningActivity>;
  licenses?: Maybe<Array<License>>;
  metadata?: Maybe<Array<KeyValuePair>>;
  nmbsBillingDetails?: Maybe<NmbsBillingDetails>;
  offer?: Maybe<Offer>;
  opportunityLineItemId?: Maybe<Scalars['String']['output']>;
  orderId: Scalars['ID']['output'];
  orderType?: Maybe<OrderType>;
  paginatedLicenses: LicenseConnection;
  /** List of payments for Order Item */
  payments?: Maybe<PaymentConnection>;
  productModality?: Maybe<ProductModality>;
  productOnboardingDetails?: Maybe<ProductOnboardingDetails>;
  productTitle?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  ssoEnabled?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<OrderItemStatus>;
  statusReason?: Maybe<Scalars['String']['output']>;
  statusUpdatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  subscriptionType?: Maybe<TypeOfContract>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};


export type OrderItemPaginatedLicensesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  onlyActiveAssociations?: InputMaybe<Scalars['Boolean']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderItemPaymentsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum OrderItemAssociationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type OrderItemConnection = {
  __typename?: 'OrderItemConnection';
  nodes: Array<OrderItem>;
  pageInfo?: Maybe<PageInfo>;
};

export enum OrderItemStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  FulfillmentCompleted = 'FULFILLMENT_COMPLETED',
  FulfillmentStarted = 'FULFILLMENT_STARTED'
}

export type OrderItemsFilterInput = {
  classEndDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  classStartDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  search: Scalars['String']['input'];
};

export enum OrderStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  FulfillmentStarted = 'FULFILLMENT_STARTED'
}

/** Configures how the Post Checkout Order Summary is displayed */
export type OrderSummaryComponent = {
  __typename?: 'OrderSummaryComponent';
  items: Array<OrderSummaryItem>;
  subtext: TextSection;
};

export type OrderSummaryComponentInput = {
  items: Array<OrderSummaryItemInput>;
  subtext: TextSectionInput;
};

/** An item to display within the Order Summary component */
export type OrderSummaryItem = {
  __typename?: 'OrderSummaryItem';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OrderSummaryItemInput = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum OrderType {
  B2B = 'B2B',
  B2I = 'B2I'
}

export type OrdersFilterInput = {
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  search: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  auditMetadata: AuditMetadata;
  awsAccountId: Scalars['String']['output'];
  awsAssociation?: Maybe<AwsAssociation>;
  billingAddress?: Maybe<Address>;
  billingAwsAccountIds?: Maybe<Array<Scalars['String']['output']>>;
  customerSince?: Maybe<Scalars['AWSDateTime']['output']>;
  /**
   * List of groups belonging to an Organization with option to include groups from Teams under that Organization.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  groupAssociations?: Maybe<GroupAssociationConnection>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationType: OrganizationType;
  organizationVisibilityClassification?: Maybe<OrganizationVisibilityClassification>;
  phoneNumber?: Maybe<Scalars['AWSPhone']['output']>;
  sfdcAccountId?: Maybe<Scalars['String']['output']>;
  sfdcParentAccountId?: Maybe<Scalars['String']['output']>;
  status: OrganizationStatus;
  /**
   * List of teams belonging to an Organization.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  teamAssociations?: Maybe<TeamAssociationConnection>;
  /**
   * List of users belonging to an Organization.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  userAssociations?: Maybe<UserAssociationConnection>;
  vendorResourceCode?: Maybe<Scalars['String']['output']>;
  vendorResourceId?: Maybe<Scalars['Int']['output']>;
};


export type OrganizationGroupAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  includeGroupsFromTeams?: InputMaybe<Scalars['Boolean']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTeamAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationUserAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  nodes: Array<Organization>;
  pageInfo?: Maybe<PageInfo>;
};

export enum OrganizationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum OrganizationType {
  Amazon = 'AMAZON',
  CustomersB2B = 'CUSTOMERS_B2B',
  CustomersB2I = 'CUSTOMERS_B2I',
  PartnersB2B = 'PARTNERS_B2B',
  PartnersB2I = 'PARTNERS_B2I'
}

export enum OrganizationVisibilityClassification {
  General = 'GENERAL',
  Restricted = 'RESTRICTED'
}

export type OutOfCycleBill = {
  __typename?: 'OutOfCycleBill';
  agreementId: Scalars['String']['output'];
  awsCustomerId?: Maybe<Scalars['String']['output']>;
  billAccountId: Scalars['String']['output'];
  billDate: Scalars['AWSDateTime']['output'];
  billInitiatedAt: Scalars['AWSDateTime']['output'];
  commercePlatformBillId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  customTaxAddress?: Maybe<CustomTaxAddress>;
  customTaxAddressId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lineItems: Array<Maybe<LineItem>>;
  lineOfBusiness: Scalars['String']['output'];
  marketplaceId: Scalars['String']['output'];
  payerAccountId: Scalars['String']['output'];
  purchaseOrder?: Maybe<PurchaseOrder>;
  serviceCode: Scalars['String']['output'];
  sor: Scalars['String']['output'];
  status: Scalars['String']['output'];
  submittedBy?: Maybe<EmailProfile>;
};

export type Ptrr = {
  __typename?: 'PTRR';
  accountName: Scalars['String']['output'];
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  awsBillingAccount: Scalars['String']['output'];
  awsEndUserAccount: Scalars['String']['output'];
  billingNotes?: Maybe<Scalars['String']['output']>;
  billingTerms?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdDate: Scalars['AWSDateTime']['output'];
  customerTaxVATId?: Maybe<Scalars['String']['output']>;
  geo: Scalars['String']['output'];
  legalEntityNameTraining: Scalars['String']['output'];
  opportunityName?: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['String']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  poNumberOnInvoice?: Maybe<Scalars['String']['output']>;
  ptfTemplateName?: Maybe<Scalars['String']['output']>;
  ptrrId: Scalars['String']['output'];
  ptrrStatus: PtrrStatus;
  ptrrStatusType: PtrrStatusType;
  recordTypeId: Scalars['String']['output'];
  region: Scalars['String']['output'];
  state: Scalars['String']['output'];
  topsName?: Maybe<Scalars['String']['output']>;
  trainingBillingContactEmail: Scalars['String']['output'];
  trainingBillingContactName: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export enum PtrrStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  ContractingInProgress = 'CONTRACTING_IN_PROGRESS',
  CustomerAccepted = 'CUSTOMER_ACCEPTED',
  New = 'NEW',
  PendingCustomer = 'PENDING_CUSTOMER',
  PendingExceptionApproval = 'PENDING_EXCEPTION_APPROVAL',
  PendingInternal = 'PENDING_INTERNAL',
  Recalled = 'RECALLED',
  Rejected = 'REJECTED',
  SchedulingCancelled = 'SCHEDULING_CANCELLED',
  SchedulingComplete = 'SCHEDULING_COMPLETE',
  SchedulingInProgress = 'SCHEDULING_IN_PROGRESS',
  SubmittedForScheduling = 'SUBMITTED_FOR_SCHEDULING',
  TopsInProgress = 'TOPS_IN_PROGRESS',
  Unknown = 'UNKNOWN'
}

export enum PtrrStatusType {
  Contracting = 'CONTRACTING',
  Default = 'DEFAULT',
  Scheduling = 'SCHEDULING'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  cursor?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

/** Represents a single payment */
export type Payment = {
  __typename?: 'Payment';
  /** Indicating whether the payment has benefits applied */
  appliedBenefits?: Maybe<Array<Maybe<OfferBenefit>>>;
  auditMetadata: AuditMetadata;
  /** Time when the payment is canceled */
  canceledAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Time payment cancellation actually began to be processed by processor */
  cancellationInitiatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Indicating the payment cancellation reason */
  cancellationReason?: Maybe<PaymentCancellationReason>;
  /** Time the payment cancellation is scheduled to go through */
  cancellationScheduledAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** unique identifier of payment in Payment Service */
  id: Scalars['ID']['output'];
  /** Time installment payment actually began to be processed by processor */
  initiatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Indicating whether the payment amount is prorated for monthly subscription */
  isProrated?: Maybe<Scalars['Boolean']['output']>;
  /** Indicating whether the payment is a renew payment */
  isRenewal?: Maybe<Scalars['Boolean']['output']>;
  /** Offer associated with payment. */
  offer: Offer;
  /** Order associated with payment. */
  order: Order;
  /** Order item associated with payment. */
  orderItem: OrderItem;
  /** Payment method associated with this payment */
  paymentMethod: PaymentMethod;
  /** Time the installment payment is scheduled to go through */
  scheduledAt: Scalars['AWSDateTime']['output'];
  /** Time payment is actually settled by (paid vs failed) */
  settledAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Status of payment */
  status: PaymentStatus;
  /** Type of payment. Currently only installment payment type for Padawan */
  type: PaymentType;
  /** User making this payment */
  user: User;
};

export enum PaymentCancellationReason {
  /** Payment canceled for fraudulent payment method */
  FraudPaymentMethod = 'FRAUD_PAYMENT_METHOD',
  /** Payment canceled when user or on-call cancels the order */
  OrderCanceled = 'ORDER_CANCELED',
  /** Payment canceled after multiple retry failures */
  PaymentFailure = 'PAYMENT_FAILURE',
  /** Payment canceled from unexpected SDMS subscription canceled status when Payment supposed to be active */
  SdmsCanceled = 'SDMS_CANCELED'
}

export type PaymentConfiguration = {
  __typename?: 'PaymentConfiguration';
  offerBillingPeriod: OfferBillingPeriod;
  paymentContexts: Array<PaymentContext>;
  paymentScheduleConfiguration?: Maybe<ScheduleConfiguration>;
  platformConfiguration: PlatformConfiguration;
  retryConfiguration: OfferRetryConfiguration;
};

export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  nodes: Array<Payment>;
  pageInfo?: Maybe<PageInfo>;
};

/** In the future will maintain context like locale and currency code which will have deeper implementation in the catalog project */
export type PaymentContext = {
  __typename?: 'PaymentContext';
  price: Scalars['Float']['output'];
};

/** A wrapper payment method in Payment Service */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  auditMetadata: AuditMetadata;
  /** AWS account ID if payment method type is AWS account */
  awsAccountId?: Maybe<Scalars['String']['output']>;
  /** Unique identifier of payment method in Payment Service */
  id: Scalars['ID']['output'];
  /** Is PaymentMethod Potentially Fraudulent */
  isFraudPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  /** Type of payment method. Currently, AWS account is the only supported type */
  type: PaymentMethodType;
  /** User payment method belongs to */
  user: User;
};

/** Type of payment methods for checkout session */
export enum PaymentMethodType {
  /** AWS account payment method */
  AwsAccount = 'AWS_ACCOUNT'
}

export enum PaymentStatus {
  /** Payment is being authorized (used for monthly subscription) */
  Authorized = 'AUTHORIZED',
  /** Payment canceled before initiated */
  Canceled = 'CANCELED',
  /** Payment not paid successfully by settled timeout window */
  Failed = 'FAILED',
  /** Payment is being processed */
  Initiated = 'INITIATED',
  /** Payment successfully paid */
  Paid = 'PAID',
  /** Payment scheduled for some time in future */
  Scheduled = 'SCHEDULED'
}

export enum PaymentType {
  /** Annual payment for SkillBuilder */
  AnnualSubscription = 'ANNUAL_SUBSCRIPTION',
  /** Installment payment for Padawan */
  Installment = 'INSTALLMENT',
  /** Monthly payment for SkillBuilder */
  MonthlySubscription = 'MONTHLY_SUBSCRIPTION',
  /** One time payment for ALACARTE like AWS Jam */
  OneTime = 'ONE_TIME'
}

export type PaymentsFilterInput = {
  orderId?: InputMaybe<Scalars['String']['input']>;
  scheduledAtEndDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  scheduledAtStartDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  statuses?: InputMaybe<Array<PaymentStatus>>;
};

export type Phone = {
  __typename?: 'Phone';
  label?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
};

export type PhoneInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

export type PhysicalAddress = {
  __typename?: 'PhysicalAddress';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type PhysicalAddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

/** Multiple platforms require discrete information so creating this for future platforms */
export type PlatformConfiguration = CommercePlatformConfiguration;

export type PlaygroundEventInput = {
  id: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type PointOfContact = {
  __typename?: 'PointOfContact';
  email: Scalars['AWSEmail']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['AWSPhone']['output']>;
};

export type PollAssessmentQuestionsWorkflowInput = {
  executionId: Scalars['String']['input'];
};

export type PollAssessmentQuestionsWorkflowOutput = {
  __typename?: 'PollAssessmentQuestionsWorkflowOutput';
  executionStatus: Scalars['String']['output'];
  input: Scalars['String']['output'];
  output?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  stopDate?: Maybe<Scalars['String']['output']>;
};

/** Configures how the Post-Checkout Page is displayed */
export type PostCheckoutPage = {
  __typename?: 'PostCheckoutPage';
  /** Confirmation component config of post-checkout page */
  confirmationComponent: ConfirmationComponent;
  /** Order summary component config of post-checkout page */
  orderSummaryComponent: OrderSummaryComponent;
  /** Title text of pre-checkout page */
  title: Scalars['String']['output'];
};

export type PostCheckoutPageInput = {
  confirmationComponent: ConfirmationComponentInput;
  orderSummaryComponent: OrderSummaryComponentInput;
  title: Scalars['String']['input'];
};

/** Configures how the Pre-Checkout Page is displayed */
export type PreCheckoutPage = {
  __typename?: 'PreCheckoutPage';
  /** Cart component config of pre-checkout page */
  cartComponent: CartComponent;
  /** Finalize checkout session component config of pre-checkout page */
  finalizeCheckoutSessionComponent: FinalizeCheckoutSessionComponent;
  /** Title text of pre-checkout page */
  title: Scalars['String']['output'];
};

export type PreCheckoutPageInput = {
  cartComponent: CartComponentInput;
  finalizeCheckoutSessionComponent: FinalizeCheckoutSessionComponentInput;
  title: Scalars['String']['input'];
};

export type PrincipalAuthorizedResourcesInput = {
  action: AuthorizationActionType;
  principal: AuthorizationPrincipalIdentityInput;
  resourceTypes: Array<AuthorizationResourceType>;
};

export enum PrivacyStatus {
  Optin = 'OPTIN',
  Optout = 'OPTOUT'
}

export enum ProductModality {
  AwsJam = 'AWS_JAM',
  Digital = 'DIGITAL',
  Ilt = 'ILT',
  Padawan = 'PADAWAN'
}

export enum ProductOnboardingAuthenticationMethod {
  Apn = 'APN',
  AwsBuilderId = 'AWS_BUILDER_ID',
  Lwa = 'LWA',
  Sso = 'SSO'
}

export type ProductOnboardingDetails = {
  __typename?: 'ProductOnboardingDetails';
  authenticationMethod?: Maybe<ProductOnboardingAuthenticationMethod>;
  emailDomains: Array<Scalars['String']['output']>;
  identityProvider?: Maybe<ProductOnboardingIdentityProvider>;
  learningAdmins: Array<ProductOnboardingUser>;
  organizationName: Scalars['String']['output'];
  ssoContact?: Maybe<ProductOnboardingUser>;
};

export type ProductOnboardingDetailsInput = {
  authenticationMethod?: InputMaybe<ProductOnboardingAuthenticationMethod>;
  emailDomains: Array<Scalars['String']['input']>;
  identityProvider?: InputMaybe<ProductOnboardingIdentityProvider>;
  learningAdmins: Array<ProductOnboardingUserInput>;
  organizationName: Scalars['String']['input'];
  ssoContact?: InputMaybe<ProductOnboardingUserInput>;
};

export enum ProductOnboardingIdentityProvider {
  Adfs = 'ADFS',
  Azure = 'AZURE',
  Duo = 'DUO',
  Google = 'GOOGLE',
  Jump = 'JUMP',
  Okta = 'OKTA',
  Onelogin = 'ONELOGIN',
  Ping = 'PING'
}

export type ProductOnboardingUser = {
  __typename?: 'ProductOnboardingUser';
  emailAddress: Scalars['AWSEmail']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type ProductOnboardingUserInput = {
  emailAddress: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export enum PublishingScope {
  Major = 'MAJOR',
  Minor = 'MINOR',
  NewCourse = 'NEW_COURSE',
  Patch = 'PATCH'
}

export type PublishingWorkflow = {
  __typename?: 'PublishingWorkflow';
  assignedTo?: Maybe<Scalars['String']['output']>;
  auditLog?: Maybe<PublishingWorkflowAuditLogConnection>;
  auditMetadata: AuditMetadata;
  comments?: Maybe<EntityComments>;
  currentWorkflowStep?: Maybe<WorkflowStep>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  learningContainer: LearningContainer;
  notes?: Maybe<Scalars['String']['output']>;
  status: PublishingWorkflowStatus;
  tags?: Maybe<Array<KeyValuePair>>;
  targetLxp: Lxp;
  targetLxpEnvStages?: Maybe<Array<EnvStage>>;
  targetPublishDate?: Maybe<Scalars['AWSDateTime']['output']>;
  watchers?: Maybe<Array<Scalars['String']['output']>>;
  workflowSteps: Array<WorkflowStep>;
};


export type PublishingWorkflowAuditLogArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PublishingWorkflowAuditLog = {
  __typename?: 'PublishingWorkflowAuditLog';
  actionBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  timestamp: Scalars['AWSDateTime']['output'];
  value: Scalars['AWSJSON']['output'];
};

export type PublishingWorkflowAuditLogConnection = {
  __typename?: 'PublishingWorkflowAuditLogConnection';
  nodes: Array<PublishingWorkflowAuditLog>;
  pageInfo?: Maybe<PageInfo>;
};

export type PublishingWorkflowConnection = {
  __typename?: 'PublishingWorkflowConnection';
  nodes: Array<PublishingWorkflow>;
  pageInfo?: Maybe<PageInfo>;
};

export enum PublishingWorkflowStatus {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export enum PublishingWorkflowStepStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Initial = 'INITIAL',
  InProgress = 'IN_PROGRESS'
}

export type PublishingWorkflowsByLearningContainerInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  learningContainerId: Scalars['ID']['input'];
  learningContainerVersion?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  billFromList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  orderEffectiveDate: Scalars['AWSDateTime']['output'];
  orderExpiryDate: Scalars['AWSDateTime']['output'];
  orderNumber: Scalars['String']['output'];
};

export type PurchaseOrderInput = {
  billFromList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderEffectiveDate: Scalars['AWSDateTime']['input'];
  orderExpiryDate: Scalars['AWSDateTime']['input'];
  orderNumber: Scalars['String']['input'];
  paymentTerm?: InputMaybe<PurchaseOrderPaymentTerm>;
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
};

export enum PurchaseOrderPaymentTerm {
  Net_0 = 'NET_0',
  Net_15 = 'NET_15',
  Net_30 = 'NET_30',
  Net_45 = 'NET_45',
  Net_60 = 'NET_60',
  Net_75 = 'NET_75',
  Net_90 = 'NET_90',
  Net_105 = 'NET_105',
  Net_120 = 'NET_120'
}

export type PurchasingPlan = InstallmentPlan;

export type Query = {
  __typename?: 'Query';
  assessment?: Maybe<Assessment>;
  assessmentQuestion?: Maybe<AssessmentQuestion>;
  assessmentQuestions?: Maybe<AssessmentQuestionsOutput>;
  assessments?: Maybe<AssessmentsOutput>;
  benefitById: OfferBenefit;
  catalogGroupById: CatalogGroup;
  catalogItemById: CatalogItem;
  catalogItemByVersionedId: CatalogItem;
  catalogItemVersionsById: CatalogItemConnection;
  catalogNamespaceById: CatalogNamespace;
  /** Get a Base64 endcoded string of Certificate by Transcript id Query */
  certificate?: Maybe<Scalars['String']['output']>;
  /** Retrieves a session by ID and optional version */
  checkoutSessionById: CheckoutSession;
  /** Get attendance records */
  classAttendance: Array<Maybe<LearningActivityAttendanceRecord>>;
  classroomsPing: Scalars['String']['output'];
  commentsByEntity: CommentsConnection;
  /** Get current user */
  currentUser: User;
  /** Get file location by user alias and file ID and version. */
  fileLocationByIdAndVersion: FileLocation;
  /** Get a specific FileMetadata latest version by its ID. */
  fileMetadataById: FileMetadata;
  /** Get a specific FileMetadata version by its ID and version. */
  fileMetadataByIdAndVersion: FileMetadata;
  /** Get all versions of FileMetadata by its ID. */
  fileMetadataVersionsById: FileMetadataConnection;
  /** Get files metadata based on search criteria. */
  filesMetadata: FileMetadataConnection;
  fulfillmentById: Fulfillment;
  getClassroom: Classroom;
  getOrderItemByOpportunityLineItemId?: Maybe<OrderItem>;
  getSalesforceOpportunityById: SalesforceOpportunity;
  getSalesforceOpportunityLineByLineItemId: SalesforceOpportunityLineItem;
  /** Gets ToBeBilledActivies for NMBS */
  getToBeBilledActivities?: Maybe<Array<Array<Scalars['ID']['output']>>>;
  /** Get a Group by its id. */
  groupById?: Maybe<Group>;
  /** Get a Group by its vendor id. */
  groupByVendorId?: Maybe<Group>;
  /** Get a list of Groups, with optional filters to search, get system groups only */
  groups?: Maybe<GroupConnection>;
  /**
   * Get Groups a User belongs to by user vibeId
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  groupsByVibeId?: Maybe<GroupConnection>;
  /** Confirm if an awsAccountId has associated active payment subscriptions over an threshold (1 by default) */
  hasInitiatedOrScheduledPayments: Scalars['Boolean']['output'];
  hello?: Maybe<Scalars['String']['output']>;
  helloDevDemo?: Maybe<Scalars['String']['output']>;
  helloDevDemoNew?: Maybe<Scalars['String']['output']>;
  helloQuery?: Maybe<Scalars['String']['output']>;
  helloWorld?: Maybe<HelloWorld>;
  hola?: Maybe<Scalars['String']['output']>;
  /** Get ilt evaluations by userId */
  iltEvaluationsByUserId?: Maybe<IltEvaluationConnection>;
  /** Get a list of all internal Users. */
  internalUsers?: Maybe<UserConnection>;
  invoiceById?: Maybe<Invoice>;
  invoiceDocumentByInvoiceId?: Maybe<InvoiceDocument>;
  /** Fetches authorization data related to users and scopes. */
  isAuthorized: ResourceAuthorizationCheckOutput;
  /** Get a Learning Account by its ID. */
  learningAccountById?: Maybe<LearningAccount>;
  /** Get a Learning Account by SFDC Account ID. */
  learningAccountBySfdcAccountId?: Maybe<LearningAccount>;
  /** Get a Learning Account by its associated SkillBuilder Branch ID, if one exists. */
  learningAccountBySkillBuilderBranchId?: Maybe<LearningAccount>;
  /** Get a learning account user association. */
  learningAccountUserById?: Maybe<LearningAccountUser>;
  /** Get a Users for Learning Account separate call, with optional search filter. */
  learningAccountUsers?: Maybe<LearningAccountUsersConnection>;
  /** Get a list of Learning Accounts, with optional search filter. */
  learningAccounts?: Maybe<LearningAccountsConnection>;
  /** Get Learning Accounts by awsAccountId. */
  learningAccountsByAwsAccountId?: Maybe<Array<Maybe<LearningAccount>>>;
  /** Get Learning Accounts by Parent SFDC Account ID. */
  learningAccountsBySfdcParentAccountId?: Maybe<Array<LearningAccount>>;
  /** Get a list of Learning Account by userId. */
  learningAccountsByUserId?: Maybe<Array<Maybe<LearningAccount>>>;
  /** Fetch learning activities by given search criteria */
  learningActivities?: Maybe<Array<Maybe<LearningActivity>>>;
  /** Fetch learning activity based on PK */
  learningActivity?: Maybe<LearningActivity>;
  /** Get a specific LearningContainer latest version by its ID. */
  learningContainerById: LearningContainer;
  /** Get a specific LearningContainer version by its ID and version. */
  learningContainerByIdAndVersion: LearningContainer;
  /** Get all versions of LearningContainer by its ID. */
  learningContainerVersionsById: LearningContainerConnection;
  /** Get learning containers based on search criteria. */
  learningContainers: LearningContainerConnection;
  /** Get a License by its ID and optional version. */
  licenseById: License;
  /** Get a LicensePolicy by its ID and optional version. */
  licensePolicyById: LicensePolicy;
  /** Get a LicenseCollection by OrderItemId. */
  licensesByOrderItemId: LicenseConnection;
  lxpAttributeTemplateByLxpAndEntity: LxpAttributeTemplate;
  lxpAttributeTemplatesByLxp: LxpAttributeTemplateConnection;
  offerById: Offer;
  orderById: Order;
  orderByOpportunityId?: Maybe<Order>;
  orderItemById: OrderItem;
  /** Get a list of orders, with optional search filter */
  orders: OrderConnection;
  ordersByOpportunityId: OrderConnection;
  /** Get an Organization by its ID. */
  organizationById?: Maybe<Organization>;
  /** Get an Organization by its SFDC account id. */
  organizationBySfdcAccountId?: Maybe<Organization>;
  /** Get an Organization by its vendor code. */
  organizationByVendorCode?: Maybe<Organization>;
  /** Get an Organization by its vendorId. */
  organizationByVendorId?: Maybe<Organization>;
  /** Get a list of Organizations, with optional search filter. */
  organizations?: Maybe<OrganizationConnection>;
  /** Get list of Organizations by their Aws Account Id. */
  organizationsByAwsAccountId?: Maybe<OrganizationConnection>;
  /** Get list of Organizations by their SFDC parent account id. */
  organizationsBySfdcParentAccountId?: Maybe<OrganizationConnection>;
  /**
   * Get Organizations a User belongs to by user vibeId
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  organizationsByVibeId?: Maybe<OrganizationConnection>;
  outOfCycleBillByExternalBillId?: Maybe<OutOfCycleBill>;
  /** Retrieve payment by its ID */
  paymentById: Payment;
  /** Retrieve payment method by its ID */
  paymentMethodById: PaymentMethod;
  playgroundQuery?: Maybe<Scalars['String']['output']>;
  pollAssessmentQuestionsGenerationWorkflow?: Maybe<PollAssessmentQuestionsWorkflowOutput>;
  /** Fetches Authorized resources for a given Principal and Action */
  principalAuthorizedResources: AuthorizedResourcesOutput;
  /** Get LicensePolicies based on principal. */
  principalLicensePolicies: LicensePolicyConnection;
  publishingWorkflowById: PublishingWorkflow;
  publishingWorkflows?: Maybe<PublishingWorkflowConnection>;
  publishingWorkflowsByLearningContainer?: Maybe<PublishingWorkflowConnection>;
  recommendations?: Maybe<Array<Recommendation>>;
  /** Get Registration record by Registration Record Id */
  registrationById?: Maybe<Registration>;
  /** Get Registration record by Learning Activity Id and User Id */
  registrationByLearningActivityIdAndUserId?: Maybe<Registration>;
  /** Get Registrations entities by Instructor Led Training LearningActivityId */
  registrationsByLearningActivityId?: Maybe<RegistrationConnection>;
  /** Get Registrations entities by UserId */
  registrationsByUserId?: Maybe<RegistrationConnection>;
  /** Get LicensePolicies based on resource. */
  resourceLicensePolicies: LicensePolicyConnection;
  /** Search Query -- Queries Kendra using a query string and optional filters. */
  searchV2?: Maybe<SearchResultV2>;
  /** Get a single Transcript by Transcript id Query */
  transcript?: Maybe<Transcript>;
  /** Get transcript record by vibe ID and catalog item ID */
  transcriptByUserVibeIdAndCatalogItemId?: Maybe<TranscriptV1>;
  /** Get transcript record by vendorUserID and vendorCatalogItem ID */
  transcriptByVendorUserIdAndVendorCatalogItemId?: Maybe<TranscriptV1>;
  /** Get Transcript list by User Id Query */
  transcriptsByUserId?: Maybe<TranscriptConnection>;
  /** Get transcripts by vibe ID */
  transcriptsByUserVibeId?: Maybe<TranscriptConnectionV1>;
  /** Get a specific file uploaded by userId and fileId. */
  userBulkOperationFileById?: Maybe<BulkOperationFile>;
  /** Get the template of bulk user operation file, for the user to download and fill out with user info. */
  userBulkOperationFileTemplate: BulkOperationFile;
  /** Get a list of files uploaded by this user. */
  userBulkOperationFiles?: Maybe<UserBulkOperationFileConnection>;
  /**
   * Get a User based on provided email address.
   * @deprecated Resolver is deprecated. Please query by 'usersByEmail' instead.
   */
  userByEmail?: Maybe<User>;
  /** Get a User by its HatID. */
  userByHatId?: Maybe<User>;
  /** Get a User by its ID. Id should be synced to VIAS so it exists in JWT claims. */
  userById?: Maybe<User>;
  /** Get a User based on their external vendor id. */
  userByVendorId?: Maybe<User>;
  /** Get a User by its VibeID. */
  userByVibeId?: Maybe<User>;
  /** Get Users based on provided email address. */
  usersByEmail: Array<User>;
  /** Returns the default steps necessary for publishing to the target LXP for a specific publishing type. */
  workflowStepTemplates: Array<WorkflowStepTemplate>;
};


export type QueryAssessmentArgs = {
  id: Scalars['String']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  version: Scalars['Int']['input'];
};


export type QueryAssessmentQuestionArgs = {
  id: Scalars['String']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  version: Scalars['Int']['input'];
};


export type QueryAssessmentQuestionsArgs = {
  input: AssessmentQuestionsInput;
};


export type QueryAssessmentsArgs = {
  input: AssessmentsInput;
};


export type QueryBenefitByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCatalogGroupByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCatalogItemByIdArgs = {
  id: Scalars['ID']['input'];
  semanticVersion?: InputMaybe<SemanticVersionInput>;
};


export type QueryCatalogItemByVersionedIdArgs = {
  versionedId: Scalars['ID']['input'];
};


export type QueryCatalogItemVersionsByIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  major?: InputMaybe<Scalars['Int']['input']>;
  minor?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCatalogNamespaceByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryCertificateArgs = {
  transcriptId: Scalars['ID']['input'];
};


export type QueryCheckoutSessionByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClassAttendanceArgs = {
  searchCriteria: ClassAttendanceQueryInput;
};


export type QueryCommentsByEntityArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  entityName: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFileLocationByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  userAlias: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};


export type QueryFileMetadataByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFileMetadataByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


export type QueryFileMetadataVersionsByIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFilesMetadataArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchFilters?: InputMaybe<FileMetadataSearchFilters>;
};


export type QueryFulfillmentByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetClassroomArgs = {
  classroomArn: Scalars['String']['input'];
};


export type QueryGetOrderItemByOpportunityLineItemIdArgs = {
  opportunityLineItemId: Scalars['ID']['input'];
};


export type QueryGetSalesforceOpportunityByIdArgs = {
  includeAllLines?: InputMaybe<Scalars['Boolean']['input']>;
  opportunityId: Scalars['String']['input'];
};


export type QueryGetSalesforceOpportunityLineByLineItemIdArgs = {
  opportunityLineItemId: Scalars['String']['input'];
};


export type QueryGroupByIdArgs = {
  groupId: Scalars['ID']['input'];
};


export type QueryGroupByVendorIdArgs = {
  groupStatus?: InputMaybe<GroupStatus>;
  vendorId: Scalars['String']['input'];
};


export type QueryGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  groupType?: InputMaybe<GroupType>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGroupsByVibeIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  vibeId: Scalars['String']['input'];
};


export type QueryHasInitiatedOrScheduledPaymentsArgs = {
  awsAccountId: Scalars['String']['input'];
  threshold?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHelloWorldArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIltEvaluationsByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryInternalUsersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvoiceByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoiceDocumentByInvoiceIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIsAuthorizedArgs = {
  input: ResourceAuthorizationCheckInput;
};


export type QueryLearningAccountByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLearningAccountBySfdcAccountIdArgs = {
  sfdcAccountId: Scalars['String']['input'];
};


export type QueryLearningAccountBySkillBuilderBranchIdArgs = {
  skillBuilderBranchId: Scalars['String']['input'];
};


export type QueryLearningAccountUserByIdArgs = {
  learningAccountUserAssociationId: Scalars['ID']['input'];
};


export type QueryLearningAccountUsersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  learningAccountId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLearningAccountsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLearningAccountsByAwsAccountIdArgs = {
  awsAccountId: Scalars['String']['input'];
};


export type QueryLearningAccountsBySfdcParentAccountIdArgs = {
  sfdcParentAccountId: Scalars['String']['input'];
};


export type QueryLearningAccountsByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryLearningActivitiesArgs = {
  learningActivitiesInput: LearningActivitiesInput;
};


export type QueryLearningActivityArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryLearningContainerByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLearningContainerByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


export type QueryLearningContainerVersionsByIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLearningContainersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchFilters?: InputMaybe<LearningContainerSearchFilters>;
};


export type QueryLicenseByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLicensePolicyByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLicensesByOrderItemIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  onlyActiveAssociations?: InputMaybe<Scalars['Boolean']['input']>;
  orderItemId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLxpAttributeTemplateByLxpAndEntityArgs = {
  entity: EntityType;
  lxp?: InputMaybe<Lxp>;
};


export type QueryLxpAttributeTemplatesByLxpArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  lxp?: InputMaybe<Lxp>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOfferByIdArgs = {
  appliedBenefits?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
};


export type QueryOrderByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrderByOpportunityIdArgs = {
  opportunityId: Scalars['ID']['input'];
};


export type QueryOrderItemByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrdersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrdersByOpportunityIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationBySfdcAccountIdArgs = {
  sfdcAccountId: Scalars['String']['input'];
};


export type QueryOrganizationByVendorCodeArgs = {
  vendorCode: Scalars['String']['input'];
};


export type QueryOrganizationByVendorIdArgs = {
  vendorId: Scalars['Int']['input'];
};


export type QueryOrganizationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganizationsByAwsAccountIdArgs = {
  awsAccountId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationsBySfdcParentAccountIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sfdcParentAccountId: Scalars['String']['input'];
};


export type QueryOrganizationsByVibeIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  vibeId: Scalars['String']['input'];
};


export type QueryOutOfCycleBillByExternalBillIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentMethodByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPollAssessmentQuestionsGenerationWorkflowArgs = {
  input?: InputMaybe<PollAssessmentQuestionsWorkflowInput>;
};


export type QueryPrincipalAuthorizedResourcesArgs = {
  input: PrincipalAuthorizedResourcesInput;
};


export type QueryPrincipalLicensePoliciesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  policyStatus?: InputMaybe<LicensePolicyStatus>;
  principalId: Scalars['ID']['input'];
  principalType: AuthorizationPrincipalType;
};


export type QueryPublishingWorkflowByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPublishingWorkflowsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPublishingWorkflowsByLearningContainerArgs = {
  input?: InputMaybe<PublishingWorkflowsByLearningContainerInput>;
};


export type QueryRecommendationsArgs = {
  input?: InputMaybe<RecommendationsInput>;
};


export type QueryRegistrationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRegistrationByLearningActivityIdAndUserIdArgs = {
  learningActivityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryRegistrationsByLearningActivityIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  learningActivityId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRegistrationsByUserIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryResourceLicensePoliciesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  policyStatus?: InputMaybe<LicensePolicyStatus>;
  resourceId: Scalars['ID']['input'];
  resourceType: AuthorizationResourceType;
};


export type QuerySearchV2Args = {
  input: SearchInputV2;
};


export type QueryTranscriptArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTranscriptByUserVibeIdAndCatalogItemIdArgs = {
  catalogItemId: Scalars['ID']['input'];
  userVibeId: Scalars['ID']['input'];
};


export type QueryTranscriptByVendorUserIdAndVendorCatalogItemIdArgs = {
  vendorCatalogItemId: Scalars['Int']['input'];
  vendorCatalogType: Scalars['String']['input'];
  vendorUserId: Scalars['Int']['input'];
};


export type QueryTranscriptsByUserIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryTranscriptsByUserVibeIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userVibeId: Scalars['ID']['input'];
};


export type QueryUserBulkOperationFileByIdArgs = {
  fileId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryUserBulkOperationFilesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  fromTimeStamp?: InputMaybe<Scalars['AWSDateTime']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  toTimeStamp?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['AWSEmail']['input'];
};


export type QueryUserByHatIdArgs = {
  hatId: Scalars['String']['input'];
};


export type QueryUserByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserByVendorIdArgs = {
  vendorId: Scalars['String']['input'];
};


export type QueryUserByVibeIdArgs = {
  version?: InputMaybe<Scalars['Int']['input']>;
  vibeId: Scalars['String']['input'];
};


export type QueryUsersByEmailArgs = {
  email: Scalars['AWSEmail']['input'];
};


export type QueryWorkflowStepTemplatesArgs = {
  lxp: Lxp;
  publishingScope?: InputMaybe<PublishingScope>;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  catalogItem?: Maybe<CatalogItem>;
  id?: Maybe<Scalars['String']['output']>;
};

export enum RecommendationType {
  Personalized = 'PERSONALIZED',
  Popular = 'POPULAR'
}

export type RecommendationsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  recommendationType?: InputMaybe<RecommendationType>;
};

export type RedemptionPeriod = {
  __typename?: 'RedemptionPeriod';
  endDate?: Maybe<Scalars['AWSDateTime']['output']>;
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type Registration = {
  __typename?: 'Registration';
  auditMetadata: AuditMetadata;
  id: Scalars['ID']['output'];
  learningActivity: LearningActivity;
  registrationStatus: RegistrationStatus;
  user: User;
};

export type RegistrationConnection = {
  __typename?: 'RegistrationConnection';
  nodes: Array<Registration>;
  pageInfo?: Maybe<PageInfo>;
};

export type RegistrationPolicyAttributes = {
  __typename?: 'RegistrationPolicyAttributes';
  auditMetadata: AuditMetadata;
  awsAccountIds?: Maybe<Array<Scalars['ID']['output']>>;
  classSize: Scalars['Int']['output'];
  learningActivityId: Scalars['ID']['output'];
  learningActivityStatus: LearningActivityRegistrationStatus;
  learningActivityTimeZone: Scalars['String']['output'];
  lmsType: LearningActivityLmsType;
  registrationCutOffTimeStamp: Scalars['AWSTimestamp']['output'];
  withdrawalCutOffTimeStamp?: Maybe<Scalars['AWSTimestamp']['output']>;
};

export type RegistrationPolicyAttributesInput = {
  awsAccountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  classSize: Scalars['Int']['input'];
  learningActivityId: Scalars['ID']['input'];
  learningActivityStatus: LearningActivityRegistrationStatus;
  learningActivityTimeZone: Scalars['String']['input'];
  lmsType: LearningActivityLmsType;
  registrationCutOffTimeStamp: Scalars['AWSTimestamp']['input'];
  withdrawalCutOffTimeStamp: Scalars['AWSTimestamp']['input'];
};

export enum RegistrationStatus {
  Canceled = 'CANCELED',
  Registered = 'REGISTERED',
  Withdrawn = 'WITHDRAWN'
}

export type RelativeScheduleConfiguration = {
  __typename?: 'RelativeScheduleConfiguration';
  offsetInHours?: Maybe<Scalars['Int']['output']>;
  recurrence?: Maybe<OfferBillingPeriod>;
};

export type RemovePublishingWorkflowTagInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  tag: KeyValuePairInput;
};

export type RemovePublishingWorkflowWatcherInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  watcher: Scalars['String']['input'];
};

export type ReplaceOrderItemInput = {
  associatedLicenseIds: Array<Scalars['String']['input']>;
  newOrderItemInput: CreateOrderItemInput;
  orderId: Scalars['String']['input'];
  orderItemId: Scalars['String']['input'];
};

export type ReplaceOrderItemOutput = {
  __typename?: 'ReplaceOrderItemOutput';
  newOrder: Order;
  originalOrder: Order;
};

export type ResourceAuthorizationCheckInput = {
  action: AuthorizationActionType;
  principal: AuthorizationPrincipalIdentityInput;
  resources: Array<AuthorizationResourceInput>;
};

export type ResourceAuthorizationCheckOutput = {
  __typename?: 'ResourceAuthorizationCheckOutput';
  results: Array<ResourcesAuthorizationResult>;
};

export type ResourcesAuthorizationResult = {
  __typename?: 'ResourcesAuthorizationResult';
  action: AuthorizationActionType;
  authorizationResult: AuthorizationResult;
  resourceId: Scalars['ID']['output'];
  resourceType: AuthorizationResourceType;
};

export type RestoreFileMetadataVersionInput = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type RestoreLearningContainerVersionInput = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type SsoDetails = {
  __typename?: 'SSODetails';
  idp?: Maybe<SsoIdentityProvider>;
  ssoEnabled: Scalars['Boolean']['output'];
  technicalPOC?: Maybe<PointOfContact>;
};

export enum SsoIdentityProvider {
  Adfs = 'ADFS',
  Azure = 'AZURE',
  Duo = 'DUO',
  Google = 'GOOGLE',
  Jump = 'JUMP',
  Okta = 'OKTA',
  Onelogin = 'ONELOGIN',
  Ping = 'PING'
}

export type SalesforceOpportunity = {
  __typename?: 'SalesforceOpportunity';
  activeInvestmentRequest?: Maybe<ActiveInvestmentRequest>;
  awsAccountInfo: AwsAccountInfo;
  bdm: PointOfContact;
  billingCurrency?: Maybe<Scalars['String']['output']>;
  closeDate?: Maybe<Scalars['AWSDateTime']['output']>;
  doNotApplyVolumeDiscount?: Maybe<Scalars['Boolean']['output']>;
  grandTotal: Scalars['Float']['output'];
  irFundingType?: Maybe<Scalars['String']['output']>;
  irTotal?: Maybe<Scalars['Float']['output']>;
  items: Array<SalesforceOpportunityLineItem>;
  lmsId?: Maybe<Scalars['String']['output']>;
  noOfExistingLicenses: Scalars['Int']['output'];
  opportunityId: Scalars['String']['output'];
  opportunityName: Scalars['String']['output'];
  opportunitySubType?: Maybe<Scalars['String']['output']>;
  optionalDatesContract?: Maybe<Scalars['Boolean']['output']>;
  sfdcAccountId: Scalars['String']['output'];
  sfdcAccountName: Scalars['String']['output'];
  sfdcParentAccountId?: Maybe<Scalars['String']['output']>;
  sfdcParentAccountName?: Maybe<Scalars['String']['output']>;
  sfdcParentOpportunityId?: Maybe<Scalars['String']['output']>;
  specialDiscountAmount?: Maybe<Scalars['Float']['output']>;
  specialDiscountPercent?: Maybe<Scalars['Float']['output']>;
  typeOfContract?: Maybe<TypeOfContract>;
  volumeDiscount?: Maybe<Scalars['Float']['output']>;
};

export type SalesforceOpportunityLineItem = {
  __typename?: 'SalesforceOpportunityLineItem';
  allowedEmailDomains?: Maybe<Array<Scalars['String']['output']>>;
  authenticationMethod?: Maybe<AuthenticationMethod>;
  billingPeriod: BillingPeriod;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  classEndDate: Scalars['AWSDateTime']['output'];
  classEndTime?: Maybe<Scalars['AWSDateTime']['output']>;
  classLocation?: Maybe<Scalars['String']['output']>;
  classStartDate: Scalars['AWSDateTime']['output'];
  classStartTime?: Maybe<Scalars['AWSDateTime']['output']>;
  classStatus?: Maybe<ClassStatus>;
  contractingPTRR?: Maybe<Ptrr>;
  contractingPtrrId?: Maybe<Scalars['String']['output']>;
  contractingRequestCreatedBy?: Maybe<PointOfContact>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  deliveryLanguage?: Maybe<Scalars['String']['output']>;
  discountAmount: Scalars['Float']['output'];
  fundsUsed?: Maybe<Scalars['Float']['output']>;
  grimsbyActivityLink?: Maybe<Scalars['String']['output']>;
  lmsId?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  opportunityLineItemId: Scalars['String']['output'];
  primaryTrainingPOC: PointOfContact;
  productName: Scalars['String']['output'];
  productValueNetAmount?: Maybe<Scalars['Float']['output']>;
  quantity: Scalars['Int']['output'];
  schedulingPTRR?: Maybe<Ptrr>;
  schedulingPtrrId?: Maybe<Scalars['String']['output']>;
  secondaryTrainingPOC?: Maybe<PointOfContact>;
  ssoDetails: SsoDetails;
  subscriptionType?: Maybe<TypeOfContract>;
  timeZone?: Maybe<Scalars['String']['output']>;
  totalBilledRevenue: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  trainingDeliveryType?: Maybe<Scalars['String']['output']>;
  trainingTE?: Maybe<Scalars['Float']['output']>;
};

export type ScheduleConfiguration = AbsoluteScheduleConfiguration | RelativeScheduleConfiguration;

export type ScopeChangeAndNotes = {
  __typename?: 'ScopeChangeAndNotes';
  notes?: Maybe<Scalars['String']['output']>;
  updateScope?: Maybe<SemanticVersionScope>;
};

export type ScopeChangeAndNotesInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  updateScope?: InputMaybe<SemanticVersionScope>;
};

export type SearchCatalogItem = {
  __typename?: 'SearchCatalogItem';
  id: Scalars['String']['output'];
};

export type SearchFacetV2 = {
  __typename?: 'SearchFacetV2';
  key: Scalars['String']['output'];
  name: LocalizedStringV2;
  values?: Maybe<Array<SearchFacetValueV2>>;
};

export type SearchFacetValueV2 = {
  __typename?: 'SearchFacetValueV2';
  totalResults: Scalars['Int']['output'];
  value: LocalizedStringV2;
};

export type SearchFilterV2 = {
  key: Scalars['String']['input'];
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SearchInputV2 = {
  filters?: InputMaybe<Array<SearchFilterV2>>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<SearchResultOrder>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortCriterion?: InputMaybe<SearchSortCriterion>;
};

export type SearchResultItemV2 = {
  __typename?: 'SearchResultItemV2';
  id: Scalars['String']['output'];
  item?: Maybe<CatalogItem>;
};

export enum SearchResultOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type SearchResultV2 = {
  __typename?: 'SearchResultV2';
  facets?: Maybe<Array<SearchFacetV2>>;
  results?: Maybe<Array<SearchResultItemV2>>;
  sortCriteria?: Maybe<Array<SearchSortCriterion>>;
  totalResults: Scalars['Int']['output'];
};

export enum SearchSortCriterion {
  CourseLevel = 'COURSE_LEVEL',
  Date = 'DATE',
  Name = 'NAME',
  Rating = 'RATING',
  Relevance = 'RELEVANCE'
}

export type SemanticVersion = {
  __typename?: 'SemanticVersion';
  major?: Maybe<Scalars['Int']['output']>;
  minor?: Maybe<Scalars['Int']['output']>;
  patch?: Maybe<Scalars['Int']['output']>;
};

export type SemanticVersionInput = {
  major: Scalars['Int']['input'];
  minor: Scalars['Int']['input'];
  patch: Scalars['Int']['input'];
};

export enum SemanticVersionScope {
  Major = 'MAJOR',
  Minor = 'MINOR',
  Patch = 'PATCH'
}

export type SendNotificationWithQuery = {
  attachmentInfo?: InputMaybe<Array<EmailAttachment>>;
  clientIdentifier?: InputMaybe<Scalars['String']['input']>;
  cultureCode?: InputMaybe<Scalars['String']['input']>;
  deliveryType: Scalars['String']['input'];
  generatedEmailContent?: InputMaybe<Scalars['AWSJSON']['input']>;
  notificationType: Scalars['String']['input'];
  queryVariables: Scalars['AWSJSON']['input'];
  templateValues?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type Service = {
  __typename?: 'Service';
  sdl?: Maybe<Scalars['String']['output']>;
};

/** TODO: eagonz@ to add/update more of Settings */
export type Settings = {
  __typename?: 'Settings';
  AutoAssignDigitalSubscription?: Maybe<Scalars['Boolean']['output']>;
  LearningAdminAccessToReporting?: Maybe<Scalars['Boolean']['output']>;
  idpName?: Maybe<Scalars['String']['output']>;
  languagePreference?: Maybe<Scalars['String']['output']>;
  viewUserSensitiveData?: Maybe<PrivacyStatus>;
};

export type StartPublishingWorkflowStepInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  publishingWorkflowStepId: Scalars['ID']['input'];
};

export enum State {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type SubProviderCapacity = {
  __typename?: 'SubProviderCapacity';
  capacity: Scalars['Int']['output'];
  providerArn: Scalars['String']['output'];
};

export type SubProviderCapacityInput = {
  capacity: Scalars['Int']['input'];
  providerArn: Scalars['String']['input'];
};

export type SubmitFulfillmentItemDetailsInput = {
  digitalFulfillmentDetails: DigitalFulfillmentDetailsInput;
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
};

export type Tag = {
  __typename?: 'Tag';
  domain: Scalars['String']['output'];
  tagName: Scalars['String']['output'];
};

export type TagInput = {
  domain: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
};

export type Team = {
  __typename?: 'Team';
  auditMetadata: AuditMetadata;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * List of groups belonging to a Team.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  groupAssociations?: Maybe<GroupAssociationConnection>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  /**
   * List of users belonging to a Team.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  userAssociations?: Maybe<UserAssociationConnection>;
};


export type TeamGroupAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type TeamUserAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type TeamAssociation = {
  __typename?: 'TeamAssociation';
  auditMetadata: AuditMetadata;
  parentResourceId: Scalars['String']['output'];
  parentResourceType: AssociationResourceType;
  team: Team;
};

export type TeamAssociationConnection = {
  __typename?: 'TeamAssociationConnection';
  nodes: Array<TeamAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export type TeamConnection = {
  __typename?: 'TeamConnection';
  nodes: Array<Team>;
  pageInfo?: Maybe<PageInfo>;
};

export type TerminateLicenseInput = {
  id: Scalars['ID']['input'];
  terminationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  terminationReason?: InputMaybe<Scalars['String']['input']>;
};

/** Used in some locations to configure how a text block is rendered */
export type TextSection = {
  __typename?: 'TextSection';
  /** inline links to add to text */
  inlineLinks: Array<LinkConfig>;
  /** Style of how term should be rendered (e.g., BOLD or NORMAL) */
  style: TextStyle;
  /** Text of term */
  text: Scalars['String']['output'];
};

export type TextSectionInput = {
  inlineLinks?: InputMaybe<Array<LinkConfigInput>>;
  style: TextStyle;
  text: Scalars['String']['input'];
};

/**
 * Used in some locations to configure how a text block is rendered.
 *
 * Currently only applicable for "terms", will be ignored for other fields
 */
export enum TextStyle {
  Bold = 'BOLD',
  Normal = 'NORMAL'
}

export type Transcript = {
  __typename?: 'Transcript';
  auditMetadata: AuditMetadata;
  completionDate: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  learningActivity: LearningActivity;
  transcriptStatus: TranscriptStatus;
  user: User;
};

export type TranscriptConnection = {
  __typename?: 'TranscriptConnection';
  nodes: Array<Transcript>;
  pageInfo?: Maybe<PageInfo>;
};

/**
 * Notes:
 * TransriptConnectionV1 used for Homecoming Discover phase
 * This status will be deprecated and replaced to the newer version in the next phase
 */
export type TranscriptConnectionV1 = {
  __typename?: 'TranscriptConnectionV1';
  nodes: Array<TranscriptV1>;
  pageInfo?: Maybe<PageInfo>;
};

export enum TranscriptStatus {
  Attended = 'ATTENDED',
  NoShow = 'NO_SHOW',
  PartiallyAttended = 'PARTIALLY_ATTENDED'
}

/**
 * Notes:
 * TransriptStatusV1 used for Homecoming Discover phase
 * This status will be deprecated and replaced to the newer version in the next phase
 *
 * Enum listing all the used enrollment status values in vendor
 */
export enum TranscriptStatusV1 {
  /** Course completed */
  Completed = 'COMPLETED',
  /** Registered and ready to begin */
  Enrolled = 'ENROLLED',
  /** Pending invitation acceptance for live sessions */
  EnrollmentToConfirm = 'ENROLLMENT_TO_CONFIRM',
  /** Learning in progress */
  InProgress = 'IN_PROGRESS',
  /** In overbooking status */
  Overbooking = 'OVERBOOKING',
  /** Course is no longer available (e.g. suspended subscription) */
  Suspended = 'SUSPENDED',
  /** Waiting for seat in a fixed capacity course */
  WaitingUsers = 'WAITING_USERS'
}

export type TranscriptV1 = {
  __typename?: 'TranscriptV1';
  auditMetadata: AuditMetadata;
  catalogItem?: Maybe<CatalogItem>;
  completedDate?: Maybe<Scalars['String']['output']>;
  coursesCompleted?: Maybe<Scalars['Int']['output']>;
  dateAssigned?: Maybe<Scalars['String']['output']>;
  enrollmentDate?: Maybe<Scalars['String']['output']>;
  firstAccessDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastAccessDate?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  status: TranscriptStatusV1;
  userVibeID?: Maybe<Scalars['String']['output']>;
};

export enum TypeOfContract {
  MidTermAddition = 'MID_TERM_ADDITION',
  New = 'NEW',
  Renewal = 'RENEWAL',
  Upgrade = 'UPGRADE'
}

export type UnarchiveFileMetadataInput = {
  id: Scalars['ID']['input'];
};

export type UnarchiveLearningContainerInput = {
  id: Scalars['ID']['input'];
};

export type UnassignLicenseFromUserInput = {
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UnknownPrincipal = {
  __typename?: 'UnknownPrincipal';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UpdateCatalogCustomAttributeSchemaInput = {
  key: Scalars['String']['input'];
  keyDisplayStrings: Array<CatalogLocalizedStringInput>;
  namespaceId: Scalars['String']['input'];
  selectOptions?: InputMaybe<Array<CatalogCustomAttributeSchemaSelectOptionInput>>;
};

export type UpdateCatalogGroupInput = {
  group: CatalogGroupInput;
  id: Scalars['ID']['input'];
};

export type UpdateCatalogItemInput = {
  id: Scalars['ID']['input'];
  item: CatalogItemInput;
  semanticVersion: SemanticVersionInput;
  status: CatalogItemStatus;
  updateToVersion: Scalars['Boolean']['input'];
};

export type UpdateCatalogItemVersionInput = {
  id: Scalars['ID']['input'];
  item: CatalogItemInput;
  semanticVersion: SemanticVersionInput;
  status: CatalogItemStatus;
};

export type UpdateCatalogNamespaceInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type UpdateClassroomInput = {
  capacity?: InputMaybe<CapacityInput>;
  clientRequestToken: Scalars['String']['input'];
  courseId?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  instructors?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  jamConfig?: InputMaybe<JamConfigInput>;
  learningActivityId?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationInput>;
  startTime?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type UpdateFileDataInput = {
  contentType?: InputMaybe<ContentType>;
  description: Scalars['String']['input'];
  file: UpdateFileInput;
  isAccessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  isApprovedForReuse?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCode;
  location?: InputMaybe<Scalars['String']['input']>;
  lxp?: InputMaybe<Lxp>;
  lxpAttributes?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
  permission?: InputMaybe<ContentPermissionInput>;
  productOwner?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  tempLocation?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateFileInput = {
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateFileMetadataInput = {
  data: UpdateFileDataInput;
  id: Scalars['ID']['input'];
  newS3Asset?: InputMaybe<Scalars['Boolean']['input']>;
  scopeChangeAndNotes?: InputMaybe<ScopeChangeAndNotesInput>;
};

export type UpdateGroupInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateIntegrationStepTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fieldDefinitions?: InputMaybe<Array<FieldDefinitionInput>>;
  id: Scalars['ID']['input'];
  lxp?: InputMaybe<Lxp>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLearningAccountInput = {
  awsAccountId: Scalars['String']['input'];
  awsAssociation?: InputMaybe<AwsAssociation>;
  billingAddress?: InputMaybe<AddressInput>;
  customerSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id: Scalars['ID']['input'];
  learningAccountVisibilityClassification?: InputMaybe<OrganizationVisibilityClassification>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['AWSPhone']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountName?: InputMaybe<Scalars['String']['input']>;
  skillBuilderBranchId?: InputMaybe<Scalars['String']['input']>;
  skillBuilderNumericBranchId?: InputMaybe<Scalars['Int']['input']>;
  status: LearningAccountStatus;
};

export type UpdateLearningContainerInput = {
  data: LearningContainerInput;
  id: Scalars['ID']['input'];
  scopeChangeAndNotes?: InputMaybe<ScopeChangeAndNotesInput>;
};

export type UpdateLicensePolicyInput = {
  policyId: Scalars['ID']['input'];
  policyStatus: LicensePolicyStatus;
};

export type UpdateLicenseToOrderItemAssociationInput = {
  licenseId: Scalars['ID']['input'];
  newOrderItemId: Scalars['ID']['input'];
  oldOrderItemId: Scalars['ID']['input'];
};

export type UpdateLxpAttributeTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<EntityType>;
  fieldDefinitions?: InputMaybe<Array<FieldDefinitionInput>>;
  id: Scalars['ID']['input'];
  lxp?: InputMaybe<Lxp>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationInput = {
  /**
   * The following attribute(s) are only allowed to be used by SERVICE to SERVICE interactions.
   * They are required for importing of external vendor attributes.
   */
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  awsAccountId: Scalars['String']['input'];
  awsAssociation?: InputMaybe<AwsAssociation>;
  billingAddress?: InputMaybe<AddressInput>;
  customerSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationType: OrganizationType;
  organizationVisibilityClassification?: InputMaybe<OrganizationVisibilityClassification>;
  phoneNumber?: InputMaybe<Scalars['AWSPhone']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountName?: InputMaybe<Scalars['String']['input']>;
  status: OrganizationStatus;
  vendorCode?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePublishingWorkflowInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  targetPublishDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UpdatePublishingWorkflowStepValuesInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  publishingWorkflowStepId: Scalars['ID']['input'];
  values: Array<KeyValuePairInput>;
};

export type UpdateTeamInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTranscriptInput = {
  completedDate?: InputMaybe<Scalars['String']['input']>;
  coursesCompleted?: InputMaybe<Scalars['Int']['input']>;
  enrollmentDate?: InputMaybe<Scalars['String']['input']>;
  firstAccessDate?: InputMaybe<Scalars['String']['input']>;
  lastAccessDate?: InputMaybe<Scalars['String']['input']>;
  lastUpdatedAt: Scalars['String']['input'];
  score?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TranscriptStatusV1>;
  vendorCatalogItemId: Scalars['Int']['input'];
  vendorCatalogType: Scalars['String']['input'];
  vendorUserId: Scalars['Int']['input'];
};

export type UpdateUserInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  emailAddress?: InputMaybe<Scalars['AWSEmail']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  is3PLearningAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  statusReason?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  userRole?: InputMaybe<UserRole>;
  /**
   * The following attributes are only allowed to be used by SERVICE to SERVICE interactions.
   * They are required for importing of external vendor attributes.
   */
  vendorId?: InputMaybe<Scalars['String']['input']>;
  vendorStatus?: InputMaybe<UserStatus>;
  vibeId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserLearningAccountAssociationInput = {
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  auditMetadata: AuditMetadata;
  emailAddress: Scalars['AWSEmail']['output'];
  emailAddressCaseSensitive: Scalars['AWSEmail']['output'];
  /** List of all Org/Team associations with user roles based on user access level */
  entityAssociations?: Maybe<UserEntityAssociationConnection>;
  firstName: Scalars['String']['output'];
  gandalfDetails?: Maybe<GandalfDetails>;
  /**
   * List of Groups a User belongs to.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  groups?: Maybe<GroupConnection>;
  id: Scalars['ID']['output'];
  is3PLearningAdmin: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  /** List of Licenses for this User. */
  licenses: LicenseConnection;
  /** Retrieves the open checkout sessions for a given user */
  openCheckoutSessions: CheckoutSessionConnection;
  /** List of orders for this User. */
  orders: OrderConnection;
  /**
   * List of Organizations a User belongs to.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  organizations?: Maybe<OrganizationConnection>;
  /** List of payments for User */
  payments?: Maybe<PaymentConnection>;
  status: UserStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
  userRole: UserRole;
  vendorId?: Maybe<Scalars['String']['output']>;
  vendorStatus?: Maybe<UserStatus>;
};


export type UserEntityAssociationsArgs = {
  associationStatus?: InputMaybe<UserAssociationStatus>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userAccess: UserAccess;
};


export type UserGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type UserLicensesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  licenseStatus?: InputMaybe<LicenseStatus>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type UserOpenCheckoutSessionsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type UserOrdersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type UserOrganizationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPaymentsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<PaymentsFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum UserAccess {
  All = 'ALL',
  Management = 'MANAGEMENT',
  Membership = 'MEMBERSHIP'
}

export type UserAssessment = {
  __typename?: 'UserAssessment';
  assessmentId: Scalars['ID']['output'];
  assessmentVersion?: Maybe<Scalars['Int']['output']>;
  completionMessages: Array<Maybe<AssessmentCompletionMessage>>;
  createdBy: Scalars['String']['output'];
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  displaySetting: Scalars['String']['output'];
  endTime?: Maybe<Scalars['AWSTimestamp']['output']>;
  finalScore?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isCategoryScoreEnabled: Scalars['Boolean']['output'];
  isCopyPasteEnabled: Scalars['Boolean']['output'];
  isCorrectAnswersShown: Scalars['String']['output'];
  isDetailedResultsEnabled: Scalars['String']['output'];
  isFinalScoreEnabled: Scalars['Boolean']['output'];
  isFlaggingEnabled: Scalars['Boolean']['output'];
  isNextBackEnabled: Scalars['Boolean']['output'];
  isNotesEnabled: Scalars['Boolean']['output'];
  isPausingEnabled: Scalars['Boolean']['output'];
  isSubmittedResponsesShown: Scalars['String']['output'];
  language: Scalars['String']['output'];
  maxAttempts: Scalars['Int']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedTimestamp: Scalars['AWSTimestamp']['output'];
  passingScore: Scalars['Float']['output'];
  pauseCount: Scalars['Int']['output'];
  pauseTime?: Maybe<Scalars['AWSTimestamp']['output']>;
  recordType: Scalars['String']['output'];
  scoreDisplay: Scalars['String']['output'];
  startTime?: Maybe<Scalars['AWSTimestamp']['output']>;
  status: Scalars['String']['output'];
  timeLimit: Scalars['Int']['output'];
  totalTimePaused: Scalars['Int']['output'];
  totalTimeSpent: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
  version: Scalars['Int']['output'];
  versionedQuestions: Array<Maybe<VersionedQuestion>>;
  waitTime: Scalars['Int']['output'];
};

export type UserAssessmentInput = {
  assessmentId: Scalars['ID']['input'];
  assessmentVersion: Scalars['Int']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  userId: Scalars['ID']['input'];
};

export type UserAssociatedEntity = Organization | Team;

export type UserAssociation = {
  __typename?: 'UserAssociation';
  associationStatus?: Maybe<UserAssociationStatus>;
  auditMetadata: AuditMetadata;
  parentResourceId: Scalars['String']['output'];
  parentResourceType: AssociationResourceType;
  user: User;
  userRoles?: Maybe<Array<UserRoleV2>>;
};

export type UserAssociationConnection = {
  __typename?: 'UserAssociationConnection';
  nodes: Array<UserAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export enum UserAssociationStatus {
  Active = 'ACTIVE',
  Invited = 'INVITED'
}

export type UserBulkOperationFileConnection = {
  __typename?: 'UserBulkOperationFileConnection';
  nodes: Array<BulkOperationFile>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes: Array<User>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserDetailsInput = {
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  userRole: UserRoleV2;
};

export type UserEntityAssociation = {
  __typename?: 'UserEntityAssociation';
  associationStatus?: Maybe<UserAssociationStatus>;
  parentResource: UserAssociatedEntity;
  userRoles?: Maybe<Array<UserRoleV2>>;
};

export type UserEntityAssociationConnection = {
  __typename?: 'UserEntityAssociationConnection';
  nodes: Array<UserEntityAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserInputWithFieldDefinition = {
  __typename?: 'UserInputWithFieldDefinition';
  canEdit: Scalars['Boolean']['output'];
  defaultValue?: Maybe<Scalars['String']['output']>;
  defaultValueArray?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  key: Scalars['String']['output'];
  lowerCharacterLimit?: Maybe<Scalars['Int']['output']>;
  nestedFieldDefinitions?: Maybe<Array<Maybe<FieldDefinition>>>;
  options?: Maybe<Array<Scalars['String']['output']>>;
  required: Scalars['Boolean']['output'];
  type: FieldType;
  upperCharacterLimit?: Maybe<Scalars['Int']['output']>;
  userInput: Scalars['AWSJSON']['output'];
};

export type UserInputWithFieldDefinitionInput = {
  canEdit: Scalars['Boolean']['input'];
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  defaultValueArray?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  key: Scalars['String']['input'];
  lowerCharacterLimit?: InputMaybe<Scalars['Int']['input']>;
  nestedFieldDefinitions?: InputMaybe<Array<InputMaybe<FieldDefinitionInput>>>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  required: Scalars['Boolean']['input'];
  type: FieldType;
  upperCharacterLimit?: InputMaybe<Scalars['Int']['input']>;
  userInput: Scalars['AWSJSON']['input'];
};

export type UserInputWithFieldDefinitionKey = {
  __typename?: 'UserInputWithFieldDefinitionKey';
  key: Scalars['String']['output'];
  userInput: Scalars['AWSJSON']['output'];
};

export type UserInputWithFieldDefinitionKeyInput = {
  key: Scalars['String']['input'];
  userInput: Scalars['AWSJSON']['input'];
};

export enum UserOperationStatus {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Succeeded = 'SUCCEEDED'
}

export enum UserRole {
  IltInstructor = 'ILT_INSTRUCTOR',
  InternalEmployee = 'INTERNAL_EMPLOYEE',
  Learner = 'LEARNER',
  LearningAccountItContact = 'LEARNING_ACCOUNT_IT_CONTACT',
  LearningAdmin = 'LEARNING_ADMIN',
  Service = 'SERVICE',
  SuperUser = 'SUPER_USER',
  TrainingOperations = 'TRAINING_OPERATIONS'
}

export enum UserRoleV2 {
  InternalEmployee = 'INTERNAL_EMPLOYEE',
  Learner = 'LEARNER',
  LearningAccountItContact = 'LEARNING_ACCOUNT_IT_CONTACT',
  LearningAdmin = 'LEARNING_ADMIN',
  Service = 'SERVICE',
  SuperUser = 'SUPER_USER'
}

export enum UserStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING'
}

export type VersionedQuestion = {
  __typename?: 'VersionedQuestion';
  id: Scalars['ID']['output'];
  version: Scalars['Int']['output'];
};

export enum VirtualLocationSource {
  Default = 'DEFAULT',
  Lads = 'LADS'
}

export type VoicePhones = {
  __typename?: 'VoicePhones';
  primaryPhone?: Maybe<Phone>;
};

export type VoicePhonesInput = {
  primaryPhone?: InputMaybe<PhoneInput>;
};

export type WorkflowStep = {
  __typename?: 'WorkflowStep';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  integrationStepId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: PublishingWorkflowStepStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
  stepSequenceNumber: Scalars['Int']['output'];
  type: WorkflowStepType;
  values: Array<UserInputWithFieldDefinition>;
};

export type WorkflowStepInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  integrationStepId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  stepSequenceNumber: Scalars['Int']['input'];
  type: WorkflowStepType;
  values: Array<UserInputWithFieldDefinitionInput>;
};

export type WorkflowStepTemplate = {
  __typename?: 'WorkflowStepTemplate';
  auditMetadata: AuditMetadata;
  description?: Maybe<Scalars['String']['output']>;
  fieldDefinitions: Array<FieldDefinition>;
  id?: Maybe<Scalars['ID']['output']>;
  lxp?: Maybe<Lxp>;
  name: Scalars['String']['output'];
  type: WorkflowStepType;
};

export enum WorkflowStepType {
  Approval = 'APPROVAL',
  CreateTicket = 'CREATE_TICKET',
  Integration = 'INTEGRATION',
  Manual = 'MANUAL'
}

export type WorkflowStepsTemplateConnection = {
  __typename?: 'WorkflowStepsTemplateConnection';
  nodes: Array<WorkflowStep>;
  pageInfo?: Maybe<PageInfo>;
};

export type CreateOutOfCycleBillInput = {
  agreementId: Scalars['String']['input'];
  billAccountId: Scalars['String']['input'];
  billDate: Scalars['AWSDateTime']['input'];
  customTaxAddress?: InputMaybe<CustomTaxAddressInput>;
  description: Scalars['String']['input'];
  lineItems: Array<InputMaybe<LineItemInput>>;
  purchaseOrder?: InputMaybe<PurchaseOrderInput>;
  serviceCode: Scalars['String']['input'];
  submittedBy?: InputMaybe<EmailProfileInput>;
};

export type CustomTaxAddress = {
  __typename?: 'customTaxAddress';
  attributes?: Maybe<Array<Maybe<Attribute>>>;
  contactPurposes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  emailAddresses?: Maybe<EmailProfile>;
  faxPhone?: Maybe<Phone>;
  label?: Maybe<Scalars['String']['output']>;
  mailingAddress: MailingAddress;
  name: Name;
  ownerCustomerId: Scalars['String']['output'];
  skipMailingAddressValidation?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  voicePhones?: Maybe<VoicePhones>;
};

export type CustomTaxAddressInput = {
  attributes?: InputMaybe<Array<InputMaybe<AttributeInput>>>;
  contactPurposes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  emailAddresses?: InputMaybe<EmailProfileInput>;
  faxPhone?: InputMaybe<PhoneInput>;
  label?: InputMaybe<Scalars['String']['input']>;
  mailingAddress: MailingAddressInput;
  name: NameInput;
  skipMailingAddressValidation?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
  voicePhones?: InputMaybe<VoicePhonesInput>;
};

export enum Join__Graph {
  Awstcassessmentsservice = 'AWSTCASSESSMENTSSERVICE',
  Awstcevaluationservice = 'AWSTCEVALUATIONSERVICE',
  Awstcrecommendations = 'AWSTCRECOMMENDATIONS',
  Awstcskillbuildersearch = 'AWSTCSKILLBUILDERSEARCH',
  Beakerclassroomsservice = 'BEAKERCLASSROOMSSERVICE',
  Catalogservice = 'CATALOGSERVICE',
  Cdkconstructsplaygroundservice = 'CDKCONSTRUCTSPLAYGROUNDSERVICE',
  Checkoutservice = 'CHECKOUTSERVICE',
  Commentservice = 'COMMENTSERVICE',
  Contentmanagementservice = 'CONTENTMANAGEMENTSERVICE',
  Contentpublishingworkflow = 'CONTENTPUBLISHINGWORKFLOW',
  Devdemoservice = 'DEVDEMOSERVICE',
  Fulfillmentservice = 'FULFILLMENTSERVICE',
  Helloworldservice = 'HELLOWORLDSERVICE',
  Holamundoservice = 'HOLAMUNDOSERVICE',
  Iltattendancemanagementservice = 'ILTATTENDANCEMANAGEMENTSERVICE',
  Iltlearningactivitydataservice = 'ILTLEARNINGACTIVITYDATASERVICE',
  Iltregistrationmanagementservice = 'ILTREGISTRATIONMANAGEMENTSERVICE',
  Ilttranscriptmanagementservice = 'ILTTRANSCRIPTMANAGEMENTSERVICE',
  Learningaccountservice = 'LEARNINGACCOUNTSERVICE',
  Licenseservice = 'LICENSESERVICE',
  Nonmeteredbillingservice = 'NONMETEREDBILLINGSERVICE',
  Notificationservice = 'NOTIFICATIONSERVICE',
  Offerservice = 'OFFERSERVICE',
  Orderservice = 'ORDERSERVICE',
  Padawanfulfillmentservice = 'PADAWANFULFILLMENTSERVICE',
  Paymentservice = 'PAYMENTSERVICE',
  Sfdcintegrationservice = 'SFDCINTEGRATIONSERVICE',
  Transcriptservice = 'TRANSCRIPTSERVICE',
  Userservice = 'USERSERVICE'
}

export type LearnerAttendanceInput = {
  attendanceDurationPercentage: Scalars['Int']['input'];
  learner: Scalars['ID']['input'];
};

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', emailAddress: any, emailAddressCaseSensitive: any, firstName: string, id: string } };

export type OrganizationBySfdcAccountIdQueryVariables = Exact<{
  sfdcAccountId: Scalars['String']['input'];
}>;


export type OrganizationBySfdcAccountIdQuery = { __typename?: 'Query', organizationBySfdcAccountId?: { __typename?: 'Organization', id: string, awsAccountId: string, name: string, sfdcParentAccountId?: string | null, status: OrganizationStatus, customerSince?: any | null, phoneNumber?: any | null, vendorResourceCode?: string | null, awsAssociation?: AwsAssociation | null, billingAddress?: { __typename?: 'Address', name: string, addressLineOne: string, addressLineTwo?: string | null, city: string, stateOrProvince?: string | null, countryCode: string, zipCode: string } | null, auditMetadata: { __typename?: 'AuditMetadata', createdBy: string, createdAt: any, lastUpdatedBy?: string | null, lastUpdatedAt?: any | null } } | null };

export type OrganizationByVendorCodeQueryVariables = Exact<{
  vendorCode: Scalars['String']['input'];
}>;


export type OrganizationByVendorCodeQuery = { __typename?: 'Query', organizationByVendorCode?: { __typename?: 'Organization', id: string, awsAccountId: string, name: string, sfdcParentAccountId?: string | null, status: OrganizationStatus, customerSince?: any | null, phoneNumber?: any | null, vendorResourceCode?: string | null, awsAssociation?: AwsAssociation | null, billingAddress?: { __typename?: 'Address', name: string, addressLineOne: string, addressLineTwo?: string | null, city: string, stateOrProvince?: string | null, countryCode: string, zipCode: string } | null, auditMetadata: { __typename?: 'AuditMetadata', createdBy: string, createdAt: any, lastUpdatedBy?: string | null, lastUpdatedAt?: any | null } } | null };


export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    emailAddress
    emailAddressCaseSensitive
    firstName
    id
  }
}
    `;
export const OrganizationBySfdcAccountIdDocument = gql`
    query OrganizationBySfdcAccountId($sfdcAccountId: String!) {
  organizationBySfdcAccountId(sfdcAccountId: $sfdcAccountId) {
    id
    awsAccountId
    name
    sfdcParentAccountId
    billingAddress {
      name
      addressLineOne
      addressLineTwo
      city
      stateOrProvince
      countryCode
      zipCode
    }
    auditMetadata {
      createdBy
      createdAt
      lastUpdatedBy
      lastUpdatedAt
    }
    status
    customerSince
    phoneNumber
    vendorResourceCode
    awsAssociation
  }
}
    `;
export const OrganizationByVendorCodeDocument = gql`
    query OrganizationByVendorCode($vendorCode: String!) {
  organizationByVendorCode(vendorCode: $vendorCode) {
    id
    awsAccountId
    name
    sfdcParentAccountId
    billingAddress {
      name
      addressLineOne
      addressLineTwo
      city
      stateOrProvince
      countryCode
      zipCode
    }
    auditMetadata {
      createdBy
      createdAt
      lastUpdatedBy
      lastUpdatedAt
    }
    status
    customerSince
    phoneNumber
    vendorResourceCode
    awsAssociation
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    currentUser(variables?: CurrentUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CurrentUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CurrentUserQuery>(CurrentUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'currentUser', 'query', variables);
    },
    OrganizationBySfdcAccountId(variables: OrganizationBySfdcAccountIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationBySfdcAccountIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationBySfdcAccountIdQuery>(OrganizationBySfdcAccountIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationBySfdcAccountId', 'query', variables);
    },
    OrganizationByVendorCode(variables: OrganizationByVendorCodeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationByVendorCodeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationByVendorCodeQuery>(OrganizationByVendorCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationByVendorCode', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;