import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetUserByIdentifier } from "../api/useGetUserByIdentifier";

export const useUserInfoCard = () => {
  const pathItems = useLocation().pathname.split("/");
  let currentUserIdentifier = pathItems.slice(-1)[0];

  //for dynamic breadcrumb generation
  if (currentUserIdentifier === "edit") {
    currentUserIdentifier = pathItems.slice(-2)[0];
  }

  const { loading, error, data } = useGetUserByIdentifier(currentUserIdentifier);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (data) {
      if (data.userById) {
        setFirstName(data.userById.firstName);
        setLastName(data.userById.lastName);
        setCreatedDate(data.userById.auditMetadata.createdAt);
        setUserStatus(data.userById.status);
        setEmailAddress(data.userById.emailAddress);
        setUserRole(data.userById.userRole);
        setUserId(data.userById.id);
      }

      if (data.userByEmail) {
        setFirstName(data.userByEmail.firstName);
        setLastName(data.userByEmail.lastName);
        setCreatedDate(data.userByEmail.auditMetadata.createdAt);
        setUserStatus(data.userByEmail.status);
        setEmailAddress(data.userByEmail.emailAddress);
        setUserRole(data.userByEmail.userRole);
        setUserId(data.userByEmail.id);
      }
    }
  }, [data]);

  return {
    userDetailLoading: loading,
    userDetailError: error,
    userDetailData: data,
    firstName,
    lastName,
    createdDate,
    userStatus,
    userRole,
    emailAddress,
    userId,
    createdBy,
  };
};
