import { SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useGetInternalUsers } from "../api/useInternalUserQuery";

export function useInternalUserTable() {
  const [filterText, setFilterText] = useState("");
  const [cursor, setCursor] = useState("");
  const { loading, error, data } = useGetInternalUsers(filterText, cursor);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [flashbarItems, setFlashbarItems] = useState([{}]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState("");
  const [pagination, setPagination] = useState(true);
  const intl = useIntl();
  const [search, setSearch] = useState("");
  const [cleared, setCleared] = useState(false);
  //cache map has a key of (filter + pageNumber) which maps to a cursor
  const [cache, setCache] = useState<Map<string, string>>(new Map([["" + 0, ""]]));

  //Make request to graphQl with updated filter request.
  const onFilterTextChange = (value: any) => {
    setFilterText(value);
    if (value === "" && value !== filterText) {
      setCleared(true);
    } else {
      setCleared(false);
    }
  };

  const setErrorFlashbar = async () => {
    setFlashbarItems([
      {
        type: "error",
        content: intl.formatMessage({ id: "usersTableComponent.flashbar.genericErrorMessage" }),
        dismissLabel: intl.formatMessage({ id: "internalUserTableComponent.flashbar.dismissLabel" }),
        dismissible: true,
        onDismiss: () => {
          setFlashbarItems([]);
        },
        statusIconAriaLabel: "error",
      },
    ]);
  };

  useEffect(() => {
    setPagination(true);
    setCache((map) => new Map(map.set(filterText + 0, "")));
    setCursor("");
    setTotalPages(1);
    setCurrentPageIndex(1);
  }, [filterText]);

  //handles edge case of clearing filter from the first page of a filter result
  useEffect(() => {
    //on clear
    if (cursor === "" && cleared) {
      setTotalPages(1);
      setCurrentPageIndex(1);
      setCleared(false);
    }
  }, [cursor, cleared]);

  //set error
  useEffect(() => {
    if (error) {
      setErrorFlashbar();
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      //handles intial loading of data on the first page
      if (totalPages === 1 && data.internalUsers.pageInfo.cursor !== "" && totalPages === currentPageIndex) {
        setCache((map) => new Map(map.set(filterText + currentPageIndex, data.internalUsers.pageInfo.cursor)));
        setTotalPages((prev) => prev + 1);
        setTotalCount("(" + data.internalUsers.nodes.length + "+)");
      } else if (data.internalUsers.pageInfo.cursor === "" && totalPages === currentPageIndex) {
        //exactly one page of data
        if (totalPages === 1) {
          setTotalCount("(" + data.internalUsers.nodes.length + ")");
        }
        //if no next page and on the last rendered page
        setPagination(false);
      } else if (cache.get(filterText + (currentPageIndex - 1)) && totalPages === currentPageIndex) {
        //there is next page and on last rendered page
        setCache((map) => new Map(map.set(filterText + currentPageIndex, data.internalUsers.pageInfo.cursor)));
        setTotalPages((prev) => prev + 1);
      }
    }
  }, [data, totalPages, currentPageIndex]);

  const onPaginationChange = (page: any) => {
    const cursorVal = cache.get(filterText + (page - 1));
    if (cursorVal || cursorVal === "") {
      // use cached values
      setCursor(cursorVal);
    }
    setCurrentPageIndex(page);
  };

  const onClearFilter = () => {
    onFilterTextChange("");
    setSearch("");
  };

  const handleSearch = (e: SetStateAction<string>) => setSearch(e);

  return {
    internalUsersLoading: loading,
    internalUsersError: error,
    internalUsersData: data,
    filterText,
    currentPageIndex,
    flashbarItems,
    totalPages,
    pagination,
    totalCount,
    search,
    handleSearch,
    onFilterTextChange,
    onClearFilter,
    onPaginationChange,
  };
}
