import TopNavigation, { TopNavigationProps } from "@amzn/awsui-components-react/polaris/top-navigation";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../../common/routes";
import { useAuth } from "../../hooks/useAuth";
import "./Header.scss";

export const Header = (): JSX.Element => {
  const intl = useIntl();
  const { user } = useAuth();
  const navigate = useNavigate();

  const navigationUtilities: TopNavigationProps.Utility[] = [
    {
      type: "button",
      text: user.email,
      iconName: "user-profile",
      iconAlt: intl.formatMessage({ id: "topNavigation.menuDropdown.iconAlt" }),
    },
  ];

  return (
    <TopNavigation
      id="h"
      className="header-wrapper"
      identity={{
        href: HOME_ROUTE,
        title: intl.formatMessage({ id: "serviceName" }),
        onFollow: (e: { preventDefault: () => void }) => {
          e.preventDefault();
          navigate(HOME_ROUTE);
        },
        logo: {
          src: "/public/aws-tc-logo.svg",
          alt: intl.formatMessage({ id: "awsTCLogo.alt" }),
        },
      }}
      utilities={navigationUtilities}
      i18nStrings={{
        overflowMenuTitleText: intl.formatMessage({ id: "topNavigation.overflowMenuTitleText" }),
        overflowMenuTriggerText: intl.formatMessage({ id: "topNavigation.overflowMenuTriggerText" }),
      }}
    />
  );
};
