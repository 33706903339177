import { gql, useMutation } from "@apollo/client";
import { CreateOrderInput } from "../types";

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrderMutation($input: CreateOrderInput!) {
    createOrder(input: $input) {
      id
      learningAccount {
        id
      }
    }
  }
`;

export const useCreateOrderMutation = (input: CreateOrderInput, callback?: any) => {
  return useMutation(CREATE_ORDER_MUTATION, {
    variables: { input },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
};
