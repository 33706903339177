import { gql, useLazyQuery } from "@apollo/client";

export const GET_USER_BULK_OPERATION_FILE_TEMPLATE = gql`
  query getUserBulkOperationFileTemplate {
    userBulkOperationFileTemplate {
      templateUrl
    }
  }
`;

export const useGetUserBulkOperationFileTemplate = () => {
  return useLazyQuery(GET_USER_BULK_OPERATION_FILE_TEMPLATE, {});
};
