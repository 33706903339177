import { Link, LinkProps } from "@amzn/awsui-components-react";
import { CancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events";
import { Fragment } from "react";
import { useIntl } from "react-intl";
import { useGetCurrentUser } from "../api/getCurrentUser";
import { useGetUserBulkOperationFileByIdLazy } from "../api/useGetBulkOperationFileById";
import { UserCreationStatus } from "./userImportsTable";

export type FileLogCellProps = {
  readonly fileId: string;
  readonly fileStatus: UserCreationStatus;
};

export const UserBulkOperationFileLogCell = ({ fileId, fileStatus }: FileLogCellProps): JSX.Element => {
  const intl = useIntl();
  const { data: currentUserData } = useGetCurrentUser();
  const [getUserBulkOperationFileById] = useGetUserBulkOperationFileByIdLazy(currentUserData?.currentUser?.id, fileId);

  const getExecutionLogReportUrlAndName = async () => {
    const { data } = await getUserBulkOperationFileById();
    const url = data.userBulkOperationFileById.executionLogReportUrl;
    const nameWithExtension = data.userBulkOperationFileById.fileMetadata.name;
    const name = nameWithExtension.replace(".csv", "");
    return { url, name };
  };

  const composeExecutionLogReportName = (fileName: string) => `${fileName}_UploadResults_${fileId}.csv`;

  const downloadExecutionLogReport = async (downloadUrl: string, fileName: string) => {
    const response = await fetch(downloadUrl);
    const blob = await response.blob();
    const downloadURL = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadURL;
    link.download = composeExecutionLogReportName(fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadExecutionLogReportOnFollow: CancelableEventHandler<LinkProps.FollowDetail> = async () => {
    try {
      const { url, name } = await getExecutionLogReportUrlAndName();
      await downloadExecutionLogReport(url, name);
    } catch (err: unknown) {
      const errorMessage = "Failed to download execution log report.";
      if (err instanceof Error) {
        console.error(`${errorMessage} ${err.message}`);
      } else {
        console.error(errorMessage);
      }
    }
  };

  return fileStatus !== UserCreationStatus.Processing ? (
    <Link
      onFollow={downloadExecutionLogReportOnFollow}
      ariaLabel={intl.formatMessage({ id: "importUsersComponent.download" })}
    >
      {intl.formatMessage({ id: "importUsersComponent.download" })}
    </Link>
  ) : (
    <Fragment>-</Fragment>
  );
};
