import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import { useIntl } from "react-intl";
import "./Footer.scss";
import Box from "@amzn/awsui-components-react/polaris/box";
import { PRIVACY_PAGE_LINK, TERMS_PAGE_LINK } from "../../common/constants";
import CookieConsentManager from "../../cookieManagement/CookieConsentManager";

const currentYear = new Date().getFullYear().toString();

export function Footer(): JSX.Element {
  const intl = useIntl();
  const cookieConsentMananger = new CookieConsentManager();

  return (
    <div className="footer-wrapper" id="footer">
      <footer className="footer">
        <nav>
          <TextContent className="right-align">
            <div>
              <SpaceBetween direction="horizontal" size="l">
                <div className="footer__actions">
                  {intl.formatMessage({ id: "footer.copyright" }, { year: currentYear })}
                </div>
                <div className="footer__actions">
                  <Box>
                    <button
                      className="link-button"
                      data-testid="privacy_link"
                      onClick={() => {
                        window.open(PRIVACY_PAGE_LINK);
                      }}
                    >
                      {intl.formatMessage({ id: "footer.privacy" })}
                    </button>
                  </Box>
                </div>
                <div className="footer__actions">
                  <Box>
                    <button
                      className="link-button"
                      data-testid="terms_link"
                      onClick={() => {
                        window.open(TERMS_PAGE_LINK);
                      }}
                    >
                      {intl.formatMessage({ id: "footer.terms" })}
                    </button>
                  </Box>
                </div>
                <div className="footer__actions">
                  <Box>
                    <button
                      className="link-button"
                      data-testid="cookie_preferences"
                      onClick={cookieConsentMananger.customizeCookies}
                    >
                      {intl.formatMessage({ id: "footer.cookiePreferences" })}
                    </button>
                  </Box>
                </div>
              </SpaceBetween>
            </div>
          </TextContent>
        </nav>
      </footer>
    </div>
  );
}
