export type CreateInternalUserInput = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  userRole: string;
};

export type UpdateUserInput = {
  userId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userRole: string;
  status: string;
};

export type CreateExternalUserInput = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  userRole: string;
  status: string;
};

export type AddLearningAccountUserInput = {
  learningAccountId: string;
  userId: string;
};

// TODO - This Enum should be imported from PlatformCommon package vs. delcared again here.
export enum RoleType {
  SERVICE = "SERVICE",
  SUPER_USER = "SUPER_USER",
  INTERNAL_EMPLOYEE = "INTERNAL_EMPLOYEE",
  LEARNING_ADMIN = "LEARNING_ADMIN",
  POST_AUTH_PRE_VIAS = "POST_AUTH_PRE_VIAS",
  LEARNING_ACCOUNT_IT_CONTACT = "LEARNING_ACCOUNT_IT_CONTACT",
  LEARNER = "LEARNER",
  ILT_INSTRUCTOR = "ILT_INSTRUCTOR",
  TRAINING_OPERATIONS = "TRAINING_OPERATIONS",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  DEACTIVATED = "DEACTIVATED",
}

export type RemoveUserFromOrganizationInput = {
  organizationId: string;
  userId: string;
};

export enum LearningAccountUserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
