import {
  Button,
  Container,
  ContentLayout,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  RadioGroup,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { AppHeader } from "../../components";
import { Role } from "../constants";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useCreateExternalUser } from "./useCreateExternalUser";

export const CreateExternalUser = (props: any): JSX.Element => {
  const currentLearningAccountId = props.learningAccountId;

  const intl = useIntl();
  const navigate = useNavigate();

  const {
    createExternalUserLoading,
    createExternalUserError,
    firstname,
    lastname,
    email,
    role,
    firstNameError,
    lastNameError,
    emailError,
    flashbarItems,
    firstNameRef,
    lastNameRef,
    emailRef,
    onEmailChange,
    onFirstNameChange,
    onLastNameChange,
    onRoleChange,
    handleSubmit,
  } = useCreateExternalUser(currentLearningAccountId);

  return (
    <ContentLayout
      data-testid="header-name"
      header={<AppHeader title={intl.formatMessage({ id: "userComponent.header.create" })} />}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <SpaceBetween direction="horizontal" size="l"></SpaceBetween>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid="cancel-button"
                formAction="none"
                variant="link"
                onClick={() => navigate(props.cancel)}
              >
                {intl.formatMessage({ id: "userComponent.cancel" })}
              </Button>
              <Button
                data-testid="save-user-button"
                variant="primary"
                loading={createExternalUserLoading}
                onClick={handleSubmit}
              >
                {intl.formatMessage({ id: `userComponent.submit.create` })}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween data-testid="master-form" direction="vertical" size="l">
            <Container
              header={
                <Header data-testid="container-title" variant="h2">
                  {intl.formatMessage({ id: "userComponent.container.header" })}
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField
                  aria-label="First name input"
                  data-testid="first-name-input"
                  label={intl.formatMessage({ id: "userComponent.textfield.firstname" })}
                  errorText={firstNameError}
                  i18nStrings={{ errorIconAriaLabel: "error" }}
                >
                  <Input
                    ref={firstNameRef}
                    className="addUserInput"
                    placeholder={intl.formatMessage({ id: "userComponent.textfield.firstname" })}
                    value={firstname}
                    onChange={(event: { detail: { value: string } }) => onFirstNameChange(event.detail.value)}
                  />
                </FormField>
                <FormField
                  aria-label="Last name input"
                  data-testid="last-name-input"
                  label={intl.formatMessage({ id: "userComponent.textfield.lastname" })}
                  errorText={lastNameError}
                  i18nStrings={{ errorIconAriaLabel: "error" }}
                >
                  <Input
                    ref={lastNameRef}
                    className="addUserInput"
                    placeholder={intl.formatMessage({ id: "userComponent.textfield.lastname" })}
                    value={lastname}
                    onChange={(event: { detail: { value: string } }) => onLastNameChange(event.detail.value)}
                  />
                </FormField>
                <FormField
                  aria-label="Email input"
                  data-testid="email-input"
                  label={intl.formatMessage({ id: "userComponent.textfield.emailaddress" })}
                  errorText={emailError}
                  i18nStrings={{ errorIconAriaLabel: "error" }}
                >
                  <Input
                    ref={emailRef}
                    className="addUserInput"
                    placeholder={intl.formatMessage({ id: "userComponent.textfield.emailaddress" })}
                    value={email}
                    onChange={(event: { detail: { value: string } }) => onEmailChange(event.detail.value)}
                  />
                </FormField>
                <FormField data-testid="role-input" label={intl.formatMessage({ id: "userComponent.radio.role" })}>
                  <RadioGroup
                    role="radio"
                    aria-label="User role input"
                    data-testid="role-radio-group"
                    //onChange={(event: { detail: { value: string } }) => onRoleChange(event.detail.value)} Will be used when Learning Admin is added to the list
                    value={role}
                    items={props.roles.map((userRoleType: any) => ({
                      value: Object.keys(Role)[Object.values(Role).indexOf(userRoleType)],
                      label: userRoleType,
                    }))}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
            {createExternalUserError ? (
              <Flashbar aria-live="polite" data-testid="flashbar" items={flashbarItems} />
            ) : null}
          </SpaceBetween>
        </Form>
      </form>
    </ContentLayout>
  );
};
