import { gql, useMutation } from "@apollo/client";
import { CreateOrganizationInput } from "../../../__generated__/supergraph";

export const CREATE_ORGANIZATION_MUTATION = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      awsAccountId
      name
      sfdcParentAccountId
    }
  }
`;

export const useCreateOrganizationMutation = (input: CreateOrganizationInput, callback?: any) => {
  return useMutation(CREATE_ORGANIZATION_MUTATION, {
    variables: { input },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
};
