import { gql, useMutation } from "@apollo/client";
import { RemoveUserFromOrganizationInput } from "../types";

export const REMOVE_USER_FROM_ORGANIZATION = gql`
  mutation removeUserFromOrganization($organizationId: String!, $userId: String!) {
    removeUserFromOrganization(organizationId: $organizationId, userId: $userId)
  }
`;

export const useRemoveUserFromOrganizationMutation = (input: RemoveUserFromOrganizationInput) =>
  useMutation(REMOVE_USER_FROM_ORGANIZATION, {
    variables: { ...input },
    onError: () => {
      return new Error("Error in removeUserFromOrganization");
    },
  });
