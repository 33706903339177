import {
  B2BBillingStatus,
  BillingPeriod,
  Order,
  ProductOnboardingAuthenticationMethod,
  ProductOnboardingIdentityProvider,
} from "./types";
import Table from "@amzn/awsui-components-react/polaris/table";
import styled from "styled-components";
import { ProductModality, TypeOfContract } from "../onboarding/types";

export const fulfillmentStatusMap: Map<string, string> = new Map<string, string>([
  ["COMPLETED", "Fulfillment Completed"],
  ["STARTED", "Fulfillment Started"],
  ["IN_PROGRESS", "Fulfillment In Progress"],
  ["CANCELED", "Fulfillment Canceled"],
]);

export const ORDER: Order = {
  id: "123456",
  learningAccount: {
    id: "4885668c-8625-498d-a439-37ff41fdb8a2",
    name: "Test Company",
  },
  opportunityId: "12345",
  status: "PENDING",
  typeOfContract: null,
  billingAwsAccountId: undefined,
  auditMetadata: {
    createdAt: "2022-12-30T18:40:17.533Z",
    createdBy: "alias",
    lastUpdatedAt: "2022-12-30T18:40:17.533Z",
    lastUpdatedBy: "alias",
  },
  items: [
    {
      id: "",
      quantity: 5,
      productTitle: "",
      productOnboardingDetails: {
        organizationName: "Org Name",
        learningAdmins: [],
        emailDomains: ["amazon@amazon.com"],
        identityProvider: ProductOnboardingIdentityProvider.ADFS,
        authenticationMethod: ProductOnboardingAuthenticationMethod.APN,
        ssoContact: {
          firstName: "FirstName",
          lastName: "LastName",
          emailAddress: "email@mazon.com",
        },
      },
      unitPrice: 10,
      subTotal: 30,
      ssoEnabled: true,
      billingPeriod: BillingPeriod.MONTHLY,
      classStartDate: "2023-06-01T08:00:00.000Z",
      classEndDate: "2024-05-31T08:00:00.000Z",
      subscriptionType: TypeOfContract.NEW,
      productModality: ProductModality.DIGITAL,
      metadata: [{ key: "key", value: "value" }],
      contractingRequestCreator: {
        email: "e@gamil.com",
        firstName: "Jane",
        lastName: "Johns",
        phone: undefined,
      },
    },
  ],
};

export const ORDER1: Order = {
  id: "123456",
  learningAccount: {
    id: "4885668c-8625-498d-a439-37ff41fdb8a2",
    name: "Test Company",
  },
  opportunityId: "12345",
  status: "PENDING",
  typeOfContract: "MID_TERM_ADDITION",
  billingAwsAccountId: "12345678",
  auditMetadata: {
    createdAt: "2022-12-30T18:40:17.533Z",
    createdBy: "alias",
    lastUpdatedAt: "2022-12-30T18:40:17.533Z",
    lastUpdatedBy: "alias",
  },
  items: [
    {
      id: "",
      quantity: 1,
      productTitle: "",
      productOnboardingDetails: {
        organizationName: "Org Name",
        learningAdmins: [],
        emailDomains: ["amazon@amazon.com"],
        identityProvider: ProductOnboardingIdentityProvider.ADFS,
        authenticationMethod: undefined,
        ssoContact: {
          firstName: "FirstName",
          lastName: "LastName",
          emailAddress: "email@mazon.com",
        },
      },
      unitPrice: 10,
      subTotal: 30,
      ssoEnabled: true,
      billingPeriod: BillingPeriod.MONTHLY,
      classStartDate: undefined as unknown as string,
      classEndDate: undefined as unknown as string,
      contractingRequestCreator: {
        email: "e@gamil.com",
        firstName: "Jane",
        lastName: "Johns",
        phone: undefined,
      },
    },
  ],
};

export const ORDER2 = {
  id: "123456",
  learningAccount: {
    id: "4885668c-8625-498d-a439-37ff41fdb8a2",
    name: "Test Company",
  },
  opportunityId: "12345",
  status: "PENDING",
  typeOfContract: "MID_TERM_ADDITION",
  billingAwsAccountId: "12345678",
  auditMetadata: {
    createdAt: "2022-12-30T18:40:17.533Z",
    createdBy: "alias",
    lastUpdatedAt: "2022-12-30T18:40:17.533Z",
    lastUpdatedBy: "alias",
  },
  items: [
    {
      id: "",
      quantity: 1,
      productTitle: "AWS Skill Builder",
      productOnboardingDetails: {
        organizationName: "Org Name",
        learningAdmins: [],
        emailDomains: ["amazon@amazon.com"],
        identityProvider: ProductOnboardingIdentityProvider.ADFS,
        authenticationMethod: undefined,
        ssoContact: {
          firstName: "FirstName",
          lastName: "LastName",
          emailAddress: "email@mazon.com",
        },
      },
      unitPrice: 10,
      subTotal: 30,
      ssoEnabled: true,
      billingPeriod: BillingPeriod.MONTHLY,
      classStartDate: undefined as unknown as string,
      classEndDate: undefined as unknown as string,
      contractingRequestCreator: {
        email: "e@gamil.com",
        firstName: "Jane",
        lastName: "Johns",
        phone: undefined,
      },
      metadata: [
        { key: "awsEndUserAccount", value: '"123"' },
        { key: "poNumberOnInvoice", value: '"testPoNumber"' },
      ],
      nmbsBillingDetails: {
        billingStatus: B2BBillingStatus.BILLED,
        billingStatusUpdatedAt: "2022-12-30T18:40:17.533Z",
        invoiceId: "12345",
        billingConsoleUILink: "https://www.amazon.com",
        billingErrorMessage: "Failed to submit to NMBS",
      },
    },
  ],
};

export const LADetailsPageTable = styled(Table)`
  * > th:nth-child(1) {
    width: 8% !important;
  }
  * > th:nth-child(2) {
    width: 8% !important;
  }
  * > th:nth-child(3) {
    width: 8% !important;
  }
  * > th:nth-child(4) {
    width: 8% !important;
  }
  * > th:nth-child(5) {
    width: 8% !important;
  }
`;
