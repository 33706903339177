import { RoleType } from "../user/types";

export interface ApplicationUserInitProps {
  email?: string;
  isLoggedIn?: boolean;
  role?: RoleType;
}
export class ApplicationUser {
  readonly email?: string;
  readonly isLoggedIn?: boolean;
  readonly role?: RoleType;

  constructor(initProps: ApplicationUserInitProps) {
    this.email = initProps.email;
    this.isLoggedIn = initProps.isLoggedIn;
    this.role = initProps.role;
  }
}
