import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useAuth } from "../../hooks/useAuth";
import { RoleType } from "../../user/types";

export const GET_LEARNING_ACCOUNTS = gql`
  query GetLearningAccounts($search: String, $cursor: String, $pageSize: Int) {
    learningAccounts(search: $search, cursor: $cursor, pageSize: $pageSize) {
      nodes {
        id
        awsAccountId
        name
      }
      pageInfo {
        cursor
        size
      }
    }
  }
`;

export const useGetLearningAccountsQuery = (filterText: string, cursor: string, pageSize: number | undefined) => {
  const { user } = useAuth();
  return useQuery(GET_LEARNING_ACCOUNTS, {
    variables: { search: filterText, cursor: cursor, pageSize: pageSize },
    skip: user.role === RoleType.POST_AUTH_PRE_VIAS,
  });
};

export const useLazyGetLearningAccountsQuery = (filterText: string, cursor: string, pageSize: number | undefined) => {
  return useLazyQuery(GET_LEARNING_ACCOUNTS, { variables: { search: filterText, cursor: cursor, pageSize: pageSize } });
};
