import { Box, ColumnLayout, Container, Header } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";

export function OrganizationOverviewCard(): JSX.Element {
  const intl = useIntl();

  const ValueWithLabel = (props: { label: any; children: any }) => (
    <div style={{ marginTop: "10px" }}>
      <Box variant="awsui-key-label">{props.label}</Box>
      <div>{props.children}</div>
    </div>
  );

  return (
    <div style={{ height: "185px", width: "180%" }} data-testid={"organization-overview-card"}>
      <Container
        fitHeight
        header={
          <Header variant="h2" description={"The organization name"}>
            {intl.formatMessage({ id: "orgOverviewCardComponent.header.overview" })}
          </Header>
        }
      >
        <ColumnLayout minColumnWidth={100} columns={4} variant="text-grid">
          <ValueWithLabel label={intl.formatMessage({ id: "orgOverviewCardComponent.classes" })}>
            <Box fontWeight="normal" fontSize="display-l" color="text-status-info">
              {"50"}
            </Box>
          </ValueWithLabel>
          <ValueWithLabel label={intl.formatMessage({ id: "orgOverviewCardComponent.pending" })}>
            <Box fontSize="display-l" fontWeight="normal" color="text-status-info">
              {"3"}
            </Box>
          </ValueWithLabel>
          <ValueWithLabel label={intl.formatMessage({ id: "orgOverviewCardComponent.completed" })}>
            <Box fontSize="display-l" fontWeight="normal" color="text-status-info">
              {"3"}
            </Box>
          </ValueWithLabel>
          <ValueWithLabel label={intl.formatMessage({ id: "orgOverviewCardComponent.totalLearners" })}>
            <Box fontSize="display-l" fontWeight="normal" color="text-status-info">
              {"150"}
            </Box>
          </ValueWithLabel>
        </ColumnLayout>
      </Container>
    </div>
  );
}
