import { AppLayout, Button, ContentLayout, Flashbar, FlashbarProps, Header } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Breadcrumbs, Navigation } from "../../components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { UserInfoCard } from "../../user/components/UserInfoCard";
import { useUserInfoCard } from "../../user/components/useUserInfoCard";
import { BadRequest } from "../../pages";
import { LoadingPage } from "../../components";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { showError } from "../../common/utilityFunctions";

const StyledFlashbar = styled(Flashbar)`
  padding-top: 8px;
`;

function UserDetailContent(): JSX.Element {
  const { userDetailData, userStatus, firstName, lastName, emailAddress, userRole, userId } = useUserInfoCard();
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <ContentLayout
      header={
        <Header
          variant="awsui-h1-sticky"
          actions={
            <Button
              variant="primary"
              data-testid="appHeader-button-edit"
              onClick={() =>
                navigate(`/users/${userId}/edit/`, {
                  state: {
                    userId: userId,
                    firstName: firstName,
                    lastName: lastName,
                    emailAddress: emailAddress,
                    userRole: userRole,
                    userStatus: userStatus,
                  },
                })
              }
            >
              {intl.formatMessage({ id: "learningAccountsUserDetailPage.buttons.edit" })}
            </Button>
          }
        >
          {userDetailData && firstName ? `${firstName} ${lastName}` : ""}
        </Header>
      }
    >
      <UserInfoCard />
    </ContentLayout>
  );
}

export function UserDetail(): JSX.Element {
  const intl = useIntl();
  const { userDetailLoading, userDetailError } = useUserInfoCard();
  const { state } = useLocation();

  const [items, setItems] = useState([
    {
      type: "success",
      content: state
        ? intl.formatMessage({ id: `userComponent.flashbar.successMessage.${state.action.toLowerCase()}` })
        : "",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setItems([]),
      statusIconAriaLabel: "success",
    },
  ] as FlashbarProps.MessageDefinition[]);

  return showError(userDetailError) ? (
    <BadRequest />
  ) : (
    <AppLayout
      content={
        userDetailLoading ? (
          <LoadingPage text={intl.formatMessage({ id: "loadingPage.loadingText.loadingDetails" })} />
        ) : (
          <UserDetailContent />
        )
      }
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      notifications={state && <StyledFlashbar items={items} />}
      breadcrumbs={<Breadcrumbs />}
      navigation={<Navigation />}
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
      toolsHide
    />
  );
}
