import { gql, useMutation } from "@apollo/client";
import { CreateInternalUserInput, UpdateUserInput } from "../types";

export const CREATE_INTERNAL_USER = gql`
  mutation MyMutation($input: CreateInternalUserInput!) {
    createInternalUser(input: $input) {
      id
      emailAddress
      firstName
      lastName
    }
  }
`;

export const UPDATE_USER = gql`
  mutation MyMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      emailAddress
      status
      statusReason
      firstName
      lastName
      userRole
    }
  }
`;

export const useMutateInternalUser = (input: CreateInternalUserInput | UpdateUserInput, callback?: any) =>
  useMutation("userId" in input ? UPDATE_USER : CREATE_INTERNAL_USER, {
    variables: { input },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
