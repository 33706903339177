import { useCollection } from "@amzn/awsui-collection-hooks";
import { Button, Link, PropertyFilter, StatusIndicator } from "@amzn/awsui-components-react";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Table from "@amzn/awsui-components-react/polaris/table";
import { Fragment } from "react";
import { useIntl } from "react-intl";
import { IMPORT_USERS_ROUTE, LEARNING_ACCOUNTS_ROUTE } from "../../../common/routes";
import { AppHeader, TableNoMatchState } from "../../../components";
import { TableEmptyState } from "../../../components/tableEmptyState/TableEmptyState";
import { UserBulkOperationFileLogCell } from "../UserBulkOperationFileLogCell";
import { PAGE_SIZE, USER_CREATION_STATUS_TO_INDICATOR_TYPE, UserBulkOperationFileExtended } from "./constants";
import { useUserImportsTable } from "./useUserImportsTable";

export const UserImportsTable = (): JSX.Element => {
  const intl = useIntl();

  const { files, loading, refetch, propertyFilterProperties, propertyFilterOptions, propertyFilterI18nStrings } =
    useUserImportsTable();
  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    files,
    {
      propertyFiltering: {
        filteringProperties: propertyFilterProperties,
        empty: <TableEmptyState emptyMessageId="userImportsTableComponent.empty" />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: "and" });
            }}
          />
        ),
      },
      pagination: { pageSize: PAGE_SIZE },
      sorting: { defaultState: { sortingColumn: { sortingField: "createdAt" }, isDescending: true } },
    }
  );

  return (
    <Table
      {...collectionProps}
      ariaLabels={{ tableLabel: intl.formatMessage({ id: "userImportsPage.header" }) }}
      data-testid="user-imports-table"
      loadingText={intl.formatMessage({ id: "userImportsTableComponent.loading" })}
      loading={loading}
      columnDefinitions={[
        {
          id: "File name",
          header: intl.formatMessage({ id: "userImportsTableComponent.header.fileName" }),
          cell: (file: UserBulkOperationFileExtended) => <Fragment>{file.fileName}</Fragment>,
          minWidth: 200,
          sortingField: "fileName",
        },
        {
          id: "Time imported",
          header: intl.formatMessage({ id: "userImportsTableComponent.header.timeImported" }),
          cell: (file: UserBulkOperationFileExtended) => file.timeImportedText,
          minWidth: 250,
          sortingField: "createdAt",
        },
        {
          id: "Status text",
          header: intl.formatMessage({ id: "userImportsTableComponent.header.status" }),
          cell: (file: UserBulkOperationFileExtended) => (
            <StatusIndicator type={USER_CREATION_STATUS_TO_INDICATOR_TYPE[file.userCreationStatus]}>
              {file.statusText}
            </StatusIndicator>
          ),
          minWidth: 200,
          sortingField: "statusText",
        },
        {
          id: "Learning account",
          header: intl.formatMessage({ id: "userImportsTableComponent.header.learningAccount" }),
          cell: (file: UserBulkOperationFileExtended) => (
            <Link href={`${LEARNING_ACCOUNTS_ROUTE}/${file.learningAccount.id}`}>{file.learningAccountName}</Link>
          ),
          minWidth: 200,
          sortingField: "learningAccountName",
        },
        {
          id: "Log",
          header: intl.formatMessage({ id: "userImportsTableComponent.header.log" }),
          cell: (file: UserBulkOperationFileExtended) => (
            <UserBulkOperationFileLogCell fileStatus={file.userCreationStatus} fileId={file.fileId} />
          ),
          minWidth: 200,
        },
      ]}
      items={items}
      wrapLines={false}
      resizableColumns={true}
      variant="full-page"
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          filteringOptions={propertyFilterOptions}
          i18nStrings={propertyFilterI18nStrings}
          filteringPlaceholder={intl.formatMessage({ id: "userImportsTableComponent.searchbar" })}
          filteringAriaLabel={intl.formatMessage({ id: "userImportsTableComponent.searchbar" })}
          expandToViewport={true}
          tokenLimit={3}
        />
      }
      stickyHeader={true}
      header={
        <div>
          <AppHeader
            title={intl.formatMessage({ id: "userImportsPage.header" })}
            counterValue={`(${
              filteredItemsCount ? (filteredItemsCount > PAGE_SIZE ? `${PAGE_SIZE}+` : filteredItemsCount) : 0
            })`}
            buttonText={intl.formatMessage({ id: "userImportsTableComponent.header.button" })}
            buttonAriaLabel={intl.formatMessage({ id: "userImportsTableComponent.header.button" })}
            navigateTo={IMPORT_USERS_ROUTE}
          />
        </div>
      }
      pagination={
        <Pagination
          ariaLabels={{
            nextPageLabel: intl.formatMessage({ id: "userImportsTableComponent.pagination.nextPage" }),
            previousPageLabel: intl.formatMessage({ id: "userImportsTableComponent.pagination.previousPage" }),
            pageLabel: (pageNumber: number) =>
              intl.formatMessage({ id: "userImportsTableComponent.pagination.pageNumber" }, { pageNumber }),
          }}
          {...paginationProps}
        />
      }
      preferences={
        <Button
          data-testid="refresh-button"
          ariaLabel={intl.formatMessage({ id: "userImportsTableComponent.refresh" })}
          iconName="refresh"
          variant="icon"
          onClick={() => refetch()}
        />
      }
    />
  );
};
