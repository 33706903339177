import { gql, useMutation } from "@apollo/client";
import { CreateExternalUserInput } from "../types";

export const CREATE_EXTERNAL_USER = gql`
  mutation MyMutation($input: CreateExternalUserInput!) {
    createExternalUser(input: $input) {
      id
    }
  }
`;

export const useCreateExternalUserMutation = (input: CreateExternalUserInput) =>
  useMutation(CREATE_EXTERNAL_USER, {
    variables: { input },
    onError: () => {
      return new Error("Error in createExternalUser");
    },
  });
