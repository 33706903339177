import { gql, useMutation } from "@apollo/client";
import { UpdateOrganizationInput } from "../../../__generated__/supergraph";

export const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      awsAccountId
      name
      sfdcParentAccountId
      billingAddress {
        name
        addressLineOne
        addressLineTwo
        city
        stateOrProvince
        countryCode
        zipCode
      }
      status
      customerSince
      phoneNumber
      vendorResourceCode
      awsAssociation
    }
  }
`;

export const useUpdateOrganizationMutation = (input: UpdateOrganizationInput, callback?: any) => {
  return useMutation(UPDATE_ORGANIZATION_MUTATION, {
    variables: { input },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
};
