import { Box, ColumnLayout, Container, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { useUserInfoCard } from "./useUserInfoCard";
import { useIntl } from "react-intl";
import { RENDER_USER_INFO_FIELDS_FOR_EXTERNAL_USERS } from "../../common/constants";
import { useLAUserInfoCard } from "./useLAUserInfoCard";

type UserInfoCardProps = {
  userType?: string;
};

export function UserInfoCard(userProps?: UserInfoCardProps): JSX.Element {
  let query;
  const externalUserView = userProps && userProps.userType === RENDER_USER_INFO_FIELDS_FOR_EXTERNAL_USERS;
  if (externalUserView) {
    query = useLAUserInfoCard;
  } else {
    query = useUserInfoCard;
  }
  const { userDetailData, userStatus, emailAddress, userRole, createdDate, createdBy } = query();

  const intl = useIntl();

  const getRoleTypeString = (roleType: string) => {
    switch (roleType) {
      case "SERVICE":
        return intl.formatMessage({ id: "learningAccountsUserDetailPage.roles.service" });
      case "SUPER_USER":
        return intl.formatMessage({ id: "learningAccountsUserDetailPage.roles.superUser" });
      case "INTERNAL_EMPLOYEE":
        return intl.formatMessage({ id: "learningAccountsUserDetailPage.roles.internalEmployee" });
      case "LEARNING_ADMIN":
        return intl.formatMessage({ id: "learningAccountsUserDetailPage.roles.learningAdministrator" });
      case "LEARNER":
        return intl.formatMessage({ id: "learningAccountsUserDetailPage.roles.learner" });
    }
  };

  const getStatusString = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return intl.formatMessage({ id: "learningAccountsUserDetailPage.status.active" });
      case "PENDING":
        return intl.formatMessage({ id: "learningAccountsUserDetailPage.status.pending" });
      case "DEACTIVATED":
        return intl.formatMessage({ id: "learningAccountsUserDetailPage.status.deactivated" });
    }
  };

  const parseDate = (rawDate: string) => {
    const months: string[] = [
      intl.formatMessage({ id: "dates.month.january" }),
      intl.formatMessage({ id: "dates.month.february" }),
      intl.formatMessage({ id: "dates.month.march" }),
      intl.formatMessage({ id: "dates.month.april" }),
      intl.formatMessage({ id: "dates.month.may" }),
      intl.formatMessage({ id: "dates.month.june" }),
      intl.formatMessage({ id: "dates.month.july" }),
      intl.formatMessage({ id: "dates.month.august" }),
      intl.formatMessage({ id: "dates.month.september" }),
      intl.formatMessage({ id: "dates.month.october" }),
      intl.formatMessage({ id: "dates.month.november" }),
      intl.formatMessage({ id: "dates.month.december" }),
    ];

    const date = new Date(rawDate);
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const ValueWithLabel = (props: { label: any; children: any }) => (
    <div style={{ marginTop: "10px" }}>
      <Box variant="awsui-key-label">{props.label}</Box>
      <div>{props.children}</div>
    </div>
  );

  return (
    <Container
      header={<Header variant="h2">{intl.formatMessage({ id: "learningAccountsUserDetailPage.details" })}</Header>}
    >
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label={intl.formatMessage({ id: "learningAccountsUserDetailPage.headings.role" })}>
            {userDetailData && getRoleTypeString(userRole)}
          </ValueWithLabel>
          <ValueWithLabel label={intl.formatMessage({ id: "learningAccountsUserDetailPage.headings.addedOn" })}>
            {userDetailData && parseDate(createdDate)}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label={intl.formatMessage({ id: "learningAccountsUserDetailPage.headings.emailAddress" })}>
            {userDetailData && emailAddress}
          </ValueWithLabel>
          {externalUserView ? (
            <ValueWithLabel label={intl.formatMessage({ id: "learningAccountsUserDetailPage.headings.addedBy" })}>
              {userDetailData && createdBy}
            </ValueWithLabel>
          ) : (
            <ValueWithLabel label={intl.formatMessage({ id: "learningAccountsUserDetailPage.headings.status" })}>
              {userDetailData && getStatusString(userStatus)}
            </ValueWithLabel>
          )}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
