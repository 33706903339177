import { AwsRumConfig } from "aws-rum-web";
import { getNodeEnvironment, NodeEnvironment } from "../common/nodeEnvironment";

// Add the values from your personal Isengard account after deploying AwsTcPlatformFrontendCDK
export const DEV_ALIAS = "";
export const DEV_RUM_GUEST_ROLE_ARN = "";
export const DEV_RUM_IDENTITY_POOL_ID = "";
export const DEV_RUM_APP_ID = "";

// Constant RUM values for all stages
export const RUM_APP_VERSION = "1.0.0";
export const RUM_APP_REGION = "us-east-1";
export const RUM_APP_ENDPOINT = "https://dataplane.rum.us-east-1.amazonaws.com";
export const RUM_APP_TELEMETRIES = ["errors", "http", "performance"];

export interface RumStageProps {
  appId: string;
  rumStageConfig: AwsRumConfig;
}

export const DEV_RUM: RumStageProps = {
  appId: DEV_RUM_APP_ID,
  rumStageConfig: {
    guestRoleArn: DEV_RUM_GUEST_ROLE_ARN,
    identityPoolId: DEV_RUM_IDENTITY_POOL_ID,
    sessionSampleRate: 0.5,
  },
};

export const BETA_RUM: RumStageProps = {
  appId: "a9060720-ef38-4f07-bbe8-9d76de23b733",
  rumStageConfig: {
    guestRoleArn: "arn:aws:iam::675425585011:role/SkillBuilderRumGuestRole",
    identityPoolId: "us-east-1:deb0b483-6ae0-46aa-8796-c30e4e94fe8f",
    sessionSampleRate: 0.5,
  },
};

export const GAMMA_RUM: RumStageProps = {
  appId: "0bda4997-a93a-45cd-96cf-6bbc08536bc2",
  rumStageConfig: {
    guestRoleArn: "arn:aws:iam::930070862788:role/SkillBuilderRumGuestRole",
    identityPoolId: "us-east-1:f0f9e04e-dbb9-4678-879e-10d50c1595ce",
    sessionSampleRate: 0.5,
  },
};

export const PROD_RUM: RumStageProps = {
  appId: "f382249b-cfce-4261-978e-bb70524f6dbc",
  rumStageConfig: {
    guestRoleArn: "arn:aws:iam::819418918833:role/SkillBuilderRumGuestRole",
    identityPoolId: "us-east-1:86cd0322-898b-415f-a15c-d2b991765011",
    sessionSampleRate: 0.5,
  },
};

export function getCurrentRumStage(): RumStageProps {
  const env = getNodeEnvironment();
  switch (env) {
    case NodeEnvironment.BETA:
      return BETA_RUM;
    case NodeEnvironment.GAMMA:
      return GAMMA_RUM;
    case NodeEnvironment.PROD:
      return PROD_RUM;
    default:
      return DEV_RUM;
  }
}
