import { useLocation } from "react-router-dom";
import { useGetLearningAccountDetailsQuery } from "../api/useGetLearningAccountDetailsQuery";

export const useLearningAccountInfoCard = () => {
  const currentLearningAccountId = useLocation().pathname.split("/")[2];
  const { loading, error, data } = useGetLearningAccountDetailsQuery(currentLearningAccountId);

  return {
    learningAccountDetailLoading: loading,
    learningAccountDetailError: error,
    learningAccountDetailData: data,
  };
};
