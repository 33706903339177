import { Box, Spinner } from "@amzn/awsui-components-react";
import styled from "styled-components";

type LoadingPageProps = {
  text: string;
};

const StyledBox = styled(Box)`
  margin: 0;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledSpinner = styled(Spinner)`
  margin-right: 5px;
`;

export function LoadingPage(props: LoadingPageProps): JSX.Element {
  return (
    <StyledBox fontWeight="light">
      <StyledSpinner variant="disabled" />
      {props.text}
    </StyledBox>
  );
}
