import { Box, Header, SpaceBetween, Link, AppLayout } from "@amzn/awsui-components-react";
import { FormattedMessage, useIntl } from "react-intl";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { HOME_ROUTE } from "../../common/routes";
import "../styles.scss";

function RequestTimeoutContent(): JSX.Element {
  const intl = useIntl();

  return (
    <Box className="error-center">
      <SpaceBetween size="m">
        <Box>
          <img src="/public/error-page.svg" alt={intl.formatMessage({ id: "errorPage.img.alt" })} />
          <Header variant="h1"> {intl.formatMessage({ id: "errorPage.requestTimeout.header" })} </Header>
          <p>{intl.formatMessage({ id: "errorPage.requestTimeout.message" })}</p>
        </Box>
        <br />
        <Box>
          <p>
            <FormattedMessage
              id="errorPage.startOver"
              values={{
                return_link: (chunks) => (
                  <Link href={HOME_ROUTE} target="_blank">
                    {chunks}
                  </Link>
                ),
              }}
            />
          </p>
        </Box>
      </SpaceBetween>
    </Box>
  );
}

export function RequestTimeout(): JSX.Element {
  const intl = useIntl();
  return (
    <AppLayout
      content={<RequestTimeoutContent />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      toolsHide
      navigationHide
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
    />
  );
}
