import { Box, ColumnLayout, Container, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";

const ValueWithLabel = (props: { label: any; children: any }) => (
  <div style={{ marginTop: "10px" }}>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

interface NewOrderProps {
  customer: string;
  orderId: string;
  salesforceOpportunityId: string;
  typeOfContract?: string;
  accountId: string;
}

export function ReviewAndSubmitDetailsCard(props: NewOrderProps): JSX.Element {
  const intl = useIntl();
  return (
    <div>
      <Container
        data-testid="review-and-submit-details-card"
        header={<Header variant="h2">{intl.formatMessage({ id: "reviewAndSubmit.orderDetails" })}</Header>}
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.customerName" })}>
              <div data-testid={"customer"}>{props.customer}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.salesforceOpportunityId" })}>
              <div data-testid={"salesforceOpportunityId"}>{props.salesforceOpportunityId}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.orderId" })}>
              <div data-testid={"order-id"}>{props.orderId}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.billingAwsAccountId" })}>
              <div data-testid={"aws-billing-accountId"}>{props.accountId}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.typeOfContract" })}>
              <div data-testid={"typeOfContract"}>{props.typeOfContract ? props.typeOfContract : "NEW"}</div>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </div>
  );
}
