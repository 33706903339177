import { Container, FormField, Header, Input, SpaceBetween } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";

export const ImportOpportunityForm = (props: any): JSX.Element => {
  const intl = useIntl();
  return (
    <Container
      header={
        <Header data-testid="container-title" variant="h2">
          {intl.formatMessage({ id: "getOpportunityComponent.searchBar.header" })}
        </Header>
      }
    >
      <SpaceBetween data-testid="main-form" direction="vertical" size="l">
        <SpaceBetween direction="vertical" size="l">
          <FormField
            i18nStrings={{
              errorIconAriaLabel: intl.formatMessage({ id: "getOpportunityComponent.searchBar.ariaLabel.explanation" }),
            }}
            aria-label="opportunity id input"
            data-testid="opportunity-id-input-field"
            description={intl.formatMessage({ id: "getOpportunityComponent.searchBar.explanation" })}
            errorText={props.validationError}
          >
            <Input
              onChange={(event: { detail: { value: string } }) => props.onInputChange(event.detail.value)}
              ariaLabel={intl.formatMessage({ id: "getOpportunityComponent.searchBar.ariaLabel.explanation" })}
              className="getOpportunityIdInput"
              data-testid="opportunity-id-input"
              placeholder={intl.formatMessage({ id: "getOpportunityComponent.searchBar.exampleId" })}
              value={props.fieldText}
            />
          </FormField>
        </SpaceBetween>
      </SpaceBetween>
    </Container>
  );
};
