import { gql, useLazyQuery } from "@apollo/client";
import { isGetOpportunityV2Enabled } from "../../common/featureFlags";

export const GET_OPPORTUNITY = gql`
  query GetOpportunityById($opportunityId: String!) {
    getSalesforceOpportunityById(opportunityId: $opportunityId) {
      opportunityId
      opportunityName
      sfdcAccountId
      sfdcAccountName
      sfdcParentAccountId
      sfdcParentAccountName
      sfdcParentOpportunityId
      grandTotal
      awsAccountInfo {
        accountId
        awsAssociation
        name
        status
        billingAddress {
          name
          addressLineOne
          addressLineTwo
          city
          phoneNumber
          stateOrProvince
          countryCode
          zipCode
        }
      }
      lmsId
      bdm {
        firstName
        lastName
        email
        phone
      }
      typeOfContract
      noOfExistingLicenses
      items {
        opportunityLineItemId
        productName
        quantity
        classStartDate
        classEndDate
        totalBilledRevenue
        discountAmount
        totalPrice
        billingPeriod
        subscriptionType
        trainingDeliveryType
        primaryTrainingPOC {
          firstName
          lastName
          email
          phone
        }
        secondaryTrainingPOC {
          firstName
          lastName
          email
          phone
        }
        allowedEmailDomains
        authenticationMethod
        ssoDetails {
          ssoEnabled
          idp
          technicalPOC {
            firstName
            lastName
            email
            phone
          }
        }
        contractingRequestCreatedBy {
          email
          firstName
          lastName
          phone
        }
        timeZone
        deliveryLanguage
        classLocation
        currencyCode
      }
    }
  }
`;

export const GET_OPPORTUNITY_HC_ADMIN = gql`
  query GetOpportunityById($opportunityId: String!) {
    getSalesforceOpportunityById(opportunityId: $opportunityId) {
      opportunityId
      opportunityName
      sfdcAccountId
      sfdcAccountName
      sfdcParentAccountId
      sfdcParentAccountName
      sfdcParentOpportunityId
      grandTotal
      awsAccountInfo {
        accountId
        awsAssociation
        name
        status
        billingAddress {
          name
          addressLineOne
          addressLineTwo
          city
          phoneNumber
          stateOrProvince
          countryCode
          zipCode
        }
      }
      lmsId
      bdm {
        firstName
        lastName
        email
        phone
      }
      typeOfContract
      noOfExistingLicenses
      items {
        opportunityLineItemId
        productName
        quantity
        classStartDate
        classEndDate
        totalBilledRevenue
        discountAmount
        totalPrice
        billingPeriod
        subscriptionType
        trainingDeliveryType
        primaryTrainingPOC {
          firstName
          lastName
          email
          phone
        }
        secondaryTrainingPOC {
          firstName
          lastName
          email
          phone
        }
        allowedEmailDomains
        authenticationMethod
        ssoDetails {
          ssoEnabled
          idp
          technicalPOC {
            firstName
            lastName
            email
            phone
          }
        }
        contractingPTRR {
          accountName
          address1
          address2
          awsBillingAccount
          awsEndUserAccount
          billingNotes
          billingTerms
          city
          country
          customerTaxVATId
          legalEntityNameTraining
          poNumberOnInvoice
          ptfTemplateName
          state
          trainingBillingContactEmail
          trainingBillingContactName
          zipCode
        }
        contractingRequestCreatedBy {
          email
          firstName
          lastName
          phone
        }
        timeZone
        deliveryLanguage
        classLocation
        currencyCode
      }
    }
  }
`;

export const useGetOpportunityInformationQuery = () => {
  const query = isGetOpportunityV2Enabled() ? GET_OPPORTUNITY_HC_ADMIN : GET_OPPORTUNITY;
  return useLazyQuery(query, { fetchPolicy: "no-cache" });
};
